import { Editable } from "@/ts/objects/editable/Editable";
import { flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { DisplayableErr } from "@/ts/objects/Err";

export abstract class EditableObject implements Editable {
  protected abstract allEditables(): Editable[];

  async saveAllChanges(force: boolean): Promise<SaveResult> {
    const results = await Promise.all(this.allEditables().map(v => v.saveAllChanges(force)));
    return flattenSaveResults(results);
  }

  needSave(): boolean {
    return this.allEditables().some(v => v.needSave());
  }

  currentErrors(): DisplayableErr[] {
    return this.allEditables().flatMap(v => v.currentErrors());
  }

  hasError(): boolean {
    return this.allEditables().some(v => v.hasError());
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"curriculum-list-nec-student-row-container",style:({
    '--student-col-width': (_vm.studentColWidth + "px"),
    '--no-content-rows-width': (_vm.noContentRowsWidth + "px"),
  })},[_c('div',{staticClass:"student-cell"},[_c('div',{staticClass:"student-info"},[_c('div',{staticClass:"student-number"},[_vm._v(_vm._s(_vm.words.studentNumberAbbr.d)+" "+_vm._s(_vm.model.studentNumber))]),_c('div',{staticClass:"student-name"},[_vm._v(_vm._s(_vm.model.studentName))])]),_c('RouterLinkWrapper',{staticClass:"student-view-link",attrs:{"to":_vm.studentViewPath}},[_vm._v(_vm._s(_vm.words.studentView.d)),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'external-link-alt']}})],1)],1),(_vm.model.contentRows.length === 0)?_c('div',{staticClass:"no-content-rows"},[_vm._v(_vm._s(_vm.messages.noCurriculumContent))]):_c('ol',{staticClass:"content-rows"},_vm._l((_vm.model.contentRows),function(contentRowModel){return _c('CurriculumListNECContentRow',{key:((contentRowModel.viewPointId) + ":" + (contentRowModel.contentId)),attrs:{"model":contentRowModel,"month-filter":_vm.monthFilter,"view-point-col-width":_vm.viewPointColWidth,"content-col-width":_vm.contentColWidth,"evaluation-col-width":_vm.evaluationColWidth,"on-input":function (month, rating) { return _vm.onInput(contentRowModel.viewPointId, contentRowModel.contentId, month, _vm.model.studentUserId, rating); },"on-change-single-publish-state":function (month, published) { return _vm.onChangeSinglePublishState(
            contentRowModel.viewPointId,
            contentRowModel.contentId,
            month,
            _vm.model.studentUserId,
            published
          ); }}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./UploadSyllabusFileButton.vue?vue&type=template&id=fdc6d122&scoped=true&"
import script from "./UploadSyllabusFileButton.vue?vue&type=script&lang=ts&"
export * from "./UploadSyllabusFileButton.vue?vue&type=script&lang=ts&"
import style0 from "./UploadSyllabusFileButton.vue?vue&type=style&index=0&id=fdc6d122&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc6d122",
  null
  
)

export default component.exports
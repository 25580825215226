







import Vue from "vue";
import OpenSyllabusFileButton from "@/views/curriculum/components/OpenSyllabusFileButton/OpenSyllabusFileButton.vue";
import CurriculumTitle from "@/views/curriculum/components/CurriculumTitle/CurriculumTitle.vue";

export default Vue.extend({
  name: "CurriculumReadMainHeader",
  components: { CurriculumTitle, OpenSyllabusFileButton },
  props: {
    // TODO curriculum icon

    curriculumName: { type: String, required: true },
    blurBottom: { type: Boolean, required: true },

    /**
     * シラバスファイルの、GCSパス。
     */
    syllabusFileGcsObjectPath: { type: String },
  },
});

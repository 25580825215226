import { Color } from "@/ts/objects/common/Color";
import { EditableNECEvaluation } from "@/ts/objects/curriculum/editable/EditableNECEvaluation";
import { INECEvaluation } from "@/ts/objects/curriculum/INECEvaluation";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { ClassStudent } from "@/ts/objects/common/Class";
import { isNullish } from "@/ts/utils";
import groupBy from "lodash/groupBy";
import { MonthValue, monthValues } from "@/ts/objects/common/MonthValue";

export type CurriculumListNECModel = {
  readonly necId: string;
  readonly necName: string;
  readonly syllabusFileGcsObjectPath: string | null;

  readonly allEnabledContentMonthRnames: string[];

  readonly studentRowFilterCheckboxItems: { key: string; label: string }[];
  readonly viewPointRowFilterCheckboxItems: { key: string; label: string }[];

  readonly studentRows: readonly CurriculumListNECStudentRowModel[];
};

export type CurriculumListNECStudentRowModel = {
  readonly studentUserId: string;
  readonly studentNumber: number;
  readonly studentName: string;

  readonly contentRows: readonly CurriculumListNECContentRowModel[];
};

export type CurriculumListNECContentRowModel = {
  readonly viewPointId: string;
  readonly viewPointName: string;
  readonly viewPointColor: Color;
  readonly contentId: string;
  readonly contentName: string;
  readonly enabledMonths: MonthValue[];

  /**
   * 評価セルのモデル。
   * 必ず12個あり、4月～3月の順に並んでいる。
   */
  readonly evaluationCells: CurriculumListNECEvaluationCellModel[];
};

export type CurriculumListNECEvaluationCellModel = {
  readonly evaluation: EditableNECEvaluation;

  /**
   * 内容構成月テキスト。
   */
  readonly contentMonthText: string;

  /**
   * 内容構成月自体が有効化されているかどうか。
   * (評価が公開済かどうかとは無関係。)
   */
  readonly contentMonthEnabled: boolean;
};

export interface ICurriculumListNECModel {
  readonly necId: string;
  readonly necName: string;
  readonly syllabusFileGcsObjectPath: string | null;

  // readonly allViewPoints: { readonly viewPointId: string; readonly viewPointName: string }[];
  readonly allEnabledContentMonthRnames: string[];

  readonly studentRowFilterCheckboxItems: { key: string; label: string }[];
  readonly viewPointRowFilterCheckboxItems: { key: string; label: string }[];

  readonly studentRows: readonly ICurriculumListNECStudentRowModel[];
}

export interface ICurriculumListNECStudentRowModel {
  readonly studentUserId: string;
  readonly studentNumber: number;
  readonly studentName: string;

  readonly contentRows: readonly ICurriculumListNECContentRowModel[];
}

export interface ICurriculumListNECContentRowModel {
  readonly viewPointId: string;
  readonly viewPointName: string;
  readonly viewPointColor: Color;
  readonly contentId: string;
  readonly contentName: string;
  readonly enabledMonths: readonly MonthValue[];

  /**
   * 評価セルのモデル。
   * 必ず12個あり、4月～3月の順に並んでいる。
   */
  readonly evaluationCells: ICurriculumListNECEvaluationCellModel[];
}

export interface ICurriculumListNECEvaluationCellModel {
  readonly evaluation: INECEvaluation;

  /**
   * 内容構成月テキスト。
   */
  readonly contentMonthText: string;

  /**
   * 内容構成月自体が有効化されているかどうか。
   * (評価が公開済かどうかとは無関係。)
   */
  readonly contentMonthEnabled: boolean;
}

export function dataToCurriculumListNECModel(
  neCurriculumTree: NECurriculumTree,
  students: ClassStudent[],
  evaluations: EditableNECEvaluation[]
): CurriculumListNECModel {
  const allContentMonths = neCurriculumTree.viewPoints.flatMap(vp => vp.contents).flatMap(c => c.months);
  const contentMonthDict = Object.fromEntries(allContentMonths.map(m => [m.self.resourceName, m]));
  const evaluationsDict = Object.fromEntries(evaluations.map(ev => [ev.resourceName, ev]));

  return {
    necId: neCurriculumTree.self.necId,
    necName: neCurriculumTree.self.name,
    syllabusFileGcsObjectPath: neCurriculumTree.self.syllabusFileGcsObjectPath,

    allEnabledContentMonthRnames: allContentMonths.filter(m => m.self.enabled).map(m => m.self.resourceName),

    studentRowFilterCheckboxItems: students.map(s => ({
      key: s.studentUserId,
      label: `${s.studentNumber}番 ${s.name}`,
    })),
    viewPointRowFilterCheckboxItems: neCurriculumTree.viewPoints.map(vp => ({
      key: vp.self.viewPointId,
      label: vp.self.name,
    })),

    studentRows: students.map(student => {
      const studentUserId = student.studentUserId;
      return {
        studentUserId,
        studentNumber: student.studentNumber,
        studentName: student.name,

        contentRows: neCurriculumTree.viewPoints.flatMap(viewPoint => {
          const viewPointId = viewPoint.self.viewPointId;
          const viewPointName = viewPoint.self.name;
          const viewPointColor = viewPoint.self.color;
          return viewPoint.contents.map(content => ({
            viewPointId,
            viewPointName,
            viewPointColor,
            contentId: content.self.contentId,
            contentName: content.self.name,
            enabledMonths: content.enabledMonths,

            evaluationCells: monthValues.map(month => {
              const contentMonthRname = `${content.self.resourceName}/months/${month}`;
              const contentMonth = contentMonthDict[contentMonthRname];
              if (isNullish(contentMonth))
                throw new Error(`dataToCurriculumListNECModel: contentMonth ${contentMonthRname} not found`);

              const evaluationRname = `${contentMonthRname}/evaluations/${studentUserId}`;
              const evaluation = evaluationsDict[evaluationRname];
              if (isNullish(evaluation))
                throw new Error(`dataToCurriculumListNECModel: evaluation ${evaluationRname} not found`);

              return {
                evaluation,
                contentMonthText: contentMonth.self.text,
                contentMonthEnabled: contentMonth.self.enabled,
              };
            }),
          }));
        }),
      };
    }),
  };
}

export type CurriculumListNECMonthStateVM = {
  readonly month: MonthValue;
  readonly publishState: CurriculumListNECMonthPublishState;
};

export type CurriculumListNECStudentRowFilterState = {
  readonly studentUserIds: string[];
};

export type CurriculumListNECContentRowFilterState = {
  readonly viewPointIds: string[];
  readonly contentSearchText: string; // TODO なんか検索ライブラリでも使う？

  /**
   * ここで指定した月すべてで、内容構成月が有効化されている行だけに絞り込む。
   */
  readonly shouldBeEnabledOn: MonthValue[];
};

export type CurriculumListNECMonthFilterState = {
  readonly months: readonly MonthValue[];
};

export const curriculumListNECMonthPublishStateValues = ["none", "partial", "all"] as const;
/**
 * 月ごとの公開状態。
 *
 * "none": その月の全行が非公開。
 * "partial": その月の一部の行が公開済、一部の行が非公開。
 * "all": その月の全行が公開済。
 */
export type CurriculumListNECMonthPublishState = typeof curriculumListNECMonthPublishStateValues[number];

/**
 * データからビューモデルを作成する。
 *
 * @param evaluationsDict 評価のdictionary。他のnecIdの評価を含んでいないことが必須。
 * @param enabledContentMonths 有効化されている内容構成月のリソース名一覧。
 */
export function dataToCurriculumListNECMonthStateVMs(
  evaluationsDict: Record<string, EditableNECEvaluation> | null,
  enabledContentMonths: string[]
): CurriculumListNECMonthStateVM[] | null {
  if (evaluationsDict === null) return null;
  const evaluationsData = Object.values(evaluationsDict);
  return Object.entries(groupBy(evaluationsData, "month")).map(
    ([monthString, evaluationsOfMonth]): CurriculumListNECMonthStateVM => {
      const month = parseInt(monthString, 10) as MonthValue;
      const filteredEvaluations = evaluationsOfMonth.filter(ev =>
        enabledContentMonths.includes(ev.contentMonthResourceName)
      );

      if (filteredEvaluations.length >= 1 && filteredEvaluations.every(ev => ev.teacherInputPublished)) {
        // log.debug(`dataToCurriculumListNECMonthStateVMs: month=${month}, state=all`);
        return { month, publishState: "all" };
      } else if (filteredEvaluations.some(ev => ev.teacherInputPublished)) {
        // log.debug(`dataToCurriculumListNECMonthStateVMs: month=${month}, state=partial`);
        return { month, publishState: "partial" };
      } else {
        // log.debug(`dataToCurriculumListNECMonthStateVMs: month=${month}, state=none`);
        return { month, publishState: "none" };
      }
    }
  );
}














import Vue, { PropType } from "vue";
import { MessageViewParam } from "@/components/MessageView.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { messages } from "@/ts/const/Messages";
import { ProjectStore } from "@/store/ProjectStore";
import { Err } from "@/ts/objects/Err";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { AppStateStore } from "@/store/AppStateStore";
import firebase from "firebase/app";
import "firebase/storage";
import { projectJournalRnameToId } from "@/ts/utils/AppUtil";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import ProjectJournalsPure from "@/views/project/student/ProjectJournals/ProjectJournalsPure.vue";
import { ProjectJournalFile } from "@/ts/objects/project/value/ProjectJournalFile";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";
import { JournalFile } from "@/ts/objects/common/JournalFile";

export default Vue.extend({
  name: "ProjectJournals",
  components: {
    ProjectJournalsPure,
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true },
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.storage = this.$storage ?? null;
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
    });
    this.reloadData();
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    log.debug("beforeRouteUpdate!");
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      this.reloadData();
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    log.debug("beforeRouteLeave!");
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;
    storage: firebase.storage.Storage | null;

    journals: EditableProjectJournal[] | null;

    filesViewJournal: string | null;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,
      storage: null,

      journals: null,

      filesViewJournal: null,

      pageLeaveService: null,
    };
  },
  computed: {
    needSave(): boolean {
      const journals = this.journals;
      if (journals === null) return false;
      return journals.some(j => j.needSave());
    },
  },
  methods: {
    async reloadData() {
      this.journals = null;
      this.messageView = null;

      const studentUserId = this.appStateStore.studentOrGuardianState?.studentUserId() ?? null;
      if (studentUserId === null) {
        this.messageView = { message: messages.pleaseSelectStudent };
        return;
      }

      const project = await this.projectStore.project.getDataWithTimeout();
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }

      if (!project.published) {
        // 児童生徒画面なので、非公開プロジェクトがここに来るはずはない、が、念のため。
        this.messageView = { message: messages.projectUnpublished };
        return;
      }

      const resp = await this.projectRepository.listEditableProjectJournals(
        project.projectId,
        studentUserId,
        false,
        true
      );
      if (resp instanceof Err) {
        log.error("Error loading project or journals!");
        this.messageView = { message: messages.failedToLoadData };
        return;
      }

      this.journals = resp.editableJournals;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      const journals = this.journals;
      if (journals === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(journals.map(j => j.saveAllChanges(force))));
    },
    setFilesView(journalRname: string | null) {
      this.filesViewJournal = journalRname;
    },
    async uploadJournalFile(file: File, journal: EditableProjectJournal) {
      const ids = projectJournalRnameToId(journal.self);
      if (ids === null) return;
      const [projectId, rubricId, journalId] = ids;
      const resp = await this.projectRepository.postJournalFile(projectId, rubricId, journalId, file, 30000);
      log.debug(`uploadFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    async deleteJournalFile(journalFile: JournalFile, journal: EditableProjectJournal): Promise<void> {
      if (!(journalFile instanceof ProjectJournalFile)) return;

      const ids = projectJournalRnameToId(journalFile.journal);
      if (ids === null) {
        log.error(`invalid journal resourceName: ${journalFile.journal}`);
        return;
      }
      const [projectId, rubricId, journalId] = ids;
      const resp = await this.projectRepository.deleteJournalFile(
        projectId,
        rubricId,
        journalId,
        journalFile.journalFileId
      );
      log.debug(`deleteFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
  },
});

import { CheckFailed } from "@/ts/app/Error";
import { words } from "@/ts/const/Words";

export const monthValues = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3] as const;
export type MonthValue = typeof monthValues[number];

export function isMonthValue(value: any): value is MonthValue {
  return typeof value === "number" && monthValues.some(v => v === value);
}

export function monthValueToEnglishAbbr(monthValue: MonthValue): string {
  switch (monthValue) {
    case 1:
      return words.month01Abbr.d;
    case 2:
      return words.month02Abbr.d;
    case 3:
      return words.month03Abbr.d;
    case 4:
      return words.month04Abbr.d;
    case 5:
      return words.month05Abbr.d;
    case 6:
      return words.month06Abbr.d;
    case 7:
      return words.month07Abbr.d;
    case 8:
      return words.month08Abbr.d;
    case 9:
      return words.month09Abbr.d;
    case 10:
      return words.month10Abbr.d;
    case 11:
      return words.month11Abbr.d;
    case 12:
      return words.month12Abbr.d;
    default:
      throw new CheckFailed();
  }
}

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store/Store";
import { Project } from "@/ts/objects/project/value/Project";
import { Err } from "@/ts/objects/Err";
import { Quarter } from "@/ts/objects/common/Quarter";
import { appStateStoreGlobal } from "@/store/AppStateStore";
import { DataSlot, DataSlotProjectionView } from "@/ts/DataSlot";
import log from "loglevel";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

/**
 * プロジェクト学習画面で選択できるすべてのプロジェクトと、その選択状態のストア。
 *
 * [教師が使う場合]
 * ホーム画面で選択したクラスの全プロジェクトを持つ。
 *
 * [児童生徒が使う場合]
 * 自分の(過去分含めた)全クラスの全プロジェクトを持つ。
 * ただし、非公開プロジェクトはそもそも取得できない。
 *
 * [保護者が使う場合]
 * 選択中の児童生徒の(過去分含めた)全クラスの全プロジェクトを持つ。
 * ただし、非公開プロジェクトはそもそも取得できない。
 */
@Module({ dynamic: true, store, name: "projectStore", namespaced: true })
export class ProjectStore extends VuexModule {
  quarter: Quarter | null = null;
  projectId: string | null = null;
  allProjects: DataSlot<Project[]> = new DataSlot<Project[]>();

  get project(): DataSlotProjectionView<Project[], Project | null> {
    const quarter = this.quarter;
    const projectId = this.projectId;

    return new DataSlotProjectionView(this.allProjects, allProjects => {
      if (quarter === null || projectId === null || allProjects === null) return null;
      const project = allProjects.find(
        p =>
          p.quarter.schoolYear === quarter.schoolYear &&
          p.quarter.quarter === quarter.quarter &&
          p.projectId === projectId
      );
      if (project === undefined) return null;
      return project;
    });
  }

  get projectsOfQuarter(): DataSlotProjectionView<Project[], Project[]> {
    const quarter = this.quarter;

    return new DataSlotProjectionView(this.allProjects, allProjects => {
      if (quarter === null) return [];
      return allProjects.filter(
        p => p.quarter.schoolYear === quarter.schoolYear && p.quarter.quarter === quarter.quarter
      );
    });
  }

  get teacherInitPath(): string {
    // const selectedQuarter = this.quarter;
    // if (selectedQuarter !== null) {
    //   return `${appStateStoreGlobal.teacherBasePath}/project/${selectedQuarter.schoolYear}/${selectedQuarter.quarter}/init`;
    // }

    const currentQuarter = appStateStoreGlobal.currentQuarter;
    const classSchoolYear = appStateStoreGlobal.teacherState?.selectedClass()?.schoolYear;
    if (classSchoolYear !== undefined) {
      const quarter = currentQuarter.schoolYear === classSchoolYear ? currentQuarter.quarter : 1;
      return `${appStateStoreGlobal.teacherBasePath}/project/${classSchoolYear}/${quarter}/init`;
    }

    return `${appStateStoreGlobal.teacherBasePath}/project/${currentQuarter.schoolYear}/1/init`;
  }

  get teacherBasePath(): string {
    const schoolYear = this.quarter?.schoolYear ?? appStateStoreGlobal.currentQuarter.schoolYear;
    const quarter = this.quarter?.quarter ?? appStateStoreGlobal.currentQuarter.quarter;
    const projectId = this.projectId ?? "-";
    const path = `${appStateStoreGlobal.teacherBasePath}/project/${schoolYear}/${quarter}/${projectId}`;
    log.debug(`ProjectStore.teacherBasePath = ${path}`);
    return path;
  }

  get studentInitPath(): string {
    const selectedQuarter = this.quarter;
    if (selectedQuarter !== null) {
      return `${appStateStoreGlobal.studentOrGuardianBasePath}/project/${selectedQuarter.schoolYear}/${selectedQuarter.quarter}/init`;
    }

    return `${appStateStoreGlobal.studentOrGuardianBasePath}/project/${appStateStoreGlobal.currentQuarter.schoolYear}/${appStateStoreGlobal.currentQuarter.quarter}/init`;
  }

  get studentBasePath(): string {
    const schoolYear = this.quarter?.schoolYear ?? appStateStoreGlobal.currentQuarter.schoolYear;
    const quarter = this.quarter?.quarter ?? appStateStoreGlobal.currentQuarter.quarter;
    const projectId = this.projectId ?? "-";
    return `${appStateStoreGlobal.studentOrGuardianBasePath}/project/${schoolYear}/${quarter}/${projectId}`;
  }

  // 直接変更せずに、Project, ProjectTのナビゲーションガードからのみ変更すること。（それにより、パスと同期できる）
  @Mutation
  public setQuarter(quarter: Quarter) {
    if (quarter.isValid) {
      this.quarter = quarter;
    } else {
      this.quarter = null;
    }
  }

  // 直接変更せずに、Project, ProjectTのナビゲーションガードからのみ変更すること。（それにより、パスと同期できる）
  @Mutation
  public setProjectId(projectId: string | null) {
    this.projectId = projectId;
  }

  @Mutation
  startLoadingWith(loadingFn: () => Promise<Project[] | null>) {
    log.debug(`ProjectStore.startLoadingWith`);
    this.allProjects.startLoadingWith(loadingFn);
  }

  get firstProjectIdOfQuarter(): DataSlotProjectionView<Project[], string | null> {
    const quarter = this.quarter;
    log.debug(`ProjectStore.firstProjectIdOfQuarter: quarter=${quarter}`);

    return new DataSlotProjectionView(this.allProjects, allProjects => {
      if (quarter === null) return null;
      const project = allProjects.find(
        p => p.quarter.schoolYear === quarter.schoolYear && p.quarter.quarter === quarter.quarter
      );
      if (project === undefined) return null;

      return project.projectId;
    });
  }

  @Action
  async reloadProjects({ projectRepository, classIds }: { projectRepository: ProjectRepository; classIds: string[] }) {
    const loadingFn: () => Promise<Project[] | null> = async function() {
      log.debug(`ProjectStore.reloadProjects.loadingFn: Started.`);
      const resp = await projectRepository.listProjects(classIds, undefined);
      if (resp instanceof Err) {
        log.error(`ProjectStore.reloadProjects.loadingFn: Error.`);
        return null;
      }
      log.debug(`ProjectStore.reloadProjects.loadingFn: Got response: ${JSON.stringify(resp)}`);
      return resp;
    };
    this.context.commit("startLoadingWith", loadingFn);
  }
}

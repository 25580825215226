


























































































import Vue, { PropType } from "vue";
import { IEECJournal, IEECJournalTree } from "@/ts/objects/curriculum/IEECJournal";
import { EECJournalFile } from "@/ts/objects/curriculum/value/EECJournal";
import JournalFilePreviewArea from "@/components/journalfile/JournalFilePreviewArea/JournalFilePreviewArea.vue";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";
import { isMonthValue, MonthValue } from "@/ts/objects/common/MonthValue";

export default Vue.extend({
  name: "CurriculumListEECJournalRow",
  components: { JournalFilePreviewArea, JournalFilesView, DisableableAutoResizeTextarea },
  props: {
    /**
     * 学習記録。
     */
    journalTree: { type: Object as PropType<IEECJournalTree>, required: true },

    /**
     * 現在、この学習記録が選択状態(チェックボックスにチェック済)ならtrue。
     */
    selected: { type: Boolean, required: true },

    /**
     * 現在、この学習記録のファイルビューが開かれていればtrue。
     */
    isFilesViewOpen: { type: Boolean, required: true },

    /**
     * 月カラムの幅（px）。
     */
    monthColWidth: { type: Number, required: true },

    /**
     * 学習活動カラムの幅（px）。
     */
    activityColWidth: { type: Number, required: true },

    /**
     * 学習記録ファイルカラムの幅（px）。
     */
    filesColWidth: { type: Number, required: true },

    /**
     * 児童生徒コメント(学習のふりかえり)カラムの幅（px）。
     */
    studentCommentColWidth: { type: Number, required: true },

    /**
     * 教師コメント(先生から)カラムの幅（px）。
     */
    teacherCommentColWidth: { type: Number, required: true },

    /**
     * 公開状態カラムの幅（px）。
     */
    publishStateColWidth: { type: Number, required: true },

    /**
     * チェックボックスカラムの幅（px）。
     */
    checkboxColWidth: { type: Number, required: true },

    /**
     * 現在ファイルビューを開いている学習記録を変更する関数。
     */
    selectFilesViewJournal: { type: Function as PropType<(rname: string | null) => void>, required: true },

    onInputMonth: { type: Function as PropType<(journalId: string, value: MonthValue) => void>, required: true },
    onInputActivity: { type: Function as PropType<(journalId: string, value: string) => void>, required: true },
    onInputStudentComment: { type: Function as PropType<(journalId: string, value: string) => void>, required: true },
    onInputTeacherComment: { type: Function as PropType<(journalId: string, value: string) => void>, required: true },
    onUploadJournalFile: {
      type: Function as PropType<(journalId: string, file: File) => Promise<void>>,
      required: true,
    },
    onDeleteJournalFile: {
      type: Function as PropType<(journalId: string, journalFileId: string) => Promise<void>>,
      required: true,
    },
    onChangeSelectionState: { type: Function as PropType<(journalId: string, value: boolean) => void>, required: true },
  },
  data(): {
    words: Words;
    messages: Messages;
  } {
    return { words, messages };
  },
  computed: {
    journal(): IEECJournal {
      return this.journalTree.self;
    },
    journalId(): string {
      return this.journal.journalId;
    },
    journalFiles(): EECJournalFile[] {
      return this.journalTree.journalFiles.map(tree => tree.self);
    },
    styles(): Record<string, string> {
      return {
        "--monthColWidth": `${this.monthColWidth}px`,
        "--activityColWidth": `${this.activityColWidth}px`,
        "--filesColWidth": `${this.filesColWidth}px`,
        "--studentCommentColWidth": `${this.studentCommentColWidth}px`,
        "--teacherCommentColWidth": `${this.teacherCommentColWidth}px`,
        "--publishStateColWidth": `${this.publishStateColWidth}px`,
        "--checkboxColWidth": `${this.checkboxColWidth}px`,
      };
    },
  },
  methods: {
    onClickFilesCell() {
      if (this.isFilesViewOpen) this.selectFilesViewJournal(null);
      else this.selectFilesViewJournal(this.journal.resourceName);
    },
    _onInputMonth(e: Event) {
      const target = e?.target;
      if (!(target instanceof HTMLSelectElement)) return;
      const month = parseInt(target.value, 10);
      if (!isMonthValue(month)) return;
      this.onInputMonth(this.journalId, month);
    },
    _onInputActivity(value: string) {
      this.onInputActivity(this.journalId, value);
    },
    _onInputStudentComment(value: string) {
      this.onInputStudentComment(this.journalId, value);
    },
    _onInputTeacherComment(value: string) {
      this.onInputTeacherComment(this.journalId, value);
    },
    async _onUploadJournalFile(file: File) {
      await this.onUploadJournalFile(this.journalId, file);
    },
    async _onDeleteJournalFile(journalFile: EECJournalFile) {
      await this.onDeleteJournalFile(journalFile.journalId, journalFile.journalFileId);
    },
    onChangeCheckbox(e: Event) {
      this.onChangeSelectionState(this.journalId, (e.target as HTMLInputElement).checked);
    },
  },
});
















import Vue, { PropType } from "vue";
import { Quarter } from "@/ts/objects/common/Quarter";
import { FormattedDatetime } from "@/ts/objects/common/FormattedDatetime";

export default Vue.extend({
  name: "HomeTDateSection",
  props: {
    currentQuarter: { type: Object as PropType<Quarter>, required: true },
    now: { type: Object as PropType<FormattedDatetime>, required: true },
  },
});

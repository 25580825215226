






import Vue from "vue";
import { getDownloadUrlOfStorageObject, isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "ProfileTableItemValueImage",
  props: {
    gcsUrl: { type: String, required: false },
  },
  data(): { url: string | null } {
    return { url: null };
  },
  watch: {
    gcsUrl: {
      async handler(newVal: string | null | undefined) {
        if (isNullish(newVal)) {
          this.url = null;
          return;
        }
        this.url = await getDownloadUrlOfStorageObject(newVal);
      },
      immediate: true,
    },
  },
});

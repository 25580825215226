import { isNullish } from "@/ts/utils";
import { IEECJournalTree } from "@/ts/objects/curriculum/IEECJournal";
import { EmptinessFilterValue, satisfiesEmptinessFilter } from "@/ts/objects/common/EmptinessFilterValue";
import { PublishStateFilterValue, satisfiesPublishStateFilter } from "@/ts/objects/common/PublishStateFilterValue";
import { ClassStudent } from "@/ts/objects/common/Class";
import { Loadable } from "@/ts/Loadable";
import { EditableEECJournalStudentTree } from "@/ts/objects/curriculum/editable/EditableEECJournal";
import { EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import { MonthValue } from "@/ts/objects/common/MonthValue";

/**
 * CurriculumListEECのビューモデル。
 */
export type CurriculumListEECModel = {
  readonly curriculumName: string;
  readonly syllabusFileGcsObjectPath: string | null;

  /**
   * 児童生徒行のリスト。(フィルタ済)
   */
  readonly studentRows: CurriculumListEECStudentRowModel[];
};

export type CurriculumListEECStudentRowModel = {
  readonly studentUserId: string;
  readonly studentNumber: number;
  readonly studentName: string;

  /**
   * 学習記録のリスト。(フィルタ済)
   */
  readonly journals: IEECJournalTree[];
};

/**
 * 選択状態。
 * キー: 学習記録のリソース名, 値: 選択中ならtrue。
 */
export type CurriculumListEECSelectionState = Record<string, boolean>;

/**
 * 児童生徒行のフィルタ状態。
 */
export type CurriculumListEECStudentRowFilterState = {
  /**
   * 表示中の児童生徒IDのリスト。
   */
  readonly studentUserIds: string[];
};

/**
 * 学習記録行のフィルタ状態。
 */
export type CurriculumListEECJournalRowFilterState = {
  /**
   * 表示中の月のリスト。
   */
  readonly months: readonly MonthValue[];
  readonly activity: EmptinessFilterValue[]; // TODO 検索できるようにすべき？
  readonly studentComment: EmptinessFilterValue[];
  readonly teacherComment: EmptinessFilterValue[];
  readonly publishState: PublishStateFilterValue[];
};

export function createCurriculumListEECModel(
  eeCurriculumTree: Loadable<EECurriculumTree>,
  students: Loadable<ClassStudent[]>,
  journalStudentTreeDict: Loadable<Record<string, EditableEECJournalStudentTree>>,
  studentRowFilterState: CurriculumListEECStudentRowFilterState,
  journalRowFilterState: CurriculumListEECJournalRowFilterState
): Loadable<CurriculumListEECModel> {
  return Loadable.fromLoadable3(
    eeCurriculumTree,
    students,
    journalStudentTreeDict,
    (eeCurriculumTreeData, studentsData, journalStudentTreeDictData) => {
      const filteredStudents = studentsData.filter(s => studentRowFilterState.studentUserIds.includes(s.studentUserId));
      return {
        curriculumName: eeCurriculumTreeData.self.name,
        syllabusFileGcsObjectPath: eeCurriculumTreeData.self.syllabusFileGcsObjectPath,
        studentRows: filteredStudents.map(student => {
          const journalStudent = journalStudentTreeDictData[student.studentUserId];
          const journals = isNullish(journalStudent) ? [] : journalStudent.journals;
          const filteredJournals = journals.filter(
            j =>
              journalRowFilterState.months.includes(j.self.month) &&
              satisfiesEmptinessFilter(j.self.activity, journalRowFilterState.activity) &&
              satisfiesEmptinessFilter(j.self.studentComment, journalRowFilterState.studentComment) &&
              satisfiesEmptinessFilter(j.self.teacherComment, journalRowFilterState.teacherComment) &&
              satisfiesPublishStateFilter(j.self.teacherInputPublished, journalRowFilterState.publishState)
          );
          return {
            studentUserId: student.studentUserId,
            studentNumber: student.studentNumber,
            studentName: student.name,
            journals: filteredJournals,
          };
        }),
      };
    }
  );
}

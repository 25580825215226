




































import Vue, { PropType } from "vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import PopupMenuButton, { MenuButton, MenuItem, Separator } from "@/components/PopupMenuButton.vue";
import { isNullish } from "@/ts/utils";

export type CurriculumSyllabusEECCellStyleType = 0 | 1;

export default Vue.extend({
  name: "CurriculumSyllabusEECCell",
  components: { DisableableAutoResizeTextarea, PopupMenuButton },
  props: {
    styleType: { type: Number as PropType<CurriculumSyllabusEECCellStyleType>, required: true },
    width: { type: Number, required: true },

    enabled: { type: Boolean, required: true },
    text: { type: String, required: true },

    onInputText: { type: Function as PropType<(value: string) => void>, required: true },
    onInputEnabled: { type: Function as PropType<(value: boolean) => void>, required: true },
    moveActivity: { type: Function as PropType<(up: boolean) => Promise<void>>, required: true },
    deleteActivity: { type: Function as PropType<() => Promise<void>>, required: true },
  },
  data(): {
    active: boolean;
    extraMenuItems: MenuItem[];
  } {
    return {
      active: false,
      extraMenuItems: [
        new MenuButton("up", "Up", ["fas", "arrow-up"]),
        new MenuButton("down", "Down", ["fas", "arrow-down"]),
        new Separator(),
        new MenuButton("delete", "Delete", ["fas", "trash-alt"]),
      ],
    };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--width": `${this.width}px`,
        "--background-color": this.styleType === 0 ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.9)",
      };
    },
  },
  methods: {
    onChangeCheckbox(e: Event) {
      const value = (e.target as HTMLInputElement).checked;
      if (isNullish(value)) return;
      this.onInputEnabled(value);
    },
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "up":
          this.moveActivity(true);
          return;
        case "down":
          this.moveActivity(false);
          return;
        case "delete":
          this.deleteActivity();
          return;
      }
    },
  },
});

/* tslint:disable */
/* eslint-disable */
/**
 * SOLAN学習サービス
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface FullProject
 */
export interface FullProject {
    /**
     * 
     * @type {string}
     * @memberof FullProject
     */
    projectId: string;
    /**
     * 
     * @type {Project}
     * @memberof FullProject
     */
    project: Project;
    /**
     * 
     * @type {Array<Rubric>}
     * @memberof FullProject
     */
    rubrics: Array<Rubric>;
}
/**
 * 値とハッシュ値のペア。 複数人による多重編集を検知したい値に関しては、これを用いる。
 * @export
 * @interface HashedValue
 */
export interface HashedValue {
    /**
     * 値。
     * @type {string}
     * @memberof HashedValue
     */
    value: string;
    /**
     * ハッシュ値。変更を検知できさえすれば良いので、`value`が常に非常に短い場合は、`value`と全く同じ値でも良い。  新規作成時も必須だが、値は無視される（空文字列で良い）。
     * @type {string}
     * @memberof HashedValue
     */
    hash: string;
}
/**
 * 
 * @export
 * @interface Journal
 */
export interface Journal {
    /**
     * 
     * @type {Array<JournalFile>}
     * @memberof Journal
     */
    files: Array<JournalFile>;
    /**
     * 
     * @type {HashedValue}
     * @memberof Journal
     */
    studentComment: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    studentRating: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof Journal
     */
    teacherComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    teacherRating?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Journal
     */
    teacherInputPublished: boolean;
}
/**
 * 所属するルーブリック/学習記録が存在しない（削除された）とき、本エンティティも（明示的に削除しなくても）存在しない。
 * @export
 * @interface JournalFile
 */
export interface JournalFile {
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    journalFileId: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    rubricId: string;
    /**
     * ファイルのタイプ。 image / video / audio / slides / any
     * @type {string}
     * @memberof JournalFile
     */
    type: string;
    /**
     * ファイルのサブタイプ。 image -> jpeg / png ... slides -> powerpoint / keynote ... ...
     * @type {string}
     * @memberof JournalFile
     */
    subtype: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    mediaType: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    ext: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    gcsObjectPath: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    thumbnailGcsObjectPath?: string;
    /**
     * 画像等の場合のみ値がある。正確な値ではない可能性があるので注意。
     * @type {number}
     * @memberof JournalFile
     */
    width?: number;
    /**
     * 画像等の場合のみ値がある。正確な値ではない可能性があるので注意。
     * @type {number}
     * @memberof JournalFile
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    updatedAt: string;
    /**
     * サムネイルが存在すれば`true`。未作成の場合や、そもそもサムネイル不要なファイルタイプの場合は`false`。
     * @type {boolean}
     * @memberof JournalFile
     */
    hasThumbnail: boolean;
}
/**
 * 
 * @export
 * @interface JournalWrite
 */
export interface JournalWrite {
    /**
     * 
     * @type {HashedValue}
     * @memberof JournalWrite
     */
    studentComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof JournalWrite
     */
    studentRating?: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof JournalWrite
     */
    teacherComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof JournalWrite
     */
    teacherRating?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JournalWrite
     */
    teacherInputPublished?: boolean;
}
/**
 * 
 * @export
 * @interface Lookback
 */
export interface Lookback {
    /**
     * 
     * @type {HashedValue}
     * @memberof Lookback
     */
    studentComment: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof Lookback
     */
    studentRating: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof Lookback
     */
    teacherComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof Lookback
     */
    teacherRating?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Lookback
     */
    teacherInputPublished: boolean;
    /**
     * 
     * @type {HashedValue}
     * @memberof Lookback
     */
    guardianComment: HashedValue;
}
/**
 * 
 * @export
 * @interface LookbackWrite
 */
export interface LookbackWrite {
    /**
     * 
     * @type {HashedValue}
     * @memberof LookbackWrite
     */
    studentComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof LookbackWrite
     */
    studentRating?: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof LookbackWrite
     */
    teacherComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof LookbackWrite
     */
    teacherRating?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LookbackWrite
     */
    teacherInputPublished?: boolean;
    /**
     * 
     * @type {HashedValue}
     * @memberof LookbackWrite
     */
    guardianComment?: HashedValue;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    studentUserId: string;
    /**
     * 年度。
     * @type {number}
     * @memberof Project
     */
    schoolYear: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof Project
     */
    name: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Project
     */
    description: HashedValue;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    started: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    completed: boolean;
    /**
     * 
     * @type {Lookback}
     * @memberof Project
     */
    lookback: Lookback;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    studentInputLocked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    guardianInputLocked: boolean;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface ProjectWrite
 */
export interface ProjectWrite {
    /**
     * 
     * @type {string}
     * @memberof ProjectWrite
     */
    studentUserId?: string;
    /**
     * 年度。
     * @type {number}
     * @memberof ProjectWrite
     */
    schoolYear?: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof ProjectWrite
     */
    name?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof ProjectWrite
     */
    description?: HashedValue;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWrite
     */
    started?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWrite
     */
    completed?: boolean;
    /**
     * 
     * @type {LookbackWrite}
     * @memberof ProjectWrite
     */
    lookback?: LookbackWrite;
}
/**
 * 
 * @export
 * @interface Rubric
 */
export interface Rubric {
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    rubricId: string;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    studentUserId: string;
    /**
     * 0：みつける 1：しらべる 2：まとめる 3：つたえる
     * @type {number}
     * @memberof Rubric
     */
    process: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    learningActivity: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    viewPointS: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    viewPointA: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    viewPointB: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    viewPointC: HashedValue;
    /**
     * 
     * @type {Journal}
     * @memberof Rubric
     */
    journal: Journal;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof Rubric
     */
    studentInputLocked: boolean;
}
/**
 * 
 * @export
 * @interface RubricWrite
 */
export interface RubricWrite {
    /**
     * 0：みつける 1：しらべる 2：まとめる 3：つたえる
     * @type {number}
     * @memberof RubricWrite
     */
    process?: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    learningActivity?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    viewPointS?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    viewPointA?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    viewPointB?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    viewPointC?: HashedValue;
    /**
     * 
     * @type {JournalWrite}
     * @memberof RubricWrite
     */
    journal?: JournalWrite;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ削除可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ削除可能。   * ただし、以下のいずれかの条件を満たす場合は削除できない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 紐づく学習記録の`teacherInputPublished`が`true`  * 教師   * なんでも削除可能。  # その他   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら操作できない。   * 該当するjournalFileが削除されていたら操作できない。 
         * @summary SOLANプロジェクト学習記録ファイルを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalFile: async (projectId: string, rubricId: string, journalFileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteJournalFile', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('deleteJournalFile', 'rubricId', rubricId)
            // verify required parameter 'journalFileId' is not null or undefined
            assertParamExists('deleteJournalFile', 'journalFileId', journalFileId)
            const localVarPath = `/solanProjects/{projectId}/rubrics/{rubricId}/journalFiles/{journalFileId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)))
                .replace(`{${"journalFileId"}}`, encodeURIComponent(String(journalFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ削除可能。   * 保護者は、自分に属する児童生徒のもののみ削除可能。   * 以下のいずれかの条件を満たす場合は削除できない。     * `started`が`true`の場合     * `completed`が`true`の場合     * ふりかえりの`teacherInputPublished`が`true`の場合  * 教師   * なんでも削除可能。  # その他   * 削除されていたら操作できない。 
         * @summary SOLANプロジェクトを削除する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/solanProjects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ削除可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ削除可能。   * ただし、以下のいずれかの条件を満たす場合は削除できない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 学習記録の`teacherInputPublished`が`true`     * 学習記録ファイルが1件以上ある（未実装・そのうちやるかも）   * 教師   * いつでも使用可能。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら操作できない。
         * @summary SOLANプロジェクトルーブリックを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRubric: async (projectId: string, rubricId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteRubric', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('deleteRubric', 'rubricId', rubricId)
            const localVarPath = `/solanProjects/{projectId}/rubrics/{rubricId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。  * 教師   * なんでも取得可能。  # その他   * journalFileの有無は対応するrubricの状態と同期する。   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら取得できない。   * 該当するjournalFileが削除されていたら取得できない。 
         * @summary SOLANプロジェクト学習記録ファイルのメタデータを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalFile: async (projectId: string, rubricId: string, journalFileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getJournalFile', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('getJournalFile', 'rubricId', rubricId)
            // verify required parameter 'journalFileId' is not null or undefined
            assertParamExists('getJournalFile', 'journalFileId', journalFileId)
            const localVarPath = `/solanProjects/{projectId}/rubrics/{rubricId}/journalFiles/{journalFileId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)))
                .replace(`{${"journalFileId"}}`, encodeURIComponent(String(journalFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ取得可能。   * 保護者は、自分に属する児童生徒のもののみ取得可能。   * ふりかえりの`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * なんでも取得可能。  # その他   * プロジェクトが削除されていたら取得できない。
         * @summary SOLANプロジェクトを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId)
            const localVarPath = `/solanProjects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。   * 学習記録の`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * なんでも取得可能。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら取得できない。   * 学習記録の有無は対応するルーブリックの状態と同期する。
         * @summary SOLANプロジェクトルーブリックを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubric: async (projectId: string, rubricId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getRubric', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('getRubric', 'rubricId', rubricId)
            const localVarPath = `/solanProjects/{projectId}/rubrics/{rubricId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトの完全な情報のリストを取得する。  # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * 使用可。
         * @summary SOLANプロジェクトの完全な情報のリストを取得する。
         * @param {Array<number>} [schoolYear] 年度。
         * @param {Array<string>} [studentUserId] 児童生徒ユーザーID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFullProjects: async (schoolYear?: Array<number>, studentUserId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/:listFullProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (schoolYear) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (studentUserId) {
                localVarQueryParameter['studentUserId'] = studentUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。  # その他 * journalFileの有無は対応するrubricの状態と同期する。 * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら取得できない。 * ルーブリックの並び順 -> 作成日時昇順 で得られる。
         * @summary SOLANプロジェクト学習記録ファイルのメタデータリストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournalFile: async (projectId: string, rubricId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listJournalFile', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('listJournalFile', 'rubricId', rubricId)
            const localVarPath = `/solanProjects/{projectId}/rubrics/{rubricId}/journalFiles`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ取得可能。   * 保護者は、自分に属する児童生徒のもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary SOLANプロジェクトリストを取得する。
         * @param {string} [studentUserId] 児童生徒のユーザーID。
         * @param {number} [schoolYear] 年度。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProject: async (studentUserId?: string, schoolYear?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/solanProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (studentUserId !== undefined) {
                localVarQueryParameter['studentUserId'] = studentUserId;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。 * 教師   * なんでも取得可能。  # その他    * `createdAt`の昇順（最新が下）で得られる。   * 紐づくプロジェクトが削除されていたら取得できない。
         * @summary SOLANプロジェクトルーブリックリストを取得する。
         * @param {string} projectId 
         * @param {boolean} [expandFiles] &#x60;true&#x60;の場合、返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRubric: async (projectId: string, expandFiles?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listRubric', 'projectId', projectId)
            const localVarPath = `/solanProjects/{projectId}/rubrics`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expandFiles !== undefined) {
                localVarQueryParameter['expandFiles'] = expandFiles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ更新可能。   * 保護者は、自分に属する児童生徒のもののみ更新可能。   * 以下のいずれかの条件を満たす場合は更新できない。     * プロジェクトの`completed`が`true`の場合     * ふりかえりの`teacherInputPublished`が`true`の場合（例外：   ふりかえりの`guardianComment`のみ更新可能）。     * ふりかえりの更新可能フィールドは`studentComment`、`studentRating`、`guardianComment`。   * ふりかえりの`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * プロジェクトはなんでも更新可能。   * ふりかえりの更新可能フィールドは`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`、`guardianComment`。   * ふりかえりの `teacherInputPublished`は`true`から`false`に変更可能。   # 制約  * 更新可能フィールドは、`name`、`description`、`startingMonth`、`endingMonth`、`started`、`completed`。 * プロジェクトの`started`を、`true`から`false`に変更することはできない。 * ~~rubricIdsフィールドは、並び替えのみ可。それ以外の操作をすると、エラーとなる。~~  # その他   * プロジェクトが削除されていたら更新できない。
         * @summary SOLANプロジェクトを更新する。
         * @param {string} projectId 
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject: async (projectId: string, projectWrite?: ProjectWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchProject', 'projectId', projectId)
            const localVarPath = `/solanProjects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ更新可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ更新可能。   * ただし、以下のいずれかの条件を満たす場合は更新できない。     * 紐づくプロジェクトの`completed`が`true`の場合     * 紐づくふりかえりの`teacherInputPublished`の`true`場合     * 学習記録の`teacherInputPublished`が`true`の場合    * 更新可能フィールドは、`process`、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`、`journal`(うち`studentComment`、`studentRating`のみ)。    * 学習記録の`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。   * 教師   * なんでも更新可能。   * 更新可能フィールドは、`process`、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`、`journal`(うち`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`)。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら更新できない。  ~~`orderNum`を更新したければ、SOLANプロジェクトの`rubricIds`を更新する。~~
         * @summary SOLANプロジェクトルーブリックを更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRubric: async (projectId: string, rubricId: string, rubricWrite?: RubricWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchRubric', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('patchRubric', 'rubricId', rubricId)
            const localVarPath = `/solanProjects/{projectId}/rubrics/{rubricId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rubricWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみアップロード可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみアップロード可能。   * ただし、以下のいずれかの条件を満たす場合はアップロードできない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 紐づく学習記録の`teacherInputPublished`が`true`  * 教師   * なんでもアップロード可能。  # その他   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら作成できない。
         * @summary SOLANプロジェクト学習記録ファイルをアップロードする。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {any} upfile アップロードするファイル。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournalFile: async (projectId: string, rubricId: string, upfile: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postJournalFile', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('postJournalFile', 'rubricId', rubricId)
            // verify required parameter 'upfile' is not null or undefined
            assertParamExists('postJournalFile', 'upfile', upfile)
            const localVarPath = `/solanProjects/{projectId}/rubrics/{rubricId}/journalFiles`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (upfile !== undefined) { 
                localVarFormParams.append('upfile', upfile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ作成可能。   * 保護者は、自分に属する児童生徒のもののみ作成可能。 * 教師   * なんでも作成可能。  # 制約  * 入力可能フィールドは、すべてのフィールド。 * 入力必須フィールドは、`studentUserId`、`schoolYear`。
         * @summary SOLANプロジェクトを作成する。
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject: async (projectWrite?: ProjectWrite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/solanProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ作成可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ作成可能。   * ただし、以下のいずれかの条件を満たす場合は作成できない。     * 紐づくプロジェクトの`completed`が`true`の場合     * 紐づくふりかえりの`teacherInputPublished`が`true`の場合  * 教師   * なんでも作成可能。  # 制約  * 入力必須フィールドは、`process`。  # その他  ~~SOLANプロジェクトエンティティ内のrubricsフィールドの末尾に追加される。~~   * 紐づくプロジェクトが削除されていたら作成できない。
         * @summary SOLANプロジェクトルーブリックを作成する。
         * @param {string} projectId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRubric: async (projectId: string, rubricWrite?: RubricWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postRubric', 'projectId', projectId)
            const localVarPath = `/solanProjects/{projectId}/rubrics`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rubricWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ削除可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ削除可能。   * ただし、以下のいずれかの条件を満たす場合は削除できない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 紐づく学習記録の`teacherInputPublished`が`true`  * 教師   * なんでも削除可能。  # その他   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら操作できない。   * 該当するjournalFileが削除されていたら操作できない。 
         * @summary SOLANプロジェクト学習記録ファイルを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournalFile(projectId: string, rubricId: string, journalFileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournalFile(projectId, rubricId, journalFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ削除可能。   * 保護者は、自分に属する児童生徒のもののみ削除可能。   * 以下のいずれかの条件を満たす場合は削除できない。     * `started`が`true`の場合     * `completed`が`true`の場合     * ふりかえりの`teacherInputPublished`が`true`の場合  * 教師   * なんでも削除可能。  # その他   * 削除されていたら操作できない。 
         * @summary SOLANプロジェクトを削除する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ削除可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ削除可能。   * ただし、以下のいずれかの条件を満たす場合は削除できない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 学習記録の`teacherInputPublished`が`true`     * 学習記録ファイルが1件以上ある（未実装・そのうちやるかも）   * 教師   * いつでも使用可能。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら操作できない。
         * @summary SOLANプロジェクトルーブリックを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRubric(projectId: string, rubricId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRubric(projectId, rubricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。  * 教師   * なんでも取得可能。  # その他   * journalFileの有無は対応するrubricの状態と同期する。   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら取得できない。   * 該当するjournalFileが削除されていたら取得できない。 
         * @summary SOLANプロジェクト学習記録ファイルのメタデータを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalFile(projectId: string, rubricId: string, journalFileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalFile(projectId, rubricId, journalFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ取得可能。   * 保護者は、自分に属する児童生徒のもののみ取得可能。   * ふりかえりの`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * なんでも取得可能。  # その他   * プロジェクトが削除されていたら取得できない。
         * @summary SOLANプロジェクトを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。   * 学習記録の`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * なんでも取得可能。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら取得できない。   * 学習記録の有無は対応するルーブリックの状態と同期する。
         * @summary SOLANプロジェクトルーブリックを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRubric(projectId: string, rubricId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRubric(projectId, rubricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プロジェクトの完全な情報のリストを取得する。  # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * 使用可。
         * @summary SOLANプロジェクトの完全な情報のリストを取得する。
         * @param {Array<number>} [schoolYear] 年度。
         * @param {Array<string>} [studentUserId] 児童生徒ユーザーID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFullProjects(schoolYear?: Array<number>, studentUserId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FullProject>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFullProjects(schoolYear, studentUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。  # その他 * journalFileの有無は対応するrubricの状態と同期する。 * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら取得できない。 * ルーブリックの並び順 -> 作成日時昇順 で得られる。
         * @summary SOLANプロジェクト学習記録ファイルのメタデータリストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJournalFile(projectId: string, rubricId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JournalFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJournalFile(projectId, rubricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ取得可能。   * 保護者は、自分に属する児童生徒のもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary SOLANプロジェクトリストを取得する。
         * @param {string} [studentUserId] 児童生徒のユーザーID。
         * @param {number} [schoolYear] 年度。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProject(studentUserId?: string, schoolYear?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProject(studentUserId, schoolYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。 * 教師   * なんでも取得可能。  # その他    * `createdAt`の昇順（最新が下）で得られる。   * 紐づくプロジェクトが削除されていたら取得できない。
         * @summary SOLANプロジェクトルーブリックリストを取得する。
         * @param {string} projectId 
         * @param {boolean} [expandFiles] &#x60;true&#x60;の場合、返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRubric(projectId: string, expandFiles?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rubric>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRubric(projectId, expandFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ更新可能。   * 保護者は、自分に属する児童生徒のもののみ更新可能。   * 以下のいずれかの条件を満たす場合は更新できない。     * プロジェクトの`completed`が`true`の場合     * ふりかえりの`teacherInputPublished`が`true`の場合（例外：   ふりかえりの`guardianComment`のみ更新可能）。     * ふりかえりの更新可能フィールドは`studentComment`、`studentRating`、`guardianComment`。   * ふりかえりの`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * プロジェクトはなんでも更新可能。   * ふりかえりの更新可能フィールドは`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`、`guardianComment`。   * ふりかえりの `teacherInputPublished`は`true`から`false`に変更可能。   # 制約  * 更新可能フィールドは、`name`、`description`、`startingMonth`、`endingMonth`、`started`、`completed`。 * プロジェクトの`started`を、`true`から`false`に変更することはできない。 * ~~rubricIdsフィールドは、並び替えのみ可。それ以外の操作をすると、エラーとなる。~~  # その他   * プロジェクトが削除されていたら更新できない。
         * @summary SOLANプロジェクトを更新する。
         * @param {string} projectId 
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProject(projectId: string, projectWrite?: ProjectWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProject(projectId, projectWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ更新可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ更新可能。   * ただし、以下のいずれかの条件を満たす場合は更新できない。     * 紐づくプロジェクトの`completed`が`true`の場合     * 紐づくふりかえりの`teacherInputPublished`の`true`場合     * 学習記録の`teacherInputPublished`が`true`の場合    * 更新可能フィールドは、`process`、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`、`journal`(うち`studentComment`、`studentRating`のみ)。    * 学習記録の`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。   * 教師   * なんでも更新可能。   * 更新可能フィールドは、`process`、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`、`journal`(うち`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`)。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら更新できない。  ~~`orderNum`を更新したければ、SOLANプロジェクトの`rubricIds`を更新する。~~
         * @summary SOLANプロジェクトルーブリックを更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRubric(projectId: string, rubricId: string, rubricWrite?: RubricWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchRubric(projectId, rubricId, rubricWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみアップロード可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみアップロード可能。   * ただし、以下のいずれかの条件を満たす場合はアップロードできない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 紐づく学習記録の`teacherInputPublished`が`true`  * 教師   * なんでもアップロード可能。  # その他   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら作成できない。
         * @summary SOLANプロジェクト学習記録ファイルをアップロードする。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {any} upfile アップロードするファイル。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJournalFile(projectId: string, rubricId: string, upfile: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJournalFile(projectId, rubricId, upfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ作成可能。   * 保護者は、自分に属する児童生徒のもののみ作成可能。 * 教師   * なんでも作成可能。  # 制約  * 入力可能フィールドは、すべてのフィールド。 * 入力必須フィールドは、`studentUserId`、`schoolYear`。
         * @summary SOLANプロジェクトを作成する。
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProject(projectWrite?: ProjectWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProject(projectWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ作成可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ作成可能。   * ただし、以下のいずれかの条件を満たす場合は作成できない。     * 紐づくプロジェクトの`completed`が`true`の場合     * 紐づくふりかえりの`teacherInputPublished`が`true`の場合  * 教師   * なんでも作成可能。  # 制約  * 入力必須フィールドは、`process`。  # その他  ~~SOLANプロジェクトエンティティ内のrubricsフィールドの末尾に追加される。~~   * 紐づくプロジェクトが削除されていたら作成できない。
         * @summary SOLANプロジェクトルーブリックを作成する。
         * @param {string} projectId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRubric(projectId: string, rubricWrite?: RubricWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRubric(projectId, rubricWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ削除可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ削除可能。   * ただし、以下のいずれかの条件を満たす場合は削除できない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 紐づく学習記録の`teacherInputPublished`が`true`  * 教師   * なんでも削除可能。  # その他   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら操作できない。   * 該当するjournalFileが削除されていたら操作できない。 
         * @summary SOLANプロジェクト学習記録ファイルを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalFile(projectId: string, rubricId: string, journalFileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteJournalFile(projectId, rubricId, journalFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ削除可能。   * 保護者は、自分に属する児童生徒のもののみ削除可能。   * 以下のいずれかの条件を満たす場合は削除できない。     * `started`が`true`の場合     * `completed`が`true`の場合     * ふりかえりの`teacherInputPublished`が`true`の場合  * 教師   * なんでも削除可能。  # その他   * 削除されていたら操作できない。 
         * @summary SOLANプロジェクトを削除する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ削除可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ削除可能。   * ただし、以下のいずれかの条件を満たす場合は削除できない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 学習記録の`teacherInputPublished`が`true`     * 学習記録ファイルが1件以上ある（未実装・そのうちやるかも）   * 教師   * いつでも使用可能。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら操作できない。
         * @summary SOLANプロジェクトルーブリックを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRubric(projectId: string, rubricId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRubric(projectId, rubricId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。  * 教師   * なんでも取得可能。  # その他   * journalFileの有無は対応するrubricの状態と同期する。   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら取得できない。   * 該当するjournalFileが削除されていたら取得できない。 
         * @summary SOLANプロジェクト学習記録ファイルのメタデータを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalFile(projectId: string, rubricId: string, journalFileId: string, options?: any): AxiosPromise<JournalFile> {
            return localVarFp.getJournalFile(projectId, rubricId, journalFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ取得可能。   * 保護者は、自分に属する児童生徒のもののみ取得可能。   * ふりかえりの`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * なんでも取得可能。  # その他   * プロジェクトが削除されていたら取得できない。
         * @summary SOLANプロジェクトを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。   * 学習記録の`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * なんでも取得可能。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら取得できない。   * 学習記録の有無は対応するルーブリックの状態と同期する。
         * @summary SOLANプロジェクトルーブリックを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubric(projectId: string, rubricId: string, options?: any): AxiosPromise<Rubric> {
            return localVarFp.getRubric(projectId, rubricId, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトの完全な情報のリストを取得する。  # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * 使用可。
         * @summary SOLANプロジェクトの完全な情報のリストを取得する。
         * @param {Array<number>} [schoolYear] 年度。
         * @param {Array<string>} [studentUserId] 児童生徒ユーザーID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFullProjects(schoolYear?: Array<number>, studentUserId?: Array<string>, options?: any): AxiosPromise<Array<FullProject>> {
            return localVarFp.listFullProjects(schoolYear, studentUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。  # その他 * journalFileの有無は対応するrubricの状態と同期する。 * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら取得できない。 * ルーブリックの並び順 -> 作成日時昇順 で得られる。
         * @summary SOLANプロジェクト学習記録ファイルのメタデータリストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournalFile(projectId: string, rubricId: string, options?: any): AxiosPromise<Array<JournalFile>> {
            return localVarFp.listJournalFile(projectId, rubricId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ取得可能。   * 保護者は、自分に属する児童生徒のもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary SOLANプロジェクトリストを取得する。
         * @param {string} [studentUserId] 児童生徒のユーザーID。
         * @param {number} [schoolYear] 年度。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProject(studentUserId?: string, schoolYear?: number, options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.listProject(studentUserId, schoolYear, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。 * 教師   * なんでも取得可能。  # その他    * `createdAt`の昇順（最新が下）で得られる。   * 紐づくプロジェクトが削除されていたら取得できない。
         * @summary SOLANプロジェクトルーブリックリストを取得する。
         * @param {string} projectId 
         * @param {boolean} [expandFiles] &#x60;true&#x60;の場合、返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRubric(projectId: string, expandFiles?: boolean, options?: any): AxiosPromise<Array<Rubric>> {
            return localVarFp.listRubric(projectId, expandFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ更新可能。   * 保護者は、自分に属する児童生徒のもののみ更新可能。   * 以下のいずれかの条件を満たす場合は更新できない。     * プロジェクトの`completed`が`true`の場合     * ふりかえりの`teacherInputPublished`が`true`の場合（例外：   ふりかえりの`guardianComment`のみ更新可能）。     * ふりかえりの更新可能フィールドは`studentComment`、`studentRating`、`guardianComment`。   * ふりかえりの`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * プロジェクトはなんでも更新可能。   * ふりかえりの更新可能フィールドは`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`、`guardianComment`。   * ふりかえりの `teacherInputPublished`は`true`から`false`に変更可能。   # 制約  * 更新可能フィールドは、`name`、`description`、`startingMonth`、`endingMonth`、`started`、`completed`。 * プロジェクトの`started`を、`true`から`false`に変更することはできない。 * ~~rubricIdsフィールドは、並び替えのみ可。それ以外の操作をすると、エラーとなる。~~  # その他   * プロジェクトが削除されていたら更新できない。
         * @summary SOLANプロジェクトを更新する。
         * @param {string} projectId 
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject(projectId: string, projectWrite?: ProjectWrite, options?: any): AxiosPromise<Project> {
            return localVarFp.patchProject(projectId, projectWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ更新可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ更新可能。   * ただし、以下のいずれかの条件を満たす場合は更新できない。     * 紐づくプロジェクトの`completed`が`true`の場合     * 紐づくふりかえりの`teacherInputPublished`の`true`場合     * 学習記録の`teacherInputPublished`が`true`の場合    * 更新可能フィールドは、`process`、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`、`journal`(うち`studentComment`、`studentRating`のみ)。    * 学習記録の`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。   * 教師   * なんでも更新可能。   * 更新可能フィールドは、`process`、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`、`journal`(うち`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`)。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら更新できない。  ~~`orderNum`を更新したければ、SOLANプロジェクトの`rubricIds`を更新する。~~
         * @summary SOLANプロジェクトルーブリックを更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRubric(projectId: string, rubricId: string, rubricWrite?: RubricWrite, options?: any): AxiosPromise<Rubric> {
            return localVarFp.patchRubric(projectId, rubricId, rubricWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみアップロード可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみアップロード可能。   * ただし、以下のいずれかの条件を満たす場合はアップロードできない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 紐づく学習記録の`teacherInputPublished`が`true`  * 教師   * なんでもアップロード可能。  # その他   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら作成できない。
         * @summary SOLANプロジェクト学習記録ファイルをアップロードする。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {any} upfile アップロードするファイル。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournalFile(projectId: string, rubricId: string, upfile: any, options?: any): AxiosPromise<JournalFile> {
            return localVarFp.postJournalFile(projectId, rubricId, upfile, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ作成可能。   * 保護者は、自分に属する児童生徒のもののみ作成可能。 * 教師   * なんでも作成可能。  # 制約  * 入力可能フィールドは、すべてのフィールド。 * 入力必須フィールドは、`studentUserId`、`schoolYear`。
         * @summary SOLANプロジェクトを作成する。
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject(projectWrite?: ProjectWrite, options?: any): AxiosPromise<Project> {
            return localVarFp.postProject(projectWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ作成可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ作成可能。   * ただし、以下のいずれかの条件を満たす場合は作成できない。     * 紐づくプロジェクトの`completed`が`true`の場合     * 紐づくふりかえりの`teacherInputPublished`が`true`の場合  * 教師   * なんでも作成可能。  # 制約  * 入力必須フィールドは、`process`。  # その他  ~~SOLANプロジェクトエンティティ内のrubricsフィールドの末尾に追加される。~~   * 紐づくプロジェクトが削除されていたら作成できない。
         * @summary SOLANプロジェクトルーブリックを作成する。
         * @param {string} projectId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRubric(projectId: string, rubricWrite?: RubricWrite, options?: any): AxiosPromise<Rubric> {
            return localVarFp.postRubric(projectId, rubricWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ削除可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ削除可能。   * ただし、以下のいずれかの条件を満たす場合は削除できない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 紐づく学習記録の`teacherInputPublished`が`true`  * 教師   * なんでも削除可能。  # その他   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら操作できない。   * 該当するjournalFileが削除されていたら操作できない。 
     * @summary SOLANプロジェクト学習記録ファイルを削除する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} journalFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJournalFile(projectId: string, rubricId: string, journalFileId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteJournalFile(projectId, rubricId, journalFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ削除可能。   * 保護者は、自分に属する児童生徒のもののみ削除可能。   * 以下のいずれかの条件を満たす場合は削除できない。     * `started`が`true`の場合     * `completed`が`true`の場合     * ふりかえりの`teacherInputPublished`が`true`の場合  * 教師   * なんでも削除可能。  # その他   * 削除されていたら操作できない。 
     * @summary SOLANプロジェクトを削除する。
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProject(projectId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ削除可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ削除可能。   * ただし、以下のいずれかの条件を満たす場合は削除できない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 学習記録の`teacherInputPublished`が`true`     * 学習記録ファイルが1件以上ある（未実装・そのうちやるかも）   * 教師   * いつでも使用可能。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら操作できない。
     * @summary SOLANプロジェクトルーブリックを削除する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRubric(projectId: string, rubricId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteRubric(projectId, rubricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。  * 教師   * なんでも取得可能。  # その他   * journalFileの有無は対応するrubricの状態と同期する。   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら取得できない。   * 該当するjournalFileが削除されていたら取得できない。 
     * @summary SOLANプロジェクト学習記録ファイルのメタデータを取得する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} journalFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJournalFile(projectId: string, rubricId: string, journalFileId: string, options?: any) {
        return DefaultApiFp(this.configuration).getJournalFile(projectId, rubricId, journalFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ取得可能。   * 保護者は、自分に属する児童生徒のもののみ取得可能。   * ふりかえりの`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * なんでも取得可能。  # その他   * プロジェクトが削除されていたら取得できない。
     * @summary SOLANプロジェクトを取得する。
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProject(projectId: string, options?: any) {
        return DefaultApiFp(this.configuration).getProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。   * 学習記録の`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * なんでも取得可能。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら取得できない。   * 学習記録の有無は対応するルーブリックの状態と同期する。
     * @summary SOLANプロジェクトルーブリックを取得する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRubric(projectId: string, rubricId: string, options?: any) {
        return DefaultApiFp(this.configuration).getRubric(projectId, rubricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プロジェクトの完全な情報のリストを取得する。  # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * 使用可。
     * @summary SOLANプロジェクトの完全な情報のリストを取得する。
     * @param {Array<number>} [schoolYear] 年度。
     * @param {Array<string>} [studentUserId] 児童生徒ユーザーID。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listFullProjects(schoolYear?: Array<number>, studentUserId?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).listFullProjects(schoolYear, studentUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。  # その他 * journalFileの有無は対応するrubricの状態と同期する。 * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら取得できない。 * ルーブリックの並び順 -> 作成日時昇順 で得られる。
     * @summary SOLANプロジェクト学習記録ファイルのメタデータリストを取得する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listJournalFile(projectId: string, rubricId: string, options?: any) {
        return DefaultApiFp(this.configuration).listJournalFile(projectId, rubricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ取得可能。   * 保護者は、自分に属する児童生徒のもののみ取得可能。 * 教師   * なんでも取得可能。
     * @summary SOLANプロジェクトリストを取得する。
     * @param {string} [studentUserId] 児童生徒のユーザーID。
     * @param {number} [schoolYear] 年度。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProject(studentUserId?: string, schoolYear?: number, options?: any) {
        return DefaultApiFp(this.configuration).listProject(studentUserId, schoolYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ取得可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ取得可能。 * 教師   * なんでも取得可能。  # その他    * `createdAt`の昇順（最新が下）で得られる。   * 紐づくプロジェクトが削除されていたら取得できない。
     * @summary SOLANプロジェクトルーブリックリストを取得する。
     * @param {string} projectId 
     * @param {boolean} [expandFiles] &#x60;true&#x60;の場合、返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRubric(projectId: string, expandFiles?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listRubric(projectId, expandFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ更新可能。   * 保護者は、自分に属する児童生徒のもののみ更新可能。   * 以下のいずれかの条件を満たす場合は更新できない。     * プロジェクトの`completed`が`true`の場合     * ふりかえりの`teacherInputPublished`が`true`の場合（例外：   ふりかえりの`guardianComment`のみ更新可能）。     * ふりかえりの更新可能フィールドは`studentComment`、`studentRating`、`guardianComment`。   * ふりかえりの`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。  * 教師   * プロジェクトはなんでも更新可能。   * ふりかえりの更新可能フィールドは`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`、`guardianComment`。   * ふりかえりの `teacherInputPublished`は`true`から`false`に変更可能。   # 制約  * 更新可能フィールドは、`name`、`description`、`startingMonth`、`endingMonth`、`started`、`completed`。 * プロジェクトの`started`を、`true`から`false`に変更することはできない。 * ~~rubricIdsフィールドは、並び替えのみ可。それ以外の操作をすると、エラーとなる。~~  # その他   * プロジェクトが削除されていたら更新できない。
     * @summary SOLANプロジェクトを更新する。
     * @param {string} projectId 
     * @param {ProjectWrite} [projectWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchProject(projectId: string, projectWrite?: ProjectWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchProject(projectId, projectWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ更新可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ更新可能。   * ただし、以下のいずれかの条件を満たす場合は更新できない。     * 紐づくプロジェクトの`completed`が`true`の場合     * 紐づくふりかえりの`teacherInputPublished`の`true`場合     * 学習記録の`teacherInputPublished`が`true`の場合    * 更新可能フィールドは、`process`、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`、`journal`(うち`studentComment`、`studentRating`のみ)。    * 学習記録の`teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。   * 教師   * なんでも更新可能。   * 更新可能フィールドは、`process`、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`、`journal`(うち`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`)。  # その他   * 紐づくプロジェクト、または該当するルーブリックが削除されていたら更新できない。  ~~`orderNum`を更新したければ、SOLANプロジェクトの`rubricIds`を更新する。~~
     * @summary SOLANプロジェクトルーブリックを更新する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {RubricWrite} [rubricWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchRubric(projectId: string, rubricId: string, rubricWrite?: RubricWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchRubric(projectId, rubricId, rubricWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみアップロード可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみアップロード可能。   * ただし、以下のいずれかの条件を満たす場合はアップロードできない。     * 紐づくプロジェクトの`completed`が`true`     * 紐づくふりかえりの`teacherInputPublished`が`true`     * 紐づく学習記録の`teacherInputPublished`が`true`  * 教師   * なんでもアップロード可能。  # その他   * 紐づくプロジェクト、または紐づくルーブリックが削除されていたら作成できない。
     * @summary SOLANプロジェクト学習記録ファイルをアップロードする。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {any} upfile アップロードするファイル。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postJournalFile(projectId: string, rubricId: string, upfile: any, options?: any) {
        return DefaultApiFp(this.configuration).postJournalFile(projectId, rubricId, upfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のもののみ作成可能。   * 保護者は、自分に属する児童生徒のもののみ作成可能。 * 教師   * なんでも作成可能。  # 制約  * 入力可能フィールドは、すべてのフィールド。 * 入力必須フィールドは、`studentUserId`、`schoolYear`。
     * @summary SOLANプロジェクトを作成する。
     * @param {ProjectWrite} [projectWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProject(projectWrite?: ProjectWrite, options?: any) {
        return DefaultApiFp(this.configuration).postProject(projectWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分のプロジェクトのもののみ作成可能。   * 保護者は、自分に属する児童生徒のプロジェクトのもののみ作成可能。   * ただし、以下のいずれかの条件を満たす場合は作成できない。     * 紐づくプロジェクトの`completed`が`true`の場合     * 紐づくふりかえりの`teacherInputPublished`が`true`の場合  * 教師   * なんでも作成可能。  # 制約  * 入力必須フィールドは、`process`。  # その他  ~~SOLANプロジェクトエンティティ内のrubricsフィールドの末尾に追加される。~~   * 紐づくプロジェクトが削除されていたら作成できない。
     * @summary SOLANプロジェクトルーブリックを作成する。
     * @param {string} projectId 
     * @param {RubricWrite} [rubricWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postRubric(projectId: string, rubricWrite?: RubricWrite, options?: any) {
        return DefaultApiFp(this.configuration).postRubric(projectId, rubricWrite, options).then((request) => request(this.axios, this.basePath));
    }
}



import { WordInternalName } from "@/ts/const/Words";
import { User } from "@/ts/objects/user/User";
import { UserColumnDef } from "@/ts/app/columns/UserColumnDef";

export type UserColumn<ColId extends WordInternalName, U extends User, T> = UserColumnDef<ColId, U, T> & {
  readonly value: T;
};

export function attachValue<ColId extends WordInternalName, U extends User, T>(
  col: UserColumnDef<ColId, U, T>,
  user: U
): UserColumn<ColId, U, T> {
  const value = col.getValue(user);
  return {
    ...col,
    value,
  };
}

export function attachValueToCols<ColId extends WordInternalName, U extends User>(
  cols: UserColumnDef<ColId, U, any>[],
  user: U
): UserColumn<ColId, U, any>[] {
  return cols.map(col => attachValue(col, user));
}

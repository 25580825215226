import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { Editable } from "@/ts/objects/editable/Editable";
import { EditableHashedString } from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { Err } from "@/ts/objects/Err";
import { Content as ContentResp } from "@/ts/api/curriculum-service";
import { INECContent } from "@/ts/objects/curriculum/INECContent";

/**
 * 編集可能な数値評価教科の内容構成。
 */
export class EditableNECContent extends EditableObject implements INECContent {
  /**
   * コンストラクタ。
   *
   * @param repo
   * @param savable
   * @param necId
   * @param viewPointId
   * @param contentId
   * @param name
   * @param nameHash
   */
  constructor(
    repo: CurriculumRepository,
    savable: boolean,
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    name: string,
    nameHash: string
  ) {
    super();

    this._name = new EditableHashedString("name", name, nameHash, savable, async ({ value, hash }) => {
      const resp = await repo.patchNECContent(necId, viewPointId, contentId, { name: { value, hash } });
      if (resp instanceof Err) return resp;
      return [resp.name.value, resp.name.hash];
    });
  }

  readonly _name: EditableHashedString;

  protected allEditables(): Editable[] {
    return [this._name];
  }

  get name(): string {
    return this._name.value;
  }

  set name(value: string) {
    this._name.value = value;
  }

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}`;
  }

  static fromContentResp(repo: CurriculumRepository, savable: boolean, resp: ContentResp): EditableNECContent {
    return new EditableNECContent(
      repo,
      savable,
      resp.necId,
      resp.viewPointId,
      resp.contentId,
      resp.name.value,
      resp.name.hash
    );
  }
}







































import Vue, { PropType } from "vue";
import StudentSelectMenu from "@/components/StudentSelectMenu/StudentSelectMenu.vue";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { AppStateStore } from "@/store/AppStateStore";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";
import { ClassStudent } from "@/ts/objects/common/Class";
import { isNullish } from "@/ts/utils";
import CurriculumStudentViewRead from "@/views/curriculum/teacher/CurriculumStudentView/CurriculumStudentViewRead.vue";
import { Grade } from "@/ts/objects/common/Grade";
import { CurriculumPeriodMode } from "@/ts/objects/curriculum/value/CurriculumPeriodMode";
import { Loadable } from "@/ts/Loadable";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { EditableNECEvaluation } from "@/ts/objects/curriculum/editable/EditableNECEvaluation";
import { Err } from "@/ts/objects/Err";
import { EditableEECJournalTree } from "@/ts/objects/curriculum/editable/EditableEECJournal";
import log from "loglevel";
import MessageView from "@/components/MessageView.vue";
import { Messages, messages } from "@/ts/const/Messages";
import { words, Words } from "@/ts/const/Words";
import { MonthValue } from "@/ts/objects/common/MonthValue";

export default Vue.extend({
  name: "CurriculumStudentView",
  components: { MessageView, CurriculumStudentViewRead, StudentSelectMenu },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    curriculumStoreT: { type: Object as PropType<CurriculumStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true },
  },
  async created() {
    const cls = this.curriculumStoreT.class;
    if (isNullish(cls)) {
      this.students = Loadable.error(messages.shouldSelectClass);
      return;
    }

    this.students = Loadable.fromValue(await cls.sortedClassStudents(this.userRepository));

    await this.loadStudentEvaluationsAndJournals();
  },
  data(): {
    students: Loadable<ClassStudent[]>;

    studentEvaluations: Loadable<EditableNECEvaluation[]>;
    studentJournalTrees: Loadable<EditableEECJournalTree[]>;

    words: Words;
    messages: Messages;
  } {
    return {
      students: Loadable.idle(),

      studentEvaluations: Loadable.idle(),
      studentJournalTrees: Loadable.idle(),

      words,
      messages,
    };
  },
  computed: {
    currentPath(): string {
      return this.curriculumStoreT.currentPath;
    },
    studentUserId(): string | null {
      return this.curriculumStoreT.studentUserId;
    },
    student(): ClassStudent | null {
      const students = this.students.data;
      const studentUserId = this.studentUserId;
      if (students === null || studentUserId === null) return null;
      return students.find(s => s.studentUserId === studentUserId) ?? null;
    },
    schoolYear(): number | null {
      return this.curriculumStoreT.schoolYear;
    },
    grade(): Grade | null {
      return this.curriculumStoreT.grade;
    },
    periodMode(): CurriculumPeriodMode | null {
      return this.curriculumStoreT.periodMode;
    },
    thisMonth(): MonthValue {
      return this.curriculumStoreT.thisMonth;
    },
    selectedMonth(): MonthValue | null {
      return this.curriculumStoreT.selectedMonth;
    },
    curriculumRname(): string | null {
      return this.curriculumStoreT.selectedCurriculumRname;
    },
    neCurriculumTrees(): Loadable<NECurriculumTree[]> {
      return this.curriculumStoreT.neCurriculumTrees;
    },
    eeCurriculumTrees(): Loadable<EECurriculumTree[]> {
      return this.curriculumStoreT.eeCurriculumTrees;
    },
  },
  watch: {
    studentUserId() {
      this.loadStudentEvaluationsAndJournals();
    },
    schoolYear() {
      this.loadStudentEvaluationsAndJournals();
    },
    grade() {
      this.loadStudentEvaluationsAndJournals();
    },
  },
  methods: {
    async loadStudentEvaluationsAndJournals() {
      log.debug(`CurriculumStudentView:loadStudentEvaluationsAndJournals: Started.`);

      this.studentEvaluations = Loadable.idle();
      this.studentJournalTrees = Loadable.idle();

      const studentUserId = this.studentUserId;
      const schoolYear = this.schoolYear;
      const grade = this.grade;
      if (studentUserId === null || schoolYear === null || grade === null) return;

      this.studentEvaluations = Loadable.loading();
      this.studentJournalTrees = Loadable.loading();

      const [evaluationResp, journalResp] = await Promise.all<
        EditableNECEvaluation[] | Err,
        EditableEECJournalTree[] | Err
      >([
        this.curriculumRepository.listEditableNECEvaluations(
          true,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          studentUserId,
          schoolYear,
          grade
        ),
        this.curriculumRepository.listEditableEECJournals(
          false,
          true,
          undefined,
          undefined,
          studentUserId,
          schoolYear,
          grade
        ),
      ]);
      if (evaluationResp instanceof Err) {
        log.error(
          `CurriculumStudentView:loadStudentEvaluationsAndJournals: Error fetching evaluations: ${evaluationResp.internalMessage}`
        );
        this.studentEvaluations = Loadable.error();
        this.studentJournalTrees = Loadable.error();
        return;
      }
      if (journalResp instanceof Err) {
        log.error(
          `CurriculumStudentView:loadStudentEvaluationsAndJournals: Error fetching evaluations: ${journalResp.internalMessage}`
        );
        this.studentEvaluations = Loadable.error();
        this.studentJournalTrees = Loadable.error();
        return;
      }

      this.studentEvaluations = Loadable.fromValue(evaluationResp);
      this.studentJournalTrees = Loadable.fromValue(journalResp);
    },
    getPath(
      periodMode: CurriculumPeriodMode | null,
      month: MonthValue | null | undefined,
      resourceName: string | null
    ): string {
      return this.curriculumStoreT.path(
        "studentview",
        resourceName,
        this.studentUserId ?? undefined,
        periodMode ?? undefined,
        month ?? undefined
      );
    },
    studentUserIdToPath(studentUserId: string) {
      log.debug(`CurriculumStudentView:studentUserIdToPath: curriculumRname=${this.curriculumRname}`);
      return this.curriculumStoreT.path(
        "studentview",
        this.curriculumRname,
        studentUserId,
        this.periodMode ?? undefined,
        this.selectedMonth ?? undefined
      );
    },
  },
});

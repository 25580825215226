











































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { Messages, messages } from "@/ts/const/Messages";
import { SolanStoreT } from "@/store/SolanStore";
import { Err } from "@/ts/objects/Err";
import { emptySaveResult, SaveResult } from "@/ts/objects/editable/SaveResult";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { words, Words } from "@/ts/const/Words";
import SolanLookbackSection from "@/views/solan/components/SolanLookbackSection/SolanLookbackSection.vue";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "SolanStudentViewLookbackT",
  components: { SolanLookbackSection, MessageView },
  props: {
    solanStoreT: { type: Object as PropType<SolanStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true },
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
    });
    this.reloadData();
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      this.reloadData();
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;
    lookback: EditableSolanLookback | null;

    pageLeaveService: PageLeaveService | null;

    words: Words;
    messages: Messages;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,
      lookback: null,

      pageLeaveService: null,

      words,
      messages,
    };
  },
  computed: {
    needSave(): boolean {
      return this.lookback?.needSave() ?? false;
    },

    studentComment(): string | null {
      return this.lookback?.studentComment ?? null;
    },
    studentRating(): string | null {
      return this.lookback?.studentRating ?? null;
    },

    teacherComment(): string | null {
      return this.lookback?.teacherComment ?? null;
    },
    teacherRating(): string | null {
      return this.lookback?.teacherRating ?? null;
    },
    teacherInputPublished(): boolean | null {
      return this.lookback?.teacherInputPublished ?? null;
    },

    guardianComment(): string | null {
      return this.lookback?.guardianComment ?? null;
    },
  },
  methods: {
    handleStudentCommentChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.studentComment = value;
    },
    handleStudentRatingChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.studentRating = value;
    },
    handleTeacherCommentChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.teacherComment = value;
    },
    handleTeacherRatingChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.teacherRating = value;
    },

    async reloadData() {
      this.lookback = null;
      this.messageView = null;

      const studentUserId = this.$route.params["solanStudentUserId"];
      if (isNullish(studentUserId)) {
        this.messageView = { message: messages.pleaseSelectStudent };
        return;
      }

      await this.solanStoreT.reloadProjectsIfStudentChanged({
        userRepository: this.userRepository,
        solanRepository: this.solanRepository,
        studentUserId,
      });

      const projectId = this.$route.params["solanProjectId"];
      if (isNullish(projectId)) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      const project = await this.solanStoreT.getProject(projectId).getDataWithTimeout();
      if (isNullish(project)) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      if (!project.started) {
        this.messageView = { message: messages.solanThemeNotStarted };
        return;
      }

      const resp = await this.solanRepository.getEditableSolanLookback(project.projectId, true, true, false);
      if (resp instanceof Err) {
        log.debug(`Error loading lookbacks: ${JSON.stringify(resp)}`);
        this.messageView = { message: messages.failedToLoadData };
        return;
      }

      this.lookback = resp;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      log.debug("SAVING!");
      return (await this.lookback?.saveAllChanges(force)) ?? emptySaveResult();
    },
  },
});

import { WordInternalName, words } from "@/ts/const/Words";
import { User } from "@/ts/objects/user/User";
import { ColumnType } from "@/ts/app/columns/ColumnTypes";
import { hasValue } from "@/ts/utils";

export type UserColumnDef<ColId extends WordInternalName, U extends User, T> = {
  readonly id: ColId;
  readonly name: string;
  readonly type: ColumnType;

  /**
   * 値を取得する。
   */
  readonly getValue: (user: U) => T;
};

export type UserColumnDefPartial<ColId extends WordInternalName, U extends User, T> = {
  id: ColId;
  name?: string;
  type: ColumnType;

  getValue: (user: U) => T;
};

export function genUserColumnDefs<ColId extends WordInternalName, U extends User, T>(
  definitions: UserColumnDefPartial<ColId, U, T>[]
): UserColumnDef<ColId, U, T>[] {
  return definitions.map(d => genUserColumnDef(d));
}

export function genUserColumnDef<ColId extends WordInternalName, U extends User, T>(
  definition: UserColumnDefPartial<ColId, U, T> | UserColumnDef<ColId, U, T>
): UserColumnDef<ColId, U, T> {
  return {
    id: definition.id,
    name: hasValue(definition.name) ? definition.name : words[definition.id].d,
    type: definition.type,

    getValue: definition.getValue,
  };
}

import { Err } from "@/ts/objects/Err";
import { EditableValue } from "@/ts/objects/editable/value/EditableValue";
import log from "loglevel";

export abstract class EditablePrimitiveValue<T> extends EditableValue {
  readonly name: string;

  /**
   * 現在編集中の、ローカルの値。
   * 編集されるたびに更新される。
   */
  private _value: T;

  /**
   * 最後の保存リクエストで送った値。
   *
   * 初期値は _value と同じ値。
   */
  private _lastRequestValue: T;

  /**
   * サーバに保存済の値。
   * 保存リクエストが成功した時に更新される。
   *
   * 初期値は _value と同じ値。
   */
  private _savedValue: T;

  /**
   * サーバに保存済の値のハッシュ。サーバへのリクエスト時に添える。
   * 保存リクエストが成功した時に更新される。
   */
  private _savedValueHash: string | null;

  /**
   * サーバへ保存する処理を行う関数。
   */
  private readonly _saver: (value: T, hash: string | null) => Promise<[T, string | null] | Err>;

  get value(): T {
    return this._value;
  }

  set value(v: T) {
    this._value = v;
  }

  protected _needSave(): boolean {
    const needSave = this._value !== this._savedValue;
    if (needSave) log.debug(`${this.name}: needs save: ${this._savedValue} -> ${this._value}`);
    return needSave;
  }

  protected async _save(): Promise<Err | null> {
    const requestValue = this._value;
    this._lastRequestValue = requestValue;
    const resp = await this._saver(requestValue, this._savedValueHash);
    if (resp instanceof Err) return resp;
    [this._savedValue, this._savedValueHash] = resp;
    return null;
  }

  protected _changedAfterLastRequest(): boolean {
    return this._value !== this._lastRequestValue;
  }

  protected constructor(
    name: string,
    value: T,
    hash: string | null,
    savable: boolean,
    saver: (value: T, hash: string | null) => Promise<[T, string | null] | Err>
  ) {
    super(savable);
    this.name = name;
    this._value = value;
    this._lastRequestValue = value;
    this._savedValue = value;
    this._savedValueHash = hash;
    this._saver = saver;
  }
}

export class EditableBoolean extends EditablePrimitiveValue<boolean> {
  constructor(name: string, value: boolean, savable: boolean, saver: (value: boolean) => Promise<boolean | Err>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _saver = async (value: boolean, hash: string | null): Promise<[boolean, string | null] | Err> => {
      const resp = await saver(value);
      if (resp instanceof Err) return resp;
      return [resp, null];
    };
    super(name, value, null, savable, _saver);
  }
}

export class EditableNumber extends EditablePrimitiveValue<number> {
  constructor(name: string, value: number, savable: boolean, saver: (value: number) => Promise<number | Err>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _saver = async (value: number, hash: string | null): Promise<[number, string | null] | Err> => {
      const resp = await saver(value);
      if (resp instanceof Err) return resp;
      return [resp, null];
    };
    super(name, value, null, savable, _saver);
  }
}

export class EditableString extends EditablePrimitiveValue<string> {
  constructor(name: string, value: string, savable: boolean, saver: (value: string) => Promise<string | Err>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _saver = async (value: string, hash: string | null): Promise<[string, string | null] | Err> => {
      const resp = await saver(value);
      if (resp instanceof Err) return resp;
      return [resp, null];
    };
    super(name, value, null, savable, _saver);
  }
}

export class EditableHashedString extends EditablePrimitiveValue<string> {
  constructor(
    name: string,
    value: string,
    hash: string | null,
    savable: boolean,
    saver: ({ value, hash }: { value: string; hash: string }) => Promise<[string, string] | Err>
  ) {
    const _saver = async (value: string, hash: string | null): Promise<[string, string | null] | Err> => {
      return await saver({ value, hash: hash ?? "" });
    };
    super(name, value, hash, savable, _saver);
  }
}













import Vue from "vue";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "SolanJournalsTableHeaderRow",
  props: {
    fontSize: { type: Number, required: true },

    processColWidth: { type: Number, required: true },
    ratingColWidth: { type: Number, required: true },
  },
  data(): { words: Words } {
    return { words };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--font-size": `${this.fontSize}px`,
        "--process-col-width": `${this.processColWidth}px`,
        "--rating-col-width": `${this.ratingColWidth}px`,
      };
    },
  },
});

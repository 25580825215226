import { UserColumn } from "@/ts/app/columns/UserColumn";
import { genUserColumnDefs } from "@/ts/app/columns/UserColumnDef";
import { Guardian, GuardianProfile } from "@/ts/objects/user/Guardian";
import { GuardianColumnDef } from "@/ts/app/columns/guardian/GuardianColumn";
import { ImageType, StringType } from "@/ts/app/columns/ColumnTypes";

export type GuardianProfileColumnId =
  | "userId"
  | "googleMail"
  | "photoUrl"
  | "name1"
  | Exclude<keyof GuardianProfile, "children">;

export type GuardianProfileColumnDef<T> = GuardianColumnDef<GuardianProfileColumnId, T>;

export type GuardianProfileColumn<T> = UserColumn<GuardianProfileColumnId, Guardian, T>;

export function guardianProfileColumnDefs(): GuardianProfileColumnDef<any>[] {
  return genUserColumnDefs<GuardianProfileColumnId, Guardian, any>([
    {
      id: "userId",
      type: StringType,
      getValue: g => g.userId,
    },
    {
      id: "googleMail",
      type: StringType,
      getValue: g => g.googleMail,
    },
    {
      id: "photoUrl",
      type: StringType,
      getValue: g => g.photoUrl,
    },
    {
      id: "name1",
      type: StringType,
      getValue: g => g.name,
    },

    {
      id: "nameKana1",
      type: StringType,
      getValue: g => g.nameKana1,
    },
    {
      id: "familyName1",
      type: StringType,
      getValue: g => g.familyName1,
    },
    {
      id: "givenName1",
      type: StringType,
      getValue: g => g.givenName1,
    },
    {
      id: "name2",
      type: StringType,
      getValue: g => g.name2,
    },
    {
      id: "nameKana2",
      type: StringType,
      getValue: g => g.nameKana2,
    },
    {
      id: "familyName2",
      type: StringType,
      getValue: g => g.familyName2,
    },
    {
      id: "givenName2",
      type: StringType,
      getValue: g => g.givenName2,
    },

    {
      id: "email1",
      type: StringType,
      getValue: g => g.email1,
    },
    {
      id: "email2",
      type: StringType,
      getValue: g => g.email2,
    },
    {
      id: "mobilePhone1",
      type: StringType,
      getValue: g => g.mobilePhone1,
    },
    {
      id: "mobilePhone2",
      type: StringType,
      getValue: g => g.mobilePhone2,
    },
    {
      id: "landlinePhone1",
      type: StringType,
      getValue: g => g.landlinePhone1,
    },
    {
      id: "landlinePhone2",
      type: StringType,
      getValue: g => g.landlinePhone2,
    },
    {
      id: "job1",
      type: StringType,
      getValue: g => g.job1,
    },
    {
      id: "job2",
      type: StringType,
      getValue: g => g.job2,
    },
    {
      id: "workPlace1",
      type: StringType,
      getValue: g => g.workPlace1,
    },
    {
      id: "workPlace2",
      type: StringType,
      getValue: g => g.workPlace2,
    },
    {
      id: "pictureGcsUrl1",
      type: ImageType,
      getValue: g => g.pictureGcsUrl1,
    },
    {
      id: "pictureGcsUrl2",
      type: ImageType,
      getValue: g => g.pictureGcsUrl2,
    },
    {
      id: "emergencyContactRelationship1",
      type: StringType,
      getValue: g => g.emergencyContactRelationship1,
    },
    {
      id: "emergencyContactPhone1",
      type: StringType,
      getValue: g => g.emergencyContactPhone1,
    },
    {
      id: "emergencyContactRelationship2",
      type: StringType,
      getValue: g => g.emergencyContactRelationship2,
    },
    {
      id: "emergencyContactPhone2",
      type: StringType,
      getValue: g => g.emergencyContactPhone2,
    },
    {
      id: "emergencyContactRelationship3",
      type: StringType,
      getValue: g => g.emergencyContactRelationship3,
    },
    {
      id: "emergencyContactPhone3",
      type: StringType,
      getValue: g => g.emergencyContactPhone3,
    },
  ]);
}

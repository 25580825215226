import { StudentClass } from "@/ts/objects/common/StudentClass";
import uniq from "lodash/uniq";

export class StudentInfo {
  readonly studentUserId: string;
  readonly name: string;
  readonly iconUrl: string;
  readonly classes: StudentClass[];
  readonly _minSchoolYear: number | null;
  readonly _maxSchoolYear: number | null;

  constructor(studentUserId: string, name: string, iconUrl: string, classes: StudentClass[]) {
    this.studentUserId = studentUserId;
    this.name = name;
    this.iconUrl = iconUrl;
    this.classes = classes;

    const schoolYears = uniq(classes.map(c => c.schoolYear));
    this._minSchoolYear = schoolYears.length > 0 ? Math.min(...schoolYears) : null;
    this._maxSchoolYear = schoolYears.length > 0 ? Math.max(...schoolYears) : null;
  }

  classOfSchoolYear(schoolYear: number): StudentClass | null {
    return this.classes.find(c => c.schoolYear === schoolYear) ?? null;
  }
}




























































































































import Vue, { PropType } from "vue";
import StudentActivityTableProcessCell from "@/components/student-activity-table/StudentActivityTableProcessCell/StudentActivityTableProcessCell.vue";
import StudentActivityTableReadonlyTextCell from "@/components/student-activity-table/StudentActivityTableReadonlyTextCell/StudentActivityTableReadonlyTextCell.vue";
import StudentActivityTableTextCell from "@/components/student-activity-table/StudentActivityTableTextCell/StudentActivityTableTextCell.vue";
import StudentActivityTableRatingCell from "@/components/student-activity-table/StudentActivityTableRatingCell/StudentActivityTableRatingCell.vue";
import StudentActivityTablePublishStateCell from "@/components/student-activity-table/StudentActivityTablePublishStateCell/StudentActivityTablePublishStateCell.vue";
import StudentActivityTableSelectRowCheckboxCell from "@/components/student-activity-table/StudentActivityTableSelectRowCheckboxCell/StudentActivityTableSelectRowCheckboxCell.vue";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import {
  SolanJournalsTColFilterState,
  SolanJournalsTStyleProps,
} from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTModel";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "SolanJournalsTTableLookbackRow",
  components: {
    StudentActivityTableSelectRowCheckboxCell,
    StudentActivityTablePublishStateCell,
    StudentActivityTableRatingCell,
    StudentActivityTableTextCell,
    StudentActivityTableReadonlyTextCell,
    StudentActivityTableProcessCell,
  },
  props: {
    lookback: { type: Object as PropType<EditableSolanLookback>, required: true },
    selected: { type: Boolean, required: true },

    onSelect: { type: Function as PropType<(selected: boolean) => void>, required: true },
    onCellBlur: { type: Function as PropType<() => void>, required: true },

    colFilterState: { type: Object as PropType<SolanJournalsTColFilterState>, required: true },
    highlightUnsaved: { type: Boolean, required: true },

    styleProps: { type: Object as PropType<SolanJournalsTStyleProps>, required: true },
  },
  data(): { words: Words } {
    return { words };
  },
});

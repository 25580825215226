export const publishStateFilterValues = ["published", "unpublished"] as const;
export type PublishStateFilterValue = typeof publishStateFilterValues[number];

export function isPublishStateFilterValue(value: any): value is PublishStateFilterValue {
  return typeof value === "string" && publishStateFilterValues.some(v => v === value);
}

export function satisfiesPublishStateFilter(published: boolean, filterValues: PublishStateFilterValue[]): boolean {
  if (published) return filterValues.includes("published");
  else return filterValues.includes("unpublished");
}

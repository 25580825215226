import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { DisplayableErr, Err, InternalErr } from "@/ts/objects/Err";
import {
  CurriculumTestDataPartialArgs,
  CurriculumTestDataSet,
  EECJournalFileArg,
  NECurriculumArg,
  partialArgsToCurriculumTestDataSet,
} from "@/test-tools/CurriculumTestDataTypes";
import { Grade } from "@/ts/objects/common/Grade";
import { EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import { NECEvaluation } from "@/ts/objects/curriculum/value/NECEvaluation";
import { EECJournalFile, EECJournalTree } from "@/ts/objects/curriculum/value/EECJournal";
import log from "loglevel";
import { delay, isNullish, necContentMonthRnameToId } from "@/ts/utils";
import {
  Activity as ActivityResp,
  ActivityWrite,
  Content as ContentResp,
  ContentMonth as ContentMonthResp,
  ContentMonthWrite,
  ContentWrite,
  EECurriculum as EECurriculumResp,
  Evaluation as EvaluationResp,
  EvaluationWrite,
  Journal as JournalResp,
  JournalWrite,
  NECurriculum as NECurriculumResp,
} from "@/ts/api/curriculum-service";
import { EditableNECEvaluation } from "@/ts/objects/curriculum/editable/EditableNECEvaluation";
import { EditableNECContent } from "@/ts/objects/curriculum/editable/EditableNECContent";
import { EditableNECContentMonth } from "@/ts/objects/curriculum/editable/EditableNECContentMonth";
import { getCurriculumTestData } from "@/test-tools/CurriculumTestData";
import {
  EditableEECJournal,
  EditableEECJournalStudentTree,
  EditableEECJournalTree,
} from "@/ts/objects/curriculum/editable/EditableEECJournal";
import { EditableEECActivity } from "@/ts/objects/curriculum/editable/EditableEECActivity";
import { getUserTestData, UserTestDataSet } from "@/test-tools/UserTestData";
import { MonthValue, monthValues } from "@/ts/objects/common/MonthValue";

export class CurriculumRepositoryMock extends CurriculumRepository {
  constructor(testData?: CurriculumTestDataPartialArgs, allWritesShouldFail?: boolean) {
    super();

    this.testData = isNullish(testData) ? getCurriculumTestData() : partialArgsToCurriculumTestDataSet(testData);
    this._allWritesShouldFail = allWritesShouldFail ?? false;
    // this._allWritesShouldFail = true;
  }

  /**
   * trueの場合、すべてのwriteオペレーション(post, patch等)が失敗する。
   */
  private readonly _allWritesShouldFail: boolean;

  private readonly _errorOnArtificialFail = new DisplayableErr(
    "CurriculumRepositoryMock: This is an artificial fail.",
    "デバッグ用エラーです。"
  );

  private _userTestData: UserTestDataSet | null = null;
  private get userTestData(): UserTestDataSet {
    if (this._userTestData !== null) return this._userTestData;
    this._userTestData = getUserTestData();
    return this._userTestData;
  }

  private testData: CurriculumTestDataSet;

  async getNECurriculum(this: this, necId: string): Promise<NECurriculumTree | Err> {
    const necArg = Object.values(this.testData.neCurriculum).find(nec => nec.necId === necId);
    if (necArg === undefined)
      return new InternalErr(`CurriculumRepositoryMock.getNECurriculum: /neCurriculums/${necId} not found.`);
    return necArg.toTree();
  }

  async listNECurriculums(this: this, schoolYear: number, grade: Grade): Promise<NECurriculumTree[] | Err> {
    await delay(250);
    log.debug(`CurriculumRepositoryMock.listNECurriculums: schoolYear=${schoolYear}, grade=${grade.value}`);
    const data = Object.values(this.testData.neCurriculum)
      .filter(nec => nec.schoolYear === schoolYear && nec.grade.value === grade.value)
      .map(d => d.toTree());
    log.debug(
      `CurriculumRepositoryMock.listNECurriculums: schoolYear=${schoolYear}, grade=${
        grade.value
      }, data=${JSON.stringify(data)}`
    );
    return data;
  }

  async uploadNECSyllabusFile(
    this: this,
    necId: string,
    _file: any,
    _timeoutMillis: number
  ): Promise<NECurriculumResp | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return {
      self: `/neCurriculums/${necId}`,
      necId,
      schoolYear: 2000,
      grade: "e1",
      name: { value: "", hash: "" },
      orderNum: 0,
      syllabusFileGcsObjectPath: "syllabus-file-gcs-object-path",
    };
  }

  async getEECurriculum(this: this, eecId: string): Promise<EECurriculumTree | Err> {
    const eecArg = Object.values(this.testData.eeCurriculum).find(eec => eec.eecId === eecId);
    if (eecArg === undefined)
      return new InternalErr(`CurriculumRepositoryMock.getEECurriculum: /eeCurriculums/${eecId} not found.`);
    return eecArg.toTree();
  }

  async listEECurriculums(this: this, schoolYear: number, grade: Grade): Promise<EECurriculumTree[] | Err> {
    return Object.values(this.testData.eeCurriculum)
      .filter(eec => eec.schoolYear === schoolYear && eec.grade.value === grade.value)
      .map(d => d.toTree());
  }

  async uploadEECSyllabusFile(
    this: this,
    eecId: string,
    _file: any,
    _timeoutMillis: number
  ): Promise<EECurriculumResp | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return {
      self: `/eeCurriculums/${eecId}`,
      eecId,
      schoolYear: 2000,
      grade: "e1",
      name: { value: "", hash: "" },
      orderNum: 0,
      syllabusFileGcsObjectPath: "syllabus-file-gcs-object-path",
    };
  }

  async sortNECViewPoints(this: this, _necId: string, _viewPointIds: string[]): Promise<void | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
  }

  async listEditableNECContents(this: this, savable: boolean, necId: string): Promise<EditableNECContent[] | Err> {
    const nec = this.testData.neCurriculum[necId];
    if (nec === undefined) return [];
    return nec.viewPoints.flatMap(vp => vp.contents).map(c => c.toEditable(this, savable));
  }

  async postAndGetEditableNECContent(
    this: this,
    savable: boolean,
    necId: string,
    viewPointId: string
  ): Promise<{ content: EditableNECContent; contentMonths: EditableNECContentMonth[] } | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    const contentId = "content999";
    return {
      content: new EditableNECContent(this, savable, necId, viewPointId, contentId, "", ""),
      contentMonths: monthValues.map(
        month => new EditableNECContentMonth(this, savable, necId, viewPointId, contentId, month, false, "", "")
      ),
    };
  }

  async patchNECContent(
    this: this,
    necId: string,
    viewPointId: string,
    contentId: string,
    contentWrite: ContentWrite
  ): Promise<ContentResp | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return {
      self: `/neCurriculums/${necId}/viewPoints/${viewPointId}/contents/${contentId}`,
      necId,
      viewPointId,
      contentId,
      name: contentWrite.name ?? { value: "", hash: "" },
    };
  }

  async deleteNECContent(this: this, _necId: string, _viewPointId: string, _contentId: string): Promise<void | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return;
  }

  async sortNECContents(this: this, _necId: string, _viewPointId: string, _contentIds: string[]): Promise<void | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
  }

  async listEditableNECContentMonths(
    this: this,
    savable: boolean,
    necId: string
  ): Promise<EditableNECContentMonth[] | Err> {
    const nec = this.testData.neCurriculum[necId];
    if (nec === undefined) return [];
    return nec.viewPoints.flatMap(vp => vp.contents).flatMap(c => c.toEditableMonths(this, savable));
  }

  async patchNECContentMonth(
    this: this,
    necId: string,
    viewPointId: string,
    contentId: string,
    month: MonthValue,
    contentMonthWrite: ContentMonthWrite
  ): Promise<ContentMonthResp | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return {
      self: `/neCurriculums/${necId}/viewPoints/${viewPointId}/contents/${contentId}/months/${month}`,
      necId,
      viewPointId,
      contentId,
      month,
      enabled: contentMonthWrite.enabled ?? false,
      text: contentMonthWrite.text ?? { value: "", hash: "" },
    };
  }

  async listNECEvaluations(
    this: this,
    necId?: string,
    viewPointId?: string,
    contentId?: string,
    month?: MonthValue,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<NECEvaluation[] | Err> {
    const studentUserIds = this.listStudentUserIds(studentUserId, classId);
    const necs = this.listNECsFrom(necId, schoolYear, grade);
    const contentMonthRnames = necs
      .flatMap(nec => nec.viewPoints)
      .flatMap(vp => vp.contents)
      .flatMap(c => c.monthResourceNames);

    return contentMonthRnames.flatMap(contentMonthRname => {
      return studentUserIds.map(_studentUserId => {
        const evaluationRname = `${contentMonthRname}/evaluations/${_studentUserId}`;
        const evaluation = this.testData.necEvaluation.find(ev => ev.resourceName === evaluationRname);
        if (!isNullish(evaluation)) {
          return evaluation;
        } else {
          const ids = necContentMonthRnameToId(contentMonthRname);
          if (ids === null) throw new Error(`CurriculumRepositoryMock.listNECEvaluations: check failed`);
          const { necId, viewPointId, contentId, month } = ids;
          return new NECEvaluation(necId, viewPointId, contentId, month, _studentUserId, "", false);
        }
      });
    });
  }

  async listEditableNECEvaluations(
    this: this,
    savable: boolean,
    necId?: string,
    viewPointId?: string,
    contentId?: string,
    month?: MonthValue,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableNECEvaluation[] | Err> {
    const evaluations = await this.listNECEvaluations(
      necId,
      viewPointId,
      contentId,
      month,
      classId,
      studentUserId,
      schoolYear,
      grade
    );
    if (evaluations instanceof Err) return evaluations;

    return evaluations.map(ev => EditableNECEvaluation.fromNECEvaluation(this, savable, ev));
  }

  async patchNECEvaluation(
    this: this,
    necId: string,
    viewPointId: string,
    contentId: string,
    month: MonthValue,
    studentUserId: string,
    evaluationWrite: EvaluationWrite
  ): Promise<EvaluationResp | Err> {
    log.debug(`CurriculumRepositoryMock.patchNECEvaluation`);
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return {
      self: `/neCurriculums/${necId}/viewPoints/${viewPointId}/contents/${contentId}/months/${month}/evaluations/${studentUserId}`,
      necId,
      viewPointId,
      contentId,
      month,
      studentUserId,
      rating: evaluationWrite.rating ?? "",
      teacherInputPublished: evaluationWrite.teacherInputPublished ?? false,
    };
  }

  async listEditableEECActivities(savable: boolean, eecId: string): Promise<EditableEECActivity[] | Err> {
    const activities = Object.values(this.testData.eeCurriculum)
      .find(eec => eec.eecId === eecId)
      ?.months?.flatMap(m => m.activities)
      ?.map(a => a.toEditable(this, savable));
    if (isNullish(activities)) return [];
    return activities;
  }

  async postAndGetEditableEECActivity(
    this: this,
    savable: boolean,
    eecId: string,
    month: MonthValue,
    activityWrite: ActivityWrite
  ): Promise<EditableEECActivity | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    const activityId = "activity999";
    const resp = {
      self: `/eeCurriculums/${eecId}/months/${month}/activities/${activityId}`,
      eecId,
      month,
      activityId,
      enabled: activityWrite.enabled ?? false,
      text: activityWrite.text ?? { value: "", hash: "" },
    };
    return EditableEECActivity.fromActivityResp(this, savable, resp);
  }

  async patchEECActivity(
    this: this,
    eecId: string,
    month: MonthValue,
    activityId: string,
    activityWrite: ActivityWrite
  ): Promise<ActivityResp | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return {
      self: `/eeCurriculums/${eecId}/months/${month}/activities/${activityId}`,
      eecId,
      month,
      activityId,
      enabled: activityWrite.enabled ?? false,
      text: activityWrite.text ?? { value: "", hash: "" },
    };
  }

  async deleteEECActivity(this: this, _eecId: string, _month: MonthValue, _activityId: string): Promise<void | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
  }

  async sortEECActivities(this: this, _eecId: string, _month: MonthValue, _activityIds: string[]): Promise<void | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
  }

  async listEditableEECJournalStudents(
    this: this,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    eecId: string | undefined,
    studentUserId?: string,
    classId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableEECJournalStudentTree[] | Err> {
    const studentUserIds = this.listStudentUserIds(studentUserId, classId);
    const eecIds = this.listEECIdsFrom(eecId, schoolYear, grade);
    return Object.values(this.testData.eecJournalStudent)
      .filter(s => eecIds.includes(s.eecId) && studentUserIds.includes(s.studentUserId))
      .map(s => s.toEditableTree(this, studentInputSavable, teacherInputSavable));
  }

  async listEECJournals(
    this: this,
    eecId?: string,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EECJournalTree[] | Err> {
    const studentUserIds = this.listStudentUserIds(studentUserId, classId);
    const eecIds = this.listEECIdsFrom(eecId, schoolYear, grade);
    return Object.values(this.testData.eecJournalStudent)
      .filter(s => eecIds.includes(s.eecId) && studentUserIds.includes(s.studentUserId))
      .flatMap(s => s.journals)
      .map(j => j.toTree());
  }

  async listEditableEECJournals(
    this: this,
    studentInputSavable: boolean,
    teacherInputSavable: boolean,
    eecId?: string,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableEECJournalTree[] | Err> {
    const studentUserIds = this.listStudentUserIds(studentUserId, classId);
    const eecIds = this.listEECIdsFrom(eecId, schoolYear, grade);
    return Object.values(this.testData.eecJournalStudent)
      .filter(s => eecIds.includes(s.eecId) && studentUserIds.includes(s.studentUserId))
      .flatMap(s => s.journals)
      .map(j => j.toEditableTree(this, studentInputSavable, teacherInputSavable));
  }

  async postAndGetEditableEECJournal(
    this: this,
    studentInputSavable: boolean,
    teacherInputSavable: boolean,
    eecId: string,
    studentUserId: string,
    journalWrite: JournalWrite
  ): Promise<EditableEECJournalTree | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    const journalId = "journal999";
    const resp = {
      self: `/eeCurriculums/${eecId}/journalStudents/${studentUserId}/journals/${journalId}`,
      eecId,
      studentUserId,
      journalId,
      month: journalWrite.month ?? 4,
      activity: journalWrite.activity ?? { value: "", hash: "" },
      studentComment: journalWrite.studentComment ?? { value: "", hash: "" },
      teacherComment: journalWrite.teacherComment ?? { value: "", hash: "" },
      teacherInputPublished: journalWrite.teacherInputPublished ?? false,
      createdAt: "2000-01-01T00:00:00Z",
      studentInputLocked: false,
    };
    return new EditableEECJournalTree(
      this,
      EditableEECJournal.fromJournalResp(this, teacherInputSavable, studentInputSavable, resp),
      []
    );
  }

  async patchEECJournal(
    eecId: string,
    studentUserId: string,
    journalId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return {
      self: `/eeCurriculums/${eecId}/journalStudents/${studentUserId}/journals/${journalId}`,
      eecId,
      studentUserId,
      journalId,
      month: journalWrite.month ?? 4,
      activity: journalWrite.activity ?? { value: "", hash: "" },
      studentComment: journalWrite.studentComment ?? { value: "", hash: "" },
      teacherComment: journalWrite.teacherComment ?? { value: "", hash: "" },
      teacherInputPublished: journalWrite.teacherInputPublished ?? false,
      createdAt: "2000-01-01T00:00:00Z",
      studentInputLocked: false,
    };
  }

  async deleteEECJournal(this: this, _eecId: string, _studentUserId: string, _journalId: string): Promise<void | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return;
  }

  async listEECJournalFiles(
    this: this,
    eecId: string,
    studentUserId: string,
    journalId: string
  ): Promise<EECJournalFile[] | Err> {
    const journal = Object.values(this.testData.eecJournalStudent)
      .filter(s => s.eecId === eecId && s.studentUserId === studentUserId)
      .flatMap(s => s.journals)
      .find(j => j.journalId === journalId);
    if (journal === undefined) return [];

    return journal.journalFiles.map(jf => jf.toObject());
  }

  async uploadEECJournalFile(
    this: this,
    eecId: string,
    studentUserId: string,
    journalId: string,
    _file: any,
    _timeoutMillis: number
  ): Promise<EECJournalFile | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    const journalFileId = "journalFile999";
    return new EECJournalFileArg({ eecId, studentUserId, journalId, journalFileId }).toObject();
  }

  async deleteEECJournalFile(
    this: this,
    _eecId: string,
    _studentUserId: string,
    _journalId: string,
    _journalFileId: string
  ): Promise<void | Err> {
    await delay(500);
    if (this._allWritesShouldFail) return this._errorOnArtificialFail;
    return;
  }

  private listStudentUserIds(studentUserId?: string, classId?: string): string[] {
    log.debug(`CurriculumRepositoryMock.listStudentUserIds: studentUserId=${studentUserId}, classId=${classId}`);

    if (studentUserId !== undefined) {
      if (classId !== undefined)
        throw new Error(
          `CurriculumRepositoryMock.listStudentUserIds: when studentUserId has value, classId must be undefined`
        );
      return [studentUserId];
    }

    if (classId === undefined)
      throw new Error(
        `CurriculumRepositoryMock.listStudentUserIds: when studentUserId is undefined, classId must have value`
      );

    const students = this.userTestData.classes[classId]?.sortedClassStudentsData();
    if (isNullish(students)) return [];

    return students.map(s => s.studentUserId);
  }

  private listNECsFrom(necId?: string, schoolYear?: number, grade?: Grade): NECurriculumArg[] {
    if (necId !== undefined) {
      if (schoolYear !== undefined || grade !== undefined)
        throw new Error(
          `CurriculumRepositoryMock.listNECsFrom: when eecId has value, schoolYear and grade must be undefined`
        );
      const nec = Object.values(this.testData.neCurriculum).find(c => c.necId === necId);
      if (nec === undefined) throw new Error(`CurriculumRepositoryMock.listNECsFrom: cannot find nec ${necId}`);
      return [nec];
    }

    if (schoolYear === undefined || grade === undefined)
      throw new Error(
        `CurriculumRepositoryMock.listNECsFrom: when eecId is undefined, schoolYear and grade must have values`
      );

    return Object.values(this.testData.neCurriculum).filter(
      c => c.schoolYear === schoolYear && c.grade.value === grade.value
    );
  }

  private listNECIdsFrom(necId?: string, schoolYear?: number, grade?: Grade): string[] {
    return this.listNECsFrom(necId, schoolYear, grade).map(c => c.necId);
  }

  private listEECIdsFrom(eecId?: string, schoolYear?: number, grade?: Grade): string[] {
    if (eecId !== undefined) {
      if (schoolYear !== undefined || grade !== undefined)
        throw new Error(
          `CurriculumRepositoryMock.listEECIdsFrom: when eecId has value, schoolYear and grade must be undefined`
        );
      return [eecId];
    }

    if (schoolYear === undefined || grade === undefined)
      throw new Error(
        `CurriculumRepositoryMock.listEECIdsFrom: when eecId is undefined, schoolYear and grade must have values`
      );

    return Object.values(this.testData.eeCurriculum)
      .filter(c => c.schoolYear === schoolYear && c.grade.value === grade.value)
      .map(c => c.eecId);
  }
}

































import Vue, { PropType } from "vue";
import { CurriculumListNECMonthPublishState } from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "CurriculumListNECHeaderPublishButton",
  props: {
    publishState: { type: String as PropType<CurriculumListNECMonthPublishState>, required: true },
    onClickPublishAll: { type: Function as PropType<() => void>, required: true },
    onClickUnpublishAll: { type: Function as PropType<() => void>, required: true },
  },
  data(): {
    active: boolean;

    words: Words;
  } {
    return {
      active: false,

      words,
    };
  },
  computed: {
    stateMessage(): string {
      switch (this.publishState) {
        case "none":
          return `All ${words.unpublished.d.toLowerCase()}`;
        case "partial":
          return `Partially ${words.published.d.toLowerCase()}`;
        case "all":
          return `All ${words.published.d.toLowerCase()}`;
        default:
          return "unknown state";
      }
    },
  },
  methods: {
    onClick() {
      this.active = !this.active;
    },
    onClickOutside() {
      this.active = false;
    },
    _onClickPublishAll() {
      this.active = false;
      this.onClickPublishAll();
    },
    _onClickUnpublishAll() {
      this.active = false;
      this.onClickUnpublishAll();
    },
  },
});








import Vue, { PropType } from "vue";
import { hasValue, solanProcessTextOf } from "@/ts/utils";
import { words } from "@/ts/const/Words";

export default Vue.extend({
  name: "StudentActivityTableProcessCell",
  props: {
    solanProcess: { type: [Number, String] as PropType<number | "lookback">, required: true },

    width: { type: Number, required: false },
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--width": hasValue(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
  methods: {
    _solanProcessTextOf(process: number | "lookback"): string {
      if (process === "lookback") return words.lookback.d;
      return solanProcessTextOf(process);
    },
  },
});

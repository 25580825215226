










import Vue from "vue";

export default Vue.extend({
  name: "CurriculumTitle",
  props: {
    // TODO curriculum icon

    curriculumName: { type: String, required: true },
  },
});



































































import Vue, { PropType } from "vue";
import { rawToActivityLog, ActivityLog } from "@/ts/objects/common/ActivityLog";
import StampModal from "@/components/StampModal.vue";
import Upload from "@/components/Upload.vue";
import ActivityLogRow from "@/views/activity/components/ActivityLogRow.vue";
import { loadPreviousActivityLogs, loadRealActivityLogs } from "@/ts/objects/common/fsActivity";
import { Err } from "@/ts/objects/Err";
import concat from "lodash/concat";
import uniq from "lodash/uniq";
import { addDays } from "date-fns";
import { AppStateStore } from "@/store/AppStateStore";
import TipBlock from "@/components/TipBlock.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import log from "loglevel";
import firebase from "firebase/app";
import { BasicUserInfo } from "@/ts/objects/common/BasicUserInfo";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";

const INTERVAL_CHAT_DATE = -7; // TODOとりあえず-1日にしている実際は7日くらい？
const PREVIOUS_LIMIT_COUNT_UP = 20;

type ActivityCondition = {
  loading: boolean;
  real: {
    oldCount: number;
  };
  prev: {
    oldCount: number;
    isFirst: boolean;
  };
  oldCount: number; // 読み込み前
  status: ScrollStatus;
  scrollById: string;
};
type ScrollStatus = { currentLocation: number; scrollingType: ScrollingType };

enum ScrollingType {
  yes,
  no,
  other,
}

type StampInfo = { gcsObjectPath: string; url: string };
export default Vue.extend({
  components: { TipBlock, LoadingBlock, Upload, ActivityLogRow },
  name: "ActivityView",
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    activityRepository: { type: Object as PropType<ActivityRepository>, required: true },

    targetStudentUserId: { type: String, required: true },
    targetBaseDate: { type: Object as PropType<Date | null>, default: null },
    targetUuid: { type: String, default: null },
  },
  data(): {
    fsDocuments: [];
    defaultAccountImage: string;
    activityTextInput: any;
    studentUserId: string;
    baseDate: Date | null;
    activityCondition: ActivityCondition;
    stamps: StampInfo[];
    fsLimit: number;
    fsPrevDocuments: [];
    unReadUuid: string | null;
  } {
    return {
      fsDocuments: [],
      defaultAccountImage: require("@/assets/default_account.png"),
      activityTextInput: "",
      studentUserId: "",
      baseDate: null,
      activityCondition: {
        loading: false,
        real: { oldCount: -1 },
        prev: { oldCount: -1, isFirst: false },
        oldCount: -1,
        status: { currentLocation: -1, scrollingType: ScrollingType.other },
        scrollById: "end-of-activity",
      },
      stamps: [],
      fsLimit: 0,
      fsPrevDocuments: [],
      unReadUuid: null,
    };
  },
  created: function() {
    const s = this.targetStudentUserId;
    this.getBaseDate(); // 基準日付を
    this.studentUserId = s;
  },
  mounted: function() {
    this.init();
  },

  computed: {
    uniqUserIds(): string[] {
      // 最新activityLogs
      const fsDocuments = this.fsDocuments;
      const userIds: string[] = uniq(fsDocuments.map((d: any) => d.userId));
      // 過去activityLogs
      const previousActivityLogs = this.fsPrevDocuments;
      const prevUserIds: string[] = uniq(previousActivityLogs.map((d: any) => d.userId));
      // concat
      const allUserIds: string[] = uniq(concat(userIds, prevUserIds));

      return allUserIds.sort();
    },
    isFirstRecord(): boolean {
      return this.isFirstData();
    },
    baseFsDate(): Date {
      if (this.targetBaseDate !== null) return this.targetBaseDate;
      if (this.baseDate === null) return this.getBaseDate();
      return this.baseDate;
    },
  },

  asyncComputed: {
    async basicUserInfos(): Promise<BasicUserInfo[]> {
      const basicUserInfos = await Promise.all(
        this.uniqUserIds.map(userId => getBasicUserInfo(this.userRepository, userId))
      );
      return basicUserInfos.filter((v: BasicUserInfo | null): v is BasicUserInfo => v !== null);
    },

    async activityLogs(): Promise<ActivityLog[]> {
      const myUserId = this.appStateStore.userState?.userId;
      if (myUserId === undefined) return [];
      const fsDocuments = this.fsDocuments;
      if (fsDocuments.length == 0) return [];
      const activityLogs: (ActivityLog | null)[] = await Promise.all<ActivityLog | null>(
        fsDocuments.map((d: any) => rawToActivityLog(Vue.prototype.$storage, d, myUserId))
      );
      const reverseActivityLogs = activityLogs
        .filter((d: ActivityLog | null): d is ActivityLog => d !== null)
        .reverse();
      readActivityLogs(this.activityRepository, this.unReadActivityLogs(reverseActivityLogs));
      if (reverseActivityLogs.length > this.activityCondition.real.oldCount) {
        if (!(this.activityCondition.real.oldCount === -1 && this.targetUuid !== null)) {
          // this.targetUuid : 先生画面から、uuid指定で遷移
          // かつ初回(this.activityCondition.real.oldCount=-1)はscrollしない
          await this.scrollToEnd();
        }
        this.activityCondition.real.oldCount = reverseActivityLogs.length;
      }
      return reverseActivityLogs;
    },

    async previousActivityLogs(): Promise<ActivityLog[]> {
      const myUserId = this.appStateStore.userState?.userId;
      if (myUserId === undefined) return [];
      const fsDocuments = this.fsPrevDocuments;
      const activityLogs = await Promise.all<ActivityLog | null>(
        fsDocuments.map((d: any) => rawToActivityLog(Vue.prototype.$storage, d, myUserId))
      );
      const reverseActivityLogs = activityLogs
        .filter((d: ActivityLog | null): d is ActivityLog => d !== null)
        .reverse();
      readActivityLogs(this.activityRepository, this.unReadActivityLogs(reverseActivityLogs));
      return reverseActivityLogs;
    },
  },

  methods: {
    keyDownCtrlEnter(e: any) {
      e.preventDefault(); //改行を無効化
    },
    keyUpCtrlEnter(e: any) {
      e.preventDefault(); // 改行を無効化
      this.send(); //送信
    },
    keyEnter(_e: any) {
      //
    },
    // 参照: https://stackoverflow.com/questions/47221119/vuejs-how-to-prevent-textarea-default-behavior
    newLine() {
      this.activityTextInput = this.activityTextInput + "\n";
    },

    getBaseDate(): Date {
      this.baseDate = addDays(new Date(), INTERVAL_CHAT_DATE);
      return this.baseDate;
    },

    async init() {
      // スタンプ一覧を取得
      this.stamps = [];
      const stamps = this.stamps;
      Vue.prototype.$storage
        ?.ref()
        .child("stamps")
        .listAll()
        .then(function(res: any) {
          res.prefixes.forEach(function(itemRef: any) {
            itemRef.listAll().then(function(res: any) {
              res.items.forEach(function(itemRef: any) {
                const path: string = itemRef.fullPath;
                const stamp: StampInfo = { url: "", gcsObjectPath: path };
                stamps.push(stamp);
              });
            });
          });
        });

      // uuidを設定
      if (this.targetUuid !== null) this.activityCondition.scrollById = this.targetUuid;
      // watch発火
      this.fsLimit = PREVIOUS_LIMIT_COUNT_UP; // trigger!!

      const activitylogs = this.fsRealActivityLogs(this.studentUserId);
      if (activitylogs === undefined) return;
      //      if (this.targetUuid !== null) this.activityCondition.status.scrollingType = ScrollingType.no;
      this.$bind("fsDocuments", activitylogs).then(_doc => {
        // this.activityCondition.real.oldCount = doc.length;
      });
    },

    async scrollToEnd() {
      const activityView: any = this.$refs.activityViewWrap;
      if (this.activityCondition.status.scrollingType === ScrollingType.no) {
        this.activityCondition.status.scrollingType = ScrollingType.other;
        return;
      }
      if (
        this.activityCondition.status.scrollingType === ScrollingType.other &&
        this.activityCondition.status.currentLocation > -1
      ) {
        const height = activityView.scrollHeight - this.activityCondition.status.currentLocation;
        if (height > 1500) {
          // this.$modal.show(Modal, {
          //   text:
          //     `activityView.scrollHeight:${activityView.scrollHeight} ` +
          //     `activityView.scrollTop:${activityView.scrollTop} ` +
          //     `currentLocation:${this.activityCondition.status.currentLocation} `
          // });
          return; // Posistionの差が1500以上の場合は処理を抜ける
        }
      }
      await this.activityScroll(this.activityCondition.scrollById, "smooth", "end", 300); // ★
      // await this.activityScroll("end-of-activity", "smooth", "end");
    },

    async activityScroll(
      elementId: string,
      // eslint-disable-next-line no-undef
      behavior: ScrollBehavior = "auto",
      // eslint-disable-next-line no-undef
      block: ScrollLogicalPosition = "start",
      waitTime: number = 10,
      reTryCount: number = 0
    ) {
      setTimeout(() => {
        const id = elementId;

        const element: HTMLElement | null = document.getElementById(id);
        if (element !== null) {
          element.scrollIntoView({ behavior: behavior, block: block });
        } else {
          // log.debug(reTryCount);
          if (reTryCount >= 5) {
            log.debug(`activityScroll() Maximum number of retries!`);
            return;
          }
          const countUp = reTryCount + 1;
          setTimeout(() => {
            this.activityScroll(elementId, behavior, block, waitTime, countUp);
          }, 50 * countUp);
        }

        this.activityCondition.loading = false;
        this.activityCondition.status.scrollingType = ScrollingType.other;
        this.activityCondition.scrollById = "end-of-activity"; // ★
      }, waitTime);
    },

    isFirstData(): boolean {
      if (this.fsPrevDocuments.length === 0) return true;
      if (this.activityCondition.prev.isFirst) return true;
      if (this.activityCondition.prev.oldCount === -1) return false;
      if (this.activityCondition.status.scrollingType === ScrollingType.no) {
        return this.activityCondition.prev.isFirst; //現在内容をそのまま返却
      }
      const fs = this.fsPrevDocuments;
      const ret = fs.length == this.activityCondition.prev.oldCount;
      this.activityCondition.prev.isFirst = ret;
      return ret;
    },

    async scrollingEvents() {
      if (this.activityCondition.status.scrollingType !== ScrollingType.other) return;
      await this.getPreviousData();
    },

    async getPreviousData() {
      const activityView: any = this.$refs.activityViewWrap;
      const top = activityView.scrollTop;
      this.activityCondition.status.currentLocation = top;

      if (top === 0) {
        this.activityCondition.status.scrollingType = ScrollingType.other;
        if (this.isFirstData()) return;
        // const activityView: any = this.$refs.activityViewWrap;
        this.activityCondition.loading = true; // Loading中
        this.activityCondition.prev.oldCount = this.fsPrevDocuments.length; // 先頭かの判断用
        await this.getPreviousActivityLogs();
      }
    },

    send() {
      const studentUserId = this.studentUserId;
      const text = this.activityTextInput;
      if (studentUserId === null) return [];
      if (text === "") return [];
      this.activityTextInput = "";
      this.activityCondition.loading = true;
      this.activityCondition.status.scrollingType = ScrollingType.yes;
      this.activityCondition.scrollById = "end-of-activity";
      this.activityRepository.postActivityLog(studentUserId, text);
    },

    async del(menuKey: string, activityId: string) {
      const studentUserId = this.studentUserId;
      if (studentUserId === null) return;
      switch (menuKey) {
        case "delete":
          await this.activityRepository.deleteActivity(studentUserId, activityId);
      }
    },

    async mylist(activityId: string, flg: boolean) {
      const studentUserId = this.studentUserId;
      if (studentUserId === null) return;
      this.activityCondition.status.scrollingType = ScrollingType.no;
      if (flg) {
        await this.activityRepository.postMylistActivityLog(studentUserId, activityId);
      } else {
        await this.activityRepository.deleteMylistActivityLog(studentUserId, activityId);
      }
    },

    async fileUpload(file: any) {
      const studentUserId = this.studentUserId;
      if (studentUserId === null) return;
      this.activityCondition.loading = true;
      this.activityCondition.status.scrollingType = ScrollingType.yes;

      await this.activityRepository.postFile(file, studentUserId);
    },

    async stampUpload(studentUserId: string, gcsObjectPath: string) {
      this.activityCondition.loading = true;
      this.activityCondition.status.scrollingType = ScrollingType.yes;
      await this.activityRepository.postActivitiesStamp(studentUserId, gcsObjectPath);
    },

    async openStampModal() {
      const stamps = this.stamps;
      stamps.forEach(stamp => {
        const ref: firebase.storage.Reference = Vue.prototype.$storage?.ref(stamp.gcsObjectPath);
        ref.getDownloadURL().then(url => {
          stamp.url = url;
        });
      });

      const studentUserId = this.studentUserId;
      if (studentUserId === null) return;
      this.$modal.show(
        StampModal,
        { stamps: stamps.sort(), studentUserId: studentUserId, f: this.stampUpload },
        {
          name: "StampModal",
          adaptive: true,
          delay: 2,
          resize: true,
          minWidth: "20%",
          maxWidth: "80%",
          height: "300px",
          width: "50%",
          shiftX: 0.8,
          shiftY: 0.8,
        }
      );
    },

    unReadActivityLogs(activitylogs: ActivityLog[]) {
      const unReadLists = activitylogs.filter(value => {
        return value?.advanced.read === false;
      });

      if (
        unReadLists !== null &&
        unReadLists.length > 0 &&
        this.activityCondition.real.oldCount === -1 &&
        this.unReadUuid === null
      ) {
        this.unReadUuid = unReadLists[0].uuid;
      }
      return unReadLists;
    },

    unReadRow(uuid: string): boolean {
      return this.unReadUuid === uuid;
    },

    async getPreviousActivityLogs() {
      const previousActivityLogs: ActivityLog[] = this.fsPrevDocuments;
      const activitylog = previousActivityLogs[previousActivityLogs.length - 1];
      this.activityCondition.scrollById = activitylog.uuid;
      this.fsLimit += PREVIOUS_LIMIT_COUNT_UP;
    },

    fsPreviousActivityLogs(limit: number) {
      if (this.baseFsDate === null) return;
      const studentUserId = this.studentUserId;
      if (studentUserId === null) return;

      return loadPreviousActivityLogs(this.$firestore, studentUserId, this.baseFsDate, limit);
    },

    fsRealActivityLogs(studentUserId: string) {
      if (this.baseFsDate === null) return;
      return loadRealActivityLogs(this.$firestore, studentUserId, this.baseFsDate);
    },
  },

  watch: {
    fsLimit: {
      handler(fsLimit: number) {
        const activitylogs = this.fsPreviousActivityLogs(fsLimit);
        if (activitylogs === undefined) return;
        this.$bind("fsPrevDocuments", activitylogs, { reset: false }).then(_doc => {
          this.activityScroll(this.activityCondition.scrollById);
        });
      },
    },
  },
});

function readActivityLogs(activityRepository: ActivityRepository, activitylogs: ActivityLog[]) {
  activitylogs.forEach(al => {
    activityRepository.readActivityLog(al.studentUserId, al.uuid);
  });
}

async function getBasicUserInfo(userRepository: UserRepository, userId: string): Promise<BasicUserInfo | null> {
  const basicUserInfo = await userRepository.getBasicUserInfo(userId);
  if (basicUserInfo instanceof Err) return null;
  return basicUserInfo;
}

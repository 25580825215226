






































































import Vue, { PropType } from "vue";
import { Class } from "@/ts/objects/common/Class";
import { AppStateStore } from "@/store/AppStateStore";
import { TeacherState } from "@/ts/objects/common/UserState";
import { ProjectStore } from "@/store/ProjectStore";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import { AuthService } from "@/ts/AuthService";
import { getDownloadUrlOfStorageObject, isNullish } from "@/ts/utils";
import { words, Words } from "@/ts/const/Words";
import AppHeaderTabT from "@/views/header/components/AppHeaderTabT/AppHeaderTabT.vue";
import { schoolTypeToDisplayValue } from "@/ts/objects/common/SchoolType";
import AppHeaderHomeButton from "@/views/header/components/AppHeaderHomeButton/AppHeaderHomeButton.vue";

export default Vue.extend({
  name: "AppHeaderT",
  components: { AppHeaderHomeButton, AppHeaderTabT, PopupMenuButton },
  props: {
    authService: { type: Object as PropType<AuthService>, required: true },
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
  },
  data(): {
    schoolLogoUrl: string | null;
    userMenuItems: MenuItem[];

    words: Words;
  } {
    return {
      schoolLogoUrl: null,
      userMenuItems: [new MenuButton("changeGoogleAccount", "Login with Different Account", undefined)],

      words,
    };
  },
  computed: {
    schoolLogoGcsUrl(): string | null {
      return this.appStateStore.school.logoGcsUrl;
    },
    selectedClass(): Class | null {
      return this.appStateStore.teacherState?.selectedClass() ?? null;
    },
    schoolTypeText(): string {
      const schoolType = this.selectedClass?.schoolType;
      if (isNullish(schoolType)) return "";
      return schoolTypeToDisplayValue(schoolType);
    },

    isCurrentTabHome(): boolean {
      return this.$route.name === "T.Home";
    },
    isCurrentTabActivity(): boolean {
      return this.$route.name === "T.Activity";
    },
    isCurrentTabCurriculum(): boolean {
      return this.$route.name === "T.Curriculum" || (this.$route.name?.startsWith("T.Curriculum.") ?? false);
    },
    isCurrentTabProject(): boolean {
      return this.$route.name === "T.Project" || (this.$route.name?.startsWith("T.Project.") ?? false);
    },
    isCurrentTabSolan(): boolean {
      return this.$route.name === "T.Solan" || (this.$route.name?.startsWith("T.Solan.") ?? false);
    },
    teacherState(): TeacherState | null {
      return this.appStateStore.teacherState;
    },
    teacherName(): string {
      return this.teacherState?.name ?? "";
    },
    teacherIconUrl(): string {
      return this.teacherState?.iconUrl ?? "";
    },
  },
  watch: {
    schoolLogoGcsUrl: {
      async handler(newValue: string | null) {
        if (isNullish(newValue)) {
          this.schoolLogoUrl = null;
          return;
        }
        this.schoolLogoUrl = await getDownloadUrlOfStorageObject(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    async selectUserMenu(menuItem: string) {
      switch (menuItem) {
        case "changeGoogleAccount":
          await this.authService.changeGoogleAccount();
          break;
      }
    },
  },
});

import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { RatingValue } from "@/ts/objects/common/Rating";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { EditableBoolean, EditableString } from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { Editable } from "@/ts/objects/editable/Editable";
import { DisplayableErr, Err } from "@/ts/objects/Err";
import { messages } from "@/ts/const/Messages";
import { NECEvaluation } from "@/ts/objects/curriculum/value/NECEvaluation";
import { INECEvaluation } from "@/ts/objects/curriculum/INECEvaluation";
import { MonthValue } from "@/ts/objects/common/MonthValue";

/**
 * 編集可能な数値評価教科の評価。
 */
export class EditableNECEvaluation extends EditableObject implements INECEvaluation {
  constructor(
    repo: CurriculumRepository,
    savable: boolean,
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    public readonly month: MonthValue,
    public readonly studentUserId: string,
    rating: RatingValue,
    teacherInputPublished: boolean
  ) {
    super();
    this._rating = new EditableString("rating", rating, savable, async value => {
      const resp = await repo.patchNECEvaluation(necId, viewPointId, contentId, month, studentUserId, {
        rating: value,
      });
      if (resp instanceof Err) return resp;
      if (resp.rating === undefined)
        return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
      return resp.rating;
    });
    this._teacherInputPublished = new EditableBoolean(
      "teacherInputPublished",
      teacherInputPublished,
      savable,
      async value => {
        const resp = await repo.patchNECEvaluation(necId, viewPointId, contentId, month, studentUserId, {
          teacherInputPublished: value,
        });
        if (resp instanceof Err) return resp;
        if (resp.teacherInputPublished === undefined)
          return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
        return resp.teacherInputPublished;
      }
    );
  }

  readonly _rating: EditableString;
  readonly _teacherInputPublished: EditableBoolean;

  protected allEditables(): Editable[] {
    return [this._rating, this._teacherInputPublished];
  }

  get rating(): string {
    return this._rating.value;
  }

  set rating(value: string) {
    this._rating.value = value;
  }

  get teacherInputPublished(): boolean {
    return this._teacherInputPublished.value;
  }

  set teacherInputPublished(value: boolean) {
    this._teacherInputPublished.value = value;
  }

  get contentResourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}`;
  }

  get contentMonthResourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}`;
  }

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}/evaluations/${this.studentUserId}`;
  }

  static fromNECEvaluation(
    repo: CurriculumRepository,
    savable: boolean,
    necEvaluation: NECEvaluation
  ): EditableNECEvaluation {
    return new EditableNECEvaluation(
      repo,
      savable,
      necEvaluation.necId,
      necEvaluation.viewPointId,
      necEvaluation.contentId,
      necEvaluation.month,
      necEvaluation.studentUserId,
      necEvaluation.rating,
      necEvaluation.teacherInputPublished
    );
  }
}

import { Student, StudentProfile } from "@/ts/objects/user/Student";
import { StudentColumnDef } from "@/ts/app/columns/student/StudentColumn";
import { UserColumn } from "@/ts/app/columns/UserColumn";
import { genUserColumnDefs } from "@/ts/app/columns/UserColumnDef";
import { DateType, ImageType, StringType, StudentClassesType } from "@/ts/app/columns/ColumnTypes";

export type StudentProfileColumnId =
  | "userId"
  | "googleMail"
  | "photoUrl"
  | "name"
  | Exclude<keyof StudentProfile, "guardian">;

export type StudentProfileColumnDef<T> = StudentColumnDef<StudentProfileColumnId, T>;

export type StudentProfileColumn<T> = UserColumn<StudentProfileColumnId, Student, T>;

export function studentProfileColumnDefs(): StudentProfileColumnDef<any>[] {
  return genUserColumnDefs<StudentProfileColumnId, Student, any>([
    {
      id: "userId",
      type: StringType,
      getValue: s => s.userId,
    },
    {
      id: "googleMail",
      type: StringType,
      getValue: s => s.googleMail,
    },
    {
      id: "photoUrl",
      type: StringType,
      getValue: s => s.photoUrl,
    },
    {
      id: "name",
      type: StringType,
      getValue: s => s.name,
    },

    {
      id: "nameKana",
      type: StringType,
      getValue: s => s.nameKana,
    },
    {
      id: "nameRome",
      type: StringType,
      getValue: s => s.nameRome,
    },
    {
      id: "familyName",
      type: StringType,
      getValue: s => s.familyName,
    },
    {
      id: "familyNameKana",
      type: StringType,
      getValue: s => s.familyNameKana,
    },
    {
      id: "givenName",
      type: StringType,
      getValue: s => s.givenName,
    },
    {
      id: "givenNameKana",
      type: StringType,
      getValue: s => s.givenNameKana,
    },

    {
      id: "classes",
      type: StudentClassesType,
      getValue: s => s.classes,
    },

    {
      id: "sex",
      type: StringType,
      getValue: s => s.sex,
    },
    {
      id: "nickname",
      type: StringType,
      getValue: s => s.nickname,
    },
    {
      id: "nicknameKana",
      type: StringType,
      getValue: s => s.nicknameKana,
    },
    {
      id: "birthday",
      type: DateType,
      getValue: s => s.birthday,
    },
    {
      id: "applicationDate",
      type: DateType,
      getValue: s => s.applicationDate,
    },
    {
      id: "kindergartenEntranceDate",
      type: DateType,
      getValue: s => s.kindergartenEntranceDate,
    },
    {
      id: "elementarySchoolEntranceDate",
      type: DateType,
      getValue: s => s.elementarySchoolEntranceDate,
    },
    {
      id: "juniorHighSchoolEntranceDate",
      type: DateType,
      getValue: s => s.juniorHighSchoolEntranceDate,
    },
    {
      id: "transferDate",
      type: DateType,
      getValue: s => s.transferDate,
    },
    {
      id: "graduationDate",
      type: DateType,
      getValue: s => s.graduationDate,
    },
    {
      id: "kindergarten",
      type: StringType,
      getValue: s => s.kindergarten,
    },
    {
      id: "previousSchool",
      type: StringType,
      getValue: s => s.previousSchool,
    },
    {
      id: "zipcode",
      type: StringType,
      getValue: s => s.zipcode,
    },
    {
      id: "address",
      type: StringType,
      getValue: s => s.address,
    },
    {
      id: "email",
      type: StringType,
      getValue: s => s.email,
    },
    {
      id: "mobilePhone",
      type: StringType,
      getValue: s => s.mobilePhone,
    },
    {
      id: "pictureGcsUrl",
      type: ImageType,
      getValue: s => s.pictureGcsUrl,
    },
    {
      id: "country",
      type: StringType,
      getValue: s => s.country,
    },
    {
      id: "religion",
      type: StringType,
      getValue: s => s.religion,
    },
    {
      id: "commutingBy",
      type: StringType,
      getValue: s => s.commutingBy,
    },
    {
      id: "bus",
      type: StringType,
      getValue: s => s.bus,
    },
    {
      id: "nearestStation",
      type: StringType,
      getValue: s => s.nearestStation,
    },
    {
      id: "hasLunch",
      type: StringType,
      getValue: s => s.hasLunch,
    },
    {
      id: "activityMonday",
      type: StringType,
      getValue: s => s.activityMonday,
    },
    {
      id: "activityTuesday",
      type: StringType,
      getValue: s => s.activityTuesday,
    },
    {
      id: "activityWednesday",
      type: StringType,
      getValue: s => s.activityWednesday,
    },
    {
      id: "activityThursday",
      type: StringType,
      getValue: s => s.activityThursday,
    },
    {
      id: "activityFriday",
      type: StringType,
      getValue: s => s.activityFriday,
    },
  ]);
}

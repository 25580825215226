












































































































































import StudentActivityTableProcessCell from "@/components/student-activity-table/StudentActivityTableProcessCell/StudentActivityTableProcessCell.vue";
import StudentActivityTableReadonlyTextCell from "@/components/student-activity-table/StudentActivityTableReadonlyTextCell/StudentActivityTableReadonlyTextCell.vue";
import StudentActivityTableFilesCell from "@/components/student-activity-table/StudentActivityTableFilesCell/StudentActivityTableFilesCell.vue";
import Vue, { PropType } from "vue";
import StudentActivityTablePublishStateCell from "@/components/student-activity-table/StudentActivityTablePublishStateCell/StudentActivityTablePublishStateCell.vue";
import StudentActivityTableSelectRowCheckboxCell from "@/components/student-activity-table/StudentActivityTableSelectRowCheckboxCell/StudentActivityTableSelectRowCheckboxCell.vue";
import StudentActivityTableTextCell from "@/components/student-activity-table/StudentActivityTableTextCell/StudentActivityTableTextCell.vue";
import StudentActivityTableRatingCell from "@/components/student-activity-table/StudentActivityTableRatingCell/StudentActivityTableRatingCell.vue";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { words, Words } from "@/ts/const/Words";
import {
  SolanJournalsTColFilterState,
  SolanJournalsTStyleProps,
} from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTModel";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { v4 as uuidv4 } from "uuid";

export default Vue.extend({
  name: "SolanJournalsTTableJournalRow",
  components: {
    JournalFilesView,
    StudentActivityTableRatingCell,
    StudentActivityTableTextCell,
    StudentActivityTableSelectRowCheckboxCell,
    StudentActivityTablePublishStateCell,
    StudentActivityTableFilesCell,
    StudentActivityTableReadonlyTextCell,
    StudentActivityTableProcessCell,
  },
  props: {
    journal: { type: Object as PropType<EditableSolanJournal>, required: true },
    selected: { type: Boolean, required: true },
    isAssociatedFilesViewOpen: { type: Boolean, required: true },

    onSelect: { type: Function as PropType<(selected: boolean) => void>, required: true },
    onCellBlur: { type: Function as PropType<() => void>, required: true },
    onFilesViewOpen: { type: Function as PropType<(open: boolean) => void>, required: true },

    colFilterState: { type: Object as PropType<SolanJournalsTColFilterState>, required: true },
    highlightUnsaved: { type: Boolean, required: true },

    onJournalFileUpload: {
      type: Function as PropType<(file: File, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },
    onJournalFileDelete: {
      type: Function as PropType<(journalFile: JournalFile, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },

    styleProps: { type: Object as PropType<SolanJournalsTStyleProps>, required: true },
  },
  data(): {
    journalFilesViewElementId: string;
    words: Words;
  } {
    return {
      journalFilesViewElementId: uuidv4(),
      words,
    };
  },
});

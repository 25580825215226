





































import Vue, { PropType } from "vue";
import { CurriculumTabName } from "@/ts/objects/curriculum/value/CurriculumTabName";
import SchoolYearControl from "@/components/SchoolYearControl/SchoolYearControl.vue";
import { Words, words } from "@/ts/const/Words";
import SecondaryHeaderTab from "@/components/button/SecondaryHeaderTab/SecondaryHeaderTab.vue";

export default Vue.extend({
  name: "CurriculumHeader",
  components: { SecondaryHeaderTab, SchoolYearControl },
  props: {
    currentTabName: { type: String as PropType<CurriculumTabName | null> },
    writeButtonPath: { type: String, required: true },
    readButtonPath: { type: String, required: true },
    schoolYear: { type: Number as PropType<number | null>, required: true },
    minSchoolYear: { type: Number as PropType<number | null>, required: true },
    maxSchoolYear: { type: Number as PropType<number | null>, required: true },
    schoolYearToPath: { type: Function as PropType<(schoolYear: number) => string>, required: true },

    writeButtonEnabled: { type: Boolean, required: true },
  },
  data(): { words: Words } {
    return { words };
  },
});

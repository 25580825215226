






import Vue, { PropType } from "vue";
import { UserColumn } from "@/ts/app/columns/UserColumn";
import { AnyUser } from "@/ts/objects/user/User";
import { WordInternalName } from "@/ts/const/Words";
import ProfileTableItem from "@/views/profile/components/ProfileTable/ProfileTableItem/ProfileTableItem.vue";

export default Vue.extend({
  name: "ProfileTable",
  components: { ProfileTableItem },
  props: {
    userColumns: {
      type: Array as PropType<UserColumn<WordInternalName, AnyUser, any>[]>,
      required: true,
    },
  },
});

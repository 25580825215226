import { isNullish } from "@/ts/utils";

export const hasLunchValues = {
  yes: "有",
  no: "無",
} as const;

/**
 * ランチ有無。
 */
export type HasLunchValue = keyof typeof hasLunchValues;
export type HasLunchDisplayValue = typeof hasLunchValues[HasLunchValue];

export function isHasLunchValue(value: unknown): value is HasLunchValue {
  return typeof value === "string" && Object.keys(hasLunchValues).some(v => v === value);
}

export function hasLunchValueToDisplayValue(value: HasLunchValue): HasLunchDisplayValue {
  return hasLunchValues[value];
}

export function displayValueToHasLunchValueOrNull(displayValue: string): HasLunchValue | null {
  const kv = Object.entries(hasLunchValues).find(([_k, v]) => v === displayValue);
  if (isNullish(kv)) return null;
  const k = kv[0];
  if (!isHasLunchValue(k)) return null;
  return k;
}

export function displayValueToHasLunchValueOrError(displayValue: string): HasLunchValue {
  const result = displayValueToHasLunchValueOrNull(displayValue);
  if (isNullish(result))
    throw new Error(`displayValueToHasLunchValueOrError: invalid has lunch display value: ${displayValue}`);
  return result;
}

export function hasLunchValueFromStringOrNull(value: string): HasLunchValue | null {
  return isHasLunchValue(value) ? value : null;
}

export function hasLunchValueFromStringOrError(value: string): HasLunchValue {
  const result = hasLunchValueFromStringOrNull(value);
  if (isNullish(result)) throw new Error(`hasLunchValueFromStringOrError: invalid has lunch value: ${value}`);
  return result;
}

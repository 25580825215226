




export default {
  name: "CurriculumMenuTItem",
  props: {
    label: { type: String, required: true },
    path: { type: String, required: true },
    selected: { type: Boolean, required: true },
  },
};

import { render, staticRenderFns } from "./CurriculumSyllabusNECContentRow.vue?vue&type=template&id=0366fc64&scoped=true&"
import script from "./CurriculumSyllabusNECContentRow.vue?vue&type=script&lang=ts&"
export * from "./CurriculumSyllabusNECContentRow.vue?vue&type=script&lang=ts&"
import style0 from "./CurriculumSyllabusNECContentRow.vue?vue&type=style&index=0&id=0366fc64&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0366fc64",
  null
  
)

export default component.exports
import { Class, ClassStudent } from "@/ts/objects/common/Class";
import { Grade } from "@/ts/objects/common/Grade";
import { Student } from "@/ts/objects/user/Student";
import { Guardian } from "@/ts/objects/user/Guardian";

export type UserTestDataSet = {
  readonly classes: Record<string, Class>;
  readonly student: Student;
  readonly guardian: Guardian;
};

export function getUserTestData(): UserTestDataSet {
  return {
    classes: {
      class000: new Class("class000", 2000, new Grade("e1"), "A", 0, [
        new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
        new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
        new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
        new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl"),
      ]),
      class001: new Class("class001", 1999, new Grade("e1"), "B", 1, [
        new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
        new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
        new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
        new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl"),
      ]),
      class002: new Class("class002", 2000, new Grade("j2"), "C", 2, [
        new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
        new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
        new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
        new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl"),
      ]),
    },
    student: {
      userType: "student",
      userId: "student000",
      googleMail: "student000@example.com",
      photoUrl: "https://avatars.dicebear.com/api/jdenticon/aaar.svg",
      name: "瀬戸 空治郎",

      classes: [
        {
          classId: "class-2000-e1-0",
          schoolYear: 2000,
          grade: new Grade("e1"),
          name: "ぜろ",
          studentNumber: 12,
        },
        {
          classId: "class-2000-e2-0",
          schoolYear: 2001,
          grade: new Grade("e2"),
          name: "いち",
          studentNumber: 13,
        },
      ],
      guardian: { userId: "guardian000" },

      nameKana: "せと そらじろう",
      nameRome: "Seto Sorajiro",
      familyName: "瀬戸",
      familyNameKana: "せと",
      givenName: "空治郎",
      givenNameKana: "そらじろう",
      sex: "male",
      nickname: "瀬戸っち",
      nicknameKana: "せとっち",
      birthday: { year: 1991, month: 1, day: 1 },
      applicationDate: { year: 1992, month: 2, day: 2 },
      kindergartenEntranceDate: { year: 1993, month: 3, day: 3 },
      elementarySchoolEntranceDate: { year: 1994, month: 4, day: 4 },
      juniorHighSchoolEntranceDate: { year: 1995, month: 5, day: 5 },
      transferDate: { year: 1996, month: 6, day: 6 },
      graduationDate: { year: 1997, month: 7, day: 7 },
      kindergarten: "瀬戸幼稚園",
      previousSchool: "前瀬戸小学校",
      zipcode: "1234567",
      address: "アメリカ合衆国カリフォルニア州シリコンバレー1-2-3瀬戸ハイツ505号室",
      email: "student000@example.com",
      mobilePhone: "09012345678",
      pictureGcsUrl: "https://example.com/pictures/student000",
      country: "日本",
      religion: "仏教",
      commutingBy: "バス",
      bus: "123系統",
      nearestStation: "京橋駅",
      hasLunch: "no",
      activityMonday: "月曜の集い",
      activityTuesday: "火曜の集い",
      activityWednesday: "水曜の集い",
      activityThursday: "木曜の集い",
      activityFriday: "金曜の集い",

      bloodType: "AB",
      normalBodyTemperature: "36.0",
      inoculation: "おたふくかぜ",
      medicalHistory: "至って健康",
      homeDoctor: "高階 経徳",
      foodAllergy: "卵",
      anaphylaxis: "スズメバチ",
      otherAllergy: "うるし",
      asthma: "なし",

      custom01: { name: "カスタム項目01", value: "カスタム項目値01" },
      custom02: { name: "カスタム項目02", value: "カスタム項目値02" },
      custom03: { name: "カスタム項目03", value: "カスタム項目値03" },
      custom04: { name: "カスタム項目04", value: "カスタム項目値04" },
      custom05: { name: "カスタム項目05", value: "カスタム項目値05" },
      custom06: { name: "カスタム項目06", value: "カスタム項目値06" },
      custom07: { name: "カスタム項目07", value: "カスタム項目値07" },
      custom08: { name: "カスタム項目08", value: "カスタム項目値08" },
      custom09: { name: "カスタム項目09", value: "カスタム項目値09" },
      custom10: { name: "カスタム項目10", value: "カスタム項目値10" },
      custom11: { name: "カスタム項目11", value: "カスタム項目値11" },
      custom12: { name: "カスタム項目12", value: "カスタム項目値12" },
      custom13: { name: "カスタム項目13", value: "カスタム項目値13" },
      custom14: { name: "カスタム項目14", value: "カスタム項目値14" },
      custom15: { name: "カスタム項目15", value: "カスタム項目値15" },
      custom16: { name: "カスタム項目16", value: "カスタム項目値16" },
      custom17: { name: "カスタム項目17", value: "カスタム項目値17" },
      custom18: { name: "カスタム項目18", value: "カスタム項目値18" },
      custom19: { name: "カスタム項目19", value: "カスタム項目値19" },
      custom20: { name: "カスタム項目20", value: "カスタム項目値20" },
      custom21: { name: "カスタム項目21", value: "カスタム項目値21" },
      custom22: { name: "カスタム項目22", value: "カスタム項目値22" },
      custom23: { name: "カスタム項目23", value: "カスタム項目値23" },
      custom24: { name: "カスタム項目24", value: "カスタム項目値24" },
      custom25: { name: "カスタム項目25", value: "カスタム項目値25" },
      custom26: { name: "カスタム項目26", value: "カスタム項目値26" },
      custom27: { name: "カスタム項目27", value: "カスタム項目値27" },
      custom28: { name: "カスタム項目28", value: "カスタム項目値28" },
      custom29: { name: "カスタム項目29", value: "カスタム項目値29" },
      custom30: { name: "カスタム項目30", value: "カスタム項目値30" },
      custom31: { name: "カスタム項目31", value: "カスタム項目値31" },
      custom32: { name: "カスタム項目32", value: "カスタム項目値32" },
      custom33: { name: "カスタム項目33", value: "カスタム項目値33" },
      custom34: { name: "カスタム項目34", value: "カスタム項目値34" },
      custom35: { name: "カスタム項目35", value: "カスタム項目値35" },
      custom36: { name: "カスタム項目36", value: "カスタム項目値36" },
      custom37: { name: "カスタム項目37", value: "カスタム項目値37" },
      custom38: { name: "カスタム項目38", value: "カスタム項目値38" },
      custom39: { name: "カスタム項目39", value: "カスタム項目値39" },
      custom40: { name: "カスタム項目40", value: "カスタム項目値40" },
    },
    guardian: {
      userType: "guardian",
      userId: "guardian000",
      googleMail: "guardian000@example.com",
      photoUrl: "https://avatars.dicebear.com/api/avataaars/j6.svg",
      name: "瀬戸 空太郎",

      children: [],

      // Userのnameが、name1。
      nameKana1: "せと そらたろう",
      familyName1: "瀬戸",
      givenName1: "空太郎",
      name2: "瀬戸 空子",
      nameKana2: "せと そらこ",
      familyName2: "瀬戸",
      givenName2: "空子",
      email1: "guardian000-1@gmail.com",
      email2: "guardian000-2@gmail.com",
      mobilePhone1: "09000000001",
      mobilePhone2: "09000000002",
      landlinePhone1: "0600000001",
      landlinePhone2: "0600000002",
      job1: "漁師",
      job2: "大学教授",
      workPlace1: "海",
      workPlace2: "東京大学",
      pictureGcsUrl1: "https://example.com/pictures/guardian000-1",
      pictureGcsUrl2: "https://example.com/pictures/guardian000-2",
      emergencyContactRelationship1: "祖父",
      emergencyContactPhone1: "09000000011",
      emergencyContactRelationship2: "祖母",
      emergencyContactPhone2: "09000000012",
      emergencyContactRelationship3: "叔母",
      emergencyContactPhone3: "09000000013",

      note01: "備考01",
      note02: "備考02",
      note03: "備考03",
      note04: "備考04",
      note05: "備考05",
      note06: "備考06",
      note07: "備考07",
      note08: "備考08",
      note09: "備考09",
      note10: "備考10",
    },
  };
}

























import Vue, { PropType } from "vue";
import AutoResizeTextarea from "@/components/AutoResizeTextarea.vue";
import { hasValue, isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "StudentActivityTableTextCell",
  components: { AutoResizeTextarea },
  props: {
    placeholder: { type: String, required: true },
    value: { type: String, required: true },
    onInput: { type: Function as PropType<(value: string) => void>, required: true },
    onBlur: { type: Function as PropType<() => void>, required: false },

    unsavedHighlight: { type: Boolean, required: true },
    errorHighlight: { type: Boolean, required: true },

    width: { type: Number, required: false },
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--width": hasValue(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
  methods: {
    onBlurInternal() {
      const _onBlur = this.onBlur;
      if (isNullish(_onBlur())) return;
      _onBlur();
    },
  },
});

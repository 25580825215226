import { IEECActivity, IEECActivityTree } from "@/ts/objects/curriculum/IEECActivity";
import { Activity as ActivityResp } from "@/ts/api/curriculum-service";
import { isMonthValue, MonthValue } from "@/ts/objects/common/MonthValue";

/**
 * 文書評価教科の学習活動。
 *
 * リソース名は /eeCurriculums/{eecId}/months/{1, 2, ..., 12}/activities/{eecActivityId}。
 *
 * 月 : 学習活動  = 1 : n
 */
export class EECActivity implements IEECActivity {
  constructor(
    public readonly eecId: string,
    public readonly month: MonthValue,
    public readonly activityId: string,
    public readonly enabled: boolean,
    public readonly text: string
  ) {}

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/months/${this.month}/activities/${this.activityId}`;
  }

  static fromActivityResp(resp: ActivityResp): EECActivity {
    if (!isMonthValue(resp.month))
      throw new Error(`EECActivity.fromActivityResp: invalid month value on ${resp.self}: ${resp.month}`);
    return new EECActivity(resp.eecId, resp.month, resp.activityId, resp.enabled, resp.text.value);
  }
}

export class EECActivityTree implements IEECActivityTree {
  constructor(public readonly self: EECActivity) {}
}









import Vue from "vue";

export default Vue.extend({
  name: "Modal",
  props: ["type", "text"],
  data() {
    return {
      icon: ["fas", "exclamation-triangle"],
    };
  },
});





















import Vue, { PropType } from "vue";

const possibleValues = ["S", "A", "B", "C", ""] as const;
type PossibleValue = typeof possibleValues[number];

export default Vue.extend({
  name: "RatingDropdown",
  props: {
    /**
     * select要素のname属性。
     */
    name: { type: String, required: true },
    rating: { type: String, required: true },
    onChange: { type: Function as PropType<(value: PossibleValue) => void>, required: true },

    disabled: { type: Boolean, required: false, default: false },

    fontSize: { type: Number, required: true },
    optionFontSize: { type: Number, required: true },
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--font-size": `${this.fontSize}px`,
        "--option-font-size": `${this.optionFontSize}px`,
      };
    },
  },
  methods: {
    onChangeInternal(e: { target: HTMLSelectElement }) {
      const value = e.target.value;
      if (possibleValues.every(v => v !== value)) return;
      this.onChange(value as PossibleValue);
    },
  },
});








import Vue, { PropType } from "vue";
import { hasValue } from "@/ts/utils";

export default Vue.extend({
  name: "StudentActivityTableSelectRowCheckboxCell",
  props: {
    checked: { type: Boolean, required: true },
    onChange: { type: Function as PropType<(checked: boolean) => void>, required: true },

    width: { type: Number, required: false },
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--width": hasValue(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
  methods: {
    onChangeInternal(event: { target: HTMLInputElement }) {
      const checked = event.target.checked ?? false;
      this.onChange(checked);
    },
  },
});

<script>
import * as urlRegex from "url-regex";

export default {
  methods: {
    parseSlot() {
      if (!this.$slots || !this.$slots.default || !this.$slots.default[0] || !this.$slots.default[0].text) {
        return [];
      }
      let processText = this.$slots.default[0].text;
      const matchedURL = processText.match(urlRegex({ exact: false }));
      if (matchedURL) {
        const set = new Set(matchedURL);
        set.forEach(url => {
          // TODO ここをもう少しうまくしたい。
          processText = processText.split(url).join(`\t\v${url}\v\t`);
        });
      }
      return processText.split("\t");
    },
  },
  render(_h) {
    return (
      <p>
        {this.parseSlot().map(text => {
          const matched = text.match(/^\v(.+)\v$/);
          if (matched) {
            return (
              <a class="link" href={matched[1]} rel="noreferrer" target="_blank">
                {matched[1]}
              </a>
            );
          }
          return text.replace(/\v/, "");
        })}
      </p>
    );
  },
};
</script>
<style scoped>
.link {
  color: blue;
  font-style: oblique;
}
</style>





























import Vue, { PropType } from "vue";
import { Class } from "@/ts/objects/common/Class";
import { SchoolType, schoolTypeToDisplayValue } from "@/ts/objects/common/SchoolType";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import SchoolYearSwitch from "@/components/SchoolYearSwitch/SchoolYearSwitch.vue";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "ClassModalButton",
  components: { SchoolYearSwitch },
  props: {
    initialSchoolYear: { type: Number, required: true },
    groupedClassSelections: { type: Array as PropType<{ schoolYear: number; selections: Class[] }[]>, required: false },
  },
  data(): {
    showModal: boolean;
    selectedSchoolYear: number;
  } {
    return {
      showModal: false,
      selectedSchoolYear: this.initialSchoolYear,
    };
  },
  computed: {
    groupedClassSelectionsInternal(): { schoolYear: number; selections: Class[] }[] {
      return this.groupedClassSelections ?? [];
    },
    classSelectionsOfSchoolYear(): Class[] {
      const group = this.groupedClassSelectionsInternal.find(g => g.schoolYear === this.selectedSchoolYear);
      if (isNullish(group)) return [];
      return group.selections;
    },
    minSchoolYear(): number {
      return minBy(this.groupedClassSelectionsInternal, g => g.schoolYear)?.schoolYear ?? 1;
    },
    maxSchoolYear(): number {
      return maxBy(this.groupedClassSelectionsInternal, g => g.schoolYear)?.schoolYear ?? 9999;
    },
  },
  methods: {
    toggleModal() {
      this.selectedSchoolYear = this.initialSchoolYear;

      if (this.groupedClassSelectionsInternal.length === 0) {
        this.showModal = false;
        return;
      }
      this.showModal = !this.showModal;
    },
    onClickOutside() {
      this.selectedSchoolYear = this.initialSchoolYear;
      this.showModal = false;
    },
    schoolTypeText(schoolType: SchoolType): string {
      return schoolTypeToDisplayValue(schoolType);
    },
    selectSchoolYear(schoolYear: number) {
      this.selectedSchoolYear = schoolYear;
    },
  },
});

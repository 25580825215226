




























































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { EditableSolanProject } from "@/ts/objects/solan/editable/EditableSolanProject";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import ToggleButton from "@/components/ToggleButton.vue";
import TipBlock from "@/components/TipBlock.vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";

export default Vue.extend({
  name: "SolanAboutPure",
  components: { TipBlock, MessageView, LoadingBlock, DisableableAutoResizeTextarea, ToggleButton },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },

    studentInputLocked: { type: Boolean },
    editing: { type: Boolean },
    projectRef: { type: Function as PropType<() => EditableSolanProject | null> },

    setEditing: { type: Function },
    saveAllAndReloadProjectsIfNeeded: { type: Function },
    saveAll: { type: Function },
    deleteProject: { type: Function },
    startProject: { type: Function },
  },
  data(): { words: Words; messages: Messages } {
    return { words, messages };
  },
  computed: {
    project(): EditableSolanProject | null {
      return this.projectRef();
    },
    isProjectStarted(): boolean {
      return this.project?.started ?? false;
    },
    infoMessage(): string {
      if (this.isProjectStarted) return `Press "Save" after editing.`; // 編集が終わったら、「保存する」を押してください。
      return `This ${words.theme.d.toLowerCase()} is not started.`; // このテーマは、まだ準備中です。
    },
  },
});

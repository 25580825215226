import { words } from "@/ts/const/Words";

const confirmDelete = "Are you sure you want to delete?";
const thisTakesEffectImmediately = "This takes effect immediately.";

export const messages = {
  browserNotCompatibleToAudio: "This browser cannot play audio files.",

  chooseActivity: `Choose ${words.activity.d}`,
  commentedByTeacher: `Commented by teacher.`,
  confirmDelete,
  confirmDeleteAllYourInputs: `All your inputs will be deleted. ${confirmDelete}`,
  confirmDeleteRubric: `All ${words.journals.d.toLowerCase()} under this ${words.rubric.d.toLowerCase()} will also be deleted. ${confirmDelete} ${thisTakesEffectImmediately}`, // 本ルーブリックに紐づく、児童生徒の学習の記録もすべて削除されますが、よろしいですか？削除はすぐに反映されます。

  disableMonthsBeforeDeleteContent: `Disable all months before delete this ${words.content.d.toLowerCase()}.`,

  error: "Error occurred.",
  exportCurrentStateAsCSV: "Export current view as CSV",

  failedToLoadData: "Cannot load data.",

  ratedByTeacher: `Graded by teacher.`,

  shouldSelectClass: `${words.class.d} should be selected.`,
  shouldSelectProject: `${words.project.d} should be selected.`,
  shouldSelectSolanTheme: `${words.theme.d} should be selected.`,
  shouldSelectStudent: `${words.student.d} should be selected.`,

  teacherRatingAndTeacherCommentAreNotPublished: `${words.teacherRating.d} and ${words.teacherComment.d} are NOT published.`,

  newJournal: `New ${words.journal.d}`,
  noCurriculumContent: "No Content",
  noRecord: "No Record",

  pageNotFound: "Page not found.",
  pleaseSelectProject: `Select a ${words.project.d.toLowerCase()}.`,
  pleaseSelectSolanTheme: `Select a ${words.theme.d.toLowerCase()}.`,
  pleaseSelectStudent: `Select a ${words.student.d.toLowerCase()}.`,
  projectUnpublished: `This ${words.project.d.toLowerCase()} is not yet published.`,

  solanThemeNotStarted: `This ${words.theme.d.toLowerCase()} is not yet started.`,
} as const;

export type Messages = typeof messages;

export type Message = Messages[keyof Messages];

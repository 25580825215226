import { parseUTCOrZero } from "@/ts/utils/AppUtil";
import { JournalFile } from "@/ts/objects/common/JournalFile";

/**
 * SOLAN学習記録ファイル。
 *
 * リソース名は、 /solanProjects/{projectId}/rubrics/{rubricId}/journalFiles/{journalFileId}。
 */
export class SolanJournalFile extends JournalFile {
  readonly projectId: string;
  readonly journalFileId: string;
  readonly rubricId: string;
  readonly type: string;
  readonly subtype: string;
  readonly mediaType: string;
  readonly filename: string;
  readonly ext: string;
  readonly gcsObjectPath: string;
  readonly thumbnailGcsObjectPath: string | null;
  readonly hasThumbnail: boolean;
  readonly width: number | null;
  readonly height: number | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(
    projectId: string,
    journalFileId: string,
    rubricId: string,
    type: string,
    subtype: string,
    mediaType: string,
    filename: string,
    ext: string,
    gcsObjectPath: string,
    thumbnailGcsObjectPath: string | null,
    hasThumbnail: boolean,
    width: number | null,
    height: number | null,
    createdAt: string,
    updatedAt: string
  ) {
    super(hasThumbnail, gcsObjectPath, thumbnailGcsObjectPath);
    this.projectId = projectId;
    this.journalFileId = journalFileId;
    this.rubricId = rubricId;
    this.type = type;
    this.subtype = subtype;
    this.mediaType = mediaType;
    this.filename = filename;
    this.ext = ext;
    this.gcsObjectPath = gcsObjectPath;
    this.thumbnailGcsObjectPath = thumbnailGcsObjectPath;
    this.hasThumbnail = hasThumbnail;
    this.width = width;
    this.height = height;
    this.createdAt = parseUTCOrZero(createdAt);
    this.updatedAt = parseUTCOrZero(updatedAt);
  }
}

































































import SolanStudentViewMenuButton from "@/views/solan/teacher/SolanStudentViewT/components/SolanStudentViewMenuButton/SolanStudentViewMenuButton.vue";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";
import Vue, { PropType } from "vue";
import { SolanStudentViewTPathSegment } from "@/views/solan/teacher/SolanStudentViewT/SolanStudentViewTPathSegment";
import { ClassStudent } from "@/ts/objects/common/Class";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { isNullish } from "@/ts/utils";
import SolanStudentViewProjectSelect from "@/views/solan/teacher/SolanStudentViewT/components/SolanStudentViewProjectSelect/SolanStudentViewProjectSelect.vue";

export default Vue.extend({
  name: "SolanStudentViewHeader",
  components: { SolanStudentViewProjectSelect, SolanStudentViewMenuButton },
  props: {
    classSchoolYear: { type: Number, required: true },

    selectedTab: { type: String as PropType<SolanStudentViewTPathSegment>, required: false },
    selectedStudent: { type: Object as PropType<ClassStudent>, required: false },
    selectedProject: { type: Object as PropType<SolanProject>, required: false },
    selectableProjects: { type: Array as PropType<SolanProject[]>, required: false },

    toProjectPath: {
      type: Function as PropType<(studentUserId: string, projectId: string) => string>,
      required: true,
    },
  },
  data(): {
    words: Words;
    messages: Messages;
  } {
    return {
      words,
      messages,
    };
  },
  computed: {
    selectedStudentUserId(): string | null {
      return this.selectedStudent?.studentUserId ?? null;
    },
    selectedProjectId(): string | null {
      return this.selectedProject?.projectId ?? null;
    },
    selectedProjectPath(): string | null {
      return this.toProjectPath(this.selectedStudentUserId ?? "-", this.selectedProjectId ?? "init");
    },

    journalsPath(): string | null {
      const _selectedProjectPath = this.selectedProjectPath;
      if (isNullish(_selectedProjectPath)) return null;
      return `${_selectedProjectPath}/journals`;
    },
    rubricsPath(): string | null {
      const _selectedProjectPath = this.selectedProjectPath;
      if (isNullish(_selectedProjectPath)) return null;
      return `${_selectedProjectPath}/rubrics`;
    },
    aboutPath(): string | null {
      const _selectedProjectPath = this.selectedProjectPath;
      if (isNullish(_selectedProjectPath)) return null;
      return `${_selectedProjectPath}/about`;
    },
    lookbackPath(): string | null {
      const _selectedProjectPath = this.selectedProjectPath;
      if (isNullish(_selectedProjectPath)) return null;
      return `${_selectedProjectPath}/lookback`;
    },
  },
  methods: {
    handleProjectSelect(path: string): void {
      this.$router.push(`${path}/${this.selectedTab ?? "-"}`).catch(() => {});
    },
  },
});

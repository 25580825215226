import { Configuration, DefaultApi as ActivityDefaultApi, Activity as ActivityResp } from "@/ts/api/activity-service";
import { AxiosInstance } from "axios";
import { Err } from "@/ts/objects/Err";
import { doReq } from "@/ts/utils";

export abstract class ActivityRepository {
  abstract postActivitiesStamp(studentUserId: string, gcsObjectPath: string): Promise<ActivityResp | Err>;

  abstract deleteActivity(studentUserId: string, activityId: string): Promise<void | Err>;

  abstract readActivityLog(studentUserId: string, activityId: string): Promise<void | Err>;

  abstract postActivityLog(text: string, studentUserId: string): Promise<ActivityResp | Err>;

  abstract postMylistActivityLog(studentUserId: string, activityId: string): Promise<ActivityResp | Err>;

  abstract deleteMylistActivityLog(studentUserId: string, activityId: string): Promise<ActivityResp | Err>;

  abstract postFile(file: File, studentUserId: string): Promise<ActivityResp | Err>;

  abstract getSearchActivities(classId: string, searchText: string): Promise<ActivityResp[] | Err>;
}

export class ActivityRepositoryImpl extends ActivityRepository {
  private readonly activityService: ActivityDefaultApi;

  constructor(serviceBasePath: string, axiosConf: Configuration | undefined, axiosInstance: AxiosInstance) {
    super();
    this.activityService = new ActivityDefaultApi(axiosConf, serviceBasePath, axiosInstance);
  }

  async postActivitiesStamp(studentUserId: string, gcsObjectPath: string): Promise<ActivityResp | Err> {
    return await doReq(() => this.activityService.postActivitiesStamp(studentUserId, { url: gcsObjectPath }));
  }

  async deleteActivity(studentUserId: string, activityId: string): Promise<void | Err> {
    return await doReq(() => this.activityService.deleteActivity(studentUserId, activityId));
  }

  async readActivityLog(studentUserId: string, activityId: string): Promise<void | Err> {
    return await doReq(() => this.activityService.postActivityRead(studentUserId, activityId));
  }

  async postActivityLog(studentUserId: string, text: string): Promise<ActivityResp | Err> {
    return await doReq(() => this.activityService.postActivitiesText(studentUserId, { text: text }));
  }

  async postMylistActivityLog(studentUserId: string, activityId: string): Promise<ActivityResp | Err> {
    return await doReq(() => this.activityService.postActivityMylist(studentUserId, activityId));
  }

  async deleteMylistActivityLog(studentUserId: string, activityId: string): Promise<ActivityResp | Err> {
    return await doReq(() => this.activityService.deleteActivityMylist(studentUserId, activityId));
  }

  async postFile(file: File, studentUserId: string): Promise<ActivityResp | Err> {
    return await doReq(() => this.activityService.postActivitiesFile(studentUserId, file));
  }

  async getSearchActivities(classId: string, searchText: string): Promise<ActivityResp[] | Err> {
    return await doReq(() => this.activityService.listSearchActivities(searchText, classId));
  }
}

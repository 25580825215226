import { SchoolType } from "@/ts/objects/common/SchoolType";
import { CheckFailed } from "@/ts/app/Error";

export const gradeValues = ["e1", "e2", "e3", "e4", "e5", "e6", "j1", "j2", "j3"] as const;
export type GradeValue = typeof gradeValues[number];

export function isGradeValue(str: string): str is GradeValue {
  return gradeValues.some(v => v === str);
}

export class Grade {
  readonly value: GradeValue;
  readonly schoolType: SchoolType;

  constructor(value: string) {
    if (!isGradeValue(value)) throw new Error(`Invalid grade value: ${value}`);
    this.value = value;
    this.schoolType = value.startsWith("e") ? "elementary" : "juniorhigh";
  }

  /**
   * 数値で値を得る。
   * e1 -> 1, e2 -> 2, ..., j3 -> 9
   */
  get intValue(): number {
    return gradeValues.findIndex(v => v === this.value) + 1;
  }

  /**
   * 数値部分の値を得る。
   * つまり、例えば e2 も j2 も 2 を返す。
   */
  get gradeNumber(): number {
    switch (this.value) {
      case "e1":
        return 1;
      case "e2":
        return 2;
      case "e3":
        return 3;
      case "e4":
        return 4;
      case "e5":
        return 5;
      case "e6":
        return 6;
      case "j1":
        return 1;
      case "j2":
        return 2;
      case "j3":
        return 3;
    }
  }
}

export function gradeFromUserServiceResp(gradeObject: { schoolType: string; gradeNumber: number }): Grade {
  switch (gradeObject.schoolType) {
    case "elementary":
      return new Grade(`e${gradeObject.gradeNumber}`);
    case "juniorhigh":
      return new Grade(`j${gradeObject.gradeNumber}`);
    default:
      throw new Error(`gradeFromUserServiceResp: invalid schoolType value: ${gradeObject.schoolType}`);
  }
}

export function gradeFromSchoolTypeAndGradeNumber(schoolType: SchoolType, gradeNumber: number): Grade {
  switch (schoolType) {
    case "elementary":
      return new Grade(`e${gradeNumber}`);
    case "juniorhigh":
      return new Grade(`j${gradeNumber}`);
    default:
      throw new CheckFailed();
  }
}

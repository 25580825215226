



















import Vue, { PropType } from "vue";
import SolanRubricsTableHeaderRow from "@/views/solan/components/SolanRubricsTable/SolanRubricsTableHeaderRow/SolanRubricsTableHeaderRow.vue";
import SolanRubricsTableRow from "@/views/solan/components/SolanRubricsTable/SolanRubricsTableRow/SolanRubricsTableRow.vue";
import { EditableSolanRubric } from "@/ts/objects/solan/editable/EditableSolanRubric";
import { UserType } from "@/ts/objects/common/UserType";

export default Vue.extend({
  name: "SolanRubricsTable",
  components: { SolanRubricsTableRow, SolanRubricsTableHeaderRow },
  props: {
    userType: { type: String as PropType<UserType>, required: false },
    rubrics: { type: Array as PropType<EditableSolanRubric[]>, required: true },
    onRubricDelete: { type: Function as PropType<(rubricId: string) => Promise<void>>, required: true },

    processColWidth: { type: Number, required: true },
    extraMenuColWidth: { type: Number, required: true },
  },
  computed: {
    reversedRubrics(): EditableSolanRubric[] {
      const rubrics = this.rubrics;
      return rubrics.slice().reverse();
    },
  },
});






























import Vue, { PropType } from "vue";
import SaveStateIndicator from "@/components/SaveStateIndicator/SaveStateIndicator.vue";
import CurriculumTitle from "@/views/curriculum/components/CurriculumTitle/CurriculumTitle.vue";
import OpenSyllabusFileButton from "@/views/curriculum/components/OpenSyllabusFileButton/OpenSyllabusFileButton.vue";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import PublishSelectedRowsButton from "@/components/PublishSelectedRowsButton/PublishSelectedRowsButton.vue";
import { CurriculumListEECSelectionState } from "@/views/curriculum/teacher/CurriculumListEEC/CurriculumListEECModel";
import ErrorNotification, { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import { messages } from "@/ts/const/Messages";

export default Vue.extend({
  name: "CurriculumListEECHeader",
  components: {
    PublishSelectedRowsButton,
    SaveStateIndicator,
    CurriculumTitle,
    OpenSyllabusFileButton,
    PopupMenuButton,
    ErrorNotification,
  },
  props: {
    curriculumName: { type: String, required: true },
    needSave: { type: Boolean, required: true },
    currentErrors: { type: Array as PropType<ErrorNotificationParam[]>, required: true },

    /**
     * シラバスファイルの、GCSパス。
     */
    syllabusFileGcsObjectPath: { type: String as PropType<string | null | undefined> },

    /**
     * 現在の選択状態。
     */
    selectionState: { type: Object as PropType<CurriculumListEECSelectionState>, required: true },

    onClickPublish: { type: Function as PropType<() => void> },
    onClickUnpublish: { type: Function as PropType<() => void> },

    exportCsv: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  data(): {
    extraMenuItems: MenuItem[];
  } {
    return {
      extraMenuItems: [new MenuButton("exportCsv", messages.exportCurrentStateAsCSV, ["fas", "download"])],
    };
  },
  computed: {
    anySelected(): boolean {
      return Object.values(this.selectionState).some(v => v);
    },
  },
  methods: {
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "exportCsv":
          this.exportCsv();
          return;
      }
    },
  },
});

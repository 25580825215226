












import Vue, { PropType } from "vue";
import { ColorLabel, labelToColor } from "@/ts/objects/common/ColorLabel";

export default Vue.extend({
  name: "SecondaryHeaderTab",
  props: {
    iconType: { type: String, required: false, default: "fas" },
    /**
     * アイコン。
     * 未設定ならアイコンなし。
     */
    icon: { type: String, required: false },

    label: { type: String, required: true },
    active: { type: Boolean, required: true },
    toPath: { type: String, required: true },

    color: { type: String as PropType<ColorLabel>, required: false, default: "white" },
    backgroundColor: { type: String as PropType<ColorLabel>, required: true },
    colorActive: { type: String as PropType<ColorLabel>, required: true },
    backgroundColorActive: { type: String as PropType<ColorLabel>, required: true },
  },
  computed: {
    styles() {
      return {
        "--color": labelToColor(this.color as ColorLabel),
        "--background-color": labelToColor(this.backgroundColor),
        "--color-active": labelToColor(this.colorActive),
        "--background-color-active": labelToColor(this.backgroundColorActive),
      };
    },
  },
});

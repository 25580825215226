



































































import Vue, { PropType } from "vue";
import { AppStateStore } from "@/store/AppStateStore";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import ErrorNotification, { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import { CheckboxItem } from "@/components/FilterableHeaderButton/FilterableHeaderButton.vue";
import { Messages, messages } from "@/ts/const/Messages";
import { Err } from "@/ts/objects/Err";
import { NavigationGuardNext, Route } from "vue-router";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { EditableSolanStudent } from "@/ts/objects/solan/editable/EditableSolanStudent";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { downloadAsCSV, solanProcessTextOf } from "@/ts/utils/AppUtil";
import { unparse } from "papaparse";
import { format } from "date-fns";
import PopupMenuButton, { MenuButton } from "@/components/PopupMenuButton.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import PublishSelectedRowsButton from "@/components/PublishSelectedRowsButton/PublishSelectedRowsButton.vue";
import SaveStateIndicator from "@/components/SaveStateIndicator/SaveStateIndicator.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { SolanJournalFile } from "@/ts/objects/solan/value/SolanJournalFile";
import { Words, words } from "@/ts/const/Words";
import { filterNotNullish } from "@/ts/utils";
import ColumnFilterSwitches from "@/components/other/ColumnFilterSwitches/ColumnFilterSwitches.vue";
import {
  SolanJournalsTColFilterState,
  SolanJournalsTEmptyFilterCheckState,
  SolanJournalsTProjectRow,
  SolanJournalsTRowFilterState,
  SolanJournalsTStudentRow,
  SolanJournalsTStyleProps,
} from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTModel";
import SolanJournalsTTableStudentRow from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTTable/SolanJournalsTTableStudentRow/SolanJournalsTTableStudentRow.vue";
import SolanJournalsTTableHeaderRow from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTTable/SolanJournalsTTableHeaderRow/SolanJournalsTTableHeaderRow.vue";

// TODO col-filterを切り替えた時、選択状態が解除されたりされなかったりする。なぜ？

type RowSelectionStateSummary = "all" | "some" | "none";

const stylePropsBase: Omit<SolanJournalsTStyleProps, "currentJournalRowWidth" | "currentTableWidth"> = {
  studentColWidth: 140,
  projectColWidth: 140,
  processColWidth: 110,
  learningActivityColWidth: 160,
  viewPointSColWidth: 160,
  viewPointAColWidth: 160,
  viewPointBColWidth: 160,
  viewPointCColWidth: 160,
  journalFilesColWidth: 160,
  studentCommentColWidth: 200,
  studentRatingColWidth: 60,
  studentInputHeaderWidth: 200 + 60,
  teacherRatingColWidth: 60,
  teacherCommentColWidth: 210,
  guardianCommentColWidth: 210,
  teacherInputPublishedColWidth: 107,
  selectionColWidth: 60,
};

export default Vue.extend({
  name: "SolanJournalsT",
  components: {
    SolanJournalsTTableHeaderRow,
    SolanJournalsTTableStudentRow,
    ColumnFilterSwitches,
    LoadingBlock,
    MessageView,
    ErrorNotification,
    PopupMenuButton,
    PublishSelectedRowsButton,
    SaveStateIndicator,
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true },
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
      onRequirementUnmet: async () => {
        this.highlightUnsaved = true;
      },
    });

    this.solanRepository
      .listEditableSolanStudents(
        this.userRepository,
        this.appStateStore.teacherState?.selectedClass() ?? null,
        true,
        true,
        false
      )
      .then(resp => {
        if (resp instanceof Err) {
          log.debug("Error loading journals!");
          this.messageView = { message: messages.failedToLoadData, fadeIn: true };
          return;
        }

        this.students = resp;
        this.studentRowFilterCheckboxItems = resp.map(student => ({
          key: student.studentUserId,
          label: `${student.studentNumber}番 ${student.name}`,
        }));

        const students = this.students;
        if (students === null) return;
      });
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;

    extraMenuItems: MenuButton[];

    students: EditableSolanStudent[] | null;

    studentRowFilterCheckboxItems: CheckboxItem[];
    teacherRatingRowFilterCheckboxItems: CheckboxItem[];
    teacherCommentRowFilterCheckboxItems: CheckboxItem[];
    guardianCommentRowFilterCheckboxItems: CheckboxItem[];

    rowFilterState: SolanJournalsTRowFilterState;
    colFilterState: SolanJournalsTColFilterState;

    studentRows: SolanJournalsTStudentRow[];

    filesViewJournal: EditableSolanJournal | null;

    highlightUnsaved: boolean;

    pageLeaveService: PageLeaveService | null;

    words: Words;
    messages: Messages;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,

      extraMenuItems: [new MenuButton("exportCsv", messages.exportCurrentStateAsCSV, ["fas", "download"])],

      students: null,

      studentRowFilterCheckboxItems: [],
      teacherRatingRowFilterCheckboxItems: [
        { key: "empty", label: words.empty.d },
        { key: "hasValue", label: words.filled.d },
      ],
      teacherCommentRowFilterCheckboxItems: [
        { key: "empty", label: words.empty.d },
        { key: "hasValue", label: words.filled.d },
      ],
      guardianCommentRowFilterCheckboxItems: [
        { key: "empty", label: words.empty.d },
        { key: "hasValue", label: words.filled.d },
      ],

      rowFilterState: {
        student: {},
        teacherRating: { empty: true, hasValue: true },
        teacherComment: { empty: true, hasValue: true },
        guardianComment: { empty: true, hasValue: true },
      },
      colFilterState: {
        learningActivity: true,
        rubrics: true,
        journalFiles: true,
        ratingsAndComments: true,
        guardianComment: true,
      },

      studentRows: [],

      filesViewJournal: null,

      highlightUnsaved: false,

      pageLeaveService: null,

      words,
      messages,
    };
  },
  computed: {
    columnFilterSwitchesState(): {
      readonly key: keyof SolanJournalsTColFilterState;
      readonly label: string;
      readonly active: boolean;
    }[] {
      const _colFilterState = this.colFilterState;
      return [
        { key: "learningActivity", label: words.activity.d, active: _colFilterState.learningActivity },
        { key: "rubrics", label: words.rubric.d, active: _colFilterState.rubrics },
        { key: "journalFiles", label: words.journalFile.d, active: _colFilterState.journalFiles },
        { key: "ratingsAndComments", label: words.ratingsAndComments.d, active: _colFilterState.ratingsAndComments },
        { key: "guardianComment", label: words.guardianComment.d, active: _colFilterState.guardianComment },
      ];
    },
    filesViewJournalRname(): string | null {
      return this.filesViewJournal?.rubricResourceName ?? null;
    },

    currentJournalRowWidth(): number {
      let width =
        stylePropsBase.processColWidth +
        stylePropsBase.teacherInputPublishedColWidth +
        stylePropsBase.selectionColWidth;
      if (this.colFilterState.learningActivity) {
        width += stylePropsBase.learningActivityColWidth;
      }
      if (this.colFilterState.rubrics) {
        width +=
          stylePropsBase.viewPointSColWidth +
          stylePropsBase.viewPointAColWidth +
          stylePropsBase.viewPointBColWidth +
          stylePropsBase.viewPointCColWidth;
      }
      if (this.colFilterState.journalFiles) {
        width += stylePropsBase.journalFilesColWidth;
      }
      if (this.colFilterState.ratingsAndComments) {
        width +=
          stylePropsBase.studentInputHeaderWidth +
          stylePropsBase.teacherRatingColWidth +
          stylePropsBase.teacherCommentColWidth;
      }
      if (this.colFilterState.guardianComment) {
        width += stylePropsBase.guardianCommentColWidth;
      }
      return width;
    },
    currentTableWidth(): number {
      return stylePropsBase.studentColWidth + stylePropsBase.projectColWidth + this.currentJournalRowWidth;
    },
    styleProps(): SolanJournalsTStyleProps {
      return {
        ...stylePropsBase,
        currentJournalRowWidth: this.currentJournalRowWidth,
        currentTableWidth: this.currentTableWidth,
      };
    },

    needSave(): boolean {
      const students = this.students;
      if (students === null) return false;
      return students.some(s => s.needSave());
    },
    currentErrors(): ErrorNotificationParam[] {
      return (
        this.students?.flatMap(s =>
          s.currentErrors().map(e => {
            return {
              heading: `${words.studentNumberAbbr} ${s.studentNumber} ${s.name}`,
              text: `${e.message}`,
            };
          })
        ) ?? []
      );
    },
    rowSelectionStateSummary(): RowSelectionStateSummary {
      // TODO この程度重くないとは思うが、要確認？
      const values: boolean[] = this.studentRows.flatMap(sr => {
        return sr.projectRows.flatMap(pr => {
          const journalSelections = pr.journalRows.map(jr => jr.selected);
          const lookbackSelections = pr.lookbackRow !== null ? [pr.lookbackRow.selected] : [];
          return [...journalSelections, ...lookbackSelections];
        });
      });
      let foundSelected = false;
      let foundUnselected = false;
      for (const v of values) {
        if (v === true) {
          foundSelected = true;
        } else {
          foundUnselected = true;
        }
      }

      if (foundSelected && !foundUnselected) {
        return "all";
      } else if (foundSelected && foundUnselected) {
        return "some";
      } else {
        return "none";
      }
    },
  },
  methods: {
    async saveAll(force: boolean): Promise<SaveResult> {
      const students = this.students;
      if (students === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(students.map(s => s.saveAllChanges(force))));
    },
    onChangeColFilterState(key: keyof SolanJournalsTColFilterState, active: boolean) {
      this.colFilterState = {
        ...this.colFilterState,
        [key]: active,
      };

      if (key === "journalFiles") this.filesViewJournal = null;
    },
    onStudentRowFilterChange(state: Record<string, boolean>) {
      log.debug(`onStudentRowFilterChanged!: ${JSON.stringify(state)}`);
      this.rowFilterState.student = state;
      this.applyRowFilter();
    },
    onTeacherRatingRowFilterChange(state: SolanJournalsTEmptyFilterCheckState) {
      log.debug(`onTeacherRatingRowFilterChanged!: ${JSON.stringify(state)}`);
      this.rowFilterState.teacherRating = state;
      this.applyRowFilter();
    },
    onTeacherCommentRowFilterChange(state: SolanJournalsTEmptyFilterCheckState) {
      log.debug(`onTeacherCommentRowFilterChanged!: ${JSON.stringify(state)}`);
      this.rowFilterState.teacherComment = state;
      this.applyRowFilter();
    },
    onGuardianCommentRowFilterChange(state: SolanJournalsTEmptyFilterCheckState) {
      log.debug(`onGuardianCommentRowFilterChanged!: ${JSON.stringify(state)}`);
      this.rowFilterState.guardianComment = state;
      this.applyRowFilter();
    },
    applyRowFilter() {
      const students = this.students;
      if (students === null) return [];

      // 画面初期表示時に何度も呼ばれるので、もし重ければなんとかする。
      const rowFilterState = this.rowFilterState;

      const filteredStudents = students.filter(s => rowFilterState.student[s.studentUserId] === true);
      const filteredStudentRows = filteredStudents
        .map(student => {
          return {
            student: student,
            projectRows: student.projects
              .map(p => {
                return {
                  studentProject: p,
                  filteredJournals: p.journals.filter(j => shouldDisplayJournal(j, rowFilterState)),
                  filteredLookback: shouldDisplayLookback(p.lookback, rowFilterState) ? p.lookback : null,
                };
              })
              .filter(v => v.filteredJournals.length > 0 || v.filteredLookback !== null)
              .map(v => {
                return {
                  studentProject: v.studentProject,
                  journalRows: v.filteredJournals.map(j => {
                    return {
                      journal: j,
                      selected: false,
                    };
                  }),
                  lookbackRow:
                    v.filteredLookback !== null
                      ? {
                          lookback: v.filteredLookback,
                          selected: false,
                        }
                      : null,
                };
              }),
          };
        })
        .filter(studentRow => studentRow.projectRows.length > 0);
      log.debug(
        `applyRowFilter!: rowFilterState=${JSON.stringify(rowFilterState)} filteredStudentRows=${JSON.stringify(
          filteredStudentRows
        )}`
      );
      this.studentRows = filteredStudentRows;
    },
    toggleAllRowSelections() {
      // 参考: https://github.com/vuejs/vue/issues/9535#issuecomment-466217819
      setTimeout(() => {
        const changeTo = this.rowSelectionStateSummary === "none" ? true : false;
        for (const sr of this.studentRows) {
          for (const pr of sr.projectRows) {
            for (const jr of pr.journalRows) {
              jr.selected = changeTo;
            }
            if (pr.lookbackRow !== null) {
              pr.lookbackRow.selected = changeTo;
            }
          }
        }
      });
    },
    publishSelectedRows() {
      if (this.rowSelectionStateSummary === "none") return;
      changeSelectedRowsPublishState(true, this.studentRows);
      this.saveAll(false);
    },
    unpublishSelectedRows() {
      if (this.rowSelectionStateSummary === "none") return;
      changeSelectedRowsPublishState(false, this.studentRows);
      this.saveAll(false);
    },
    setFilesView(studentUserId: string | null, projectId: string | null, rubricId: string | null) {
      if (studentUserId === null || projectId === null || rubricId === null) {
        this.filesViewJournal = null;
        return;
      }

      const studentRow: SolanJournalsTStudentRow | undefined = this.studentRows.find(
        s => s.student.studentUserId === studentUserId
      );
      if (studentRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      const projectRow: SolanJournalsTProjectRow | undefined = studentRow.projectRows.find(
        p => p.studentProject.project.projectId === projectId
      );
      if (projectRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      const journalRow = projectRow.journalRows.find(j => j.journal.rubricId === rubricId);
      if (journalRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      this.filesViewJournal = journalRow.journal;
    },
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "exportCsv":
          this.exportCsv();
          break;
      }
    },
    async uploadJournalFile(file: File, journal: EditableSolanJournal) {
      const resp = await this.solanRepository.postJournalFile(journal.projectId, journal.rubricId, file, 30000);
      log.debug(`uploadFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    async deleteJournalFile(journalFile: JournalFile, journal: EditableSolanJournal): Promise<void> {
      if (!(journalFile instanceof SolanJournalFile)) return;

      const resp = await this.solanRepository.deleteJournalFile(
        journalFile.projectId,
        journalFile.rubricId,
        journalFile.journalFileId
      );
      log.debug(`deleteFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    exportCsv() {
      exportCsv(this.studentRows, this.colFilterState);
    },
  },
});

function changeSelectedRowsPublishState(changeTo: boolean, studentRows: SolanJournalsTStudentRow[]) {
  studentRows.forEach(sr => {
    sr.projectRows.forEach(pr => {
      pr.journalRows
        .filter(jr => jr.selected)
        .forEach(jr => {
          jr.journal.teacherInputPublished = changeTo;
        });
      if (pr.lookbackRow !== null && pr.lookbackRow.selected) {
        pr.lookbackRow.lookback.teacherInputPublished = changeTo;
      }
    });
  });
}

function shouldDisplayJournal(journal: EditableSolanJournal, rowFilterState: SolanJournalsTRowFilterState): boolean {
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && journal.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && journal.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && journal.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && journal.teacherComment !== "");
  const guardianCommentOk = rowFilterState.guardianComment.empty;
  return teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function shouldDisplayLookback(lookback: EditableSolanLookback, rowFilterState: SolanJournalsTRowFilterState): boolean {
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && lookback.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && lookback.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && lookback.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && lookback.teacherComment !== "");
  const guardianCommentOk =
    (rowFilterState.guardianComment.empty && lookback.guardianComment === "") ||
    (rowFilterState.guardianComment.hasValue && lookback.guardianComment !== "");
  return teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function exportCsv(studentRows: SolanJournalsTStudentRow[], colFilterState: SolanJournalsTColFilterState) {
  const csvRows = studentRows.flatMap(s => {
    return s.projectRows.flatMap(p => {
      const journalCsvRows = p.journalRows.map(j => {
        return {
          [words.studentNumber.d]: s.student.studentNumber,
          [words.name.d]: s.student.name,
          [words.theme.d]: p.studentProject.project.name,
          [words.process.d]: solanProcessTextOf(j.journal.process),
          [words.activity.d]: j.journal.rubricLearningActivity,
          S: j.journal.rubricViewPointS,
          A: j.journal.rubricViewPointA,
          B: j.journal.rubricViewPointB,
          C: j.journal.rubricViewPointC,
          [words.journalFile.d]: j.journal.journalFiles.length,
          [words.studentComment.d]: j.journal.studentComment,
          [words.studentRating.d]: j.journal.studentRating,
          [words.teacherRating.d]: j.journal.teacherRating,
          [words.teacherComment.d]: j.journal.teacherComment,
          [words.guardianComment.d]: "",
          [words.teacherInputPublished.d]: j.journal.teacherInputPublished ? words.published.d : words.unpublished.d,
        };
      });
      if (p.lookbackRow === null) {
        return journalCsvRows;
      }

      const lookbackRow = {
        [words.studentNumber.d]: s.student.studentNumber,
        [words.name.d]: s.student.name,
        [words.theme.d]: p.studentProject.project.name,
        [words.process.d]: words.lookback.d,
        [words.activity.d]: "",
        S: "",
        A: "",
        B: "",
        C: "",
        [words.journalFile.d]: 0,
        [words.studentComment.d]: p.lookbackRow.lookback.studentComment,
        [words.studentRating.d]: p.lookbackRow.lookback.studentRating,
        [words.teacherRating.d]: p.lookbackRow.lookback.teacherRating,
        [words.teacherComment.d]: p.lookbackRow.lookback.teacherComment,
        [words.guardianComment.d]: p.lookbackRow.lookback.guardianComment,
        [words.teacherInputPublished.d]: p.lookbackRow.lookback.teacherInputPublished
          ? words.published.d
          : words.unpublished.d,
      };

      return [...journalCsvRows, lookbackRow];
    });
  });
  const columnNames = filterNotNullish([
    words.studentNumber.d,
    words.name.d,
    words.theme.d,
    words.process.d,
    colFilterState.learningActivity ? words.activity.d : undefined,
    colFilterState.rubrics ? "S" : undefined,
    colFilterState.rubrics ? "A" : undefined,
    colFilterState.rubrics ? "B" : undefined,
    colFilterState.rubrics ? "C" : undefined,
    colFilterState.journalFiles ? words.journalFile.d : undefined,
    colFilterState.ratingsAndComments ? words.studentComment.d : undefined,
    colFilterState.ratingsAndComments ? words.studentRating.d : undefined,
    colFilterState.ratingsAndComments ? words.teacherRating.d : undefined,
    colFilterState.ratingsAndComments ? words.teacherComment.d : undefined,
    colFilterState.guardianComment ? words.guardianComment.d : undefined,
    words.teacherInputPublished.d,
  ]);

  downloadAsCSV(
    unparse(csvRows, { columns: columnNames }),
    `SOLAN_Inquiry_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`
  );
}

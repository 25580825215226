















import Vue, { PropType } from "vue";
import { ClassStudent } from "@/ts/objects/common/Class";
import { Loadable } from "@/ts/Loadable";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "StudentSelectMenu",
  props: {
    students: { type: Object as PropType<Loadable<ClassStudent[]>>, required: true },
    selectedStudentUserId: { type: String as PropType<string | null | undefined> },
    toPath: { type: Function as PropType<(studentUserId: string) => string>, required: true },
  },
  data(): {
    words: Words;
  } {
    return { words };
  },
  computed: {
    studentsData(): ClassStudent[] {
      return this.students.data ?? [];
    },
  },
});

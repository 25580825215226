













































import Vue, { PropType } from "vue";
import ActivityView from "@/views/activity/components/ActivityView.vue";
import RecentActivityView from "@/views/activity/components/RecentActivityView.vue";
import { ClassStudent } from "@/ts/objects/common/Class";

import { AppStateStore } from "@/store/AppStateStore";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";
import log from "loglevel";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";

export default Vue.extend({
  name: "ActivityT",
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    activityRepository: { type: Object as PropType<ActivityRepository>, required: true },

    targetStudentUserId: { type: String, required: false },
    targetBaseDate: { type: String, required: false }, // $router.push のquery指定ではstringのみのため
    targetUuid: { type: String, required: false },
  },
  components: { ActivityView, RecentActivityView },
  data(): {
    selectedStudentUserId: string | null;
    selectedUuid: string | null;
    selectedDate: Date | null;

    words: Words;
    messages: Messages;
  } {
    return {
      selectedStudentUserId: null,
      selectedUuid: null,
      selectedDate: null,

      words,
      messages,
    };
  },
  asyncComputed: {
    async selectedStudent(): Promise<ClassStudent | null> {
      const studentUserId = this.selectedStudentUserId;
      if (studentUserId === null) return null;
      return this.appStateStore.teacherState?.studentOfSelectedClass(this.userRepository, studentUserId) ?? null;
    },
    async classStudents(): Promise<ClassStudent[]> {
      return this.appStateStore.teacherState?.studentsOfSelectedClass(this.userRepository) ?? [];
    },
  },
  mounted: function() {
    const cameFromHomeT =
      this.targetStudentUserId !== undefined || this.targetBaseDate !== undefined || this.targetUuid !== undefined;
    log.debug(`ActivityT: cameFromHomeT=${cameFromHomeT}`);
    if (cameFromHomeT) {
      this.selectedRecentActivity(this.targetStudentUserId, this.targetUuid, new Date(this.targetBaseDate));
    }
  },
  methods: {
    displayActivity(studentUserId: string) {
      this.setActivityViewProps(studentUserId);
    },

    selectedRecentActivity(studentUserId: string, uuid: string, date: Date) {
      this.setActivityViewProps(studentUserId, uuid, date);
    },

    setActivityViewProps(studentUserId: string, uuid: string | null = null, date: Date | null = null) {
      this.selectedUuid = uuid;
      this.selectedStudentUserId = studentUserId;
      this.selectedDate = date;
    },
  },
});

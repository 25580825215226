import { isNullish } from "@/ts/utils";

export const schoolTypes = {
  elementary: "Elementary",
  juniorhigh: "Junior High",
} as const;

/**
 * 学校タイプ。
 */
export type SchoolType = keyof typeof schoolTypes;
export type SchoolTypeDisplayValue = typeof schoolTypes[SchoolType];

export function isSchoolType(value: unknown): value is SchoolType {
  return typeof value === "string" && Object.keys(schoolTypes).some(v => v === value);
}

export function asSchoolTypeOrNull(value: unknown): SchoolType | null {
  return isSchoolType(value) ? value : null;
}

export function asSchoolTypeOrError(value: unknown): SchoolType {
  const result = asSchoolTypeOrNull(value);
  if (isNullish(result)) throw new Error(`asSchoolTypeOrError: invalid school type: ${value}`);
  return result;
}

export function schoolTypeToDisplayValue(value: SchoolType): SchoolTypeDisplayValue {
  return schoolTypes[value];
}

export function displayValueToSchoolTypeOrNull(displayValue: string): SchoolType | null {
  const kv = Object.entries(schoolTypes).find(([_k, v]) => v === displayValue);
  if (isNullish(kv)) return null;
  const k = kv[0];
  if (!isSchoolType(k)) return null;
  return k;
}

export function displayValueToSchoolTypeOrError(displayValue: string): SchoolType {
  const result = displayValueToSchoolTypeOrNull(displayValue);
  if (isNullish(result))
    throw new Error(`displayValueToSchoolTypeOrError: invalid school type display value: ${displayValue}`);
  return result;
}

export function schoolTypeFromStringOrError(value: string): SchoolType {
  if (isSchoolType(value)) {
    return value;
  }
  throw new Error(`schoolTypeFromStringOrError: invalid school type value: ${value}`);
}



















import Vue, { PropType } from "vue";

type FilterSwitchState = {
  readonly key: string;
  readonly label: string;
  readonly active: boolean;
};

export default Vue.extend({
  name: "ColumnFilterSwitches",
  props: {
    state: { type: Array as PropType<FilterSwitchState[]>, required: true },
    onChange: { type: Function as PropType<(key: string, active: boolean) => void>, required: true },
  },
});









































import Vue, { PropType } from "vue";
import CurriculumSyllabusNECContentRow from "@/views/curriculum/teacher/CurriculumSyllabusNEC/components/CurriculumSyllabusNECContentRow/CurriculumSyllabusNECContentRow.vue";
import {
  ContentInputEvent,
  ContentMonthInputEvent,
  ICurriculumSyllabusNECViewPointRowModel,
} from "@/views/curriculum/teacher/CurriculumSyllabusNEC/CurriculumSyllabusNECModel";
import { MonthValue, monthValueToEnglishAbbr } from "@/ts/objects/common/MonthValue";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "CurriculumSyllabusNECViewPointRow",
  components: { CurriculumSyllabusNECContentRow },
  props: {
    model: { type: Object as PropType<ICurriculumSyllabusNECViewPointRowModel>, required: true },
    monthsToDisplay: { type: Array as PropType<MonthValue[]>, required: true },

    onInputContent: {
      type: Function as PropType<(contentId: string, event: ContentInputEvent) => void>,
      required: true,
    },
    onInputContentMonth: {
      type: Function as PropType<(contentId: string, month: MonthValue, event: ContentMonthInputEvent) => void>,
      required: true,
    },
    moveContent: {
      type: Function as PropType<(contentId: string, up: boolean) => Promise<void>>,
      required: true,
    },
    createContent: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    deleteContent: {
      type: Function as PropType<(contentId: string) => Promise<void>>,
      required: true,
    },
  },
  data(): {
    words: Words;
  } {
    return { words };
  },
  methods: {
    toMonthText: monthValueToEnglishAbbr,
  },
});

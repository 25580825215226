import { Student } from "@/ts/objects/user/Student";
import { WordInternalName } from "@/ts/const/Words";
import { UserColumnDef } from "@/ts/app/columns/UserColumnDef";
import { attachValueToCols, UserColumn } from "@/ts/app/columns/UserColumn";
import {
  StudentCustomColumnNames,
  studentCustomColumnNamesFromStudent,
} from "@/ts/objects/common/StudentCustomColumnNames";
import { studentProfileColumnDefs, StudentProfileColumnId } from "@/ts/app/columns/student/StudentProfileColumn";
import { studentHealthColumnDefs, StudentHealthColumnId } from "@/ts/app/columns/student/StudentHealthColumn";
import { studentCustomColumnDefs, StudentCustomColumnId } from "@/ts/app/columns/student/StudentCustomColumn";
import { filterNotNullish, isNullish } from "@/ts/utils";

export type StudentColumnId = StudentProfileColumnId | StudentHealthColumnId | StudentCustomColumnId;

export type StudentColumnDef<ColId extends WordInternalName, T> = UserColumnDef<ColId, Student, T>;

export type StudentColumn<ColId extends WordInternalName, T> = UserColumn<ColId, Student, T>;

export function getStudentColumnDefs(colNames: StudentCustomColumnNames): StudentColumnDef<StudentColumnId, any>[] {
  return [...studentProfileColumnDefs(), ...studentHealthColumnDefs(), ...studentCustomColumnDefs(colNames)];
}

export function getStudentColumns(student: Student, colIds?: StudentColumnId[]): StudentColumn<StudentColumnId, any>[] {
  const allColumnDefs = getStudentColumnDefs(studentCustomColumnNamesFromStudent(student));
  const columnDefs = isNullish(colIds)
    ? allColumnDefs
    : filterNotNullish(colIds.map(id => allColumnDefs.find(def => def.id === id)));

  return attachValueToCols(columnDefs, student);
}
































import Vue, { PropType } from "vue";
import clamp from "lodash/clamp";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "SchoolYearControl",
  components: { RouterLinkWrapper },
  props: {
    schoolYear: { type: Number as PropType<number | null>, required: true },
    minSchoolYear: { type: Number as PropType<number | null>, required: true },
    maxSchoolYear: { type: Number as PropType<number | null>, required: true },

    mode: { type: String as PropType<"link" | "button">, required: false, default: "link" },
    schoolYearToPath: { type: Function as PropType<(schoolYear: number) => string>, required: false },
    onChange: { type: Function as PropType<(schoolYear: number) => void>, required: false },

    opacity: { type: Number, default: 0.25 },
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--opacity": `${this.opacity}`,
      };
    },
    prevSchoolYear(): number | null {
      const schoolYear = this.schoolYear;
      if (schoolYear === null) return null;
      return clamp(schoolYear - 1, this.minSchoolYear ?? 2000, this.maxSchoolYear ?? 2000);
    },
    nextSchoolYear(): number | null {
      const schoolYear = this.schoolYear;
      if (schoolYear === null) return null;
      return clamp(schoolYear + 1, this.minSchoolYear ?? 2000, this.maxSchoolYear ?? 2000);
    },
  },
  methods: {
    schoolYearToPathInternal(schoolYear: number | null): string | null {
      const schoolYearToPath = this.schoolYearToPath;
      if (isNullish(schoolYearToPath) || isNullish(schoolYear)) return null;
      return schoolYearToPath(schoolYear);
    },
    onChangeInternal(schoolYear: number | null) {
      const onChange = this.onChange;
      if (isNullish(onChange) || isNullish(schoolYear)) return;
      onChange(schoolYear);
    },
  },
});

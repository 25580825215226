


















import Vue, { PropType } from "vue";
import CurriculumHeader from "@/views/curriculum/student/CurriculumHeader/CurriculumHeader.vue";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import log from "loglevel";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { getDataWithTimeout } from "@/ts/Loadable";
import { AppStateStore } from "@/store/AppStateStore";
import VueRouter from "vue-router";

export default Vue.extend({
  name: "Curriculum",
  components: { MessageView, CurriculumHeader },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    curriculumStoreS: { type: Object as PropType<CurriculumStoreS>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true },
  },
  async created() {
    await initialize(this.curriculumStoreS, this.curriculumRepository, this.$router);
  },
  computed: {
    messageView(): MessageViewParam | null {
      const store = this.curriculumStoreS;
      if (store.tab === null || store.selectedSchoolYear === null || store.grade === null)
        return { message: messages.pageNotFound };
      return null;
    },
    schoolYear(): number | null {
      return this.curriculumStoreS.selectedSchoolYear;
    },
    studentUserId(): string | null {
      return this.curriculumStoreS.studentUserId;
    },
    headerWriteButtonPath(): string {
      return (
        this.curriculumStoreS.firstCurriculumWritePath.data ?? this.appStateStore.studentOrGuardianCurriculumDefaultPath
      );
    },
    headerReadButtonPath(): string {
      return (
        this.curriculumStoreS.firstCurriculumReadPath.data ?? this.appStateStore.studentOrGuardianCurriculumDefaultPath
      );
    },
    minSchoolYear(): number | null {
      return this.appStateStore.studentOrGuardianState?.studentMinSchoolYear() ?? null;
    },
    maxSchoolYear(): number | null {
      return this.appStateStore.studentOrGuardianState?.studentMaxSchoolYear() ?? null;
    },
  },
  watch: {
    /**
     * 年度が変更されたとき、再初期化する。
     * (※年度が変更されると、必ず学年も変更されるため、学年をwatchする必要は無い。)
     */
    async schoolYear() {
      log.debug(`Curriculum: watch: schoolYear`);
      await initialize(this.curriculumStoreS, this.curriculumRepository, this.$router);
    },

    /**
     * 児童生徒IDが変更されたとき、再初期化する。
     */
    async studentUserId() {
      log.debug(`Curriculum: watch: studentUserId`);
      await initialize(this.curriculumStoreS, this.curriculumRepository, this.$router);
    },
  },
  methods: {
    schoolYearToPath(schoolYear: number): string {
      if (this.appStateStore.isAppUserStudent) {
        return this.appStateStore.studentOrGuardianCurriculumPath(schoolYear, "write", null, null, null);
      }
      if (this.appStateStore.isAppUserGuardian) {
        return this.appStateStore.studentOrGuardianCurriculumPath(
          schoolYear,
          "read",
          "monthly",
          this.appStateStore.now.month,
          null
        );
      }
      return "";
    },
  },
});

/**
 * 教科学習ページを初期化(データリロード等)する。
 * 年度, 児童生徒 のいずれかが変更されたとき、呼び出しが必要。
 * (※年度が変更されると、必ず学年も変更されるため、学年をwatchする必要は無い。)
 */
async function initialize(
  curriculumStoreS: CurriculumStoreS,
  curriculumRepository: CurriculumRepository,
  router: VueRouter
) {
  log.debug("Curriculum: initializing...");
  await curriculumStoreS.loadAllDataOfSelectedYear(curriculumRepository);

  log.debug(`this.curriculumStoreS.selectedCurriculumRname=${curriculumStoreS.selectedCurriculumRname}`);
  if (curriculumStoreS.selectedCurriculumRname === null) {
    const path = await getDataWithTimeout(() => curriculumStoreS.defaultPathOfSelectedTab);
    log.debug(`Curriculum: path=${path}`);
    if (path !== null) router.push(path).catch(() => {});
  }
}

import { parseUTCOrZero, projectJournalFileRnameToId } from "@/ts/utils/AppUtil";
import { JournalFile } from "@/ts/objects/common/JournalFile";

/**
 * プロジェクト学習記録ファイル。
 *
 * リソース名は、 /projects/{projectId}/rubrics/{rubricId}/journals/{journalId}/journalFiles/{journalFileId}。
 */
export class ProjectJournalFile extends JournalFile {
  readonly self: string;
  readonly projectId: string;
  readonly rubricId: string;
  readonly journalId: string;
  readonly journalFileId: string;
  readonly journal: string;
  readonly type: string;
  readonly subtype: string;
  readonly mediaType: string;
  readonly filename: string;
  readonly ext: string;
  readonly gcsObjectPath: string;
  readonly thumbnailGcsObjectPath: string | null;
  readonly hasThumbnail: boolean;
  readonly width: number | null;
  readonly height: number | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(
    self: string,
    journal: string,
    type: string,
    subtype: string,
    mediaType: string,
    filename: string,
    ext: string,
    gcsObjectPath: string,
    thumbnailGcsObjectPath: string | null,
    hasThumbnail: boolean,
    width: number | null,
    height: number | null,
    createdAt: string,
    updatedAt: string
  ) {
    super(hasThumbnail, gcsObjectPath, thumbnailGcsObjectPath);
    this.self = self;
    [this.projectId, this.rubricId, this.journalId, this.journalFileId] = projectJournalFileRnameToId(self) ?? [
      "unknown",
      "unknown",
      "unknown",
      "unknown",
    ]; // TODO これどうにかする？
    this.journal = journal;
    this.type = type;
    this.subtype = subtype;
    this.mediaType = mediaType;
    this.filename = filename;
    this.ext = ext;
    this.gcsObjectPath = gcsObjectPath;
    this.thumbnailGcsObjectPath = thumbnailGcsObjectPath;
    this.hasThumbnail = hasThumbnail;
    this.width = width;
    this.height = height;
    this.createdAt = parseUTCOrZero(createdAt);
    this.updatedAt = parseUTCOrZero(updatedAt);
  }
}

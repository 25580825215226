export const emptinessFilterValues = ["hasValue", "empty"] as const;
export type EmptinessFilterValue = typeof emptinessFilterValues[number];

export function isEmptinessFilterValue(value: any): value is EmptinessFilterValue {
  return typeof value === "string" && emptinessFilterValues.some(v => v === value);
}

export function satisfiesEmptinessFilter(value: string, filterValues: EmptinessFilterValue[]): boolean {
  if (value.length === 0) return filterValues.includes("empty");
  else return filterValues.includes("hasValue");
}






import Vue from "vue";

export default Vue.extend({
  name: "ErrorPage",
  props: {
    message: { type: String, default: "ページが見つかりません。" },
  },
});

import { render, staticRenderFns } from "./ProfileTableItemValueText.vue?vue&type=template&id=4e5ff7f6&scoped=true&"
import script from "./ProfileTableItemValueText.vue?vue&type=script&lang=ts&"
export * from "./ProfileTableItemValueText.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileTableItemValueText.vue?vue&type=style&index=0&id=4e5ff7f6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5ff7f6",
  null
  
)

export default component.exports
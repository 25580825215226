












































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { words, Words } from "@/ts/const/Words";
import SolanLookbackSection from "@/views/solan/components/SolanLookbackSection/SolanLookbackSection.vue";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "SolanLookbackPure",
  components: { SolanLookbackSection, MessageView },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },
    lookbackRef: { type: Function as PropType<() => EditableSolanLookback | null> },

    reloadData: { type: Function },
    saveAll: { type: Function },
  },
  data(): { words: Words } {
    return { words };
  },
  computed: {
    lookback(): EditableSolanLookback | null {
      return this.lookbackRef();
    },

    studentComment(): string | null {
      return this.lookback?.studentComment ?? null;
    },
    studentRating(): string | null {
      return this.lookback?.studentRating ?? null;
    },
    studentInputLocked(): boolean | null {
      return this.lookback?.studentInputLocked ?? null;
    },

    teacherComment(): string | null {
      return this.lookback?.teacherComment ?? null;
    },
    teacherRating(): string | null {
      return this.lookback?.teacherRating ?? null;
    },

    guardianComment(): string | null {
      return this.lookback?.guardianComment ?? null;
    },
    guardianInputLocked(): boolean | null {
      return this.lookback?.guardianInputLocked ?? null;
    },
  },
  methods: {
    handleStudentCommentChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.studentComment = value;
    },
    handleStudentRatingChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.studentRating = value;
    },
    handleTeacherCommentChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.teacherComment = value;
    },
    handleTeacherRatingChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.teacherRating = value;
    },
    handleGuardianCommentChange(value: string) {
      const lookback = this.lookback;
      if (isNullish(lookback)) return;
      lookback.guardianComment = value;
    },
  },
});

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "@/store/Store";
import { appStateStoreGlobal } from "@/store/AppStateStore";
import { CurriculumTabNameT, isCurriculumTabNameT } from "@/ts/objects/curriculum/value/CurriculumTabNameT";
import { CurriculumPeriodMode, isCurriculumPeriodMode } from "@/ts/objects/curriculum/value/CurriculumPeriodMode";
import { CurriculumCollectionId, isCurriculumCollectionId } from "@/ts/objects/curriculum/value/CurriculumCollectionId";
import { Loadable, LoadableError, LoadableIdle, LoadableLoading } from "@/ts/Loadable";
import { NECurriculum, NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { EECurriculum, EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import { isNullish, rnameToRnameObject } from "@/ts/utils";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import log from "loglevel";
import { Err } from "@/ts/objects/Err";
import { Class } from "@/ts/objects/common/Class";
import { Grade } from "@/ts/objects/common/Grade";
import { isMonthValue, MonthValue } from "@/ts/objects/common/MonthValue";

export type CurriculumRouteT = {
  readonly classId: string | null;
  readonly tab: CurriculumTabNameT | null;
  readonly collectionId: CurriculumCollectionId | null;
  readonly curriculumId: string | null;

  readonly studentViewRoute: {
    readonly studentUserId: string | null;
    readonly periodMode: CurriculumPeriodMode | null;
    readonly month: MonthValue | null;
  };
};

@Module({ dynamic: true, store, name: "curriculumStoreT", namespaced: true })
export class CurriculumStoreT extends VuexModule {
  get currentPath(): string {
    return appStateStoreGlobal.route.path;
  }

  get currentRoute(): CurriculumRouteT {
    const name = appStateStoreGlobal.route.name;
    const m = /^T\.Curriculum\.(\w+)\.(\w+)/.exec(name);
    if (m === null) {
      log.debug(`CurriculumStoreT.currentRoute: Failed.`);
      return {
        classId: null,
        tab: null,
        collectionId: null,
        curriculumId: null,

        studentViewRoute: {
          studentUserId: null,
          // tab: null,
          periodMode: null,
          month: null,
        },
      };
    }

    const classId = appStateStoreGlobal.teacherState?.selectedClass()?.id ?? null;

    const tab = isCurriculumTabNameT(m[1]) ? m[1] : null;
    const collectionId = isCurriculumCollectionId(m[2]) ? m[2] : null;

    const _curriculumId = appStateStoreGlobal.route.params["curriculumId"];
    const curriculumId = _curriculumId !== "-" ? _curriculumId : null;

    const _studentUserId = appStateStoreGlobal.route.params["studentUserId"];
    const studentUserId = _studentUserId !== "-" ? _studentUserId : null;

    // const _studentTab = appStateStoreGlobal.route.params["studentCurriculumTab"];
    // const studentTab = isCurriculumTabName(_studentTab) ? _studentTab : null;

    const _periodMode = appStateStoreGlobal.route.params["curriculumPeriodMode"];
    const periodMode = isCurriculumPeriodMode(_periodMode) ? _periodMode : null;

    const _month = parseInt(appStateStoreGlobal.route.params["curriculumMonth"], 10);
    const month = isMonthValue(_month) ? _month : null;

    const result = {
      classId,
      tab,
      collectionId,
      curriculumId,

      studentViewRoute: {
        studentUserId,
        // tab: studentTab,
        periodMode,
        month,
      },
    };
    log.debug(`CurriculumStoreT.currentRoute: result=${JSON.stringify(result)}`);
    return result;
  }

  /**
   * クラス。
   */
  get class(): Class | null {
    return appStateStoreGlobal.teacherState?.selectedClass() ?? null;
  }

  /**
   * クラスID。
   */
  get classId(): string | null {
    return this.class?.id ?? null;
  }

  /**
   * 年度。
   */
  get schoolYear(): number | null {
    return this.class?.schoolYear ?? null;
  }

  /**
   * 学年。
   */
  get grade(): Grade | null {
    return this.class?.grade ?? null;
  }

  /**
   * 選択中のタブ。
   * 一覧表示/児童生徒画面/シラバス/未選択(null)。
   */
  get tab(): CurriculumTabNameT | null {
    return this.currentRoute.tab;
  }

  /**
   * 選択中の教科のコレクションID。
   * "neCurriculums" or "eeCurriculums"。
   */
  get selectedCurriculumCollectionId(): CurriculumCollectionId | null {
    return this.currentRoute.collectionId;
  }

  /**
   * 選択中の教科のリソース名。
   */
  get selectedCurriculumRname(): string | null {
    const collectionId = this.selectedCurriculumCollectionId;
    const id = this.currentRoute.curriculumId;

    if (collectionId === null || id === null) return null;

    return `/${collectionId}/${id}`;
  }

  /**
   * 今月。
   */
  get thisMonth(): MonthValue {
    return appStateStoreGlobal.now.month;
  }

  /**
   * (児童生徒画面用)選択中の児童生徒ID。
   */
  get studentUserId(): string | null {
    return this.currentRoute.studentViewRoute.studentUserId;
  }

  // /**
  //  * (児童生徒画面用)選択中の児童生徒用画面内タブ。
  //  * 書く/見る/未選択(null)。
  //  */
  // get studentViewTab(): CurriculumTabName | null {
  //   return this.currentRoute.studentViewRoute.tab;
  // }

  /**
   * (児童生徒画面用)表示期間のモード。
   * 月別表示/年間表示/未選択(null)。
   */
  get periodMode(): CurriculumPeriodMode | null {
    return this.currentRoute.studentViewRoute.periodMode;
  }

  /**
   * (児童生徒画面用)選択中の月。
   */
  get selectedMonth(): MonthValue | null {
    return this.currentRoute.studentViewRoute.month;
  }

  /**
   * 選択中の(年度, 学年)の、全データをロードする。
   * ※ よって、クラスが変更されたとき、リロードが必要。
   *
   * ロード前に、一旦ロード中状態に変更する。
   */
  @Action
  async loadAllData(curriculumRepository: CurriculumRepository) {
    // TODO たぶん大丈夫だとは思うが、教科学習画面にいるとき、クラスを切り替えたときに、前のクラスIDでロードしに行かないか確認する。
    const schoolYear = this.schoolYear;
    const grade = this.grade;
    if (schoolYear === null || grade === null) {
      this.context.commit("setAllData", {
        allNECurriculums: new LoadableIdle(),
        allEECurriculums: new LoadableIdle(),
      });
      return;
    }

    this.context.commit("setAllData", {
      allNECurriculums: new LoadableLoading(),
      allEECurriculums: new LoadableLoading(),
    });

    log.debug(`CurriculumStoreT.loadAllData: schoolYear=${schoolYear}, grade=${grade}`);

    const [allNECurriculums, allEECurriculums] = await Promise.all([
      // allNECurriculums
      (async () => {
        log.debug(`CurriculumStore.loadAllData: neCurriculums Loadable: schoolYear=${schoolYear}, grade=${grade}`);
        const resp = await curriculumRepository.listNECurriculums(schoolYear, grade);
        if (resp instanceof Err) return null;
        return resp;
      })(),
      // allEECurriculums
      (async () => {
        const resp = await curriculumRepository.listEECurriculums(schoolYear, grade);
        if (resp instanceof Err) return null;
        return resp;
      })(),
    ]);

    if (allNECurriculums === null || allEECurriculums === null) {
      this.context.commit("setAllData", {
        allNECurriculums: new LoadableError(),
        allEECurriculums: new LoadableError(),
      });
      return;
    }

    this.context.commit("setAllData", {
      allNECurriculums: Loadable.fromValue(allNECurriculums),
      allEECurriculums: Loadable.fromValue(allEECurriculums),
    });
  }

  /**
   * 1教科分だけリロードする。
   * 現在選択中の(年度, 学年)に含まれないものを指定した場合、何も起こらない。
   */
  @Action
  async loadACurriculum({
    curriculumRepository,
    resourceName,
  }: {
    curriculumRepository: CurriculumRepository;
    resourceName: string;
  }) {
    const rnameObject = rnameToRnameObject(resourceName);
    const collectionId = rnameObject?.fragments[0].collectionId;
    const curriculumId = rnameObject?.fragments[0].resourceId;
    if (
      rnameObject === null ||
      rnameObject.fragments.length !== 1 ||
      !isCurriculumCollectionId(collectionId) ||
      isNullish(curriculumId)
    )
      return;

    switch (collectionId) {
      case "neCurriculums": {
        const resp = await curriculumRepository.getNECurriculum(curriculumId);
        if (resp instanceof Err) return;

        const schoolYear = this.schoolYear;
        const grade = this.grade;
        if (isNullish(schoolYear) || isNullish(grade)) return;

        if (resp.self.schoolYear !== schoolYear || resp.self.grade.value !== grade.value) {
          log.debug(
            `CurriculumStoreT.loadACurriculum: Got response, but (schoolYear, grade) is (${resp.self.schoolYear}, ${resp.self.grade.value}), which is different from selected ones (${schoolYear}, ${grade.value})`
          );
          return;
        }
        this.context.commit("setNECurriculum", resp);
        return;
      }
      case "eeCurriculums": {
        const resp = await curriculumRepository.getEECurriculum(curriculumId);
        if (resp instanceof Err) return;

        const schoolYear = this.schoolYear;
        const grade = this.grade;
        if (isNullish(schoolYear) || isNullish(grade)) return;

        if (resp.self.schoolYear !== schoolYear || resp.self.grade.value !== grade.value) {
          log.debug(
            `CurriculumStoreT.loadACurriculum: Got response, but (schoolYear, grade) is (${resp.self.schoolYear}, ${resp.self.grade.value}), which is different from selected ones (${schoolYear}, ${grade.value})`
          );
          return;
        }
        this.context.commit("setEECurriculum", resp);
        return;
      }
      default:
        return;
    }
  }

  @Mutation
  setAllData(payload: {
    allNECurriculums: Loadable<NECurriculumTree[]>;
    allEECurriculums: Loadable<EECurriculumTree[]>;
  }) {
    this.neCurriculumTrees = payload.allNECurriculums;
    this.eeCurriculumTrees = payload.allEECurriculums;
  }

  /**
   * 数値評価教科をセットする。
   * 既に同じIDのものが存在すれば上書きする。
   */
  @Mutation
  setNECurriculum(payload: NECurriculumTree) {
    log.debug(`CurriculumStoreT.setNECurriculum: payload=${JSON.stringify(payload)}`);
    const current = this.neCurriculumTrees.data;
    if (current === null) {
      log.debug(`CurriculumStoreT.setNECurriculum: current is null.`);
      this.neCurriculumTrees = Loadable.fromValue([payload]);
      return;
    }

    const existingIdx = current.findIndex(nec => nec.self.necId === payload.self.necId);
    if (existingIdx < 0) {
      log.debug(`CurriculumStoreT.setNECurriculum: existingIdx < 0`);
      this.neCurriculumTrees = Loadable.fromValue([...current, payload]);
      return;
    }

    current.splice(existingIdx, 1, payload);
    this.neCurriculumTrees = Loadable.fromValue(current);
    log.debug(`CurriculumStoreT.setNECurriculum: Completed.`);
  }

  /**
   * 文書評価教科をセットする。
   * 既に同じIDのものが存在すれば上書きする。
   */
  @Mutation
  setEECurriculum(payload: EECurriculumTree) {
    const current = this.eeCurriculumTrees.data;
    if (current === null) {
      this.eeCurriculumTrees = Loadable.fromValue([payload]);
      return;
    }

    const existingIdx = current.findIndex(eec => eec.self.eecId === payload.self.eecId);
    if (existingIdx < 0) {
      this.eeCurriculumTrees = Loadable.fromValue([...current, payload]);
      return;
    }

    current.splice(existingIdx, 1, payload);
    this.eeCurriculumTrees = Loadable.fromValue(current);
  }

  get isDataIdleOrLoading(): boolean {
    return this.neCurriculumTrees.isIdleOrLoading || this.eeCurriculumTrees.isIdleOrLoading;
  }

  get isDataLoaded(): boolean {
    return this.neCurriculumTrees.isDataLoaded && this.eeCurriculumTrees.isDataLoaded;
  }

  /**
   * 選択中のクラスの、全ての数値評価教科のリスト(ツリー)。
   */
  neCurriculumTrees: Loadable<NECurriculumTree[]> = new LoadableIdle();

  /**
   * 選択中のクラスの、全ての数値評価教科のリスト。
   */
  get neCurriculums(): Loadable<NECurriculum[]> {
    return Loadable.fromLoadable(this.neCurriculumTrees, trees => trees.map(t => t.self));
  }

  /**
   * 選択中の数値評価教科(ツリー)。
   */
  get neCurriculumTree(): Loadable<NECurriculumTree | null> {
    const _neCurriculums = this.neCurriculumTrees;
    if (_neCurriculums === null) return new LoadableIdle();

    const selectedCurriculumRname = this.selectedCurriculumRname;

    return Loadable.fromLoadable(_neCurriculums, neCurriculums => {
      if (selectedCurriculumRname === null) return null;
      return neCurriculums.find(c => c.self.resourceName === selectedCurriculumRname) ?? null;
    });
  }

  /**
   * 選択中の数値評価教科。
   */
  get neCurriculum(): Loadable<NECurriculum | null> {
    return Loadable.fromLoadable(this.neCurriculumTree, neCurriculumTree => neCurriculumTree?.self ?? null);
  }

  /**
   * 選択中のクラスの、全ての文書評価教科のリスト(ツリー)。
   */
  eeCurriculumTrees: Loadable<EECurriculumTree[]> = new LoadableIdle();

  /**
   * 選択中のクラスの、全ての文書評価教科のリスト。
   */
  get eeCurriculums(): Loadable<EECurriculum[]> {
    return Loadable.fromLoadable(this.eeCurriculumTrees, trees => trees.map(t => t.self));
  }

  /**
   * 選択中の文書評価教科(ツリー)。
   */
  get eeCurriculumTree(): Loadable<EECurriculumTree | null> {
    const _eeCurriculumTrees = this.eeCurriculumTrees;
    if (_eeCurriculumTrees === null) return new LoadableIdle();

    const selectedCurriculumRname = this.selectedCurriculumRname;
    if (selectedCurriculumRname === null) return new LoadableIdle();

    return Loadable.fromLoadable(_eeCurriculumTrees, eeCurriculumTrees => {
      return eeCurriculumTrees.find(c => c.self.resourceName === selectedCurriculumRname) ?? null;
    });
  }

  /**
   * 選択中の文書評価教科。
   */
  get eeCurriculum(): Loadable<EECurriculum | null> {
    return Loadable.fromLoadable(this.eeCurriculumTree, eeCurriculumTree => eeCurriculumTree?.self ?? null);
  }

  /**
   * 最初の教科を取得する。
   */
  get firstCurriculumTree(): Loadable<NECurriculumTree | EECurriculumTree | null> {
    const _neCurriculums = this.neCurriculumTrees;
    const _eeCurriculums = this.eeCurriculumTrees;
    if (_neCurriculums === null || _eeCurriculums === null) return new LoadableIdle();
    return Loadable.fromLoadable2(_neCurriculums, _eeCurriculums, (neCurriculums, eeCurriculums) => {
      log.debug(
        `CurriculumStoreT.firstCurriculum: neCurriculums.length=${neCurriculums.length}, eeCurriculums.length=${eeCurriculums.length}`
      );
      if (neCurriculums.length >= 1) return neCurriculums[0];
      else if (eeCurriculums.length >= 1) return eeCurriculums[0];
      else return null;
    });
  }

  get path() {
    const _base = appStateStoreGlobal.teacherBasePath;

    return function(
      tab: CurriculumTabNameT,
      resourceName: string | null,
      studentUserId?: string,
      periodMode?: CurriculumPeriodMode,
      month?: MonthValue
    ) {
      const _resourceName = resourceName ?? "/-/-";

      if (tab === "studentview") {
        const _studentUserId = studentUserId ?? "-";
        const _periodMode = periodMode ?? "yearly";
        const _month = month?.toString(10) ?? "-";
        return `${_base}/curriculum/${tab}/${_studentUserId}/${_periodMode}/${_month}${_resourceName}`;
      } else if (tab === "list" || tab === "syllabus") {
        return `${_base}/curriculum/${tab}${_resourceName}`;
      } else {
        return _base;
      }
    };
  }

  get firstCurriculumListPath(): Loadable<string> {
    const firstCurriculum = this.firstCurriculumTree;
    if (firstCurriculum === null) return new LoadableIdle();
    return Loadable.fromLoadable(firstCurriculum, curriculum => {
      return this.path("list", curriculum?.self.resourceName ?? null);
    });
  }

  get firstCurriculumStudentViewPath(): Loadable<string> {
    const firstCurriculum = this.firstCurriculumTree;
    if (firstCurriculum === null) return new LoadableIdle();
    return Loadable.fromLoadable(firstCurriculum, curriculum => {
      return this.path("studentview", curriculum?.self.resourceName ?? null, undefined, "yearly", undefined);
    });
  }

  get firstCurriculumSyllabusPath(): Loadable<string> {
    const firstCurriculum = this.firstCurriculumTree;
    if (firstCurriculum === null) return new LoadableIdle();
    return Loadable.fromLoadable(firstCurriculum, curriculum => {
      return this.path("syllabus", curriculum?.self.resourceName ?? null);
    });
  }

  get defaultPathOfSelectedTab(): Loadable<string> {
    if (this.tab === "list") return this.firstCurriculumListPath;
    if (this.tab === "studentview") return Loadable.fromValue(""); // TODO
    if (this.tab === "syllabus") return this.firstCurriculumSyllabusPath;
    return Loadable.fromValue("");
  }
}

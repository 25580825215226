import { Activity as ActivityResp } from "@/ts/api/activity-service";
import { Err } from "@/ts/objects/Err";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";

export class ActivityRepositoryMock extends ActivityRepository {
  private dummyResponse = {
    uuid: "e4fa2692-b87b-4681-9aae-3b9dfa42bc36",
    userId: "dummyuserid",
    studentUserId: "dummystudentuserid",
    classId: "dummyclassid",
    createdAt: "2000-01-01T00:00:00Z",
    updatedAt: "2000-01-01T00:00:00Z",
    type: "text",
    text: "dummytext",
    deleteFlg: false,
    file: undefined,
    advanced: undefined,
  };

  async postActivitiesStamp(_studentUserId: string, _gcsObjectPath: string): Promise<ActivityResp | Err> {
    return this.dummyResponse;
  }

  async deleteActivity(_studentUserId: string, _activityId: string): Promise<void | Err> {
    return;
  }

  async readActivityLog(_studentUserId: string, _activityId: string): Promise<void | Err> {
    return;
  }

  async postActivityLog(_studentUserId: string, _text: string): Promise<ActivityResp | Err> {
    return this.dummyResponse;
  }

  async postMylistActivityLog(_studentUserId: string, _activityId: string): Promise<ActivityResp | Err> {
    return this.dummyResponse;
  }

  async deleteMylistActivityLog(_studentUserId: string, _activityId: string): Promise<ActivityResp | Err> {
    return this.dummyResponse;
  }

  async postFile(_file: File, _studentUserId: string): Promise<ActivityResp | Err> {
    return this.dummyResponse;
  }

  async getSearchActivities(_classId: string, _searchText: string): Promise<ActivityResp[] | Err> {
    // TODO ActivityRepositoryMock.getSearchActivitiesの実装。
    return [];
  }
}

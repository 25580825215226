


























import Vue, { PropType } from "vue";
import { RatingValue } from "@/ts/objects/common/Rating";
import { words, Words } from "@/ts/const/Words";
import { MonthValue, monthValueToEnglishAbbr } from "@/ts/objects/common/MonthValue";

export default Vue.extend({
  name: "CurriculumListNECHeaderMonthEditButton",
  props: {
    month: { type: Number as PropType<MonthValue>, required: true },
    onClickFillAll: { type: Function as PropType<(rating: RatingValue, overwrite: boolean) => void>, required: true },
  },
  data(): {
    active: boolean;
    rating: RatingValue;
    overwrite: boolean;

    words: Words;
  } {
    return {
      active: false,
      rating: "A",
      overwrite: false,

      words,
    };
  },
  computed: {
    monthText(): string {
      return monthValueToEnglishAbbr(this.month);
    },
    buttonMessage(): string {
      const rating = this.rating;
      if (rating !== "") {
        return `Fill this column with ${rating}`;
      } else {
        return `Empty this column`;
      }
    },
    buttonEnabled(): boolean {
      const rating = this.rating;
      const overwrite = this.overwrite;

      return ["S", "A", "B", "C"].includes(rating) || (rating === "" && overwrite);
    },
  },
  methods: {
    onClick() {
      this.rating = "A";
      this.overwrite = false;
      this.active = !this.active;
    },
    onClickOutside() {
      this.active = false;
    },
    _onClickFillAll() {
      if (!this.active || !this.buttonEnabled) return;

      this.active = false;
      this.onClickFillAll(this.rating, this.overwrite);
    },
  },
});

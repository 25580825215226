
















































import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import HomeTRecentStudyItem from "@/views/home/teacher/components/HomeTRecentStudy/HomeTRecentStudyItem/HomeTRecentStudyItem.vue";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";
import Vue, { PropType } from "vue";
import { DataSlot } from "@/ts/DataSlot";
import { RecentStudy } from "@/ts/objects/common/RecentStudy";
import { Quarter } from "@/ts/objects/common/Quarter";

type RecentStudiesTabState = {
  curriculum: boolean;
  project: boolean;
  solan: boolean;
};

export default Vue.extend({
  name: "HomeTRecentStudy",
  components: { LoadingBlock, HomeTRecentStudyItem },
  props: {
    recentStudiesTabState: {
      type: Object as PropType<RecentStudiesTabState>,
      required: true,
    },
    onChangeTabState: {
      type: Function as PropType<(tabState: RecentStudiesTabState) => void>,
      required: true,
    },

    recentStudies: { type: Object as PropType<DataSlot<RecentStudy[]>>, required: true },
    currentQuarter: { type: Object as PropType<Quarter>, required: true },
    teacherBasePath: { type: String, required: true },
    teacherCurriculumDefaultPath: { type: String, required: true },
  },
  data(): { words: Words; messages: Messages } {
    return {
      words,
      messages,
    };
  },
  computed: {
    reversedRecentStudies(): RecentStudy[] | null {
      return this.recentStudies.data?.slice().reverse() ?? null;
    },
    filteredRecentStudies(): RecentStudy[] | null {
      const filterState = this.recentStudiesTabState;
      return (
        this.reversedRecentStudies?.filter(
          rs =>
            (filterState.curriculum && rs.objectService() === "curriculum") ||
            (filterState.project && rs.objectService() === "project") ||
            (filterState.solan && rs.objectService() === "solan")
        ) ?? null
      );
    },
  },
  methods: {
    toggleTabState(tab: keyof RecentStudiesTabState) {
      const tabState = this.recentStudiesTabState;
      this.onChangeTabState({
        ...tabState,
        [tab]: !tabState[tab],
      });
    },
  },
});



























import Vue, { PropType } from "vue";
import { UserColumn } from "@/ts/app/columns/UserColumn";
import { WordInternalName } from "@/ts/const/Words";
import { AnyUser } from "@/ts/objects/user/User";
import ProfileTableItemValueText from "@/views/profile/components/ProfileTable/ProfileTableItem/ProfileTableItemValueText/ProfileTableItemValueText.vue";
import ProfileTableItemValueImage from "@/views/profile/components/ProfileTable/ProfileTableItem/ProfileTableItemValueImage/ProfileTableItemValueImage.vue";
import ProfileTableItemValueDate from "@/views/profile/components/ProfileTable/ProfileTableItem/ProfileTableItemValueDate/ProfileTableItemValueDate.vue";
import ProfileTableItemValueStudentClasses from "@/views/profile/components/ProfileTable/ProfileTableItem/ProfileTableItemValueStudentClasses/ProfileTableItemValueStudentClasses.vue";

export default Vue.extend({
  name: "ProfileTableItem",
  components: {
    ProfileTableItemValueStudentClasses,
    ProfileTableItemValueDate,
    ProfileTableItemValueImage,
    ProfileTableItemValueText,
  },
  props: {
    userColumn: { type: Object as PropType<UserColumn<WordInternalName, AnyUser, any>>, required: true },
  },
});







































import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import RatingDropdown from "@/components/dropdowns/RatingDropdown.vue";
import Vue, { PropType } from "vue";
import { words, Words } from "@/ts/const/Words";
import { isNullish } from "@/ts/utils";
import NotPublishedIndicator from "@/components/other/NotPublishedIndicator/NotPublishedIndicator.vue";

export default Vue.extend({
  name: "SolanLookbackSection",
  components: { NotPublishedIndicator, RatingDropdown, LoadingBlock, DisableableAutoResizeTextarea },
  props: {
    title: { type: String, required: true },

    commentPlaceholder: { type: String, required: false, default: "" },
    /**
     * コメント。
     * 未指定の場合、ローディング表示とする。
     */
    comment: { type: String, required: false },
    onCommentChange: { type: Function as PropType<(value: string) => void>, required: false },

    ratingDropdownName: { type: String, required: false, default: "" },
    /**
     * 評価。
     * 未指定の場合、単に表示しない。
     */
    rating: { type: String, required: false },
    onRatingChange: { type: Function as PropType<(value: string) => void>, required: false },

    /**
     * 公開済かどうか。
     * 指定した場合、NotPublishedIndicatorを表示する。
     * 指定しなかった場合、NotPublishedIndicatorは表示しない。
     */
    published: { type: Boolean, required: false, default: undefined },

    /**
     * コメントテキストボックスから抜けたときに呼ばれる関数。
     */
    onBlur: { type: Function as PropType<() => void>, required: false },

    disabled: { type: Boolean, required: false },

    titleFontSize: { type: Number, required: true },
  },
  data(): { words: Words } {
    return { words };
  },
  computed: {
    styles() {
      return {
        "--title-font-size": `${this.titleFontSize}px`,
      };
    },
  },
  methods: {
    handleCommentChange(value: string) {
      const onCommentChange = this.onCommentChange;
      if (isNullish(onCommentChange)) return;
      onCommentChange(value);
    },
    handleRatingChange(value: string) {
      const onRatingChange = this.onRatingChange;
      if (isNullish(onRatingChange)) return;
      onRatingChange(value);
    },
    handleBlur() {
      const onBlur = this.onBlur;
      if (isNullish(onBlur)) return;
      onBlur();
    },
  },
});

import { Mutation, VuexModule, Module } from "vuex-module-decorators";
import { store } from "@/store/Store";
import log from "loglevel";

export type ProjectInfo = {
  name: string;
  startingYear: number;
  startingMonth: number;
  endingYear: number;
  endingMonth: number;
};

// プロジェクト編集中に、プロジェクトヘッダからプロジェクト編集画面への値の伝播に用いるストア。
@Module({ dynamic: true, store, name: "projectInfoOnEditStore", namespaced: true })
export class ProjectInfoOnEditStore extends VuexModule {
  // この値がnullでないとき、編集中である。
  info: ProjectInfo | null = null;

  @Mutation
  public startEditing(initialInfo: ProjectInfo) {
    log.debug(`startEditing: startingYear=${initialInfo.startingYear}`);
    this.info = {
      name: initialInfo.name,
      startingYear: initialInfo.startingYear,
      startingMonth: initialInfo.startingMonth,
      endingYear: initialInfo.endingYear,
      endingMonth: initialInfo.endingMonth,
    };
    log.debug(`startEditing: ${JSON.stringify(this.info)}`);
  }

  @Mutation
  public finishEditing() {
    this.info = null;
  }

  @Mutation
  public updateName(value: string) {
    const info = this.info;
    if (info === null) return;
    info.name = value;
  }

  @Mutation
  public updateStartingYear(value: number) {
    const info = this.info;
    if (info === null) return;
    info.startingYear = value;
  }

  @Mutation
  public updateStartingMonth(value: number) {
    const info = this.info;
    if (info === null) return;
    info.startingMonth = value;
  }

  @Mutation
  public updateEndingYear(value: number) {
    const info = this.info;
    if (info === null) return;
    info.endingYear = value;
  }

  @Mutation
  public updateEndingMonth(value: number) {
    const info = this.info;
    if (info === null) return;
    info.endingMonth = value;
  }
}














































import Vue, { PropType } from "vue";
import { isRatingValue, RatingValue } from "@/ts/objects/common/Rating";
import log from "loglevel";
import TipBlock from "@/components/TipBlock.vue";
import { ICurriculumListNECEvaluationCellModel } from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";
import CurriculumListNECEvaluationCellPublishState from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECEvaluationCellPublishState/CurriculumListNECEvaluationCellPublishState.vue";

export default Vue.extend({
  name: "CurriculumListNECEvaluationCell",
  components: { CurriculumListNECEvaluationCellPublishState, TipBlock },
  props: {
    /**
     * モデル。
     */
    model: { type: Object as PropType<ICurriculumListNECEvaluationCellModel>, required: true },

    width: { type: Number, required: true },

    /**
     * 更新時のコールバック。
     */
    onInput: { type: Function as PropType<(rating: RatingValue) => void>, required: true },
    onChangeSinglePublishState: { type: Function as PropType<(published: boolean) => void>, required: true },
  },
  methods: {
    _onChange(event: Event) {
      const value = (event.target as HTMLInputElement).value;
      if (!isRatingValue(value)) {
        log.debug(`CurriculumListNECContentCell:_onChange: Invalid ratingValue: ${value}`);
        return;
      }
      this.onInput(value);
    },
  },
});

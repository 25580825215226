






























import Vue, { PropType } from "vue";
import { isNullish } from "@/ts/utils";
import SchoolYearSwitch from "@/components/SchoolYearSwitch/SchoolYearSwitch.vue";
import { words, Words } from "@/ts/const/Words";

type ProjectModel = {
  readonly projectId: string;
  readonly studentUserId: string;
  readonly schoolYear: number;
  readonly name: string;
};

export default Vue.extend({
  name: "SolanStudentViewProjectSelect",
  components: { SchoolYearSwitch },
  props: {
    initialSchoolYear: { type: Number, required: true },
    selectableProjects: { type: Array as PropType<ProjectModel[]>, required: false },

    toProjectPath: {
      type: Function as PropType<(studentUserId: string, projectId: string) => string>,
      required: true,
    },
  },
  data(): {
    showPopup: boolean;
    selectedSchoolYear: number;

    words: Words;
  } {
    return {
      showPopup: false,
      selectedSchoolYear: this.initialSchoolYear,

      words,
    };
  },
  computed: {
    projects(): ProjectModel[] {
      const selectableProjects = this.selectableProjects;
      if (isNullish(selectableProjects)) return [];
      return selectableProjects;
    },
    projectsOfSchoolYear(): ProjectModel[] {
      return this.projects.filter(p => p.schoolYear === this.selectedSchoolYear);
    },
  },
  methods: {
    togglePopup() {
      this.selectedSchoolYear = this.initialSchoolYear;
      this.showPopup = !this.showPopup;
    },
    closePopup() {
      this.showPopup = false;
    },
    selectSchoolYear(schoolYear: number) {
      this.selectedSchoolYear = schoolYear;
    },
  },
});

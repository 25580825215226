















import Vue, { PropType } from "vue";
import { ClassStudent } from "@/ts/objects/common/Class";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "StudentListPane",
  props: {
    classStudents: { type: Array as PropType<ClassStudent[]>, required: false },
    selectedStudentUserId: { type: String, required: false },
    studentUserIdToPath: { type: Function as PropType<(studentUserId: string) => string>, required: true },
  },
  computed: {
    classStudentsInternal(): ClassStudent[] {
      const _classStudents = this.classStudents;
      if (isNullish(_classStudents)) return [];
      return _classStudents;
    },
  },
});

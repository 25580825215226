<template>
  <div>
    <label v-if="!value" class="upload-content-space user-photo default">
      <input ref="file" class="file-button" type="file" @change="upload" />
      <font-awesome-icon class="fnicon" :icon="['far', 'file-alt']" />
    </label>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
export default {
  name: "Upload",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    async upload(event) {
      const files = event.target.files || event.dataTransfer.files;
      const file = files[0];

      if (this.checkFile(file)) {
        // const picture = await this.getBase64(file);
        this.$emit("fileUpload", file);
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    checkFile(file) {
      let result = true;
      const SIZE_LIMIT = 50 * 1024 * 1024; // 50MB
      // キャンセルしたら処理中断
      if (!file) {
        result = false;
      }
      // jpeg か png 関連ファイル以外は受付けない
      // if (file.type !== "image/jpeg" && file.type !== "image/png") {
      //   result = false;
      // }

      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        const fmt = new Intl.NumberFormat("ja");
        this.$modal.show(
          Modal,
          {
            type: "information",
            text: `ファイルサイズが大きすぎます。
            上限:${fmt.format(SIZE_LIMIT / 1024 / 1024)}MB 
            実際:${fmt.format(file.size / 1024 / 1024)}MB`,
          },
          {
            width: "300px",
            height: "auto",
          }
        );
        result = false;
      }
      return result;
    },
  },
};
</script>

<style scoped>
.user-photo.default {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  letter-spacing: 0.3px;
}

.user-photo-image {
  max-width: 85px;
  display: block;
}

.user-photo-image:hover {
  opacity: 0.8;
}

.file-button {
  display: none;
}

.uploaded {
  align-items: center;
  display: flex;
}
.fnicon {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-right: 5px;
}
</style>

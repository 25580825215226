import { Color } from "@/ts/objects/common/Color";
import { ViewPoint as ViewPointResp } from "@/ts/api/curriculum-service";
import { NECContentTree } from "@/ts/objects/curriculum/value/NECContent";

/**
 * 数値評価教科の観点。
 *
 * リソース名は /neCurriculums/{necId}/viewPoints/{viewPointId}。
 */
export class NECViewPoint {
  constructor(
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly name: string,
    public readonly color: Color
  ) {}

  get necResourceName(): string {
    return `/neCurriculums/${this.necId}`;
  }

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}`;
  }

  static fromViewPointResp(resp: ViewPointResp): NECViewPoint {
    return new NECViewPoint(
      resp.necId,
      resp.viewPointId,
      resp.name.value,
      new Color(resp.color.red, resp.color.green, resp.color.blue)
    );
  }
}

export class NECViewPointTree {
  constructor(public readonly self: NECViewPoint, public readonly contents: NECContentTree[]) {}
}
























import Vue, { PropType } from "vue";
import { CurriculumTabNameT } from "@/ts/objects/curriculum/value/CurriculumTabNameT";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "CurriculumHeaderT",
  props: {
    currentTabName: { type: String as PropType<CurriculumTabNameT | null>, required: true },
    listButtonPath: { type: String, required: true },
    studentViewButtonPath: { type: String, required: true },
    syllabusButtonPath: { type: String, required: true },
  },
  data(): {
    words: Words;
  } {
    return { words };
  },
});

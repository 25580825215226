export type FirestoreTimestampJson = {
  seconds: number;
  nanoseconds: number;
};

export function isFirestoreTimestampJson(obj: any): obj is FirestoreTimestampJson {
  // objectの型が異なる backend : obj.nanos / firestore : obj.nanoseconds
  return typeof obj.seconds === "number" && (typeof obj.nanos === "number" || typeof obj.nanoseconds === "number");
}

export type KtorTimestampJson = {
  epochSecond: number;
  nano: number;
};

export function isKtorTimestampJson(obj: any): obj is KtorTimestampJson {
  return typeof obj.epochSecond === "number" && typeof obj.nano === "number";
}

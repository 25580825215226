






import Vue, { PropType } from "vue";
import { DateValue, dateValueToDisplayValue } from "@/ts/objects/common/DateValue";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "ProfileTableItemValueDate",
  props: {
    dateValue: { type: Object as PropType<DateValue>, required: false },
  },
  computed: {
    dateDisplayValue(): string | null {
      const _dateValue = this.dateValue;
      if (isNullish(_dateValue)) return null;
      return dateValueToDisplayValue(_dateValue);
    },
  },
});





























import Vue, { PropType } from "vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import JournalFilesViewItem from "@/components/journalfile/JournalFilesViewItem.vue";
import JournalFileUploadButton from "@/components/journalfile/JournalFileUploadButton.vue";
import { isNullish } from "@/ts/utils/CommonUtil";
import { messages, Messages } from "@/ts/const/Messages";

export default Vue.extend({
  name: "JournalFilesView",
  components: { JournalFileUploadButton, JournalFilesViewItem },
  props: {
    journalFiles: { type: Array as PropType<JournalFile[]>, required: true },
    readonly: { type: Boolean, required: true },
    backgroundColor: { type: String, required: true },

    /**
     * 固定横幅が必要な場合は指定する。指定しなければ、auto。
     * 単位: px。
     */
    width: { type: Number },

    /**
     * 学習記録ファイルをアップロードする関数。
     * readonly = true の場合は不要。
     */
    uploadJournalFile: { type: Function as PropType<(file: File) => Promise<void>> },

    /**
     * 学習記録ファイルを削除する関数。
     * readonly = true の場合は不要。
     */
    deleteJournalFile: {
      type: Function as PropType<(file: JournalFile) => Promise<void>>,
    },
  },
  data(): { messages: Messages } {
    return { messages };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--background-color": this.backgroundColor,
        "--width": !isNullish(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
});















import Vue, { PropType } from "vue";
import ProfileHeader from "@/views/profile/ProfileHeader/ProfileHeader.vue";
import { ProfileStore } from "@/store/ProfileStore";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";

export default Vue.extend({
  name: "Profile",
  components: { MessageView, ProfileHeader },
  props: {
    profileStore: { type: Object as PropType<ProfileStore>, required: true },
  },
  computed: {
    messageView(): MessageViewParam | null {
      const store = this.profileStore;
      if (store.tab === null) return { message: messages.pageNotFound };
      return null;
    },
  },
});

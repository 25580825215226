import { Grade, gradeFromUserServiceResp } from "@/ts/objects/common/Grade";
import { StudentClass as StudentClassResp } from "@/ts/api/user-service";

export type StudentClass = {
  readonly classId: string;

  readonly schoolYear: number;
  readonly grade: Grade;
  readonly name: string;

  readonly studentNumber: number;
};

export function studentClassFromRespOrError(r: StudentClassResp): StudentClass {
  return {
    classId: r.classId,

    schoolYear: r.schoolYear,
    grade: gradeFromUserServiceResp(r.grade),
    name: r.name,

    studentNumber: r.studentNumber ?? 0, // とりあえず。これnullableにしたほうが良い気がするが。
  };
}

export function getClassOfSchoolYear(classes: readonly StudentClass[], schoolYear: number): StudentClass | null {
  return classes.find(c => c.schoolYear === schoolYear) ?? null;
}

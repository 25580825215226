




























































































import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import JournalFilePreviewArea from "@/components/journalfile/JournalFilePreviewArea/JournalFilePreviewArea.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import { SaveResult } from "@/ts/objects/editable/SaveResult";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "ProjectJournalsPure",
  components: {
    JournalFilesView,
    JournalFilePreviewArea,
    DisableableAutoResizeTextarea,
    LoadingBlock,
    MessageView,
  },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },

    journals: { type: Array as PropType<EditableProjectJournal[] | null> },

    /**
     * 現在ファイルビューを開いている学習記録のリソース名。
     */
    filesViewJournal: { type: String as PropType<string | null> },

    /**
     * すべてセーブする関数。
     */
    saveAll: { type: Function as PropType<(force: boolean) => Promise<SaveResult>>, required: true },

    /**
     * ファイルビューを開いている学習記録を変更する。
     */
    setFilesView: { type: Function as PropType<(journalRname: string | null) => void>, required: true },

    /**
     * 学習記録ファイルをアップロードする関数。
     */
    uploadJournalFile: {
      type: Function as PropType<(file: File, journal: EditableProjectJournal) => Promise<void>>,
      required: true,
    },

    /**
     * 学習記録ファイルを削除する関数。
     */
    deleteJournalFile: {
      type: Function as PropType<(file: JournalFile, journal: EditableProjectJournal) => Promise<void>>,
      required: true,
    },
  },
  data(): {
    filesViewColor: string;

    words: Words;
  } {
    return {
      filesViewColor: "#ffc2c2",

      words,
    };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--files-view-color": this.filesViewColor,
      };
    },
  },
  methods: {
    isMyFilesViewOpen(journalRname: string): boolean {
      const filesViewJournal = this.filesViewJournal;
      if (filesViewJournal === null) return false;
      return filesViewJournal === journalRname;
    },
  },
});
























import Vue from "vue";
import AutoResizeTextarea from "@/components/AutoResizeTextarea.vue";

export default Vue.extend({
  name: "DisableableAutoResizeTextarea",
  components: { AutoResizeTextarea },
  props: {
    placeholder: { type: String, default: "" },
    value: { type: String, default: "" },
    maxChars: { type: Number, default: 9999 },
    minHeight: { type: Number, default: 1 }, // 単位: ピクセル
    maxHeight: { type: Number, default: 500 }, // 単位: ピクセル
    paddingHorizontal: { type: Number, default: 0 },
    paddingVertical: { type: Number, default: 0 },
    triggerOnWindowResize: { type: Boolean, default: true },
    blur: {
      type: Function,
      default: () => {
        return;
      },
    },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    styles(): any {
      return {
        padding: `${this.paddingVertical}px ${this.paddingHorizontal}px`,
      };
    },
    innerValue: {
      get(): string {
        return this.value;
      },
      set(newValue: string) {
        this.$emit("input", newValue);
      },
    },
  },
});

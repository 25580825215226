import { Guardian, GuardianNotes } from "@/ts/objects/user/Guardian";
import { GuardianColumnDef } from "@/ts/app/columns/guardian/GuardianColumn";
import { UserColumn } from "@/ts/app/columns/UserColumn";
import { genUserColumnDefs } from "@/ts/app/columns/UserColumnDef";
import { StringType } from "@/ts/app/columns/ColumnTypes";

export type GuardianNotesColumnId = keyof GuardianNotes;

export type GuardianNotesColumnDef<T> = GuardianColumnDef<GuardianNotesColumnId, T>;

export type GuardianNotesColumn<T> = UserColumn<GuardianNotesColumnId, Guardian, T>;

export function guardianNotesColumnDefs(): GuardianNotesColumnDef<any>[] {
  return genUserColumnDefs<GuardianNotesColumnId, Guardian, any>([
    {
      id: "note01",
      type: StringType,
      getValue: g => g.note01,
    },
    {
      id: "note02",
      type: StringType,
      getValue: g => g.note02,
    },
    {
      id: "note03",
      type: StringType,
      getValue: g => g.note03,
    },
    {
      id: "note04",
      type: StringType,
      getValue: g => g.note04,
    },
    {
      id: "note05",
      type: StringType,
      getValue: g => g.note05,
    },
    {
      id: "note06",
      type: StringType,
      getValue: g => g.note06,
    },
    {
      id: "note07",
      type: StringType,
      getValue: g => g.note07,
    },
    {
      id: "note08",
      type: StringType,
      getValue: g => g.note08,
    },
    {
      id: "note09",
      type: StringType,
      getValue: g => g.note09,
    },
    {
      id: "note10",
      type: StringType,
      getValue: g => g.note10,
    },
  ]);
}

import clamp from "lodash/clamp";
import arrayMove from "array-move";
import cloneDeep from "lodash/cloneDeep";

/**
 * 引数がnullまたはundefinedであればtrueを返す。
 *
 * `value == null` でも全く同じことができる: https://stackoverflow.com/a/5515385/12579447
 * が、イコールの数を数えるのが面倒だし、きっとそのうち間違えるので、テンプレート外ではこの関数を推奨する。
 */
export function isNullish<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

/**
 * 引数がnullでもundefinedでもなければtrueを返す。
 */
export function hasValue<T>(value: T | null | undefined): value is T {
  return !isNullish(value);
}

export function filterNotNullish<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((v): v is T => hasValue(v));
}

/**
 * 指定したミリ秒だけ待つ。
 */
export async function delay(millis: number): Promise<void> {
  await new Promise<void>(resolve => setTimeout(() => resolve(), millis));
}

/**
 * 配列の末尾に値を追加するが、既に同じものが存在すれば何もしない。
 * ※ setでやればいいようなものだが、setはリアクティブにできないので・・・。
 */
export function pushUniq<T>(arr: T[], value: T): T[] {
  if (arr.find(v => v === value) !== undefined) return arr;
  return [...arr, value];
}

/**
 * 配列の中で、1要素を上(indexの小さい方)か下(indexの大きい方)へ1個動かす。
 * 配列をmutateせず、変更があった場合はコピーを返す。
 *
 * 指定した要素が見つからなければ何もしない。('==='で比較する。)
 * upを指定したのにすでに一番上にいたりと、動けない場合も何もしない。
 */
export function arrayMoveOne<T>(arr: readonly T[], target: T, up: boolean): T[] {
  const currentIdx = arr.findIndex(value => value === target);
  if (currentIdx < 0) return arr.slice();

  const toIdx = clamp(up ? currentIdx - 1 : currentIdx + 1, 0, arr.length - 1);

  return arrayMove(arr, currentIdx, toIdx);
}

/**
 * 最初に見つかった、条件を満たす要素を更新する。
 * 元の配列は更新せず、コピーを返す。
 *
 * @return [更新済配列, 更新対象が見つかったかどうか]
 */
export function updateFirst<T>(arr: readonly T[], findElem: (v: T) => boolean, update: (v: T) => T): [T[], boolean] {
  const idx = arr.findIndex(findElem);
  if (idx < 0) return [arr.slice(), false];

  const _arr = arr.slice();
  _arr.splice(idx, 1, update(cloneDeep(_arr[idx])));
  return [_arr, true];
}

const pluralRules = new Intl.PluralRules("en-US", { type: "ordinal" });
const ordinalsSuffixes = {
  zero: "", // English localeでは使わないみたい。
  one: "st",
  two: "nd",
  few: "rd",
  many: "", // English localeでは使わないみたい。
  other: "th",
};

/**
 * 0 -> th, 1 -> st のように序数の接尾語を得る。
 * 参考: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules
 */
export function getOrdinalsSuffix(n: number): string {
  const rule = pluralRules.select(n);
  const suffix = ordinalsSuffixes[rule];
  if (isNullish(suffix)) return "";
  return suffix;
}

/**
 * 0 -> 0th, 1 -> 1st のように序数表現にする。
 * 参考: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules
 */
export function formatOrdinals(n: number): string {
  return `${n}${getOrdinalsSuffix(n)}`;
}

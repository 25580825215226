











import Vue from "vue";

export default Vue.extend({
  name: "SolanStudentViewMenuButton",
  props: {
    label: { type: String, required: true },
    active: { type: Boolean, required: true },
    toPath: { type: String, required: false },
  },
});





















import Vue, { PropType } from "vue";
import CurriculumSyllabusEECColumn from "@/views/curriculum/teacher/CurriculumSyllabusEEC/components/CurriculumSyllabusEECColumn/CurriculumSyllabusEECColumn.vue";
import {
  ICurriculumSyllabusEECModel,
  ICurriculumSyllabusEECMonthModel,
} from "@/views/curriculum/teacher/CurriculumSyllabusEEC/CurriculumSyllabusEECModel";
import { MonthValue } from "@/ts/objects/common/MonthValue";

export default Vue.extend({
  name: "CurriculumSyllabusEECTable",
  components: { CurriculumSyllabusEECColumn },
  props: {
    model: { type: Object as PropType<ICurriculumSyllabusEECModel>, required: true },

    monthsToDisplay: { type: Array as PropType<MonthValue[]>, required: true },

    onInputText: {
      type: Function as PropType<(month: MonthValue, activityId: string, value: string) => void>,
      required: true,
    },
    onInputEnabled: {
      type: Function as PropType<(month: MonthValue, activityId: string, value: boolean) => void>,
      required: true,
    },
    moveActivity: {
      type: Function as PropType<(month: MonthValue, activityId: string, up: boolean) => Promise<void>>,
      required: true,
    },
    createActivity: {
      type: Function as PropType<(month: MonthValue) => Promise<void>>,
      required: true,
    },
    deleteActivity: {
      type: Function as PropType<(month: MonthValue, activityId: string) => Promise<void>>,
      required: true,
    },
  },
  computed: {
    filteredMonths(): ICurriculumSyllabusEECMonthModel[] {
      return this.model.months.filter(m => this.monthsToDisplay.includes(m.month));
    },
  },
});








import Vue from "vue";

export default Vue.extend({
  name: "AppHeaderTabT",
  props: {
    label: { type: String, required: true },
    active: { type: Boolean, required: true },
    toPath: { type: String, required: true },
  },
});


















import Vue, { PropType } from "vue";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { hasValue, isNullish } from "@/ts/utils";
import JournalFileExpandButton from "@/components/journalfile/JournalFileExpandButton/JournalFileExpandButton.vue";

export default Vue.extend({
  name: "StudentActivityTableFilesCell",
  components: { JournalFileExpandButton },
  props: {
    isAssociatedFilesViewOpen: { type: Boolean, required: true },
    // TODO solan学習専用になってしまっているので、ここを修正してプロジェクト学習でも使えるようにしたい。
    solanProcess: { type: Number, required: false },
    journalFiles: { type: Array as PropType<JournalFile[]>, required: true },
    onClick: { type: Function as PropType<(open: boolean) => void>, required: true },

    associatedFilesViewElementId: { type: String, required: true },

    width: { type: Number, required: false },
  },
  data(): { words: Words; messages: Messages } {
    return { words, messages };
  },
  computed: {
    solanProcessClassName(): string {
      const solanProcess = this.solanProcess;
      if (isNullish(solanProcess)) return "cell-of-no-process";
      return `cell-of-process-${solanProcess}`;
    },
    styles(): Record<string, string> {
      return {
        "--width": hasValue(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
});

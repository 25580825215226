import { Student, StudentHealth } from "@/ts/objects/user/Student";
import { StudentColumnDef } from "@/ts/app/columns/student/StudentColumn";
import { UserColumn } from "@/ts/app/columns/UserColumn";
import { genUserColumnDefs } from "@/ts/app/columns/UserColumnDef";
import { StringType } from "@/ts/app/columns/ColumnTypes";

export type StudentHealthColumnId = keyof StudentHealth;

export type StudentHealthColumnDef<T> = StudentColumnDef<StudentHealthColumnId, T>;

export type StudentHealthColumn<T> = UserColumn<StudentHealthColumnId, Student, T>;

export function studentHealthColumnDefs(): StudentHealthColumnDef<any>[] {
  return genUserColumnDefs<StudentHealthColumnId, Student, any>([
    {
      id: "bloodType",
      type: StringType,
      getValue: s => s.bloodType,
    },
    {
      id: "normalBodyTemperature",
      type: StringType,
      getValue: s => s.normalBodyTemperature,
    },
    {
      id: "inoculation",
      type: StringType,
      getValue: s => s.inoculation,
    },
    {
      id: "medicalHistory",
      type: StringType,
      getValue: s => s.medicalHistory,
    },
    {
      id: "homeDoctor",
      type: StringType,
      getValue: s => s.homeDoctor,
    },
    {
      id: "foodAllergy",
      type: StringType,
      getValue: s => s.foodAllergy,
    },
    {
      id: "anaphylaxis",
      type: StringType,
      getValue: s => s.anaphylaxis,
    },
    {
      id: "otherAllergy",
      type: StringType,
      getValue: s => s.otherAllergy,
    },
    {
      id: "asthma",
      type: StringType,
      getValue: s => s.asthma,
    },
  ]);
}

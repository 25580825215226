

















import Vue, { PropType } from "vue";
import CurriculumSyllabusNECPure from "@/views/curriculum/teacher/CurriculumSyllabusNEC/CurriculumSyllabusNECPure.vue";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import {
  ContentInputEvent,
  ContentMonthInputEvent,
  CurriculumSyllabusNECContentRowModel,
  CurriculumSyllabusNECModel,
  CurriculumSyllabusNECViewPointRowModel,
  dataToCurriculumSyllabusNECModel,
} from "@/views/curriculum/teacher/CurriculumSyllabusNEC/CurriculumSyllabusNECModel";
import { EditableNECContent } from "@/ts/objects/curriculum/editable/EditableNECContent";
import { Err } from "@/ts/objects/Err";
import { EditableNECContentMonth } from "@/ts/objects/curriculum/editable/EditableNECContentMonth";
import log from "loglevel";
import { getDataWithTimeout, Loadable } from "@/ts/Loadable";
import { QuarterSwitchValue, quarterSwitchValueToMonths } from "@/components/QuarterSwitch/QuarterSwitch.vue";
import { arrayMoveOne, downloadBlob, isNullish } from "@/ts/utils";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import debounce from "lodash/debounce";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { NavigationGuardNext, Route } from "vue-router";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import { unparse } from "papaparse";
import { format } from "date-fns";
import { words } from "@/ts/const/Words";
import { messages } from "@/ts/const/Messages";
import { MonthValue, monthValueToEnglishAbbr } from "@/ts/objects/common/MonthValue";

// TODO 追加・削除時のエラーを表示する。

export default Vue.extend({
  name: "CurriculumSyllabusNEC",
  components: { CurriculumSyllabusNECPure },
  props: {
    curriculumStoreT: { type: Object as PropType<CurriculumStoreT>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true },
  },
  async created() {
    log.debug(`CurriculumSyllabusNEC:created: Started.`);

    this.debouncedUpdateNeedSave = debounce(this.updateNeedSave, 500);
    this.debouncedUpdateCurrentErrors = debounce(this.updateCurrentErrors, 500);
    this.debouncedSaveAll = debounce(() => this.saveAll(false), 3000);
    this.periodicSaverId = window.setInterval(() => {
      if (!this.needSave) return;
      log.debug(`SAVING! (by periodicSaver)`);
      this.debouncedSaveAll();
    }, 10000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
        this.updateNeedSave(); // この行が無いと、needSaveがdebounceにより未更新である場合、requirementToLeaveを一瞬で通ってしまう。
      },
      requirementToLeave: async () => !this.needSave,
      onRequirementUnmet: async () => {
        // TODO highlightUnsaved?
        // this.highlightUnsaved = true;
      },
      onLeaveCompleted: async () => {
        const necId = this.necId;
        if (isNullish(necId)) return;
        await this.curriculumStoreT.loadACurriculum({
          curriculumRepository: this.curriculumRepository,
          resourceName: `/neCurriculums/${necId}`,
        });
      },
    });

    this.model = Loadable.loading();

    const necTree = await getDataWithTimeout(() => this.curriculumStoreT.neCurriculumTree, 10);
    if (necTree === null) {
      log.error(`CurriculumSyllabusNEC:created: necTree is null.`);
      this.model = Loadable.error();
      return;
    }

    const [contentResp, monthResp] = await Promise.all<EditableNECContent[] | Err, EditableNECContentMonth[] | Err>([
      this.curriculumRepository.listEditableNECContents(true, necTree.self.necId),
      this.curriculumRepository.listEditableNECContentMonths(true, necTree.self.necId),
    ]);
    if (contentResp instanceof Err) {
      log.error(`CurriculumSyllabusNEC:created: Error fetching content: ${contentResp.internalMessage}`);
      this.model = Loadable.error();
      return;
    }
    if (monthResp instanceof Err) {
      log.error(`CurriculumSyllabusNEC:created: Error fetching contentMonth: ${monthResp.internalMessage}`);
      this.model = Loadable.error();
      return;
    }

    const model = dataToCurriculumSyllabusNECModel(necTree, contentResp, monthResp);
    this.model = Loadable.fromValue(model);

    log.debug(`CurriculumSyllabusNEC:created: Completed.`);
  },
  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    model: Loadable<CurriculumSyllabusNECModel>;

    quarterSwitchValue: QuarterSwitchValue;

    currentlyCreatingContent: boolean;
    currentlyDeletingContent: boolean;

    needSave: boolean;
    currentErrors: ErrorNotificationParam[];

    debouncedUpdateNeedSave: any;
    debouncedUpdateCurrentErrors: any;
    debouncedSaveAll: any;
    periodicSaverId: number | undefined;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      model: Loadable.idle(),

      quarterSwitchValue: "all",

      currentlyCreatingContent: false,
      currentlyDeletingContent: false,

      needSave: false,
      currentErrors: [],

      debouncedUpdateNeedSave: undefined,
      debouncedUpdateCurrentErrors: undefined,
      debouncedSaveAll: undefined,
      periodicSaverId: undefined,

      pageLeaveService: null,
    };
  },
  computed: {
    modelData(): CurriculumSyllabusNECModel | null {
      return this.model.data;
    },
    necId(): string | null {
      return this.modelData?.necId ?? null;
    },
    viewPointsDict(): { [viewPointId: string]: CurriculumSyllabusNECViewPointRowModel } | null {
      const model = this.modelData;
      if (model === null) return null;
      return Object.fromEntries(model.viewPointRows.map(vp => [vp.viewPointId, vp]));
    },
    contentsDict(): { [viewPointId: string]: CurriculumSyllabusNECViewPointRowModel } | null {
      const model = this.modelData;
      if (model === null) return null;
      return Object.fromEntries(model.viewPointRows.map(vp => [vp.viewPointId, vp]));
    },
    allContentRows(): CurriculumSyllabusNECContentRowModel[] | null {
      return this.modelData?.viewPointRows?.flatMap(vp => vp.contentRows) ?? null;
    },
    allContents(): EditableNECContent[] | null {
      return this.allContentRows?.map(c => c.content) ?? null;
    },
    allContentMonths(): EditableNECContentMonth[] | null {
      return this.allContentRows?.flatMap(c => c.months) ?? null;
    },
  },
  watch: {
    modelData: {
      handler: function() {
        log.debug(`CurriculumSyllabusNEC:watch:modelData`);
        this.debouncedUpdateNeedSave();
        this.debouncedUpdateCurrentErrors();
      },
      deep: true,
    },
  },
  methods: {
    updateNeedSave() {
      const allContents = this.allContents;
      const allContentMonths = this.allContentMonths;
      if (allContents === null || allContentMonths === null) {
        this.needSave = false;
        return;
      }

      this.needSave = allContents.some(c => c.needSave()) || allContentMonths.some(cm => cm.needSave());
    },
    updateCurrentErrors() {
      const allContentRows = this.allContentRows;
      const viewPointsDict = this.viewPointsDict;
      if (allContentRows === null || viewPointsDict === null) {
        this.currentErrors = [];
        return;
      }

      const contentErrors = allContentRows.flatMap(contentRow =>
        contentRow.content.currentErrors().map(err => {
          const viewPointRow = viewPointsDict[contentRow.content.viewPointId];
          return {
            heading: `${viewPointRow?.name ?? ""} - ${contentRow.content.name}`,
            text: err.message,
          };
        })
      );
      const contentMonthErrors = allContentRows.flatMap(contentRow =>
        contentRow.months.flatMap(contentMonth =>
          contentMonth.currentErrors().map(err => {
            const viewPointRow = viewPointsDict[contentRow.content.viewPointId];
            return {
              heading: `${viewPointRow?.name ?? ""} - ${contentRow.content.name} - ${contentMonth.month}月`,
              text: err.message,
            };
          })
        )
      );

      this.currentErrors = [...contentErrors, ...contentMonthErrors];
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      const allContents = this.allContents;
      const allContentMonths = this.allContentMonths;
      if (allContents === null || allContentMonths === null) return emptySaveResult();

      log.debug(`CurriculumSyllabusNEC: SAVING!`);

      const saveResults = await Promise.all([
        ...allContents.map(c => c.saveAllChanges(force)),
        ...allContentMonths.map(cm => cm.saveAllChanges(force)),
      ]);
      return flattenSaveResults(saveResults);
    },
    onInputQuarterSwitch(value: QuarterSwitchValue) {
      this.quarterSwitchValue = value;
    },
    async onInputContent(viewPointId: string, contentId: string, event: ContentInputEvent) {
      const content = getContent(this.modelData, viewPointId, contentId);
      if (content === null) return;

      content.name = event.name;

      this.debouncedSaveAll();
    },
    async onInputContentMonth(
      viewPointId: string,
      contentId: string,
      month: MonthValue,
      event: ContentMonthInputEvent
    ) {
      const contentMonth = getContentMonth(this.modelData, viewPointId, contentId, month);
      if (contentMonth === null) return;

      contentMonth.enabled = event.enabled;
      contentMonth.text = event.text;

      this.debouncedSaveAll();
    },
    async moveContent(viewPointId: string, contentId: string, up: boolean) {
      const model = this.modelData;
      if (model === null) return;

      log.debug(`CurriculumSyllabusNEC:moveContent: viewPointId=${viewPointId}, contentId=${contentId}, up=${up}`);

      const viewPointRow = model.viewPointRows.find(vr => vr.viewPointId === viewPointId);
      if (viewPointRow === undefined) return;

      const contentRow = viewPointRow.contentRows.find(cr => cr.content.contentId === contentId);
      if (contentRow === undefined) return;

      const sorted = arrayMoveOne(viewPointRow.contentRows, contentRow, up);
      viewPointRow.contentRows = sorted;

      const resp = await this.curriculumRepository.sortNECContents(
        model.necId,
        viewPointId,
        sorted.map(cr => cr.content.contentId)
      );
      if (resp instanceof Err) {
        log.error(`CurriculumSyllabusNEC:sortContents: Error sorting contents: ${resp.internalMessage}`);
        return;
      }

      this.debouncedSaveAll();
    },
    async createContent(viewPointId: string) {
      if (this.currentlyCreatingContent) {
        log.debug(`CurriculumSyllabusNEC:createContent: Already creating content!`);
        return;
      }

      const model = this.modelData;
      if (model === null) return;

      const viewPointRow = model.viewPointRows.find(vp => vp.viewPointId === viewPointId);
      if (isNullish(viewPointRow)) return;

      this.currentlyCreatingContent = true;
      const resp = await this.curriculumRepository.postAndGetEditableNECContent(true, model.necId, viewPointId);
      if (resp instanceof Err) {
        log.error(`Error creating necContent: ${resp.internalMessage}`);
        this.currentlyCreatingContent = false;
        return;
      }

      const { content, contentMonths } = resp;
      viewPointRow.contentRows = [...viewPointRow.contentRows, { content, months: contentMonths }];

      this.currentlyCreatingContent = false;

      this.debouncedSaveAll();
    },
    async deleteContent(viewPointId: string, contentId: string) {
      if (this.currentlyDeletingContent) {
        log.debug(`CurriculumSyllabusNEC:deleteContent: Already deleting content!`);
        return;
      }

      const model = this.modelData;
      if (model === null) return;

      const viewPointRow = model.viewPointRows.find(vp => vp.viewPointId === viewPointId);
      if (isNullish(viewPointRow)) return;

      const contentRow = viewPointRow.contentRows.find(cr => cr.content.contentId === contentId);
      if (isNullish(contentRow)) return;

      if (contentRow.months.some(m => m.enabled)) {
        // この内容構成を削除するには、すべての月を無効化してください。
        window.alert(messages.disableMonthsBeforeDeleteContent);
        return;
      }

      // この内容構成を削除してよろしいですか？
      if (!window.confirm(`Are you sure you want to delete this ${words.content.d.toLowerCase()}?`)) return;

      log.debug(`DELETE!!!`);
      this.currentlyDeletingContent = true;

      const resp = await this.curriculumRepository.deleteNECContent(model.necId, viewPointId, contentId);
      if (resp instanceof Err) {
        log.error(`Error deleting necContent: ${resp.internalMessage}`);
        this.currentlyDeletingContent = false;
        return;
      }

      viewPointRow.contentRows = viewPointRow.contentRows.filter(cr => cr.content.contentId !== contentId);

      this.currentlyDeletingContent = false;

      this.debouncedSaveAll();
    },
    async uploadSyllabusFile(file: File) {
      const model = this.modelData;
      if (model === null) return;

      const resp = await this.curriculumRepository.uploadNECSyllabusFile(model.necId, file, 30000);
      log.debug(`CurriculumSyllabusNEC:uploadSyllabusFile: resp=${JSON.stringify(resp)}`);
      if (resp instanceof Err) {
        // TODO エラーハンドリング？
        log.error(`CurriculumSyllabusNEC:uploadSyllabusFile: Error uploading syllabus file: ${resp.internalMessage}`);
        return;
      }
      model.syllabusFileGcsObjectPath = resp.syllabusFileGcsObjectPath ?? null;
    },
    exportCsv() {
      const model = this.modelData;
      if (isNullish(model)) return;

      exportCsv(model, this.quarterSwitchValue);
    },
  },
});

function getContent(
  model: CurriculumSyllabusNECModel | null,
  viewPointId: string,
  contentId: string
): EditableNECContent | null {
  return getContentRow(model, viewPointId, contentId)?.content ?? null;
}

function getContentMonth(
  model: CurriculumSyllabusNECModel | null,
  viewPointId: string,
  contentId: string,
  month: MonthValue
): EditableNECContentMonth | null {
  const contentRow = getContentRow(model, viewPointId, contentId);
  if (isNullish(contentRow)) return null;

  return contentRow.months.find(m => m.month === month) ?? null;
}

function getContentRow(
  model: CurriculumSyllabusNECModel | null,
  viewPointId: string,
  contentId: string
): CurriculumSyllabusNECContentRowModel | null {
  if (model === null) return null;

  const viewPointRow = model.viewPointRows.find(vp => vp.viewPointId === viewPointId);
  if (isNullish(viewPointRow)) return null;

  const contentRow = viewPointRow.contentRows.find(cr => cr.content.contentId === contentId);
  if (isNullish(contentRow)) return null;

  return contentRow;
}

function exportCsv(model: CurriculumSyllabusNECModel, quarterSwitchValue: QuarterSwitchValue) {
  const monthsToDisplay = quarterSwitchValueToMonths(quarterSwitchValue);
  const csvRows = model.viewPointRows.flatMap(vr =>
    vr.contentRows.map(cr => {
      const monthToText = monthsToDisplay.map((month): [string, string] => {
        const contentMonth = cr.months.find(cr => cr.month === month);
        if (contentMonth === undefined || !contentMonth.enabled) {
          return [monthValueToEnglishAbbr(month), ""];
        }
        return [monthValueToEnglishAbbr(month), contentMonth.text];
      });
      return {
        [words.viewPoint.d]: vr.name,
        [words.content.d]: cr.content.name,
        ...Object.fromEntries(monthToText),
      };
    })
  );
  const columnNames = [words.viewPoint.d, words.content.d, ...monthsToDisplay.map(monthValueToEnglishAbbr)];

  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, unparse(csvRows, { columns: columnNames })], { type: "text/plain" });
  downloadBlob(blob, `${model.necName}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`);
}

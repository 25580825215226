








































































































import Vue, { PropType } from "vue";
import { format } from "date-fns";
import { downloadFileByUrl, fileTypeToDisplayName } from "@/ts/utils/AppUtil";
import { isNullish } from "@/ts/utils/CommonUtil";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";

export default Vue.extend({
  name: "JournalFileModal",
  props: {
    journalFile: { type: Object as PropType<JournalFile>, required: true },
    readonly: { type: Boolean, default: false },

    /**
     * この学習記録ファイルを削除する関数。
     * readonly = true の場合は不要。
     */
    deleteJournalFile: {
      type: Function as PropType<(file: JournalFile) => Promise<void>>,
    },
  },
  data(): {
    mediaOverlay: boolean;

    words: Words;
    messages: Messages;
  } {
    return {
      mediaOverlay: false,

      words,
      messages,
    };
  },
  computed: {
    modalType(): string {
      if (this.journalFile.type === "image") {
        return "image";
      } else if (this.journalFile.type === "video") {
        return "video";
      } else if (this.journalFile.type === "audio") {
        return "audio";
      } else {
        return "any";
      }
    },
    fileTypeIcon(): [string, string] {
      return ["fas", "file-alt"];
    },
    fileTypeText(): string {
      return fileTypeToDisplayName(this.journalFile.type);
    },
    mediaType(): string {
      return this.journalFile.mediaType;
    },
    fileUrl(): string | null {
      return this.journalFile.fileUrl;
    },
    thumbnailUrl(): string | null {
      return this.journalFile.thumbnailUrl;
    },
    imageWidth(): number | null {
      return this.journalFile.width ?? null;
    },
    imageHeight(): number | null {
      return this.journalFile.height ?? null;
    },
    filename(): string {
      return `${this.journalFile.filename}.${this.journalFile.ext}`;
    },
    datetimeText(): string {
      return format(this.journalFile.updatedAt, "yyyy/M/d H:mm");
    },
  },
  methods: {
    toggleMediaOverlay() {
      this.mediaOverlay = !this.mediaOverlay;
    },
    async download() {
      const url = this.fileUrl;
      if (url === null) return;

      await downloadFileByUrl(url, this.filename, this.mediaType);
    },
    async deleteFile() {
      if (this.readonly) {
        return;
      }

      if (!window.confirm(messages.confirmDelete)) return;

      this.$modal.hideAll();

      const journalFile = this.journalFile;

      journalFile.markAsDeleting();

      if (!isNullish(this.deleteJournalFile)) await this.deleteJournalFile(journalFile);
    },
  },
});













import Vue from "vue";
import { words, Words } from "@/ts/const/Words";
import { hasValue } from "@/ts/utils";

export default Vue.extend({
  name: "StudentActivityTableProjectCell",
  props: {
    projectName: { type: String, required: true },

    /**
     * 児童生徒ビューへのパス。
     * 未設定なら、リンク自体表示しない。
     */
    studentViewPath: { type: String, required: false },

    width: { type: Number, required: false },
  },
  data(): { words: Words } {
    return { words };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--width": hasValue(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
});









import Vue from "vue";

export default Vue.extend({
  name: "RouterLinkWrapper",
  props: {
    to: { type: String, required: true },
    enabled: { type: Boolean, default: true },
  },
});

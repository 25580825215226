














































































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";

export default Vue.extend({
  name: "ProjectLookbackPure",
  components: { DisableableAutoResizeTextarea, LoadingBlock, MessageView },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },
    lookbackRef: { type: Function as PropType<() => EditableProjectLookback | null> },

    saveAll: { type: Function, required: true },
  },
  data(): {
    words: Words;
    messages: Messages;
  } {
    return { words, messages };
  },
  computed: {
    lookback(): EditableProjectLookback | null {
      return this.lookbackRef();
    },
  },
});

import { Err } from "@/ts/objects/Err";
import { EditableValue } from "@/ts/objects/editable/value/EditableValue";
import log from "loglevel";

export class EditableYearMonth extends EditableValue {
  readonly name: string;
  private _year: number;
  private _lastRequestYear: number;
  private _savedYear: number;
  private _month: number;
  private _lastRequestMonth: number;
  private _savedMonth: number;
  private readonly _saver: (year: number, month: number) => Promise<[number, number] | Err>;

  constructor(
    name: string,
    year: number,
    month: number,
    savable: boolean,
    saver: (year: number, month: number) => Promise<[number, number] | Err>
  ) {
    super(savable);

    this.name = name;

    this._year = year;
    this._lastRequestYear = year;
    this._savedYear = year;

    this._month = month;
    this._lastRequestMonth = month;
    this._savedMonth = month;

    this._saver = saver;
  }

  get year(): number {
    return this._year;
  }

  set year(value: number) {
    this._year = value;
  }

  get month(): number {
    return this._month;
  }

  set month(value: number) {
    this._month = value;
  }

  protected _needSave(): boolean {
    const needSave = this._year !== this._savedYear || this._month !== this._savedMonth;
    if (needSave)
      log.debug(
        `${this.name}: needs save: (${this._savedYear}, ${this._savedMonth}) -> (${this._year}, ${this._month})`
      );
    return needSave;
  }

  protected async _save(): Promise<Err | null> {
    const resp = await this._saver(this._year, this._month);
    if (resp instanceof Err) return resp;
    [this._savedYear, this._savedMonth] = resp;
    return null;
  }

  protected _changedAfterLastRequest(): boolean {
    return this._year !== this._lastRequestYear || this._month !== this._lastRequestMonth;
  }
}

import Axios, { AxiosResponse } from "axios";
import { DisplayableErr, Err, InternalErr } from "@/ts/objects/Err";
import chunk from "lodash/chunk";
import log from "loglevel";
import { utcToZonedTime } from "date-fns-tz";
import Vue from "vue";
import { isNullish } from "@/ts/utils/CommonUtil";
import { isMonthValue, MonthValue } from "@/ts/objects/common/MonthValue";

/**
 * axiosリクエストを実行する。
 *
 * @param func 実行すべきaxiosリクエスト。
 */
export async function doReq<T>(func: () => Promise<AxiosResponse<T>>): Promise<Err | T> {
  try {
    const resp = await func();
    if (resp.status < 100 || 300 <= resp.status)
      return new InternalErr(`Error response from service: ${resp.status}: ${resp.statusText}`);
    log.debug(`Successful response from service: ${resp.status}, ${JSON.stringify(resp.data)}`);
    return resp.data;
  } catch (e) {
    // TODO ちゃんと400とか500エラーをキャッチできるか？
    const errorString = JSON.stringify(e);
    log.debug(`Error from axios: ${errorString}`);
    log.error(`Error from server: ${JSON.stringify(e.response)}`);
    return new DisplayableErr(errorString, e.response?.data?.message ?? "エラーが発生しました。");
  }
}

export function parseUTCOrZero(text: string, timezone = "Asia/Tokyo"): Date {
  try {
    return utcToZonedTime(text, timezone);
  } catch (e) {
    log.debug(`parseDateOrZero: Failed to parse date. text=${text}`);
    return new Date(0);
  }
}

/**
 * バケット内パスから、ダウンロードURLを取得する。
 *
 * @param storageObjectPath バケットルートからの相対パス。 例: user/school/logo.png
 */
export async function getDownloadUrlOfStorageObject(storageObjectPath: string): Promise<string | null> {
  const fileRef = Vue.prototype.$storage?.ref(storageObjectPath);
  if (isNullish(fileRef)) return null;
  const fileUrl = await fileRef.getDownloadURL().catch((e: any) => {
    log.error(`getDownloadUrlOfStorageObject: error=${JSON.stringify(e)}`);
    return undefined;
  });
  if (typeof fileUrl !== "string") return null;
  return fileUrl;
}

export async function downloadFileByUrl(url: string, filename: string, mediaType: string) {
  const resp: any = await doReq(() => Axios.get(url, { responseType: "blob" }));
  if (resp instanceof Err) {
    log.error(`Error on download: ${resp.internalMessage}`);
    return;
  }

  const blob = new Blob([resp], { type: mediaType });
  await downloadBlob(blob, filename);
}

export async function downloadBlob(blob: Blob, filename: string) {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  a.target = "_blank";
  a.click();
  URL.revokeObjectURL(a.href);
}

export async function downloadAsCSV(csvText: string, filename: string) {
  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csvText], { type: "text/plain" });
  await downloadBlob(blob, filename);
}

export function eecJournalRnameToId(
  resourceName: string
): { eecId: string; studentUserId: string; journalId: string } | null {
  const m = /^\/eeCurriculums\/([\w-]+)\/journalStudents\/([\w-]+)\/journals\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined || m[3] === undefined) return null;
  return {
    eecId: m[1],
    studentUserId: m[2],
    journalId: m[3],
  };
}

export function eecJournalFileRnameToId(
  resourceName: string
): { eecId: string; studentUserId: string; journalId: string; journalFileId: string } | null {
  const m = /^\/eeCurriculums\/([\w-]+)\/journalStudents\/([\w-]+)\/journals\/([\w-]+)\/journalFiles\/([\w-]+)$/.exec(
    resourceName
  );
  if (m === null || m[1] === undefined || m[2] === undefined || m[3] === undefined || m[4] === undefined) return null;
  return {
    eecId: m[1],
    studentUserId: m[2],
    journalId: m[3],
    journalFileId: m[4],
  };
}

export function necContentMonthRnameToId(
  resourceName: string
): { necId: string; viewPointId: string; contentId: string; month: MonthValue } | null {
  const m = /^\/neCurriculums\/([\w-]+)\/viewPoints\/([\w-]+)\/contents\/([\w-]+)\/months\/([\w-]+)$/.exec(
    resourceName
  );
  if (m === null || m[1] === undefined || m[2] === undefined || m[3] === undefined || m[4] === undefined) return null;

  const month = parseInt(m[4], 10);
  if (!isMonthValue(month)) return null;
  return {
    necId: m[1],
    viewPointId: m[2],
    contentId: m[3],
    month,
  };
}

export function necEvaluationRnameToId(
  resourceName: string
): { necId: string; viewPointId: string; contentId: string; month: MonthValue; studentUserId: string } | null {
  const m = /^\/neCurriculums\/([\w-]+)\/viewPoints\/([\w-]+)\/contents\/([\w-]+)\/months\/([\w-]+)\/evaluations\/([\w-]+)$/.exec(
    resourceName
  );
  if (
    m === null ||
    m[1] === undefined ||
    m[2] === undefined ||
    m[3] === undefined ||
    m[4] === undefined ||
    m[5] === undefined
  )
    return null;

  const month = parseInt(m[4], 10);
  if (!isMonthValue(month)) return null;
  return {
    necId: m[1],
    viewPointId: m[2],
    contentId: m[3],
    month,
    studentUserId: m[5],
  };
}

export function projectRnameToId(resourceName: string): string | null {
  const m = /^\/projects\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined) return null;
  return m[1];
}

export function projectLookbackRnameToId(resourceName: string): [string, string] | null {
  const m = /^\/projects\/([\w-]+)\/lookbacks\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined) return null;
  return [m[1], m[2]];
}

export function projectRubricRnameToId(resourceName: string): [string, string] | null {
  const m = /^\/projects\/([\w-]+)\/rubrics\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined) return null;
  return [m[1], m[2]];
}

export function projectJournalRnameToId(resourceName: string): [string, string, string] | null {
  const m = /^\/projects\/([\w-]+)\/rubrics\/([\w-]+)\/journals\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined || m[3] === undefined) return null;
  return [m[1], m[2], m[3]];
}

export function projectJournalFileRnameToId(resourceName: string): [string, string, string, string] | null {
  const m = /^\/projects\/([\w-]+)\/rubrics\/([\w-]+)\/journals\/([\w-]+)\/journalFiles\/([\w-]+)$/.exec(resourceName);
  if (m === null || m[1] === undefined || m[2] === undefined || m[3] === undefined || m[4] === undefined) return null;
  return [m[1], m[2], m[3], m[4]];
}

export type RnameObject = {
  rname: string;
  fragments: RnameObjectFragment[];
};

export type RnameObjectFragment = {
  collectionId: string;
  resourceId: string;
};

export function rnameToRnameObject(resourceName: string): RnameObject | null {
  const splitName = resourceName.split("/");

  if (splitName.length <= 1 || splitName.length % 2 !== 1 || splitName[0] !== "") return null;

  const fragments = splitName.slice(1);

  const regex = /^[\w-]+$/;
  if (!fragments.every(fragment => regex.test(fragment))) return null;

  return {
    rname: resourceName,
    fragments: chunk(fragments, 2).map(chunk => {
      return {
        collectionId: chunk[0],
        resourceId: chunk[1],
      };
    }),
  };
}

export function fileTypeToDisplayName(fileType: string): string {
  switch (fileType) {
    case "image":
      return "Image";
    case "video":
      return "Video";
    case "audio":
      return "Audio";
    case "slides":
      return "Slides";
    default:
      return "File";
  }
}

export function fileTypeToIcon(fileType: string): [string, string] {
  switch (fileType) {
    case "image":
      return ["fas", "image"];
    case "video":
      return ["fas", "file-video"];
    case "audio":
      return ["fas", "file-audio"];
    default:
      return ["fas", "file-alt"];
  }
}

export function solanProcessTextOf(process: number): string {
  switch (process) {
    case 0:
      return "みつける";
    case 1:
      return "しらべる";
    case 2:
      return "まとめる";
    case 3:
      return "つたえる";
    default:
      return "???";
  }
}























import Vue, { PropType } from "vue";
import log from "loglevel";

export abstract class MenuItem {}

export class MenuButton extends MenuItem {
  constructor(public readonly key: string, public readonly label: string, public readonly icon?: [string, string]) {
    super();
  }
}

export class Separator extends MenuItem {}

type MenuItemObject = {
  isSeparator: boolean;
  key?: string;
  label?: string;
  icon?: [string, string];
};

export default Vue.extend({
  name: "PopupMenuButton",
  props: {
    items: { type: Array as PropType<MenuItem[]>, default: () => [] },
    align: { type: String, default: "left" }, // left, center, right
    fontSize: { type: Number, default: 16 },
    zIndex: { type: Number, default: 10 },
    closeOnSelect: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  data(): {
    showPopup: boolean;
  } {
    return {
      showPopup: false,
    };
  },
  computed: {
    styles(): any {
      return {
        "--font-size": `${this.fontSize}px`,
        "--z-index": this.zIndex,
      };
    },
    menuItemObjects(): MenuItemObject[] {
      return this.items.map(item => {
        if (item instanceof MenuButton) {
          return {
            isSeparator: false,
            key: item.key,
            label: item.label,
            icon: item.icon,
          };
        } else {
          return {
            isSeparator: true,
          };
        }
      });
    },
  },
  methods: {
    toggleMenuArea() {
      if (this.disabled) {
        this.showPopup = false;
        return;
      }
      this.showPopup = !this.showPopup;
    },
    onClickOutside(_event: any) {
      this.showPopup = false;
    },
    onClickItem(key: string) {
      log.debug(`onClickItem: key=${key}`);
      if (this.disabled) return;

      this.$emit("input", key);
      if (this.closeOnSelect) {
        this.showPopup = false;
      }
    },
  },
});

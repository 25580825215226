import axios, { AxiosInstance } from "axios";
import Vue from "vue";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import log from "loglevel";
import { ProjectRepository, ProjectRepositoryImpl } from "@/ts/repositories/ProjectRepository";
import { ProjectRepositoryMock } from "@/test-tools/mocks/ProjectRepositoryMock";
import { UserRepository, UserRepositoryImpl } from "@/ts/repositories/UserRepository";
import { UserRepositoryMock } from "@/test-tools/mocks/UserRepositoryMock";
import { Quarter } from "@/ts/objects/common/Quarter";
import { AuthService } from "@/ts/AuthService";
import { SolanRepository, SolanRepositoryImpl } from "@/ts/repositories/SolanRepository";
import { SolanRepositoryMock } from "@/test-tools/mocks/SolanRepositoryMock";
import { LogRepository, LogRepositoryImpl } from "@/ts/repositories/LogRepository";
import { ActivityRepository, ActivityRepositoryImpl } from "@/ts/repositories/ActivityRepository";
import { ActivityRepositoryMock } from "@/test-tools/mocks/ActivityRepositoryMock";
import { LogRepositoryMock } from "@/test-tools/mocks/LogRepositoryMock";
import { CurriculumRepository, CurriculumRepositoryImpl } from "@/ts/repositories/CurriculumRepository";
import { CurriculumRepositoryMock } from "@/test-tools/mocks/CurriculumRepositoryMock";
import { isNullish } from "@/ts/utils";

export function initializeRepositories(
  authService: AuthService
): [UserRepository, ActivityRepository, ProjectRepository, SolanRepository, CurriculumRepository, LogRepository] {
  const userRepository = new UserRepositoryImpl(
    `https://${window.location.host}/api/user`,
    undefined,
    getAxiosInstance(authService)
  );
  const activityRepository = new ActivityRepositoryImpl(
    `https://${window.location.host}/api/activity`,
    undefined,
    getAxiosInstance(authService)
  );
  const projectRepository = new ProjectRepositoryImpl(
    `https://${window.location.host}/api/project`,
    undefined,
    getAxiosInstance(authService)
  );
  const solanRepository = new SolanRepositoryImpl(
    `https://${window.location.host}/api/solan`,
    undefined,
    getAxiosInstance(authService)
  );
  const curriculumRepository = new CurriculumRepositoryImpl(
    `https://${window.location.host}/api/curriculum`,
    undefined,
    getAxiosInstance(authService)
  );
  const logRepository = new LogRepositoryImpl(
    `https://${window.location.host}/api/log`,
    undefined,
    getAxiosInstance(authService)
  );
  return [userRepository, activityRepository, projectRepository, solanRepository, curriculumRepository, logRepository];
}

export function initializeRepositoryMocks(): [
  UserRepository,
  ActivityRepository,
  ProjectRepository,
  SolanRepository,
  CurriculumRepository,
  LogRepository
] {
  const userRepository = new UserRepositoryMock(new Quarter(2000, 1));
  const activityRepository = new ActivityRepositoryMock();
  const projectRepository = new ProjectRepositoryMock();
  const solanRepository = new SolanRepositoryMock();
  const curriculumRepository = new CurriculumRepositoryMock();
  const logRepository = new LogRepositoryMock();
  return [userRepository, activityRepository, projectRepository, solanRepository, curriculumRepository, logRepository];
}

function getAxiosInstance(authService: AuthService): AxiosInstance {
  const instance = axios.create();
  instance.defaults.timeout = 15000;
  instance.defaults.withCredentials = false;
  instance.interceptors.request.use(
    function(config) {
      const idToken = Vue.prototype.$googleIdToken;
      if (isNullish(config.headers)) {
        config.headers = {};
      }
      if (idToken !== null) {
        config.headers["Authorization"] = `Bearer ${idToken}`;
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
  createAuthRefreshInterceptor(
    instance,
    async (): Promise<any> => {
      log.debug("createAuthRefreshInterceptor:refreshAuthCall");
      await authService.signInToGoogle(true);
      return Promise.resolve();
    }
  );
  return instance;
}

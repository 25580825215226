import { ActionLog } from "@/ts/objects/common/ActionLog";
import { Err } from "@/ts/objects/Err";
import { RecentStudy } from "@/ts/objects/common/RecentStudy";
import { LogRepository } from "@/ts/repositories/LogRepository";

export class LogRepositoryMock extends LogRepository {
  async loadActionLogs(_studentUserIds: string[]): Promise<ActionLog[] | Err> {
    // TODO LogRepositoryMockのテストデータ作成。
    return [];
  }

  async loadRecentStudies(_studentUserIds: string[]): Promise<RecentStudy[] | Err> {
    // TODO LogRepositoryMockのテストデータ作成。
    return [];
  }
}

import { parseUTCOrZero } from "@/ts/utils/AppUtil";

/**
 * SOLANルーブリック。
 *
 * リソース名は、 /solanProjects/{projectId}/rubrics/{rubricId}。
 */
export class SolanRubric {
  constructor(
    public readonly rubricId: string,
    public readonly projectId: string,
    public readonly studentUserId: string,
    public readonly process: number, // 0: みつける, 1: しらべる, 2: まとめる, 3: つたえる
    public readonly learningActivity: string,
    public readonly viewPointS: string,
    public readonly viewPointA: string,
    public readonly viewPointB: string,
    public readonly viewPointC: string,
    createdAt: string
  ) {
    this.createdAt = parseUTCOrZero(createdAt);
  }

  readonly createdAt: Date;
}

/* tslint:disable */
/* eslint-disable */
/**
 * ログサービス
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionLog
 */
export interface ActionLog {
    /**
     * 
     * @type {string}
     * @memberof ActionLog
     */
    actionLogId: string;
    /**
     * 児童生徒ID。
     * @type {string}
     * @memberof ActionLog
     */
    studentUserId: string;
    /**
     * 児童生徒の名前。
     * @type {string}
     * @memberof ActionLog
     */
    studentUserName: string;
    /**
     * 児童生徒アイコンURL。
     * @type {string}
     * @memberof ActionLog
     */
    studentPhotoUrl: string;
    /**
     * 行為者ユーザータイプ。
     * @type {string}
     * @memberof ActionLog
     */
    subjectUserType: string;
    /**
     * 行為者ユーザーID。
     * @type {string}
     * @memberof ActionLog
     */
    subjectUserId: string;
    /**
     * 行為者ユーザー名。
     * @type {string}
     * @memberof ActionLog
     */
    subjectUserName: string;
    /**
     * 行為者ユーザーアイコンURL。
     * @type {string}
     * @memberof ActionLog
     */
    subjectUserPhotoUrl: string;
    /**
     * 行為対象サービス。
     * @type {string}
     * @memberof ActionLog
     */
    objectService: string;
    /**
     * 行為対象リソース名。
     * @type {string}
     * @memberof ActionLog
     */
    objectResourceName: string;
    /**
     * 更新したプロパティのカテゴリ。更新アクションで、かつ区別が必要な場合のみ、値が入る。
     * @type {string}
     * @memberof ActionLog
     */
    updatedPropertyCategory?: string;
    /**
     * ログデータ。(メインとなるメッセージ)
     * @type {string}
     * @memberof ActionLog
     */
    data: string;
    /**
     * ログの作成日時。
     * @type {string}
     * @memberof ActionLog
     */
    createdAt: string;
    /**
     * ログの更新日時。重複するログが一定時間内に複数回送られてきた場合、2個目以降は作成されず、`updatedAt`が更新されるだけとなる。
     * @type {string}
     * @memberof ActionLog
     */
    updatedAt: string;
    /**
     * 
     * @type {ActionLogMeta}
     * @memberof ActionLog
     */
    meta?: ActionLogMeta;
}
/**
 * メタデータ。検索やdeduplicateには使用されない、任意のデータ。
 * @export
 * @interface ActionLogMeta
 */
export interface ActionLogMeta {
    /**
     * 年度。データに紐づく年度であり、作成年度とは限らない。
     * @type {number}
     * @memberof ActionLogMeta
     */
    schoolYear?: number;
    /**
     * 月。データに紐づく月であり、作成月とは限らない。
     * @type {number}
     * @memberof ActionLogMeta
     */
    month?: number;
}
/**
 * 
 * @export
 * @interface PubsubMessage
 */
export interface PubsubMessage {
    /**
     * 
     * @type {PubsubMessageMessage}
     * @memberof PubsubMessage
     */
    message?: PubsubMessageMessage;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessage
     */
    subscription?: string;
}
/**
 * 
 * @export
 * @interface PubsubMessageMessage
 */
export interface PubsubMessageMessage {
    /**
     * 
     * @type {PubsubMessageMessageAttributes}
     * @memberof PubsubMessageMessage
     */
    attributes?: PubsubMessageMessageAttributes;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessage
     */
    data?: string;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessage
     */
    messageId?: string;
}
/**
 * 
 * @export
 * @interface PubsubMessageMessageAttributes
 */
export interface PubsubMessageMessageAttributes {
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessageAttributes
     */
    studentUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessageAttributes
     */
    subjectUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessageAttributes
     */
    subjectUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessageAttributes
     */
    objectService?: string;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessageAttributes
     */
    objectResourceName?: string;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessageAttributes
     */
    updatedPropertyCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessageAttributes
     */
    metaSchoolYear?: string;
    /**
     * 
     * @type {string}
     * @memberof PubsubMessageMessageAttributes
     */
    metaMonth?: string;
}
/**
 * 「最近の学習」欄に表示しやすくするために、アクションログを日単位でグループ化したもの。   行為対象サービス(`objectService`。`project`や`solan`等)と内容(`data`)が一致するアクションログをひとまとめにしている。同じ児童生徒の同じログが複数回現れる可能性はある。  (JSTで)日を跨ぐグループ化は行わない。
 * @export
 * @interface RecentStudy
 */
export interface RecentStudy {
    /**
     * 
     * @type {Array<ActionLog>}
     * @memberof RecentStudy
     */
    actionLogs: Array<ActionLog>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。  # その他  * `startDatetime`を指定しなかった場合、デフォルト値は2週間前となる。(`endDatetime`は指定していた場合、`endDatetime`の2週間前) * `updatedBy`の昇順で得られる。
         * @summary アクションログリストを取得する。
         * @param {string} [startDatetime] 検索開始日時。指定しなかった場合、デフォルト値は1週間前となる。(&#x60;endDatetime&#x60;は指定していた場合、&#x60;endDatetime&#x60;の1週間前)
         * @param {string} [endDatetime] 検索終了日時。
         * @param {Array<string>} [studentUserId] 児童生徒ID。複数指定可。
         * @param {string} [subjectUserType] 行為者ユーザータイプ。
         * @param {string} [subjectUserId] 行為者ユーザーID。
         * @param {string} [objectService] 行為対象サービス。
         * @param {string} [objectResourceName] 行為対象リソース名。
         * @param {boolean} [ignoreObjectResourceNameToDeduplicate] trueの場合、objectResourceNameの差は無視して、studentUserId, subjectUserId, objectService, dataがすべて一致する、updatedAtがの差が1時間以内のActionLogは、一番最後のもの以外は捨てる( &#x3D; 返却値に含めない)。例えば、 10:15, 10:50, 11:42, 12:43 が重複していれば、 11:42, 12:43 の2個だけを返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActionLog: async (startDatetime?: string, endDatetime?: string, studentUserId?: Array<string>, subjectUserType?: string, subjectUserId?: string, objectService?: string, objectResourceName?: string, ignoreObjectResourceNameToDeduplicate?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/actionLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDatetime !== undefined) {
                localVarQueryParameter['startDatetime'] = startDatetime;
            }

            if (endDatetime !== undefined) {
                localVarQueryParameter['endDatetime'] = endDatetime;
            }

            if (studentUserId) {
                localVarQueryParameter['studentUserId'] = studentUserId;
            }

            if (subjectUserType !== undefined) {
                localVarQueryParameter['subjectUserType'] = subjectUserType;
            }

            if (subjectUserId !== undefined) {
                localVarQueryParameter['subjectUserId'] = subjectUserId;
            }

            if (objectService !== undefined) {
                localVarQueryParameter['objectService'] = objectService;
            }

            if (objectResourceName !== undefined) {
                localVarQueryParameter['objectResourceName'] = objectResourceName;
            }

            if (ignoreObjectResourceNameToDeduplicate !== undefined) {
                localVarQueryParameter['ignoreObjectResourceNameToDeduplicate'] = ignoreObjectResourceNameToDeduplicate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # 概要  最近の学習リストを取得する。最近の学習とは、「最近の学習」欄に表示しやすくするためにアクションログをグループ化したものに過ぎない。  # ユーザー別制約  * 児童生徒と保護者   * 取得不可。 * 教師   * なんでも取得可能。
         * @summary 最近の学習リストを取得する。
         * @param {string} [startDatetime] 検索開始日時。指定しなかった場合、デフォルト値は1週間前となる。(&#x60;endDatetime&#x60;は指定していた場合、&#x60;endDatetime&#x60;の1週間前)
         * @param {string} [endDatetime] 検索終了日時。
         * @param {Array<string>} [studentUserId] 児童生徒ID。複数指定可。
         * @param {string} [subjectUserType] 行為者ユーザータイプ。
         * @param {string} [subjectUserId] 行為者ユーザーID。
         * @param {string} [objectService] 行為対象サービス。
         * @param {string} [objectResourceName] 行為対象リソース名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecentStudies: async (startDatetime?: string, endDatetime?: string, studentUserId?: Array<string>, subjectUserType?: string, subjectUserId?: string, objectService?: string, objectResourceName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/:listRecentStudies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDatetime !== undefined) {
                localVarQueryParameter['startDatetime'] = startDatetime;
            }

            if (endDatetime !== undefined) {
                localVarQueryParameter['endDatetime'] = endDatetime;
            }

            if (studentUserId) {
                localVarQueryParameter['studentUserId'] = studentUserId;
            }

            if (subjectUserType !== undefined) {
                localVarQueryParameter['subjectUserType'] = subjectUserType;
            }

            if (subjectUserId !== undefined) {
                localVarQueryParameter['subjectUserId'] = subjectUserId;
            }

            if (objectService !== undefined) {
                localVarQueryParameter['objectService'] = objectService;
            }

            if (objectResourceName !== undefined) {
                localVarQueryParameter['objectResourceName'] = objectResourceName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pub/Subからのメッセージを処理するエンドポイント。  ユーザーが呼び出すことはできない。  # ユーザー別制約  誰も呼び出すことはできない。(Pub/Subサービスによる呼出のみ)
         * @summary Pub/Subからのメッセージを処理する。
         * @param {PubsubMessage} [pubsubMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagePush: async (pubsubMessage?: PubsubMessage, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/messagePush`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubsubMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。  # その他  * `startDatetime`を指定しなかった場合、デフォルト値は2週間前となる。(`endDatetime`は指定していた場合、`endDatetime`の2週間前) * `updatedBy`の昇順で得られる。
         * @summary アクションログリストを取得する。
         * @param {string} [startDatetime] 検索開始日時。指定しなかった場合、デフォルト値は1週間前となる。(&#x60;endDatetime&#x60;は指定していた場合、&#x60;endDatetime&#x60;の1週間前)
         * @param {string} [endDatetime] 検索終了日時。
         * @param {Array<string>} [studentUserId] 児童生徒ID。複数指定可。
         * @param {string} [subjectUserType] 行為者ユーザータイプ。
         * @param {string} [subjectUserId] 行為者ユーザーID。
         * @param {string} [objectService] 行為対象サービス。
         * @param {string} [objectResourceName] 行為対象リソース名。
         * @param {boolean} [ignoreObjectResourceNameToDeduplicate] trueの場合、objectResourceNameの差は無視して、studentUserId, subjectUserId, objectService, dataがすべて一致する、updatedAtがの差が1時間以内のActionLogは、一番最後のもの以外は捨てる( &#x3D; 返却値に含めない)。例えば、 10:15, 10:50, 11:42, 12:43 が重複していれば、 11:42, 12:43 の2個だけを返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActionLog(startDatetime?: string, endDatetime?: string, studentUserId?: Array<string>, subjectUserType?: string, subjectUserId?: string, objectService?: string, objectResourceName?: string, ignoreObjectResourceNameToDeduplicate?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActionLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActionLog(startDatetime, endDatetime, studentUserId, subjectUserType, subjectUserId, objectService, objectResourceName, ignoreObjectResourceNameToDeduplicate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # 概要  最近の学習リストを取得する。最近の学習とは、「最近の学習」欄に表示しやすくするためにアクションログをグループ化したものに過ぎない。  # ユーザー別制約  * 児童生徒と保護者   * 取得不可。 * 教師   * なんでも取得可能。
         * @summary 最近の学習リストを取得する。
         * @param {string} [startDatetime] 検索開始日時。指定しなかった場合、デフォルト値は1週間前となる。(&#x60;endDatetime&#x60;は指定していた場合、&#x60;endDatetime&#x60;の1週間前)
         * @param {string} [endDatetime] 検索終了日時。
         * @param {Array<string>} [studentUserId] 児童生徒ID。複数指定可。
         * @param {string} [subjectUserType] 行為者ユーザータイプ。
         * @param {string} [subjectUserId] 行為者ユーザーID。
         * @param {string} [objectService] 行為対象サービス。
         * @param {string} [objectResourceName] 行為対象リソース名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRecentStudies(startDatetime?: string, endDatetime?: string, studentUserId?: Array<string>, subjectUserType?: string, subjectUserId?: string, objectService?: string, objectResourceName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecentStudy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRecentStudies(startDatetime, endDatetime, studentUserId, subjectUserType, subjectUserId, objectService, objectResourceName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Pub/Subからのメッセージを処理するエンドポイント。  ユーザーが呼び出すことはできない。  # ユーザー別制約  誰も呼び出すことはできない。(Pub/Subサービスによる呼出のみ)
         * @summary Pub/Subからのメッセージを処理する。
         * @param {PubsubMessage} [pubsubMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async messagePush(pubsubMessage?: PubsubMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.messagePush(pubsubMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。  # その他  * `startDatetime`を指定しなかった場合、デフォルト値は2週間前となる。(`endDatetime`は指定していた場合、`endDatetime`の2週間前) * `updatedBy`の昇順で得られる。
         * @summary アクションログリストを取得する。
         * @param {string} [startDatetime] 検索開始日時。指定しなかった場合、デフォルト値は1週間前となる。(&#x60;endDatetime&#x60;は指定していた場合、&#x60;endDatetime&#x60;の1週間前)
         * @param {string} [endDatetime] 検索終了日時。
         * @param {Array<string>} [studentUserId] 児童生徒ID。複数指定可。
         * @param {string} [subjectUserType] 行為者ユーザータイプ。
         * @param {string} [subjectUserId] 行為者ユーザーID。
         * @param {string} [objectService] 行為対象サービス。
         * @param {string} [objectResourceName] 行為対象リソース名。
         * @param {boolean} [ignoreObjectResourceNameToDeduplicate] trueの場合、objectResourceNameの差は無視して、studentUserId, subjectUserId, objectService, dataがすべて一致する、updatedAtがの差が1時間以内のActionLogは、一番最後のもの以外は捨てる( &#x3D; 返却値に含めない)。例えば、 10:15, 10:50, 11:42, 12:43 が重複していれば、 11:42, 12:43 の2個だけを返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActionLog(startDatetime?: string, endDatetime?: string, studentUserId?: Array<string>, subjectUserType?: string, subjectUserId?: string, objectService?: string, objectResourceName?: string, ignoreObjectResourceNameToDeduplicate?: boolean, options?: any): AxiosPromise<Array<ActionLog>> {
            return localVarFp.listActionLog(startDatetime, endDatetime, studentUserId, subjectUserType, subjectUserId, objectService, objectResourceName, ignoreObjectResourceNameToDeduplicate, options).then((request) => request(axios, basePath));
        },
        /**
         * # 概要  最近の学習リストを取得する。最近の学習とは、「最近の学習」欄に表示しやすくするためにアクションログをグループ化したものに過ぎない。  # ユーザー別制約  * 児童生徒と保護者   * 取得不可。 * 教師   * なんでも取得可能。
         * @summary 最近の学習リストを取得する。
         * @param {string} [startDatetime] 検索開始日時。指定しなかった場合、デフォルト値は1週間前となる。(&#x60;endDatetime&#x60;は指定していた場合、&#x60;endDatetime&#x60;の1週間前)
         * @param {string} [endDatetime] 検索終了日時。
         * @param {Array<string>} [studentUserId] 児童生徒ID。複数指定可。
         * @param {string} [subjectUserType] 行為者ユーザータイプ。
         * @param {string} [subjectUserId] 行為者ユーザーID。
         * @param {string} [objectService] 行為対象サービス。
         * @param {string} [objectResourceName] 行為対象リソース名。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecentStudies(startDatetime?: string, endDatetime?: string, studentUserId?: Array<string>, subjectUserType?: string, subjectUserId?: string, objectService?: string, objectResourceName?: string, options?: any): AxiosPromise<Array<RecentStudy>> {
            return localVarFp.listRecentStudies(startDatetime, endDatetime, studentUserId, subjectUserType, subjectUserId, objectService, objectResourceName, options).then((request) => request(axios, basePath));
        },
        /**
         * Pub/Subからのメッセージを処理するエンドポイント。  ユーザーが呼び出すことはできない。  # ユーザー別制約  誰も呼び出すことはできない。(Pub/Subサービスによる呼出のみ)
         * @summary Pub/Subからのメッセージを処理する。
         * @param {PubsubMessage} [pubsubMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messagePush(pubsubMessage?: PubsubMessage, options?: any): AxiosPromise<void> {
            return localVarFp.messagePush(pubsubMessage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。  # その他  * `startDatetime`を指定しなかった場合、デフォルト値は2週間前となる。(`endDatetime`は指定していた場合、`endDatetime`の2週間前) * `updatedBy`の昇順で得られる。
     * @summary アクションログリストを取得する。
     * @param {string} [startDatetime] 検索開始日時。指定しなかった場合、デフォルト値は1週間前となる。(&#x60;endDatetime&#x60;は指定していた場合、&#x60;endDatetime&#x60;の1週間前)
     * @param {string} [endDatetime] 検索終了日時。
     * @param {Array<string>} [studentUserId] 児童生徒ID。複数指定可。
     * @param {string} [subjectUserType] 行為者ユーザータイプ。
     * @param {string} [subjectUserId] 行為者ユーザーID。
     * @param {string} [objectService] 行為対象サービス。
     * @param {string} [objectResourceName] 行為対象リソース名。
     * @param {boolean} [ignoreObjectResourceNameToDeduplicate] trueの場合、objectResourceNameの差は無視して、studentUserId, subjectUserId, objectService, dataがすべて一致する、updatedAtがの差が1時間以内のActionLogは、一番最後のもの以外は捨てる( &#x3D; 返却値に含めない)。例えば、 10:15, 10:50, 11:42, 12:43 が重複していれば、 11:42, 12:43 の2個だけを返却する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listActionLog(startDatetime?: string, endDatetime?: string, studentUserId?: Array<string>, subjectUserType?: string, subjectUserId?: string, objectService?: string, objectResourceName?: string, ignoreObjectResourceNameToDeduplicate?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listActionLog(startDatetime, endDatetime, studentUserId, subjectUserType, subjectUserId, objectService, objectResourceName, ignoreObjectResourceNameToDeduplicate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # 概要  最近の学習リストを取得する。最近の学習とは、「最近の学習」欄に表示しやすくするためにアクションログをグループ化したものに過ぎない。  # ユーザー別制約  * 児童生徒と保護者   * 取得不可。 * 教師   * なんでも取得可能。
     * @summary 最近の学習リストを取得する。
     * @param {string} [startDatetime] 検索開始日時。指定しなかった場合、デフォルト値は1週間前となる。(&#x60;endDatetime&#x60;は指定していた場合、&#x60;endDatetime&#x60;の1週間前)
     * @param {string} [endDatetime] 検索終了日時。
     * @param {Array<string>} [studentUserId] 児童生徒ID。複数指定可。
     * @param {string} [subjectUserType] 行為者ユーザータイプ。
     * @param {string} [subjectUserId] 行為者ユーザーID。
     * @param {string} [objectService] 行為対象サービス。
     * @param {string} [objectResourceName] 行為対象リソース名。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRecentStudies(startDatetime?: string, endDatetime?: string, studentUserId?: Array<string>, subjectUserType?: string, subjectUserId?: string, objectService?: string, objectResourceName?: string, options?: any) {
        return DefaultApiFp(this.configuration).listRecentStudies(startDatetime, endDatetime, studentUserId, subjectUserType, subjectUserId, objectService, objectResourceName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pub/Subからのメッセージを処理するエンドポイント。  ユーザーが呼び出すことはできない。  # ユーザー別制約  誰も呼び出すことはできない。(Pub/Subサービスによる呼出のみ)
     * @summary Pub/Subからのメッセージを処理する。
     * @param {PubsubMessage} [pubsubMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public messagePush(pubsubMessage?: PubsubMessage, options?: any) {
        return DefaultApiFp(this.configuration).messagePush(pubsubMessage, options).then((request) => request(this.axios, this.basePath));
    }
}



/* tslint:disable */
/* eslint-disable */
/**
 * プロジェクト学習サービス
 * プロジェクト学習に関する処理を行う。
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponse
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message?: string;
}
/**
 * 値とハッシュ値のペア。 複数人による多重編集を検知したい値に関しては、これを用いる。
 * @export
 * @interface HashedValue
 */
export interface HashedValue {
    /**
     * 値。
     * @type {string}
     * @memberof HashedValue
     */
    value: string;
    /**
     * ハッシュ値。変更を検知できさえすれば良いので、`value`が常に非常に短い場合は、`value`と全く同じ値でも良い。  新規作成時も必須だが、値は無視される（空文字列で良い）。
     * @type {string}
     * @memberof HashedValue
     */
    hash: string;
}
/**
 * 明示的な作成、削除はできない。  ルーブリックAと児童生徒Bが存在し、ルーブリックAと児童生徒Bが同じクラスに所属している場合、（データとしての実体が存在するかに関わらず）本エンティティは必ず存在する。それ以外の場合、必ず存在しない。
 * @export
 * @interface Journal
 */
export interface Journal {
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    self: string;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    journalId: string;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    project: string;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    rubric: string;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    studentUserId: string;
    /**
     * 
     * @type {Array<JournalFile>}
     * @memberof Journal
     */
    files: Array<JournalFile>;
    /**
     * 
     * @type {HashedValue}
     * @memberof Journal
     */
    studentComment: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    studentRating: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof Journal
     */
    teacherComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof Journal
     */
    teacherRating?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Journal
     */
    teacherInputPublished: boolean;
    /**
     * `true`の場合、これを取得したユーザーが児童生徒入力値(`studentComment`, `studentRating`, プロジェクト学習記録ファイル)を更新できない(リクエストしても403エラーになる)ことを示す。
     * @type {boolean}
     * @memberof Journal
     */
    studentInputLocked: boolean;
}
/**
 * 所属するプロジェクト学習記録が存在しない（削除された）とき、本エンティティも（明示的に削除しなくても）存在しない。
 * @export
 * @interface JournalFile
 */
export interface JournalFile {
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    self: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    journalFileId: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    project: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    journal: string;
    /**
     * ファイルのタイプ。 image / video / audio / slides / any
     * @type {string}
     * @memberof JournalFile
     */
    type: string;
    /**
     * ファイルのサブタイプ。 image -> jpeg / png ... slides -> powerpoint / keynote ... ...
     * @type {string}
     * @memberof JournalFile
     */
    subtype: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    mediaType: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    ext: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    gcsObjectPath: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    thumbnailGcsObjectPath?: string;
    /**
     * 画像等の場合のみ値がある。正確な値ではない可能性があるので注意。
     * @type {number}
     * @memberof JournalFile
     */
    width?: number;
    /**
     * 画像等の場合のみ値がある。正確な値ではない可能性があるので注意。
     * @type {number}
     * @memberof JournalFile
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof JournalFile
     */
    updatedAt: string;
    /**
     * サムネイルが存在すれば`true`。未作成の場合や、そもそもサムネイル不要なファイルタイプの場合は`false`。
     * @type {boolean}
     * @memberof JournalFile
     */
    hasThumbnail: boolean;
}
/**
 * 
 * @export
 * @interface JournalWrite
 */
export interface JournalWrite {
    /**
     * 
     * @type {string}
     * @memberof JournalWrite
     */
    rubric?: string;
    /**
     * 
     * @type {string}
     * @memberof JournalWrite
     */
    studentUserId?: string;
    /**
     * 
     * @type {Array<JournalFile>}
     * @memberof JournalWrite
     */
    files?: Array<JournalFile>;
    /**
     * 
     * @type {HashedValue}
     * @memberof JournalWrite
     */
    studentComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof JournalWrite
     */
    studentRating?: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof JournalWrite
     */
    teacherComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof JournalWrite
     */
    teacherRating?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JournalWrite
     */
    teacherInputPublished?: boolean;
}
/**
 * 明示的な作成、削除はできない。  プロジェクトAと児童生徒Bが存在し、プロジェクトAと児童生徒Bが同じクラスに所属している場合、（データとしての実体が存在するかに関わらず）本エンティティは必ず存在する。それ以外の場合、必ず存在しない。
 * @export
 * @interface Lookback
 */
export interface Lookback {
    /**
     * 
     * @type {string}
     * @memberof Lookback
     */
    self: string;
    /**
     * 
     * @type {string}
     * @memberof Lookback
     */
    lookbackId?: string;
    /**
     * 
     * @type {string}
     * @memberof Lookback
     */
    project: string;
    /**
     * 
     * @type {string}
     * @memberof Lookback
     */
    studentUserId: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof Lookback
     */
    studentComment: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof Lookback
     */
    studentRating: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof Lookback
     */
    teacherComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof Lookback
     */
    teacherRating?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Lookback
     */
    teacherInputPublished: boolean;
    /**
     * 
     * @type {HashedValue}
     * @memberof Lookback
     */
    guardianComment: HashedValue;
    /**
     * `true`の場合、これを取得したユーザーが児童生徒入力値(`studentComment`, `studentRating`)を更新できない(リクエストしても403エラーになる)ことを示す。
     * @type {boolean}
     * @memberof Lookback
     */
    studentInputLocked: boolean;
    /**
     * `true`の場合、これを取得したユーザーが保護者入力値(`guardianComment`)を更新できない(リクエストしても403エラーになる)ことを示す。
     * @type {boolean}
     * @memberof Lookback
     */
    guardianInputLocked: boolean;
}
/**
 * 
 * @export
 * @interface LookbackWrite
 */
export interface LookbackWrite {
    /**
     * 
     * @type {string}
     * @memberof LookbackWrite
     */
    studentUserId?: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof LookbackWrite
     */
    studentComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof LookbackWrite
     */
    studentRating?: string;
    /**
     * 
     * @type {HashedValue}
     * @memberof LookbackWrite
     */
    teacherComment?: HashedValue;
    /**
     * 
     * @type {string}
     * @memberof LookbackWrite
     */
    teacherRating?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LookbackWrite
     */
    teacherInputPublished?: boolean;
    /**
     * 
     * @type {HashedValue}
     * @memberof LookbackWrite
     */
    guardianComment?: HashedValue;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    self: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    projectId: string;
    /**
     * 作成時入力必須。更新不可。  ※ openapi-generator/typescript-axiosのバグにより、ここを\"class\"というネーミングにすることが不可能（勝手に\"_class\"に変えられる）
     * @type {string}
     * @memberof Project
     */
    classId: string;
    /**
     * 
     * @type {Quarter}
     * @memberof Project
     */
    quarter: Quarter;
    /**
     * 
     * @type {HashedValue}
     * @memberof Project
     */
    name: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Project
     */
    description: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Project
     */
    relatedSyllabus: HashedValue;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    viewPointSEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    viewPointAEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    viewPointBEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    viewPointCEnabled: boolean;
    /**
     * 
     * @type {YearMonth}
     * @memberof Project
     */
    startingMonth: YearMonth;
    /**
     * 
     * @type {YearMonth}
     * @memberof Project
     */
    endingMonth: YearMonth;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    published: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    completed: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    rubrics: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface ProjectWrite
 */
export interface ProjectWrite {
    /**
     * 作成時入力必須。更新不可。  ※ openapi-generator/typescript-axiosのバグにより、ここを\"class\"というネーミングにすることが不可能（勝手に\"_class\"に変えられる）
     * @type {string}
     * @memberof ProjectWrite
     */
    classId?: string;
    /**
     * 
     * @type {Quarter}
     * @memberof ProjectWrite
     */
    quarter?: Quarter;
    /**
     * 
     * @type {HashedValue}
     * @memberof ProjectWrite
     */
    name?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof ProjectWrite
     */
    description?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof ProjectWrite
     */
    relatedSyllabus?: HashedValue;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWrite
     */
    viewPointSEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWrite
     */
    viewPointAEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWrite
     */
    viewPointBEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWrite
     */
    viewPointCEnabled?: boolean;
    /**
     * 
     * @type {YearMonth}
     * @memberof ProjectWrite
     */
    startingMonth?: YearMonth;
    /**
     * 
     * @type {YearMonth}
     * @memberof ProjectWrite
     */
    endingMonth?: YearMonth;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWrite
     */
    published?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWrite
     */
    completed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWrite
     */
    rubrics?: Array<string>;
}
/**
 * クォーター。学期。
 * @export
 * @interface Quarter
 */
export interface Quarter {
    /**
     * 
     * @type {number}
     * @memberof Quarter
     */
    schoolYear: number;
    /**
     * 
     * @type {number}
     * @memberof Quarter
     */
    quarter: number;
}
/**
 * 所属するプロジェクトが存在しない（削除された）とき、本エンティティも（明示的に削除しなくても）存在しない。
 * @export
 * @interface Rubric
 */
export interface Rubric {
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    self: string;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    rubricId?: string;
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    project: string;
    /**
     * 0始まり。
     * @type {number}
     * @memberof Rubric
     */
    orderNum: number;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    learningActivity: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    viewPointS: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    viewPointA: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    viewPointB: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof Rubric
     */
    viewPointC: HashedValue;
}
/**
 * 
 * @export
 * @interface RubricWrite
 */
export interface RubricWrite {
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    learningActivity?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    viewPointS?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    viewPointA?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    viewPointB?: HashedValue;
    /**
     * 
     * @type {HashedValue}
     * @memberof RubricWrite
     */
    viewPointC?: HashedValue;
}
/**
 * 年と月のタプル。
 * @export
 * @interface YearMonth
 */
export interface YearMonth {
    /**
     * 
     * @type {number}
     * @memberof YearMonth
     */
    year: number;
    /**
     * 1 ～ 12
     * @type {number}
     * @memberof YearMonth
     */
    month: number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ削除可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ削除可能。   * かつ、以下の条件を満たす場合のみ削除可能。     * 紐づくプロジェクトの`completed`が`false`。     * 紐づく学習記録の`teacherInputPublished`が`false`。 * 教師   * すべて削除可能。
         * @summary プロジェクト学習記録ファイルを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalFile: async (projectId: string, rubricId: string, journalId: string, journalFileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteJournalFile', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('deleteJournalFile', 'rubricId', rubricId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('deleteJournalFile', 'journalId', journalId)
            // verify required parameter 'journalFileId' is not null or undefined
            assertParamExists('deleteJournalFile', 'journalFileId', journalFileId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}/journals/{journalId}/journalFiles/{journalFileId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)))
                .replace(`{${"journalFileId"}}`, encodeURIComponent(String(journalFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary プロジェクトを削除する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary プロジェクトルーブリックを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRubric: async (projectId: string, rubricId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteRubric', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('deleteRubric', 'rubricId', rubricId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクト学習記録を取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {boolean} [expandFiles] 返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournal: async (projectId: string, rubricId: string, journalId: string, expandFiles?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getJournal', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('getJournal', 'rubricId', rubricId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('getJournal', 'journalId', journalId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}/journals/{journalId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expandFiles !== undefined) {
                localVarQueryParameter['expandFiles'] = expandFiles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ取得可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary プロジェクト学習記録ファイルのメタデータを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalFile: async (projectId: string, rubricId: string, journalId: string, journalFileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getJournalFile', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('getJournalFile', 'rubricId', rubricId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('getJournalFile', 'journalId', journalId)
            // verify required parameter 'journalFileId' is not null or undefined
            assertParamExists('getJournalFile', 'journalFileId', journalFileId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}/journals/{journalId}/journalFiles/{journalFileId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)))
                .replace(`{${"journalFileId"}}`, encodeURIComponent(String(journalFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトふりかえりを取得する。
         * @param {string} projectId 
         * @param {string} lookbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLookback: async (projectId: string, lookbackId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getLookback', 'projectId', projectId)
            // verify required parameter 'lookbackId' is not null or undefined
            assertParamExists('getLookback', 'lookbackId', lookbackId)
            const localVarPath = `/projects/{projectId}/lookbacks/{lookbackId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"lookbackId"}}`, encodeURIComponent(String(lookbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * `published`が`false`の場合、NotFoundを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * 非公開プロジェクトに属するものは取得できない。 * 教師   * なんでも取得可能。
         * @summary プロジェクトルーブリックを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubric: async (projectId: string, rubricId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getRubric', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('getRubric', 'rubricId', rubricId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。  # その他  プロジェクトエンティティ内のrubricsフィールドで指定した並び順で得られる。その他のフィールドについての並び順は特に決まりはない。
         * @summary プロジェクト学習記録リストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} [studentUserId] 児童生徒ユーザーID。
         * @param {boolean} [expandFiles] 返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournal: async (projectId: string, rubricId: string, studentUserId?: string, expandFiles?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listJournal', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('listJournal', 'rubricId', rubricId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}/journals`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (studentUserId !== undefined) {
                localVarQueryParameter['studentUserId'] = studentUserId;
            }

            if (expandFiles !== undefined) {
                localVarQueryParameter['expandFiles'] = expandFiles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ取得可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。その場合、`journalId`も`-`である必要がある。 * `journalId`   * `-`を渡すと、学習記録を指定せずに取得する。  # その他  * ルーブリックの並び順 -> クラス内児童生徒番号順 -> 作成日時昇順 で得られる。
         * @summary プロジェクト学習記録ファイルのメタデータリストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournalFile: async (projectId: string, rubricId: string, journalId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listJournalFile', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('listJournalFile', 'rubricId', rubricId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('listJournalFile', 'journalId', journalId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}/journals/{journalId}/journalFiles`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトふりかえりリストを取得する。
         * @param {string} projectId 
         * @param {string} [studentUserId] 児童生徒ユーザーID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLookback: async (projectId: string, studentUserId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listLookback', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/lookbacks`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (studentUserId !== undefined) {
                localVarQueryParameter['studentUserId'] = studentUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * `purblished`が`false`のものは取得できない。 * 教師   * なんでも取得可能。
         * @summary プロジェクトリストを取得する。
         * @param {Array<string>} classId 
         * @param {number} [schoolYear] &#x60;schoolYear&#x60;と&#x60;quarter&#x60;は、指定するなら必ず両方指定しなければならない。
         * @param {number} [quarter] &#x60;schoolYear&#x60;と&#x60;quarter&#x60;は、指定するなら必ず両方指定しなければならない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProject: async (classId: Array<string>, schoolYear?: number, quarter?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('listProject', 'classId', classId)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (classId) {
                localVarQueryParameter['classId'] = classId;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }

            if (quarter !== undefined) {
                localVarQueryParameter['quarter'] = quarter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * 非公開プロジェクトに属するものは取得できない。 * 教師   * なんでも取得可能。  # その他  * プロジェクトエンティティ内のrubricsフィールドで指定した並び順で得られる。
         * @summary プロジェクトルーブリックリストを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRubric: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('listRubric', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/rubrics`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ更新可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ更新可能。   * かつ、以下の条件を満たす場合のみ更新可能。     * 紐づくプロジェクトの`completed`が`false`。     * `teacherInputPublished`が`false`。   * 更新可能フィールドは、`studentComment`、`studentRating`。   * `teacherInputPublished`が`false`の場合、返却値について、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * すべて更新可能。   * 更新可能フィールドは、`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`。
         * @summary プロジェクト学習記録を更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJournal: async (projectId: string, rubricId: string, journalId: string, journalWrite?: JournalWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchJournal', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('patchJournal', 'rubricId', rubricId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('patchJournal', 'journalId', journalId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}/journals/{journalId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(journalWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ更新可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ更新可能。   * かつ、紐づくプロジェクトの`completed`が`false`の場合のみ更新可能。   * 更新可能フィールドは`studentComment`、`studentRating`。保護者のみ、`guardianComment`も更新可能。   * `teacherInputPublished`が`true`の場合、`studentComment`、`studentRating`は更新不可。   * `teacherInputPublished`が`false`の場合、返却値について、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * すべて更新可能。   * 更新可能フィールドは`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`、`guardianComment`。
         * @summary プロジェクトふりかえりを更新する。
         * @param {string} projectId 
         * @param {string} lookbackId 
         * @param {LookbackWrite} [lookbackWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLookback: async (projectId: string, lookbackId: string, lookbackWrite?: LookbackWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchLookback', 'projectId', projectId)
            // verify required parameter 'lookbackId' is not null or undefined
            assertParamExists('patchLookback', 'lookbackId', lookbackId)
            const localVarPath = `/projects/{projectId}/lookbacks/{lookbackId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"lookbackId"}}`, encodeURIComponent(String(lookbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lookbackWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。   * 更新可能フィールドは、`name`、`description`、`relatedSyllabus`、`viewPointSEnabled`、`viewPointAEnabled`、`viewPointBEnabled`、`viewPointCEnabled`、`startingMonth`、`endingMonth`、`published`、`completed`、`rubrics`。  # 制約  * `published`フィールドは、`true`から`false`に変更することはできない。 * `rubrics`フィールドに関しては、並び替えのみ可。それ以外の操作をすると、エラーとなる。
         * @summary プロジェクトを更新する。
         * @param {string} projectId 
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject: async (projectId: string, projectWrite?: ProjectWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchProject', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。   * 更新可能フィールドは、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`。  # その他  `orderNum`を更新したければ、SOLANプロジェクトの`rubricIds`を更新する。
         * @summary プロジェクトルーブリックを更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRubric: async (projectId: string, rubricId: string, rubricWrite?: RubricWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchRubric', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('patchRubric', 'rubricId', rubricId)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rubricWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分である場合のみアップロード可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒である場合のみアップロード可能。   * かつ、以下の条件を満たす場合のみアップロード可能。     * 紐づくプロジェクトの`completed`が`false`。     * 紐づく学習記録の`teacherInputPublished`が`false`。 * 教師   * いつでもアップロード可能。
         * @summary プロジェクト学習記録ファイルをアップロードする。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {any} upfile アップロードするファイル。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournalFile: async (projectId: string, rubricId: string, journalId: string, upfile: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postJournalFile', 'projectId', projectId)
            // verify required parameter 'rubricId' is not null or undefined
            assertParamExists('postJournalFile', 'rubricId', rubricId)
            // verify required parameter 'journalId' is not null or undefined
            assertParamExists('postJournalFile', 'journalId', journalId)
            // verify required parameter 'upfile' is not null or undefined
            assertParamExists('postJournalFile', 'upfile', upfile)
            const localVarPath = `/projects/{projectId}/rubrics/{rubricId}/journals/{journalId}/journalFiles`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"rubricId"}}`, encodeURIComponent(String(rubricId)))
                .replace(`{${"journalId"}}`, encodeURIComponent(String(journalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (upfile !== undefined) { 
                localVarFormParams.append('upfile', upfile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。      # 制約  * 入力可能フィールドは、`rubrics`を除くすべてのフィールド。 * 入力必須フィールドは、`classId`、`quarter`。
         * @summary プロジェクトを作成する。
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject: async (projectWrite?: ProjectWrite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。  # その他  プロジェクトエンティティ内のrubricsフィールドの末尾に追加される。
         * @summary プロジェクトルーブリックを作成する。
         * @param {string} projectId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRubric: async (projectId: string, rubricWrite?: RubricWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postRubric', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/rubrics`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rubricWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ削除可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ削除可能。   * かつ、以下の条件を満たす場合のみ削除可能。     * 紐づくプロジェクトの`completed`が`false`。     * 紐づく学習記録の`teacherInputPublished`が`false`。 * 教師   * すべて削除可能。
         * @summary プロジェクト学習記録ファイルを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJournalFile(projectId: string, rubricId: string, journalId: string, journalFileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJournalFile(projectId, rubricId, journalId, journalFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary プロジェクトを削除する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary プロジェクトルーブリックを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRubric(projectId: string, rubricId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRubric(projectId, rubricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクト学習記録を取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {boolean} [expandFiles] 返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournal(projectId: string, rubricId: string, journalId: string, expandFiles?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Journal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournal(projectId, rubricId, journalId, expandFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ取得可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary プロジェクト学習記録ファイルのメタデータを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJournalFile(projectId: string, rubricId: string, journalId: string, journalFileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalFile(projectId, rubricId, journalId, journalFileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトふりかえりを取得する。
         * @param {string} projectId 
         * @param {string} lookbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLookback(projectId: string, lookbackId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lookback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLookback(projectId, lookbackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * `published`が`false`の場合、NotFoundを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * 非公開プロジェクトに属するものは取得できない。 * 教師   * なんでも取得可能。
         * @summary プロジェクトルーブリックを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRubric(projectId: string, rubricId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRubric(projectId, rubricId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。  # その他  プロジェクトエンティティ内のrubricsフィールドで指定した並び順で得られる。その他のフィールドについての並び順は特に決まりはない。
         * @summary プロジェクト学習記録リストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} [studentUserId] 児童生徒ユーザーID。
         * @param {boolean} [expandFiles] 返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJournal(projectId: string, rubricId: string, studentUserId?: string, expandFiles?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Journal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJournal(projectId, rubricId, studentUserId, expandFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ取得可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。その場合、`journalId`も`-`である必要がある。 * `journalId`   * `-`を渡すと、学習記録を指定せずに取得する。  # その他  * ルーブリックの並び順 -> クラス内児童生徒番号順 -> 作成日時昇順 で得られる。
         * @summary プロジェクト学習記録ファイルのメタデータリストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJournalFile(projectId: string, rubricId: string, journalId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JournalFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJournalFile(projectId, rubricId, journalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトふりかえりリストを取得する。
         * @param {string} projectId 
         * @param {string} [studentUserId] 児童生徒ユーザーID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLookback(projectId: string, studentUserId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lookback>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLookback(projectId, studentUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * `purblished`が`false`のものは取得できない。 * 教師   * なんでも取得可能。
         * @summary プロジェクトリストを取得する。
         * @param {Array<string>} classId 
         * @param {number} [schoolYear] &#x60;schoolYear&#x60;と&#x60;quarter&#x60;は、指定するなら必ず両方指定しなければならない。
         * @param {number} [quarter] &#x60;schoolYear&#x60;と&#x60;quarter&#x60;は、指定するなら必ず両方指定しなければならない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProject(classId: Array<string>, schoolYear?: number, quarter?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProject(classId, schoolYear, quarter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * 非公開プロジェクトに属するものは取得できない。 * 教師   * なんでも取得可能。  # その他  * プロジェクトエンティティ内のrubricsフィールドで指定した並び順で得られる。
         * @summary プロジェクトルーブリックリストを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRubric(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rubric>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRubric(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ更新可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ更新可能。   * かつ、以下の条件を満たす場合のみ更新可能。     * 紐づくプロジェクトの`completed`が`false`。     * `teacherInputPublished`が`false`。   * 更新可能フィールドは、`studentComment`、`studentRating`。   * `teacherInputPublished`が`false`の場合、返却値について、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * すべて更新可能。   * 更新可能フィールドは、`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`。
         * @summary プロジェクト学習記録を更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchJournal(projectId: string, rubricId: string, journalId: string, journalWrite?: JournalWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Journal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchJournal(projectId, rubricId, journalId, journalWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ更新可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ更新可能。   * かつ、紐づくプロジェクトの`completed`が`false`の場合のみ更新可能。   * 更新可能フィールドは`studentComment`、`studentRating`。保護者のみ、`guardianComment`も更新可能。   * `teacherInputPublished`が`true`の場合、`studentComment`、`studentRating`は更新不可。   * `teacherInputPublished`が`false`の場合、返却値について、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * すべて更新可能。   * 更新可能フィールドは`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`、`guardianComment`。
         * @summary プロジェクトふりかえりを更新する。
         * @param {string} projectId 
         * @param {string} lookbackId 
         * @param {LookbackWrite} [lookbackWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLookback(projectId: string, lookbackId: string, lookbackWrite?: LookbackWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lookback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLookback(projectId, lookbackId, lookbackWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。   * 更新可能フィールドは、`name`、`description`、`relatedSyllabus`、`viewPointSEnabled`、`viewPointAEnabled`、`viewPointBEnabled`、`viewPointCEnabled`、`startingMonth`、`endingMonth`、`published`、`completed`、`rubrics`。  # 制約  * `published`フィールドは、`true`から`false`に変更することはできない。 * `rubrics`フィールドに関しては、並び替えのみ可。それ以外の操作をすると、エラーとなる。
         * @summary プロジェクトを更新する。
         * @param {string} projectId 
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProject(projectId: string, projectWrite?: ProjectWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProject(projectId, projectWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。   * 更新可能フィールドは、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`。  # その他  `orderNum`を更新したければ、SOLANプロジェクトの`rubricIds`を更新する。
         * @summary プロジェクトルーブリックを更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchRubric(projectId: string, rubricId: string, rubricWrite?: RubricWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchRubric(projectId, rubricId, rubricWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分である場合のみアップロード可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒である場合のみアップロード可能。   * かつ、以下の条件を満たす場合のみアップロード可能。     * 紐づくプロジェクトの`completed`が`false`。     * 紐づく学習記録の`teacherInputPublished`が`false`。 * 教師   * いつでもアップロード可能。
         * @summary プロジェクト学習記録ファイルをアップロードする。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {any} upfile アップロードするファイル。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postJournalFile(projectId: string, rubricId: string, journalId: string, upfile: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postJournalFile(projectId, rubricId, journalId, upfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。      # 制約  * 入力可能フィールドは、`rubrics`を除くすべてのフィールド。 * 入力必須フィールドは、`classId`、`quarter`。
         * @summary プロジェクトを作成する。
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProject(projectWrite?: ProjectWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProject(projectWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。  # その他  プロジェクトエンティティ内のrubricsフィールドの末尾に追加される。
         * @summary プロジェクトルーブリックを作成する。
         * @param {string} projectId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRubric(projectId: string, rubricWrite?: RubricWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRubric(projectId, rubricWrite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ削除可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ削除可能。   * かつ、以下の条件を満たす場合のみ削除可能。     * 紐づくプロジェクトの`completed`が`false`。     * 紐づく学習記録の`teacherInputPublished`が`false`。 * 教師   * すべて削除可能。
         * @summary プロジェクト学習記録ファイルを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJournalFile(projectId: string, rubricId: string, journalId: string, journalFileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteJournalFile(projectId, rubricId, journalId, journalFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary プロジェクトを削除する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
         * @summary プロジェクトルーブリックを削除する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRubric(projectId: string, rubricId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRubric(projectId, rubricId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクト学習記録を取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {boolean} [expandFiles] 返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournal(projectId: string, rubricId: string, journalId: string, expandFiles?: boolean, options?: any): AxiosPromise<Journal> {
            return localVarFp.getJournal(projectId, rubricId, journalId, expandFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ取得可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary プロジェクト学習記録ファイルのメタデータを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {string} journalFileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJournalFile(projectId: string, rubricId: string, journalId: string, journalFileId: string, options?: any): AxiosPromise<JournalFile> {
            return localVarFp.getJournalFile(projectId, rubricId, journalId, journalFileId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトふりかえりを取得する。
         * @param {string} projectId 
         * @param {string} lookbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLookback(projectId: string, lookbackId: string, options?: any): AxiosPromise<Lookback> {
            return localVarFp.getLookback(projectId, lookbackId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * `published`が`false`の場合、NotFoundを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * 非公開プロジェクトに属するものは取得できない。 * 教師   * なんでも取得可能。
         * @summary プロジェクトルーブリックを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRubric(projectId: string, rubricId: string, options?: any): AxiosPromise<Rubric> {
            return localVarFp.getRubric(projectId, rubricId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。  # その他  プロジェクトエンティティ内のrubricsフィールドで指定した並び順で得られる。その他のフィールドについての並び順は特に決まりはない。
         * @summary プロジェクト学習記録リストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} [studentUserId] 児童生徒ユーザーID。
         * @param {boolean} [expandFiles] 返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournal(projectId: string, rubricId: string, studentUserId?: string, expandFiles?: boolean, options?: any): AxiosPromise<Array<Journal>> {
            return localVarFp.listJournal(projectId, rubricId, studentUserId, expandFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ取得可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。その場合、`journalId`も`-`である必要がある。 * `journalId`   * `-`を渡すと、学習記録を指定せずに取得する。  # その他  * ルーブリックの並び順 -> クラス内児童生徒番号順 -> 作成日時昇順 で得られる。
         * @summary プロジェクト学習記録ファイルのメタデータリストを取得する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournalFile(projectId: string, rubricId: string, journalId: string, options?: any): AxiosPromise<Array<JournalFile>> {
            return localVarFp.listJournalFile(projectId, rubricId, journalId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
         * @summary プロジェクトふりかえりリストを取得する。
         * @param {string} projectId 
         * @param {string} [studentUserId] 児童生徒ユーザーID。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLookback(projectId: string, studentUserId?: string, options?: any): AxiosPromise<Array<Lookback>> {
            return localVarFp.listLookback(projectId, studentUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * `purblished`が`false`のものは取得できない。 * 教師   * なんでも取得可能。
         * @summary プロジェクトリストを取得する。
         * @param {Array<string>} classId 
         * @param {number} [schoolYear] &#x60;schoolYear&#x60;と&#x60;quarter&#x60;は、指定するなら必ず両方指定しなければならない。
         * @param {number} [quarter] &#x60;schoolYear&#x60;と&#x60;quarter&#x60;は、指定するなら必ず両方指定しなければならない。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProject(classId: Array<string>, schoolYear?: number, quarter?: number, options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.listProject(classId, schoolYear, quarter, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * 非公開プロジェクトに属するものは取得できない。 * 教師   * なんでも取得可能。  # その他  * プロジェクトエンティティ内のrubricsフィールドで指定した並び順で得られる。
         * @summary プロジェクトルーブリックリストを取得する。
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRubric(projectId: string, options?: any): AxiosPromise<Array<Rubric>> {
            return localVarFp.listRubric(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ更新可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ更新可能。   * かつ、以下の条件を満たす場合のみ更新可能。     * 紐づくプロジェクトの`completed`が`false`。     * `teacherInputPublished`が`false`。   * 更新可能フィールドは、`studentComment`、`studentRating`。   * `teacherInputPublished`が`false`の場合、返却値について、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * すべて更新可能。   * 更新可能フィールドは、`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`。
         * @summary プロジェクト学習記録を更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {JournalWrite} [journalWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchJournal(projectId: string, rubricId: string, journalId: string, journalWrite?: JournalWrite, options?: any): AxiosPromise<Journal> {
            return localVarFp.patchJournal(projectId, rubricId, journalId, journalWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ更新可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ更新可能。   * かつ、紐づくプロジェクトの`completed`が`false`の場合のみ更新可能。   * 更新可能フィールドは`studentComment`、`studentRating`。保護者のみ、`guardianComment`も更新可能。   * `teacherInputPublished`が`true`の場合、`studentComment`、`studentRating`は更新不可。   * `teacherInputPublished`が`false`の場合、返却値について、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * すべて更新可能。   * 更新可能フィールドは`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`、`guardianComment`。
         * @summary プロジェクトふりかえりを更新する。
         * @param {string} projectId 
         * @param {string} lookbackId 
         * @param {LookbackWrite} [lookbackWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLookback(projectId: string, lookbackId: string, lookbackWrite?: LookbackWrite, options?: any): AxiosPromise<Lookback> {
            return localVarFp.patchLookback(projectId, lookbackId, lookbackWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。   * 更新可能フィールドは、`name`、`description`、`relatedSyllabus`、`viewPointSEnabled`、`viewPointAEnabled`、`viewPointBEnabled`、`viewPointCEnabled`、`startingMonth`、`endingMonth`、`published`、`completed`、`rubrics`。  # 制約  * `published`フィールドは、`true`から`false`に変更することはできない。 * `rubrics`フィールドに関しては、並び替えのみ可。それ以外の操作をすると、エラーとなる。
         * @summary プロジェクトを更新する。
         * @param {string} projectId 
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject(projectId: string, projectWrite?: ProjectWrite, options?: any): AxiosPromise<Project> {
            return localVarFp.patchProject(projectId, projectWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。   * 更新可能フィールドは、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`。  # その他  `orderNum`を更新したければ、SOLANプロジェクトの`rubricIds`を更新する。
         * @summary プロジェクトルーブリックを更新する。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchRubric(projectId: string, rubricId: string, rubricWrite?: RubricWrite, options?: any): AxiosPromise<Rubric> {
            return localVarFp.patchRubric(projectId, rubricId, rubricWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分である場合のみアップロード可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒である場合のみアップロード可能。   * かつ、以下の条件を満たす場合のみアップロード可能。     * 紐づくプロジェクトの`completed`が`false`。     * 紐づく学習記録の`teacherInputPublished`が`false`。 * 教師   * いつでもアップロード可能。
         * @summary プロジェクト学習記録ファイルをアップロードする。
         * @param {string} projectId 
         * @param {string} rubricId 
         * @param {string} journalId 
         * @param {any} upfile アップロードするファイル。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postJournalFile(projectId: string, rubricId: string, journalId: string, upfile: any, options?: any): AxiosPromise<JournalFile> {
            return localVarFp.postJournalFile(projectId, rubricId, journalId, upfile, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。      # 制約  * 入力可能フィールドは、`rubrics`を除くすべてのフィールド。 * 入力必須フィールドは、`classId`、`quarter`。
         * @summary プロジェクトを作成する。
         * @param {ProjectWrite} [projectWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject(projectWrite?: ProjectWrite, options?: any): AxiosPromise<Project> {
            return localVarFp.postProject(projectWrite, options).then((request) => request(axios, basePath));
        },
        /**
         * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。  # その他  プロジェクトエンティティ内のrubricsフィールドの末尾に追加される。
         * @summary プロジェクトルーブリックを作成する。
         * @param {string} projectId 
         * @param {RubricWrite} [rubricWrite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRubric(projectId: string, rubricWrite?: RubricWrite, options?: any): AxiosPromise<Rubric> {
            return localVarFp.postRubric(projectId, rubricWrite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ削除可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ削除可能。   * かつ、以下の条件を満たす場合のみ削除可能。     * 紐づくプロジェクトの`completed`が`false`。     * 紐づく学習記録の`teacherInputPublished`が`false`。 * 教師   * すべて削除可能。
     * @summary プロジェクト学習記録ファイルを削除する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} journalId 
     * @param {string} journalFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteJournalFile(projectId: string, rubricId: string, journalId: string, journalFileId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteJournalFile(projectId, rubricId, journalId, journalFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
     * @summary プロジェクトを削除する。
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProject(projectId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。
     * @summary プロジェクトルーブリックを削除する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRubric(projectId: string, rubricId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteRubric(projectId, rubricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
     * @summary プロジェクト学習記録を取得する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} journalId 
     * @param {boolean} [expandFiles] 返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJournal(projectId: string, rubricId: string, journalId: string, expandFiles?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).getJournal(projectId, rubricId, journalId, expandFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ取得可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
     * @summary プロジェクト学習記録ファイルのメタデータを取得する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} journalId 
     * @param {string} journalFileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJournalFile(projectId: string, rubricId: string, journalId: string, journalFileId: string, options?: any) {
        return DefaultApiFp(this.configuration).getJournalFile(projectId, rubricId, journalId, journalFileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
     * @summary プロジェクトふりかえりを取得する。
     * @param {string} projectId 
     * @param {string} lookbackId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLookback(projectId: string, lookbackId: string, options?: any) {
        return DefaultApiFp(this.configuration).getLookback(projectId, lookbackId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * `published`が`false`の場合、NotFoundを返す。 * 教師   * なんでも取得可能。
     * @summary プロジェクトを取得する。
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProject(projectId: string, options?: any) {
        return DefaultApiFp(this.configuration).getProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * 非公開プロジェクトに属するものは取得できない。 * 教師   * なんでも取得可能。
     * @summary プロジェクトルーブリックを取得する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRubric(projectId: string, rubricId: string, options?: any) {
        return DefaultApiFp(this.configuration).getRubric(projectId, rubricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。  # その他  プロジェクトエンティティ内のrubricsフィールドで指定した並び順で得られる。その他のフィールドについての並び順は特に決まりはない。
     * @summary プロジェクト学習記録リストを取得する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} [studentUserId] 児童生徒ユーザーID。
     * @param {boolean} [expandFiles] 返却値の&#x60;files&#x60;フィールドが、学習記録ファイル詳細を返す。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listJournal(projectId: string, rubricId: string, studentUserId?: string, expandFiles?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listJournal(projectId, rubricId, studentUserId, expandFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分であるもののみ取得可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。  # パスパラメータ  * `rubricId`   * `-`を渡すと、ルーブリックを指定せずに取得する。その場合、`journalId`も`-`である必要がある。 * `journalId`   * `-`を渡すと、学習記録を指定せずに取得する。  # その他  * ルーブリックの並び順 -> クラス内児童生徒番号順 -> 作成日時昇順 で得られる。
     * @summary プロジェクト学習記録ファイルのメタデータリストを取得する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} journalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listJournalFile(projectId: string, rubricId: string, journalId: string, options?: any) {
        return DefaultApiFp(this.configuration).listJournalFile(projectId, rubricId, journalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。   * `teacherInputPublished`が`false`の場合、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * なんでも取得可能。
     * @summary プロジェクトふりかえりリストを取得する。
     * @param {string} projectId 
     * @param {string} [studentUserId] 児童生徒ユーザーID。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listLookback(projectId: string, studentUserId?: string, options?: any) {
        return DefaultApiFp(this.configuration).listLookback(projectId, studentUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * `purblished`が`false`のものは取得できない。 * 教師   * なんでも取得可能。
     * @summary プロジェクトリストを取得する。
     * @param {Array<string>} classId 
     * @param {number} [schoolYear] &#x60;schoolYear&#x60;と&#x60;quarter&#x60;は、指定するなら必ず両方指定しなければならない。
     * @param {number} [quarter] &#x60;schoolYear&#x60;と&#x60;quarter&#x60;は、指定するなら必ず両方指定しなければならない。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProject(classId: Array<string>, schoolYear?: number, quarter?: number, options?: any) {
        return DefaultApiFp(this.configuration).listProject(classId, schoolYear, quarter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、自分が所属するクラスのもののみ取得可能。   * 保護者は、自分に属する児童生徒が所属するクラスのもののみ取得可能。   * 非公開プロジェクトに属するものは取得できない。 * 教師   * なんでも取得可能。  # その他  * プロジェクトエンティティ内のrubricsフィールドで指定した並び順で得られる。
     * @summary プロジェクトルーブリックリストを取得する。
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRubric(projectId: string, options?: any) {
        return DefaultApiFp(this.configuration).listRubric(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ更新可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ更新可能。   * かつ、以下の条件を満たす場合のみ更新可能。     * 紐づくプロジェクトの`completed`が`false`。     * `teacherInputPublished`が`false`。   * 更新可能フィールドは、`studentComment`、`studentRating`。   * `teacherInputPublished`が`false`の場合、返却値について、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * すべて更新可能。   * 更新可能フィールドは、`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`。
     * @summary プロジェクト学習記録を更新する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} journalId 
     * @param {JournalWrite} [journalWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchJournal(projectId: string, rubricId: string, journalId: string, journalWrite?: JournalWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchJournal(projectId, rubricId, journalId, journalWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ更新可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ更新可能。   * かつ、紐づくプロジェクトの`completed`が`false`の場合のみ更新可能。   * 更新可能フィールドは`studentComment`、`studentRating`。保護者のみ、`guardianComment`も更新可能。   * `teacherInputPublished`が`true`の場合、`studentComment`、`studentRating`は更新不可。   * `teacherInputPublished`が`false`の場合、返却値について、`teacherComment`と`teacherRating`は、実際の値に関わらず、必ずnullを返す。 * 教師   * すべて更新可能。   * 更新可能フィールドは`studentComment`、`studentRating`、`teacherComment`、`teacherRating`、`teacherInputPublished`、`guardianComment`。
     * @summary プロジェクトふりかえりを更新する。
     * @param {string} projectId 
     * @param {string} lookbackId 
     * @param {LookbackWrite} [lookbackWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchLookback(projectId: string, lookbackId: string, lookbackWrite?: LookbackWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchLookback(projectId, lookbackId, lookbackWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。   * 更新可能フィールドは、`name`、`description`、`relatedSyllabus`、`viewPointSEnabled`、`viewPointAEnabled`、`viewPointBEnabled`、`viewPointCEnabled`、`startingMonth`、`endingMonth`、`published`、`completed`、`rubrics`。  # 制約  * `published`フィールドは、`true`から`false`に変更することはできない。 * `rubrics`フィールドに関しては、並び替えのみ可。それ以外の操作をすると、エラーとなる。
     * @summary プロジェクトを更新する。
     * @param {string} projectId 
     * @param {ProjectWrite} [projectWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchProject(projectId: string, projectWrite?: ProjectWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchProject(projectId, projectWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。   * 更新可能フィールドは、`learningActivity`、`viewPointS`、`viewPointA`、`viewPointB`、`viewPointC`。  # その他  `orderNum`を更新したければ、SOLANプロジェクトの`rubricIds`を更新する。
     * @summary プロジェクトルーブリックを更新する。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {RubricWrite} [rubricWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchRubric(projectId: string, rubricId: string, rubricWrite?: RubricWrite, options?: any) {
        return DefaultApiFp(this.configuration).patchRubric(projectId, rubricId, rubricWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 児童生徒は、学習記録（`journalId`）の`studentUserId`が自分である場合のみアップロード可能。   * 保護者は、学習記録（`journalId`）の`studentUserId`が自分に属する児童生徒である場合のみアップロード可能。   * かつ、以下の条件を満たす場合のみアップロード可能。     * 紐づくプロジェクトの`completed`が`false`。     * 紐づく学習記録の`teacherInputPublished`が`false`。 * 教師   * いつでもアップロード可能。
     * @summary プロジェクト学習記録ファイルをアップロードする。
     * @param {string} projectId 
     * @param {string} rubricId 
     * @param {string} journalId 
     * @param {any} upfile アップロードするファイル。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postJournalFile(projectId: string, rubricId: string, journalId: string, upfile: any, options?: any) {
        return DefaultApiFp(this.configuration).postJournalFile(projectId, rubricId, journalId, upfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。      # 制約  * 入力可能フィールドは、`rubrics`を除くすべてのフィールド。 * 入力必須フィールドは、`classId`、`quarter`。
     * @summary プロジェクトを作成する。
     * @param {ProjectWrite} [projectWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProject(projectWrite?: ProjectWrite, options?: any) {
        return DefaultApiFp(this.configuration).postProject(projectWrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * # ユーザー別制約  * 児童生徒と保護者   * 使用不可。 * 教師   * いつでも使用可能。  # その他  プロジェクトエンティティ内のrubricsフィールドの末尾に追加される。
     * @summary プロジェクトルーブリックを作成する。
     * @param {string} projectId 
     * @param {RubricWrite} [rubricWrite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postRubric(projectId: string, rubricWrite?: RubricWrite, options?: any) {
        return DefaultApiFp(this.configuration).postRubric(projectId, rubricWrite, options).then((request) => request(this.axios, this.basePath));
    }
}











import Vue from "vue";

export default Vue.extend({
  name: "ProfileInnerHeaderUserName",
  props: {
    photoUrl: { type: String, required: false },
    name: { type: String, required: true },
  },
});

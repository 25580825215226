import { Module, VuexModule } from "vuex-module-decorators";
import { store } from "@/store/Store";
import { appStateStoreGlobal } from "@/store/AppStateStore";
import { isProfileTabName, ProfileTabName } from "@/ts/objects/profile/value/ProfileTabName";
import { isNullish } from "@/ts/utils";
import log from "loglevel";

export type ProfileRoute = {
  readonly tab: ProfileTabName | null;
};

/**
 * プロフィールストア。
 * 保護者専用。
 */
@Module({ dynamic: true, store, name: "profileStore", namespaced: true })
export class ProfileStore extends VuexModule {
  get currentPath(): string {
    return appStateStoreGlobal.route.path;
  }

  get currentRoute(): ProfileRoute {
    const name = appStateStoreGlobal.route.name;
    const m = /^S\.Profile\.(\w+)/.exec(name);
    if (isNullish(m)) {
      log.debug(`ProfileStore.currentRoute: Failed.`);
      return { tab: null };
    }

    const tab = isProfileTabName(m[1]) ? m[1] : null;
    return { tab };
  }

  /**
   * 選択中のタブ。
   * 書く/見る/未選択(null)。
   */
  get tab(): ProfileTabName | null {
    return this.currentRoute.tab;
  }

  get studentTabPath(): string {
    return `${appStateStoreGlobal.studentOrGuardianBasePath}/profile/student`;
  }

  get guardianTabPath(): string {
    return `${appStateStoreGlobal.studentOrGuardianBasePath}/profile/guardian`;
  }
}

import { FirestoreTimestampJson, isFirestoreTimestampJson } from "@/ts/JsonObjects";
import log from "loglevel";

const twoDigitsFormatter = new Intl.NumberFormat("ja-JP", {
  minimumIntegerDigits: 2,
  useGrouping: false,
});

export class ActivityLog {
  readonly uuid: string;
  readonly isMine: boolean;
  readonly userId: string;
  readonly studentUserId: string;
  readonly classId: string | null;
  readonly createdAt: Date;
  readonly type: ActivityLogType;
  readonly text: string;
  readonly deleteFlg: boolean;
  readonly file: File;
  readonly advanced: Advanced;
  readonly imageMeta: ActivityLogImageMeta;

  constructor(
    uuid: string,
    isMine: boolean,
    userId: string,
    studentUserId: string,
    classId: string | null,
    createdAt: Date,
    type: ActivityLogType,
    text: string,
    deleteFlg: boolean,
    file: File,
    advanced: Advanced,
    imageMeta: ActivityLogImageMeta
  ) {
    this.uuid = uuid;
    this.isMine = isMine;
    this.userId = userId;
    this.studentUserId = studentUserId; // TODO 個人情報leakでありうるかも。キャスト時に消す意味は特に無いので、この項目を消したいなら、取得自体できなくすること。
    this.classId = classId;
    this.createdAt = createdAt;
    this.type = type;
    this.text = text;
    this.deleteFlg = deleteFlg;
    this.file = file;
    this.advanced = advanced;
    this.imageMeta = imageMeta;
  }

  createdAtString(): string {
    const month = twoDigitsFormatter.format(this.createdAt.getMonth() + 1);
    const date = twoDigitsFormatter.format(this.createdAt.getDate());
    const hours = twoDigitsFormatter.format(this.createdAt.getHours());
    const minutes = twoDigitsFormatter.format(this.createdAt.getMinutes());
    return `${month}/${date} ${hours}:${minutes}`;
  }
}

const activityLogTypes = ["text", "image", "stamp", "video", "audio", "other"] as const;
type ActivityLogType = typeof activityLogTypes[number];

export class Advanced {
  readonly mylistedUsers: string[];
  readonly readUsers: string[];
  readonly mylisted: boolean;
  readonly read: boolean;

  constructor(mylistedUsers: string[], readUsers: string[], mylisted: boolean, read: boolean) {
    this.mylistedUsers = mylistedUsers;
    this.readUsers = readUsers;
    this.mylisted = mylisted;
    this.read = read;
  }
}

export class File {
  readonly type: string;
  readonly subType: string;
  readonly gcsObjectPath: string;
  readonly gcsObjectThumbnailPath: string;
  url: string;
  thumbnailUrl: string;
  readonly fileName: string;

  constructor(type: string, subType: string, gcsObjectPath: string, gcsObjectThumbnailPath: string, fileName: string) {
    this.type = type;
    this.subType = subType;
    this.gcsObjectPath = gcsObjectPath;
    // 途中からfirestoreの項目を追加した場合
    if (gcsObjectThumbnailPath === undefined) {
      this.gcsObjectThumbnailPath = "";
    } else {
      this.gcsObjectThumbnailPath = gcsObjectThumbnailPath;
    }
    this.url = "";
    this.thumbnailUrl = "";

    this.fileName = fileName;
  }
}

export type ActivityLogImageMeta = {
  thumbnailUrl: string;
  thumbnailHeight: number;
  thumbnailWidth: number;
};

type ActivityLogJson = {
  uuid: string;
  userId: string;
  studentUserId: string;
  classId: string | null;
  createdAt: FirestoreTimestampJson;
  type: ActivityLogType;
  text: string;
  deleteFlg: boolean;
  file: File;
  advanced: Advanced;
};

export function isActivityLogJson(obj: any): obj is ActivityLogJson {
  return (
    typeof obj.userId === "string" &&
    typeof obj.studentUserId === "string" &&
    isFirestoreTimestampJson(obj.createdAt) &&
    typeof obj.type === "string" &&
    activityLogTypes.includes(obj.type) &&
    typeof obj.text === "string" &&
    typeof obj.deleteFlg === "boolean" &&
    (typeof obj.uuid === "string" || typeof obj.uuid.value === "string") // firestoreと、backendからではobject中身が少し異なる
  );
}

export async function rawToActivityLog(storage: any, obj: any, myUserId: string): Promise<ActivityLog | null> {
  let uuid = obj.uuid;
  if (typeof obj.uuid !== "string") uuid = obj.uuid.value;
  let nanoseconds = obj.createdAt.nanoseconds;
  if (nanoseconds === undefined) nanoseconds = obj.createdAt.nanos;

  if (!isActivityLogJson(obj)) {
    return null;
  }

  const imageMeta: ActivityLogImageMeta = {
    thumbnailUrl: "",
    thumbnailHeight: 0,
    thumbnailWidth: 0,
  };

  const f = new File(
    obj.file.type,
    obj.file.subType,
    obj.file.gcsObjectPath,
    obj.file.gcsObjectThumbnailPath,
    obj.file.fileName
  );

  if (!obj.deleteFlg && obj.type !== "text") {
    const fileRef = storage?.ref(obj.file.gcsObjectPath);
    if (fileRef === undefined) return null;
    const fileUrl = await fileRef.getDownloadURL().catch((e: any) => {
      log.debug(`ActivityView.rawToActivityLog(): error=${JSON.stringify(e)}`);
      return ["", ""];
    });
    f.url = fileUrl;

    if (obj.file.gcsObjectThumbnailPath !== undefined && obj.file.gcsObjectThumbnailPath !== "") {
      const thumbnailRef = storage?.ref(obj.file.gcsObjectThumbnailPath);
      const thumbnailFileUrl = await thumbnailRef.getDownloadURL().catch((e: any) => {
        log.debug(`ActivityView.rawToActivityLog(): error=${JSON.stringify(e)}`);
        return ["", ""];
      });
      f.thumbnailUrl = thumbnailFileUrl;
    }
  }

  const a = new Advanced(
    obj.advanced.mylistedUsers,
    obj.advanced.readUsers,
    obj.advanced.mylistedUsers.includes(myUserId),
    obj.advanced.readUsers.includes(myUserId)
  );

  return new ActivityLog(
    uuid,
    obj.userId === myUserId,
    obj.userId,
    obj.studentUserId,
    obj.classId,
    new Date(obj.createdAt.seconds * 1000 + nanoseconds / 1000 / 1000),
    obj.type,
    obj.text,
    obj.deleteFlg,
    f,
    a,
    imageMeta
  );
}

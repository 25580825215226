
































import Vue, { PropType } from "vue";
import { RecentStudy } from "@/ts/objects/common/RecentStudy";
import TipBlock from "@/components/TipBlock.vue";
import { Quarter } from "@/ts/objects/common/Quarter";
import { words } from "@/ts/const/Words";

type ActionObject = {
  text: string;
  cssClass: string;
  linkPath: string;
};

export default Vue.extend({
  name: "HomeTRecentStudyItem",
  components: { TipBlock },
  props: {
    recentStudy: { type: Object as PropType<RecentStudy>, required: true },
    currentQuarter: { type: Object as PropType<Quarter>, required: true },
    teacherBasePath: { type: String, required: true },
    curriculumDefaultPath: { type: String, required: true },
  },
  computed: {
    actionObject(): ActionObject {
      switch (this.recentStudy.objectService()) {
        case "curriculum":
          return {
            text: words.curriculumLearning.d,
            cssClass: "curriculum",
            linkPath: this.curriculumDefaultPath,
          };
        case "project":
          return {
            text: words.projectLearning.d,
            cssClass: "project",
            linkPath: `${this.teacherBasePath}/project/${this.currentQuarter.schoolYear}/${this.currentQuarter.quarter}/init`,
          };
        case "solan":
          return {
            text: words.solanLearning.d,
            cssClass: "solan",
            linkPath: `${this.teacherBasePath}/solan`,
          };
        default:
          return {
            text: "",
            cssClass: "",
            linkPath: "",
          };
      }
    },
  },
});









































































import Vue, { PropType } from "vue";
import { ProjectStore } from "@/store/ProjectStore";
import { Err } from "@/ts/objects/Err";
import { NavigationGuardNext, Route } from "vue-router";
import { EditableProjectStudent } from "@/ts/objects/project/editable/EditableProjectStudent";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import { ProjectRubric } from "@/ts/objects/project/value/ProjectRubric";
import { Messages, messages } from "@/ts/const/Messages";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { AppStateStore } from "@/store/AppStateStore";
import ErrorNotification, { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import { unparse } from "papaparse";
import { format } from "date-fns";
import PopupMenuButton, { MenuButton } from "@/components/PopupMenuButton.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { downloadBlob, filterNotNullish, isNullish, projectJournalRnameToId } from "@/ts/utils";
import PublishSelectedRowsButton from "@/components/PublishSelectedRowsButton/PublishSelectedRowsButton.vue";
import SaveStateIndicator from "@/components/SaveStateIndicator/SaveStateIndicator.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { ProjectJournalFile } from "@/ts/objects/project/value/ProjectJournalFile";
import { Words, words } from "@/ts/const/Words";
import ColumnFilterSwitches from "@/components/other/ColumnFilterSwitches/ColumnFilterSwitches.vue";
import {
  ProjectJournalsTColFilterState,
  ProjectJournalsTEmptyFilterCheckState,
  ProjectJournalsTRowFilterState,
  ProjectJournalsTStudentRow,
  ProjectJournalsTStyleProps,
} from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsTModel";
import ProjectJournalsTTableHeaderRow from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsTTable/ProjectJournalsTTableHeaderRow/ProjectJournalsTTableHeaderRow.vue";
import { CheckboxItem } from "@/components/FilterableHeaderButton/FilterableHeaderButton.vue";
import ProjectJournalsTTableStudentRow from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsTTable/ProjectJournalsTTableStudentRow/ProjectJournalsTTableStudentRow.vue";

// TODO col-filterを切り替えた時、選択状態が解除されたりされなかったりする。なぜ？

type RowSelectionStateSummary = "all" | "some" | "none";

const stylePropsBase: Omit<ProjectJournalsTStyleProps, "currentJournalRowWidth" | "currentTableWidth"> = {
  studentColWidth: 140,
  learningActivityColWidth: 160,
  journalFilesColWidth: 160,
  studentCommentColWidth: 200,
  studentRatingColWidth: 60,
  studentInputHeaderWidth: 200 + 60,
  teacherRatingColWidth: 60,
  teacherCommentColWidth: 210,
  guardianCommentColWidth: 210,
  teacherInputPublishedColWidth: 107,
  selectionColWidth: 60,
};

export default Vue.extend({
  name: "ProjectJournalsT",
  components: {
    ProjectJournalsTTableStudentRow,
    ProjectJournalsTTableHeaderRow,
    ColumnFilterSwitches,
    SaveStateIndicator,
    PublishSelectedRowsButton,
    LoadingBlock,
    MessageView,
    ErrorNotification,
    PopupMenuButton,
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true },
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
      onRequirementUnmet: async () => {
        this.highlightUnsaved = true;
      },
    });

    this.projectStore.project.getDataWithTimeout().then(project => {
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }
      if (!project.published) {
        this.messageView = { message: messages.projectUnpublished };
        return;
      }

      this.projectRepository
        .listEditableProjectStudents(
          this.userRepository,
          project.projectId,
          this.appStateStore.teacherState?.selectedClass() ?? null,
          true,
          true,
          false
        )
        .then(resp => {
          if (resp instanceof Err) {
            log.debug("Error loading journals!");
            this.messageView = { message: messages.failedToLoadData, fadeIn: true };
            return;
          }

          this.students = resp.editableStudents;
          this.studentRowFilterCheckboxItems = resp.editableStudents.map(student => {
            return {
              key: student.studentUserId,
              label: `${student.studentNumber} ${student.name}`,
            };
          });
          this.rubrics = resp.rubrics;
          this.learningActivityRowFilterCheckboxItems = [
            ...resp.rubrics.map(rubric => {
              return {
                key: rubric.self,
                label: rubric.learningActivity,
              };
            }),
            { key: "lookback", label: words.lookback.d },
          ];
        });
    });
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;

    extraMenuItems: MenuButton[];

    students: EditableProjectStudent[] | null;
    rubrics: ProjectRubric[] | null;

    studentRowFilterCheckboxItems: CheckboxItem[];
    learningActivityRowFilterCheckboxItems: CheckboxItem[];
    teacherRatingRowFilterCheckboxItems: CheckboxItem[];
    teacherCommentRowFilterCheckboxItems: CheckboxItem[];
    guardianCommentRowFilterCheckboxItems: CheckboxItem[];

    rowFilterState: ProjectJournalsTRowFilterState;
    colFilterState: ProjectJournalsTColFilterState;

    studentRows: ProjectJournalsTStudentRow[];

    filesViewJournal: EditableProjectJournal | null;

    highlightUnsaved: boolean;

    pageLeaveService: PageLeaveService | null;

    words: Words;
    messages: Messages;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,

      extraMenuItems: [new MenuButton("exportCsv", messages.exportCurrentStateAsCSV, ["fas", "download"])],

      students: null,
      rubrics: null,

      studentRowFilterCheckboxItems: [],
      learningActivityRowFilterCheckboxItems: [],
      teacherRatingRowFilterCheckboxItems: [
        { key: "empty", label: words.empty.d },
        { key: "hasValue", label: words.filled.d },
      ],
      teacherCommentRowFilterCheckboxItems: [
        { key: "empty", label: words.empty.d },
        { key: "hasValue", label: words.filled.d },
      ],
      guardianCommentRowFilterCheckboxItems: [
        { key: "empty", label: words.empty.d },
        { key: "hasValue", label: words.filled.d },
      ],

      /**
       * 行フィルタの状態。
       */
      rowFilterState: {
        student: {},
        learningActivity: {},
        teacherRating: { empty: true, hasValue: true },
        teacherComment: { empty: true, hasValue: true },
        guardianComment: { empty: true, hasValue: true },
      },

      /**
       * 列フィルタの状態。
       */
      colFilterState: {
        learningActivity: true,
        journalFiles: true,
        ratingsAndComments: true,
        guardianComment: true,
      },

      /**
       * 現在表示中の児童生徒行のリスト。
       */
      studentRows: [],

      filesViewJournal: null,

      highlightUnsaved: false,

      pageLeaveService: null,

      words,
      messages,
    };
  },
  computed: {
    columnFilterSwitchesState(): {
      readonly key: keyof ProjectJournalsTColFilterState;
      readonly label: string;
      readonly active: boolean;
    }[] {
      const _colFilterState = this.colFilterState;
      return [
        { key: "learningActivity", label: words.activity.d, active: _colFilterState.learningActivity },
        { key: "journalFiles", label: words.journalFile.d, active: _colFilterState.journalFiles },
        { key: "ratingsAndComments", label: words.ratingsAndComments.d, active: _colFilterState.ratingsAndComments },
        { key: "guardianComment", label: words.guardianComment.d, active: _colFilterState.guardianComment },
      ];
    },
    filesViewJournalRname(): string | null {
      return this.filesViewJournal?.self ?? null;
    },

    currentJournalRowWidth(): number {
      let width = stylePropsBase.teacherInputPublishedColWidth + stylePropsBase.selectionColWidth;
      if (this.colFilterState.learningActivity) {
        width += stylePropsBase.learningActivityColWidth;
      }
      if (this.colFilterState.journalFiles) {
        width += stylePropsBase.journalFilesColWidth;
      }
      if (this.colFilterState.ratingsAndComments) {
        width +=
          stylePropsBase.studentInputHeaderWidth +
          stylePropsBase.teacherRatingColWidth +
          stylePropsBase.teacherCommentColWidth;
      }
      if (this.colFilterState.guardianComment) {
        width += stylePropsBase.guardianCommentColWidth;
      }
      return width;
    },
    currentTableWidth(): number {
      return stylePropsBase.studentColWidth + this.currentJournalRowWidth;
    },
    projectBasePath(): string {
      return this.projectStore.teacherBasePath;
    },
    styleProps(): ProjectJournalsTStyleProps {
      return {
        ...stylePropsBase,
        currentJournalRowWidth: this.currentJournalRowWidth,
        currentTableWidth: this.currentTableWidth,
      };
    },

    needSave(): boolean {
      log.debug(`ProjectJournalsT: needSave`);
      const students = this.students;
      if (students === null) return false;
      const needSave = students.some(s => s.needSave());
      log.debug(`ProjectJournalsT: needSave=${needSave}`);
      return needSave;
    },
    currentErrors(): ErrorNotificationParam[] {
      return (
        this.students?.flatMap(s =>
          s.currentErrors().map(e => {
            return {
              heading: `${words.studentNumberAbbr} ${s.studentNumber} ${s.name}`,
              text: `${e.message}`,
            };
          })
        ) ?? []
      );
    },
    rowSelectionStateSummary(): RowSelectionStateSummary {
      // TODO この程度重くないとは思うが、要確認？
      const values = this.studentRows.flatMap(sr => {
        const journalSelections = sr.journalRows.map(jr => jr.selected);
        const lookbackSelections = sr.lookbackRow !== null ? [sr.lookbackRow.selected] : [];
        return [...journalSelections, ...lookbackSelections];
      });
      let foundSelected = false;
      let foundUnselected = false;
      for (const v of values) {
        if (v === true) {
          foundSelected = true;
        } else {
          foundUnselected = true;
        }
      }

      if (foundSelected && !foundUnselected) {
        return "all";
      } else if (foundSelected && foundUnselected) {
        return "some";
      } else {
        return "none";
      }
    },
  },
  methods: {
    async saveAll(force: boolean): Promise<SaveResult> {
      const students = this.students;
      if (students === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(students.map(s => s.saveAllChanges(force))));
    },
    onChangeColFilterState(key: keyof ProjectJournalsTColFilterState, active: boolean) {
      this.colFilterState = {
        ...this.colFilterState,
        [key]: active,
      };

      if (key === "journalFiles") this.filesViewJournal = null;
    },
    onStudentRowFilterChange(state: Record<string, boolean>) {
      this.rowFilterState.student = state;
      this.applyRowFilter();
    },
    onLearningActivityRowFilterChange(state: Record<string, boolean>) {
      this.rowFilterState.learningActivity = state;
      this.applyRowFilter();
    },
    onTeacherRatingRowFilterChange(state: ProjectJournalsTEmptyFilterCheckState) {
      this.rowFilterState.teacherRating = state;
      this.applyRowFilter();
    },
    onTeacherCommentRowFilterChange(state: ProjectJournalsTEmptyFilterCheckState) {
      this.rowFilterState.teacherComment = state;
      this.applyRowFilter();
    },
    onGuardianCommentRowFilterChange(state: ProjectJournalsTEmptyFilterCheckState) {
      this.rowFilterState.guardianComment = state;
      this.applyRowFilter();
    },
    applyRowFilter() {
      const students = this.students;
      if (students === null) return [];

      // 画面初期表示時に何度も呼ばれるので、もし重ければなんとかする。
      const rowFilterState = this.rowFilterState;

      const filteredStudents = students.filter(s => rowFilterState.student[s.studentUserId] === true);
      const filteredStudentRows = filteredStudents
        .map(student => {
          return {
            student: student,
            journalRows: student.projectJournals
              .filter(j => shouldDisplayJournal(j, rowFilterState))
              .map(j => {
                return {
                  journal: j,
                  selected: false,
                };
              }),
            lookbackRow: shouldDisplayLookback(student.projectLookback, rowFilterState)
              ? {
                  lookback: student.projectLookback,
                  selected: false,
                }
              : null,
          };
        })
        .filter(fjs => fjs.journalRows.length > 0 || fjs.lookbackRow !== null);
      log.debug(`applyRowFilter!`);
      this.studentRows = filteredStudentRows;
    },
    toggleAllRowSelections() {
      // 参考: https://github.com/vuejs/vue/issues/9535#issuecomment-466217819
      setTimeout(() => {
        const changeTo = this.rowSelectionStateSummary === "none" ? true : false;
        for (const sr of this.studentRows) {
          for (const jr of sr.journalRows) {
            jr.selected = changeTo;
          }
          if (sr.lookbackRow !== null) {
            sr.lookbackRow.selected = changeTo;
          }
        }
      });
    },
    publishSelectedRows() {
      if (this.rowSelectionStateSummary === "none") return;
      changeSelectedRowsPublishState(true, this.studentRows);
      this.saveAll(false);
    },
    unpublishSelectedRows() {
      if (this.rowSelectionStateSummary === "none") return;
      changeSelectedRowsPublishState(false, this.studentRows);
      this.saveAll(false);
    },
    setFilesView(studentUserId: string | null, journalRname: string | null) {
      console.log(`setFilesView: studentUserId=${studentUserId}, journalRname=${journalRname}`);
      if (isNullish(studentUserId) || isNullish(journalRname)) {
        this.filesViewJournal = null;
        return;
      }

      const studentRow: ProjectJournalsTStudentRow | undefined = this.studentRows.find(
        s => s.student.studentUserId === studentUserId
      );
      if (studentRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      const journalRow = studentRow.journalRows.find(j => j.journal.self === journalRname);
      if (journalRow === undefined) {
        this.filesViewJournal = null;
        return;
      }
      this.filesViewJournal = journalRow.journal;
    },
    isMyFilesViewOpen(journalRname: string): boolean {
      const filesViewJournal = this.filesViewJournal;
      if (filesViewJournal === null) return false;
      return filesViewJournal.self === journalRname;
    },
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "exportCsv":
          this.exportCsv();
          break;
      }
    },
    async uploadJournalFile(file: File, journal: EditableProjectJournal) {
      const ids = projectJournalRnameToId(journal.self);
      if (ids === null) return;
      const [projectId, rubricId, journalId] = ids;
      const resp = await this.projectRepository.postJournalFile(projectId, rubricId, journalId, file, 30000);
      log.debug(`uploadFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    async deleteJournalFile(journalFile: JournalFile, journal: EditableProjectJournal) {
      if (!(journalFile instanceof ProjectJournalFile)) return;

      const ids = projectJournalRnameToId(journalFile.journal);
      if (ids === null) {
        log.error(`invalid journal resourceName: ${journalFile.journal}`);
        return;
      }
      const [projectId, rubricId, journalId] = ids;
      const resp = await this.projectRepository.deleteJournalFile(
        projectId,
        rubricId,
        journalId,
        journalFile.journalFileId
      );
      log.debug(`deleteFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    exportCsv() {
      const projectName = this.projectStore.project.data?.name;
      if (isNullish(projectName)) return;
      exportCsv(this.studentRows, this.colFilterState, projectName);
    },
  },
});

function changeSelectedRowsPublishState(changeTo: boolean, studentRows: ProjectJournalsTStudentRow[]) {
  studentRows.forEach(sr => {
    sr.journalRows
      .filter(jr => jr.selected)
      .forEach(jr => {
        jr.journal.teacherInputPublished = changeTo;
      });
    if (sr.lookbackRow !== null && sr.lookbackRow.selected) {
      sr.lookbackRow.lookback.teacherInputPublished = changeTo;
    }
  });
}

function shouldDisplayJournal(
  journal: EditableProjectJournal,
  rowFilterState: ProjectJournalsTRowFilterState
): boolean {
  const learningActivityOk = rowFilterState.learningActivity[journal.rubric];
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && journal.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && journal.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && journal.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && journal.teacherComment !== "");
  const guardianCommentOk = rowFilterState.guardianComment.empty;
  return learningActivityOk && teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function shouldDisplayLookback(
  lookback: EditableProjectLookback,
  rowFilterState: ProjectJournalsTRowFilterState
): boolean {
  const learningActivityOk = rowFilterState.learningActivity["lookback"];
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && lookback.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && lookback.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && lookback.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && lookback.teacherComment !== "");
  const guardianCommentOk =
    (rowFilterState.guardianComment.empty && lookback.guardianComment === "") ||
    (rowFilterState.guardianComment.hasValue && lookback.guardianComment !== "");
  return learningActivityOk && teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function exportCsv(
  studentRows: ProjectJournalsTStudentRow[],
  colFilterState: ProjectJournalsTColFilterState,
  projectName: string
) {
  const csvRows = studentRows.flatMap(s => {
    const journalCsvRows = s.journalRows.map(j => {
      return {
        [words.studentNumber.d]: s.student.studentNumber,
        [words.name.d]: s.student.name,
        [words.activity.d]: j.journal.rubricLearningActivity,
        [words.journalFile.d]: j.journal.journalFiles.length,
        [words.studentComment.d]: j.journal.studentComment,
        [words.studentRating.d]: j.journal.studentRating,
        [words.teacherRating.d]: j.journal.teacherRating,
        [words.teacherComment.d]: j.journal.teacherComment,
        [words.guardianComment.d]: "",
        [words.teacherInputPublished.d]: j.journal.teacherInputPublished ? words.published.d : words.unpublished.d,
      };
    });
    if (s.lookbackRow === null) {
      return journalCsvRows;
    }

    const lookbackRow = {
      [words.studentNumber.d]: s.student.studentNumber,
      [words.name.d]: s.student.name,
      [words.activity.d]: words.lookback.d,
      [words.journalFile.d]: 0,
      [words.studentComment.d]: s.lookbackRow.lookback.studentComment,
      [words.studentRating.d]: s.lookbackRow.lookback.studentRating,
      [words.teacherRating.d]: s.lookbackRow.lookback.teacherRating,
      [words.teacherComment.d]: s.lookbackRow.lookback.teacherComment,
      [words.guardianComment.d]: s.lookbackRow.lookback.guardianComment,
      [words.teacherInputPublished.d]: s.lookbackRow.lookback.teacherInputPublished
        ? words.published.d
        : words.unpublished.d,
    };

    return [...journalCsvRows, lookbackRow];
  });
  const columnNames = filterNotNullish([
    words.studentNumber.d,
    words.name.d,
    colFilterState.learningActivity ? words.activity.d : undefined,
    colFilterState.journalFiles ? words.journalFile.d : undefined,
    colFilterState.ratingsAndComments ? words.studentComment.d : undefined,
    colFilterState.ratingsAndComments ? words.studentRating.d : undefined,
    colFilterState.ratingsAndComments ? words.teacherRating.d : undefined,
    colFilterState.ratingsAndComments ? words.teacherComment.d : undefined,
    colFilterState.guardianComment ? words.guardianComment.d : undefined,
    words.teacherInputPublished.d,
  ]);

  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, unparse(csvRows, { columns: columnNames })], { type: "text/plain" });
  downloadBlob(blob, `${projectName}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`);
}

/**
 * SOLANプロジェクト(テーマ)。
 *
 * リソース名は、 /solanProjects/{projectId}。
 */
export class SolanProject {
  constructor(
    public readonly projectId: string,
    public readonly studentUserId: string,
    public readonly schoolYear: number,
    public readonly name: string,
    public readonly description: string,
    public readonly started: boolean,
    public readonly completed: boolean,
    public readonly studentInputLocked: boolean
  ) {}
}

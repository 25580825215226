

















import Vue, { PropType } from "vue";
import { format } from "date-fns";
import { ActionLog } from "@/ts/objects/common/ActionLog";
import {
  eecJournalFileRnameToId,
  eecJournalRnameToId,
  necEvaluationRnameToId,
  rnameToRnameObject,
} from "@/ts/utils/AppUtil";
import log from "loglevel";
import last from "lodash/last";
import { Quarter } from "@/ts/objects/common/Quarter";
import { CurriculumTabName } from "@/ts/objects/curriculum/value/CurriculumTabName";
import { CurriculumPeriodMode } from "@/ts/objects/curriculum/value/CurriculumPeriodMode";
import { isNullish } from "@/ts/utils";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";
import { words } from "@/ts/const/Words";
import { MonthValue } from "@/ts/objects/common/MonthValue";

type ActionObject = {
  text: string;
  cssClass: string;
  linkPath: string | null;
};

export default Vue.extend({
  name: "ActionLogRow",
  components: { RouterLinkWrapper },
  props: {
    actionLog: { type: Object as PropType<ActionLog>, required: true },
    studentOrGuardianBasePath: { type: String, required: true },
    currentQuarter: { type: Object as PropType<Quarter>, required: true },

    getCurriculumPath: {
      type: Function as PropType<
        (
          schoolYear: number | null,
          tab: CurriculumTabName,
          periodMode: CurriculumPeriodMode | null,
          month: MonthValue | null | undefined,
          resourceName: string | null
        ) => string
      >,
      required: true,
    },
    curriculumDefaultPath: { type: String, required: true },
  },
  computed: {
    dateText(): string {
      return format(this.actionLog.updatedAt, "M/d");
    },
    timeText(): string {
      return format(this.actionLog.updatedAt, "H:mm");
    },
    subjectUserName(): string {
      if (this.actionLog.subjectUserType === "guardian") return words.guardian.d;
      return this.actionLog.subjectUserName;
    },
    actionObject(): ActionObject {
      const actionLog = this.actionLog;
      const studentOrGuardianBasePath = this.studentOrGuardianBasePath;
      const currentQuarter = this.currentQuarter;
      const getCurriculumPath = this.getCurriculumPath;
      const curriculumDefaultPath = this.curriculumDefaultPath;

      switch (actionLog.objectService) {
        case "curriculum":
          return toCurriculumActionObject(actionLog, getCurriculumPath, curriculumDefaultPath);
        case "project":
          return toProjectActionObject(actionLog, studentOrGuardianBasePath, currentQuarter);
        case "solan":
          return toSolanActionObject(actionLog, studentOrGuardianBasePath, currentQuarter);
        default:
          return {
            text: "",
            cssClass: "",
            linkPath: null,
          };
      }
    },
  },
});

function toCurriculumActionObject(
  actionLog: ActionLog,
  getCurriculumPath: (
    schoolYear: number | null,
    tab: CurriculumTabName,
    periodMode: CurriculumPeriodMode | null,
    month: MonthValue | null | undefined,
    resourceName: string | null
  ) => string,
  curriculumDefaultPath: string
): ActionObject {
  const partialResult = {
    text: words.curriculumLearning.d,
    cssClass: "curriculum",
  };

  const schoolYear = actionLog.meta.schoolYear;
  const month = actionLog.meta.month;
  if (isNullish(schoolYear) || isNullish(month)) {
    return {
      ...partialResult,
      linkPath: curriculumDefaultPath,
    };
  }

  const maybeJournalRname = eecJournalRnameToId(actionLog.objectResourceName);
  if (maybeJournalRname !== null) {
    return {
      ...partialResult,
      linkPath: getCurriculumPath(schoolYear, "read", "monthly", month, `/eeCurriculums/${maybeJournalRname.eecId}`),
    };
  }

  const maybeJournalFileRname = eecJournalFileRnameToId(actionLog.objectResourceName);
  if (maybeJournalFileRname !== null) {
    return {
      ...partialResult,
      linkPath: getCurriculumPath(
        schoolYear,
        "read",
        "monthly",
        month,
        `/eeCurriculums/${maybeJournalFileRname.eecId}`
      ),
    };
  }

  const maybeEvaluationRname = necEvaluationRnameToId(actionLog.objectResourceName);
  if (maybeEvaluationRname !== null) {
    return {
      ...partialResult,
      linkPath: getCurriculumPath(schoolYear, "read", "monthly", month, `/neCurriculums/${maybeEvaluationRname.necId}`),
    };
  }

  return {
    ...partialResult,
    linkPath: curriculumDefaultPath,
  };
}

function toProjectActionObject(
  actionLog: ActionLog,
  studentOrGuardianBasePath: string,
  currentQuarter: Quarter
): ActionObject {
  const rnameObject = rnameToRnameObject(actionLog.objectResourceName);
  log.debug(`rnameObject=${JSON.stringify(rnameObject)}, updatedPropertyCategory=${actionLog.updatedPropertyCategory}`);

  let linkPathTail = "";
  if (rnameObject === null || rnameObject.fragments.length < 2) {
    linkPathTail = "init";
  } else {
    linkPathTail = rnameObject.fragments[0].resourceId;
    const subCollectionId = last(rnameObject.fragments)?.collectionId;
    switch (subCollectionId) {
      case "lookbacks":
        linkPathTail += "/lookback";
        break;
      case "journals":
        linkPathTail += "/journals";
        break;
      case "journalFiles":
        linkPathTail += "/journals";
        break;
      default:
        break;
    }
  }

  return {
    text: "Projects",
    cssClass: "project",
    linkPath: `${studentOrGuardianBasePath}/project/${currentQuarter.schoolYear}/${currentQuarter.quarter}/${linkPathTail}`,
  };
}

function toSolanActionObject(
  actionLog: ActionLog,
  studentOrGuardianBasePath: string,
  currentQuarter: Quarter
): ActionObject {
  const rnameObject = rnameToRnameObject(actionLog.objectResourceName);
  log.debug(`rnameObject=${JSON.stringify(rnameObject)}, updatedPropertyCategory=${actionLog.updatedPropertyCategory}`);

  let linkPathTail = "";
  if (rnameObject === null || rnameObject.fragments.length === 0) {
    linkPathTail = "init";
  } else if (rnameObject.fragments.length === 1) {
    linkPathTail = rnameObject.fragments[0].resourceId;
    if (actionLog.updatedPropertyCategory === "lookback") {
      linkPathTail += "/lookback";
    } else {
      linkPathTail += "/about";
    }
  } else {
    linkPathTail = rnameObject.fragments[0].resourceId;
    const subCollectionId = last(rnameObject.fragments)?.collectionId;
    switch (subCollectionId) {
      case "rubrics":
        if (actionLog.updatedPropertyCategory === "journal") {
          linkPathTail += "/journals";
        } else {
          linkPathTail += "/rubrics";
        }
        break;
      case "journalFiles":
        linkPathTail += "/journals";
        break;
      default:
        break;
    }
  }

  return {
    text: "SOLAN Inquiry",
    cssClass: "solan",
    linkPath: `${studentOrGuardianBasePath}/solan/${currentQuarter.schoolYear}/${linkPathTail}`,
  };
}

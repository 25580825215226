













import Vue, { PropType } from "vue";
import QuarterSwitchItem from "@/components/QuarterSwitch/QuarterSwitchItem.vue";
import { MonthValue } from "@/ts/objects/common/MonthValue";

export const quarterSwitchValues = ["all", "q1", "q2", "q3", "q4"] as const;
export type QuarterSwitchValue = typeof quarterSwitchValues[number];

export function quarterSwitchValueToMonths(quarterSwitchValue: QuarterSwitchValue): readonly MonthValue[] {
  switch (quarterSwitchValue) {
    case "all":
      return [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
    case "q1":
      return [4, 5, 6];
    case "q2":
      return [7, 8, 9];
    case "q3":
      return [10, 11, 12];
    case "q4":
      return [1, 2, 3];
    default:
      return [];
  }
}

export default Vue.extend({
  name: "QuarterSwitch",
  components: { QuarterSwitchItem },
  props: {
    value: { type: String as PropType<QuarterSwitchValue>, required: true },
  },
  methods: {
    emit(v: QuarterSwitchValue) {
      this.$emit("input", v);
    },
  },
});

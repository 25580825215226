import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { EditableHashedString } from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { Err } from "@/ts/objects/Err";
import { Editable } from "@/ts/objects/editable/Editable";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { parseUTCOrZero } from "@/ts/utils/AppUtil";

/**
 * 編集可能なSOLANルーブリック。
 *
 * リソース名は、 /solanProjects/{projectId}/rubrics/{rubricId}。
 */
export class EditableSolanRubric extends EditableObject {
  readonly projectId: string;
  readonly rubricId: string;
  readonly studentUserId: string;

  /**
   * プロセス。
   *
   * 0 -> みつける
   * 1 -> しらべる
   * 2 -> まとめる
   * 3 -> つたえる
   */
  readonly process: number;
  private readonly _learningActivity: EditableHashedString;
  private readonly _viewPointS: EditableHashedString;
  private readonly _viewPointA: EditableHashedString;
  private readonly _viewPointB: EditableHashedString;
  private readonly _viewPointC: EditableHashedString;
  readonly createdAt: Date;
  readonly studentInputLocked: boolean;

  constructor(
    repo: SolanRepository,
    savable: boolean,
    projectId: string,
    rubricId: string,
    studentUserId: string,
    process: number,
    learningActivity: string,
    learningActivityHash: string,
    viewPointS: string,
    viewPointSHash: string,
    viewPointA: string,
    viewPointAHash: string,
    viewPointB: string,
    viewPointBHash: string,
    viewPointC: string,
    viewPointCHash: string,
    createdAt: string,
    studentInputLocked: boolean
  ) {
    super();
    this.projectId = projectId;
    this.rubricId = rubricId;
    this.studentUserId = studentUserId;
    this.process = process;
    this._learningActivity = new EditableHashedString(
      "learningActivity",
      learningActivity,
      learningActivityHash,
      savable,
      async ({ value, hash }) => {
        const resp = await repo.patchRubric(projectId, rubricId, { learningActivity: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.learningActivity.value, resp.learningActivity.hash];
      }
    );
    this._viewPointS = new EditableHashedString(
      "viewPointS",
      viewPointS,
      viewPointSHash,
      savable,
      async ({ value, hash }) => {
        const resp = await repo.patchRubric(projectId, rubricId, { viewPointS: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.viewPointS.value, resp.viewPointS.hash];
      }
    );
    this._viewPointA = new EditableHashedString(
      "viewPointA",
      viewPointA,
      viewPointAHash,
      savable,
      async ({ value, hash }) => {
        const resp = await repo.patchRubric(projectId, rubricId, { viewPointA: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.viewPointA.value, resp.viewPointA.hash];
      }
    );
    this._viewPointB = new EditableHashedString(
      "viewPointB",
      viewPointB,
      viewPointBHash,
      savable,
      async ({ value, hash }) => {
        const resp = await repo.patchRubric(projectId, rubricId, { viewPointB: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.viewPointB.value, resp.viewPointB.hash];
      }
    );
    this._viewPointC = new EditableHashedString(
      "viewPointC",
      viewPointC,
      viewPointCHash,
      savable,
      async ({ value, hash }) => {
        const resp = await repo.patchRubric(projectId, rubricId, { viewPointC: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.viewPointC.value, resp.viewPointC.hash];
      }
    );
    this.createdAt = parseUTCOrZero(createdAt);
    this.studentInputLocked = studentInputLocked;
  }

  protected allEditables(): Editable[] {
    return [this._learningActivity, this._viewPointS, this._viewPointA, this._viewPointB, this._viewPointC];
  }

  get learningActivity(): string {
    return this._learningActivity.value;
  }

  set learningActivity(value: string) {
    this._learningActivity.value = value;
  }

  get viewPointS(): string {
    return this._viewPointS.value;
  }

  set viewPointS(value: string) {
    this._viewPointS.value = value;
  }

  get viewPointA(): string {
    return this._viewPointA.value;
  }

  set viewPointA(value: string) {
    this._viewPointA.value = value;
  }

  get viewPointB(): string {
    return this._viewPointB.value;
  }

  set viewPointB(value: string) {
    this._viewPointB.value = value;
  }

  get viewPointC(): string {
    return this._viewPointC.value;
  }

  set viewPointC(value: string) {
    this._viewPointC.value = value;
  }
}

// async function saveLearningActivity(
//   projectId: string,
//   rubricId: string,
//   value: string,
//   hash: string
// ): Promise<[string, string] | Err> {
//   const req: RubricWrite = { learningActivity: { value: value, hash: hash } };
//   const resp = await doReq(() => solanService!.patchRubric(projectId, rubricId, req));
//   if (resp instanceof Err) return resp;
//   return [resp.learningActivity.value, resp.learningActivity.hash];
// }
//
// async function saveViewPointS(
//   projectId: string,
//   rubricId: string,
//   value: string,
//   hash: string
// ): Promise<[string, string] | Err> {
//   const req: RubricWrite = { viewPointS: { value: value, hash: hash } };
//   const resp = await doReq(() => solanService!.patchRubric(projectId, rubricId, req));
//   if (resp instanceof Err) return resp;
//   return [resp.viewPointS.value, resp.viewPointS.hash];
// }
//
// async function saveViewPointA(
//   projectId: string,
//   rubricId: string,
//   value: string,
//   hash: string
// ): Promise<[string, string] | Err> {
//   const req: RubricWrite = { viewPointA: { value: value, hash: hash } };
//   const resp = await doReq(() => solanService!.patchRubric(projectId, rubricId, req));
//   if (resp instanceof Err) return resp;
//   return [resp.viewPointA.value, resp.viewPointA.hash];
// }
//
// async function saveViewPointB(
//   projectId: string,
//   rubricId: string,
//   value: string,
//   hash: string
// ): Promise<[string, string] | Err> {
//   const req: RubricWrite = { viewPointB: { value: value, hash: hash } };
//   const resp = await doReq(() => solanService!.patchRubric(projectId, rubricId, req));
//   if (resp instanceof Err) return resp;
//   return [resp.viewPointB.value, resp.viewPointB.hash];
// }
//
// async function saveViewPointC(
//   projectId: string,
//   rubricId: string,
//   value: string,
//   hash: string
// ): Promise<[string, string] | Err> {
//   const req: RubricWrite = { viewPointC: { value: value, hash: hash } };
//   const resp = await doReq(() => solanService!.patchRubric(projectId, rubricId, req));
//   if (resp instanceof Err) return resp;
//   return [resp.viewPointC.value, resp.viewPointC.hash];
// }


























import Vue from "vue";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "SolanRubricsTableHeaderRow",
  props: {
    processColWidth: { type: Number, required: true },
    extraMenuColWidth: { type: Number, required: true },
  },
  data(): {
    words: Words;
  } {
    return { words };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--process-col-width": `${this.processColWidth}px`,
        "--extra-menu-col-width": `${this.extraMenuColWidth}px`,
      };
    },
  },
});

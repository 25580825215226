import { getOrdinalsSuffix } from "@/ts/utils";
import { words } from "@/ts/const/Words";
import { MonthValue, monthValueToEnglishAbbr } from "@/ts/objects/common/MonthValue";

export type FormattedDatetime = {
  year: number;
  month: MonthValue;
  monthAbbr: string;
  dayOfMonth: number;
  dayOfMonthSuffix: string;
  dayOfWeek: string;
  hour: number;
  minute: number;
  second: number;
};

const daysOfWeek = [
  words.dayOfWeekSunAbbr.d,
  words.dayOfWeekMonAbbr.d,
  words.dayOfWeekTueAbbr.d,
  words.dayOfWeekWedAbbr.d,
  words.dayOfWeekThuAbbr.d,
  words.dayOfWeekFriAbbr.d,
  words.dayOfWeekSatAbbr.d,
];

export function formatDate(date: Date): FormattedDatetime {
  const month = (date.getMonth() + 1) as MonthValue;
  return {
    year: date.getFullYear(),
    month,
    monthAbbr: monthValueToEnglishAbbr(month),
    dayOfMonth: date.getDate(),
    dayOfMonthSuffix: getOrdinalsSuffix(date.getDate()),
    dayOfWeek: daysOfWeek[date.getDay()],
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
  };
}

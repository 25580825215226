



















import Vue, { PropType } from "vue";
import { words, Words } from "@/ts/const/Words";
import { solanProcessTextOf } from "@/ts/utils";

export default Vue.extend({
  name: "SolanCreateRubricButton",
  props: {
    selectedProcess: { type: Number, required: true },
    onProcessSelect: { type: Function as PropType<(process: number) => void>, required: true },

    onRubricAdd: { type: Function as PropType<(process: number) => Promise<void>>, required: true },
  },
  data(): {
    process0Label: string;
    process1Label: string;
    process2Label: string;
    process3Label: string;

    words: Words;
  } {
    return {
      process0Label: solanProcessTextOf(0),
      process1Label: solanProcessTextOf(1),
      process2Label: solanProcessTextOf(2),
      process3Label: solanProcessTextOf(3),

      words,
    };
  },
  methods: {
    onProcessSelectInternal(ev: { target: HTMLInputElement }) {
      this.onProcessSelect(parseInt(ev.target.value, 10));
    },
    onRubricAddInternal() {
      this.onRubricAdd(this.selectedProcess);
    },
  },
});

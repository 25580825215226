











import Vue, { PropType } from "vue";
import { QuarterSwitchValue } from "@/components/QuarterSwitch/QuarterSwitch.vue";

export default Vue.extend({
  name: "QuarterSwitchItem",
  props: {
    itemLabel: { type: String, required: true },
    itemValue: { type: String as PropType<QuarterSwitchValue>, required: true },
    selectedValue: { type: String as PropType<QuarterSwitchValue>, required: true },
    emit: { type: Function as PropType<(v: QuarterSwitchValue) => void>, required: true },
  },
  methods: {
    _emit() {
      this.emit(this.itemValue);
    },
  },
});




























































import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import Vue, { PropType } from "vue";
import { words, Words } from "@/ts/const/Words";
import { solanProcessTextOf } from "@/ts/utils";
import { EditableSolanRubric } from "@/ts/objects/solan/editable/EditableSolanRubric";
import { UserType } from "@/ts/objects/common/UserType";

export default Vue.extend({
  name: "SolanRubricsTableRow",
  components: { PopupMenuButton, DisableableAutoResizeTextarea },
  props: {
    userType: { type: String as PropType<UserType>, required: false },
    rubric: { type: Object as PropType<EditableSolanRubric>, required: true },
    onRubricDelete: { type: Function as PropType<(rubricId: string) => Promise<void>>, required: true },

    processColWidth: { type: Number, required: true },
    extraMenuColWidth: { type: Number, required: true },
  },
  data(): {
    rubricPopupMenuItems: MenuItem[];

    words: Words;
  } {
    return {
      rubricPopupMenuItems: [new MenuButton("delete", "Delete", ["fas", "trash-alt"])],

      words,
    };
  },
  computed: {
    editable(): boolean {
      const studentInputLocked = this.rubric.studentInputLocked;

      switch (this.userType) {
        case "teacher":
          return true;
        case "student":
          return !studentInputLocked;
        case "guardian":
          return !studentInputLocked;
        default: {
          return false;
        }
      }
    },

    styles(): Record<string, string> {
      return {
        "--process-col-width": `${this.processColWidth}px`,
        "--extra-menu-col-width": `${this.extraMenuColWidth}px`,
      };
    },
  },
  methods: {
    onRubricPopupMenuClicked(menuKey: string, rubricId: string) {
      switch (menuKey) {
        case "delete":
          this.onRubricDelete(rubricId);
          return;
      }
    },
    _solanProcessTextOf(process: number): string {
      return solanProcessTextOf(process);
    },
  },
});

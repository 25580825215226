





















import Vue, { PropType } from "vue";
import { UserRepository } from "@/ts/repositories/UserRepository";
import ProfileStudentHeader from "@/views/profile/ProfileStudent/ProfileStudentHeader/ProfileStudentHeader.vue";
import { Student } from "@/ts/objects/user/Student";
import { AppStateStore } from "@/store/AppStateStore";
import { isNullish } from "@/ts/utils";
import { Err } from "@/ts/objects/Err";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { StudentClass } from "@/ts/objects/common/StudentClass";
import { getStudentColumns, StudentColumn, StudentColumnId } from "@/ts/app/columns/student/StudentColumn";
import ProfileTable from "@/views/profile/components/ProfileTable/ProfileTable.vue";
import { SchoolType } from "@/ts/objects/common/SchoolType";

const tableColIds: StudentColumnId[] = [
  "pictureGcsUrl",

  "name",
  "nameKana",
  "nameRome",

  "sex",
  "address",
  "mobilePhone",
  "birthday",
  "zipcode",
  "country",
  "religion",
  "hasLunch",
  "email",
  "commutingBy",
  "bus",
  "nearestStation",
  "previousSchool",
  "kindergarten",

  "activityMonday",
  "activityTuesday",
  "activityWednesday",
  "activityThursday",
  "activityFriday",

  "bloodType",
  "normalBodyTemperature",
  "inoculation",
  "medicalHistory",
  "homeDoctor",
  "foodAllergy",
  "anaphylaxis",
  "otherAllergy",
  "asthma",
];

export default Vue.extend({
  name: "ProfileStudent",
  components: { ProfileTable, LoadingBlock, ProfileStudentHeader },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
  },
  data(): {
    student: Student | null;
  } {
    return {
      student: null,
    };
  },
  computed: {
    studentUserId(): string | null {
      return this.appStateStore.studentOrGuardianState?.studentInfo()?.studentUserId ?? null;
    },
    schoolYear(): number {
      return this.appStateStore.currentQuarter.schoolYear;
    },

    currentClass(): StudentClass | null {
      return this.appStateStore.studentOrGuardianState?.studentInfo()?.classOfSchoolYear(this.schoolYear) ?? null;
    },

    schoolType(): SchoolType | null {
      return this.currentClass?.grade?.schoolType ?? null;
    },
    gradeNumber(): number | null {
      return this.currentClass?.grade?.gradeNumber ?? null;
    },
    className(): string | null {
      return this.currentClass?.name ?? null;
    },
    studentNumber(): number | null {
      return this.currentClass?.studentNumber ?? null;
    },

    studentColumns(): StudentColumn<StudentColumnId, any>[] | null {
      const _student = this.student;
      if (isNullish(_student)) return null;
      return getStudentColumns(_student, tableColIds);
    },
  },
  watch: {
    studentUserId: {
      handler: async function(newValue: string | null) {
        if (isNullish(newValue)) {
          this.student = null;
          return;
        }

        const result = await this.userRepository.getStudent(newValue);
        if (result instanceof Err) {
          this.student = null;
          return;
        }
        this.student = result;
      },
      immediate: true,
    },
  },
});

/**
 * エラーを表す抽象クラス。
 */
export abstract class Err {
  /**
   * コンソールログ等に吐くためのメッセージ。
   */
  abstract readonly internalMessage: string;

  // /**
  //  * trueの場合、同じことをしてもどうせ同じエラーが返るから、同じことをしない方が良い、という意図を伝える。
  //  * 無視しても構わないが、例えばサーバ側への無駄な多重リクエストを防止するため等に用いることができる。
  //  */
  // abstract readonly shouldCache: boolean;
}

/**
 * ユーザーに通知しない内部エラー。
 */
export class InternalErr extends Err {
  readonly internalMessage: string;

  constructor(message: string) {
    super();
    this.internalMessage = message;
  }
}

/**
 * ユーザーに通知するエラー。
 */
export class DisplayableErr extends Err {
  readonly internalMessage: string;

  /**
   * ユーザー向けに表示するメッセージ。
   */
  readonly message: string;

  constructor(internalMessage: string, message: string) {
    super();
    this.internalMessage = internalMessage;
    this.message = message;
  }
}

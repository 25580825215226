




import Vue, { PropType } from "vue";
import { ProjectStore } from "@/store/ProjectStore";
import { messages } from "@/ts/const/Messages";
import { Err } from "@/ts/objects/Err";
import { MessageViewParam } from "@/components/MessageView.vue";
import { ProjectRubric } from "@/ts/objects/project/value/ProjectRubric";
import { Project } from "@/ts/objects/project/value/Project";
import ProjectRubricsPure from "@/views/project/student/ProjectRubrics/ProjectRubricsPure.vue";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

export default Vue.extend({
  name: "ProjectRubrics",
  components: { ProjectRubricsPure },
  props: {
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true },
  },
  created() {
    this.projectStore.project.getDataWithTimeout().then(project => {
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }

      this.project = project;

      this.projectRepository.listProjectRubrics(project.projectId).then(resp => {
        if (resp instanceof Err) {
          this.messageView = { message: messages.failedToLoadData, fadeIn: true };
          return;
        }

        this.rubrics = resp;
      });
    });
  },
  data(): {
    messageView: MessageViewParam | null;

    project: Project | null;
    rubrics: ProjectRubric[] | null;
  } {
    return {
      messageView: null,

      project: null,
      rubrics: null,
    };
  },
});

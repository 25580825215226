































































import FilterableHeaderButton, { CheckboxItem } from "@/components/FilterableHeaderButton/FilterableHeaderButton.vue";
import Vue, { PropType } from "vue";
import { words, Words } from "@/ts/const/Words";
import {
  ProjectJournalsTColFilterState,
  ProjectJournalsTEmptyFilterCheckState,
  ProjectJournalsTRowFilterState,
  ProjectJournalsTStyleProps,
} from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsTModel";

export default Vue.extend({
  name: "ProjectJournalsTTableHeaderRow",
  components: { FilterableHeaderButton },
  props: {
    studentRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },
    learningActivityRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },
    teacherRatingRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },
    teacherCommentRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },
    guardianCommentRowFilterCheckboxItems: { type: Array as PropType<CheckboxItem[]>, required: true },

    rowFilterState: { type: Object as PropType<ProjectJournalsTRowFilterState>, required: true },

    onStudentRowFilterChange: { type: Function as PropType<(state: Record<string, boolean>) => void>, required: true },
    onLearningActivityRowFilterChange: {
      type: Function as PropType<(state: Record<string, boolean>) => void>,
      required: true,
    },
    onTeacherRatingRowFilterChange: {
      type: Function as PropType<(state: ProjectJournalsTEmptyFilterCheckState) => void>,
      required: true,
    },
    onTeacherCommentRowFilterChange: {
      type: Function as PropType<(state: ProjectJournalsTEmptyFilterCheckState) => void>,
      required: true,
    },
    onGuardianCommentRowFilterChange: {
      type: Function as PropType<(state: ProjectJournalsTEmptyFilterCheckState) => void>,
      required: true,
    },

    rowSelectionStateSummary: { type: String as PropType<"all" | "some" | "none">, required: true },
    toggleAllRowSelections: { type: Function as PropType<() => void>, required: true },

    colFilterState: { type: Object as PropType<ProjectJournalsTColFilterState>, required: true },

    styleProps: { type: Object as PropType<ProjectJournalsTStyleProps>, required: true },
  },
  data(): { words: Words } {
    return { words };
  },
  computed: {
    allSelected(): boolean {
      return this.rowSelectionStateSummary === "all";
    },
    someSelected(): boolean {
      return this.rowSelectionStateSummary === "some";
    },
    noneSelected(): boolean {
      return this.rowSelectionStateSummary === "none";
    },
    styles(): Record<string, string> {
      return {
        "--width": `${this.styleProps.currentTableWidth + 2}px`,

        "--studentColWidth": `${this.styleProps.studentColWidth}px`,
        "--learningActivityColWidth": `${this.styleProps.learningActivityColWidth}px`,
        "--journalFilesColWidth": `${this.styleProps.journalFilesColWidth}px`,
        "--studentCommentColWidth": `${this.styleProps.studentCommentColWidth}px`,
        "--studentRatingColWidth": `${this.styleProps.studentRatingColWidth}px`,
        "--studentInputHeaderWidth": `${this.styleProps.studentInputHeaderWidth}px`,
        "--teacherRatingColWidth": `${this.styleProps.teacherRatingColWidth}px`,
        "--teacherCommentColWidth": `${this.styleProps.teacherCommentColWidth}px`,
        "--guardianCommentColWidth": `${this.styleProps.guardianCommentColWidth}px`,
        "--teacherInputPublishedColWidth": `${this.styleProps.teacherInputPublishedColWidth}px`,
        "--selectionColWidth": `${this.styleProps.selectionColWidth}px`,
      };
    },
  },
});















import Vue, { PropType } from "vue";
import JournalFilePreviewArea from "@/components/journalfile/JournalFilePreviewArea/JournalFilePreviewArea.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { messages, Messages } from "@/ts/const/Messages";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "JournalFileExpandButton",
  components: { JournalFilePreviewArea },
  props: {
    journalFiles: { type: Array as PropType<JournalFile[]>, required: true },
    onClick: { type: Function as PropType<() => void>, required: true },

    ariaExpanded: { type: Boolean, required: true },
    ariaControls: { type: String, required: true },
  },
  data(): { words: Words; messages: Messages } {
    return { words, messages };
  },
});






























import Vue, { PropType } from "vue";
import CurriculumListEECPure from "@/views/curriculum/teacher/CurriculumListEEC/CurriculumListEECPure.vue";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { getDataWithTimeout, Loadable, LoadableIdle } from "@/ts/Loadable";
import { ClassStudent } from "@/ts/objects/common/Class";
import { Err } from "@/ts/objects/Err";
import {
  EditableEECJournalStudentTree,
  EditableEECJournalTree,
} from "@/ts/objects/curriculum/editable/EditableEECJournal";
import { EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import {
  createCurriculumListEECModel,
  CurriculumListEECJournalRowFilterState,
  CurriculumListEECSelectionState,
  CurriculumListEECStudentRowFilterState,
  CurriculumListEECModel,
} from "@/views/curriculum/teacher/CurriculumListEEC/CurriculumListEECModel";
import { downloadBlob, eecJournalRnameToId, isNullish } from "@/ts/utils";
import { NavigationGuardNext, Route } from "vue-router";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import debounce from "lodash/debounce";
import { WholeSelectionState } from "@/ts/objects/common/WholeSelectionState";
import { unparse } from "papaparse";
import { format } from "date-fns";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import { words } from "@/ts/const/Words";
import { MonthValue, monthValues } from "@/ts/objects/common/MonthValue";

export default Vue.extend({
  name: "CurriculumListEEC",
  components: { CurriculumListEECPure },
  props: {
    curriculumStoreT: { type: Object as PropType<CurriculumStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true },
  },
  async created() {
    log.debug(`CurriculumListEEC:created: Started.`);

    this.debouncedUpdateNeedSave = debounce(this.updateNeedSave, 500);
    this.debouncedUpdateCurrentErrors = debounce(this.updateCurrentErrors, 500);
    this.debouncedSaveAll = debounce(() => this.saveAll(false), 3000);
    this.periodicSaverId = window.setInterval(() => {
      if (!this.needSave) return;
      this.debouncedSaveAll();
    }, 10000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
        this.updateNeedSave(); // この行が無いと、needSaveがdebounceにより未更新である場合、requirementToLeaveを一瞬で通ってしまう。
      },
      requirementToLeave: async () => !this.needSave,
      onRequirementUnmet: async () => {
        // TODO highlightUnsaved?
        // this.highlightUnsaved = true;
      },
    });

    const eecTree = await getDataWithTimeout(() => this.curriculumStoreT.eeCurriculumTree, 10);
    const cls = this.curriculumStoreT.class;
    if (eecTree === null || cls === null) {
      log.debug(`CurriculumListEEC:created: eecTree or class is null.`);
      return;
    }

    log.debug(`CurriculumListEEC:created: eecTree=${JSON.stringify(eecTree)}`);

    const [students, resp] = await Promise.all<ClassStudent[], EditableEECJournalStudentTree[] | Err>([
      cls.sortedClassStudents(this.userRepository),
      this.curriculumRepository.listEditableEECJournalStudents(
        true,
        true,
        eecTree.self.eecId,
        undefined,
        cls.id,
        undefined,
        undefined
      ),
    ]);
    if (resp instanceof Err) {
      log.error(`CurriculumListEEC:created: error loading journalStudents: ${resp.internalMessage}`);
      return;
    }

    this.eeCurriculumTree = Loadable.fromValue(eecTree);
    this.students = Loadable.fromValue(students);

    this.studentRowFilterState = {
      studentUserIds: students.map(s => s.studentUserId),
    };
    this.selectionState = Object.fromEntries(resp.flatMap(js => js.journals).map(j => [j.self.resourceName, false]));
    this.journalStudentTreeDict = Loadable.fromValue(Object.fromEntries(resp.map(js => [js.self.studentUserId, js])));

    this.createModel();

    log.debug(`CurriculumListEEC:created: Completed.`);
  },
  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    eeCurriculumTree: Loadable<EECurriculumTree>;
    students: Loadable<ClassStudent[]>;
    journalStudentTreeDict: Loadable<Record<string, EditableEECJournalStudentTree>>;

    studentRowFilterState: CurriculumListEECStudentRowFilterState;
    journalRowFilterState: CurriculumListEECJournalRowFilterState;

    model: Loadable<CurriculumListEECModel>;

    selectionState: CurriculumListEECSelectionState;

    filesViewJournal: string | null;

    currentErrors: ErrorNotificationParam[];

    needSave: boolean;

    debouncedUpdateNeedSave: any;
    debouncedUpdateCurrentErrors: any;
    debouncedSaveAll: any;
    periodicSaverId: number | undefined;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      eeCurriculumTree: new LoadableIdle(),
      students: new LoadableIdle(),
      journalStudentTreeDict: new LoadableIdle(),

      studentRowFilterState: {
        studentUserIds: [],
      },
      journalRowFilterState: {
        months: monthValues,
        activity: ["empty", "hasValue"],
        studentComment: ["empty", "hasValue"],
        teacherComment: ["empty", "hasValue"],
        publishState: ["unpublished", "published"],
      },

      model: Loadable.idle(),

      selectionState: {},

      filesViewJournal: null,

      currentErrors: [],

      needSave: false,

      debouncedUpdateNeedSave: undefined,
      debouncedUpdateCurrentErrors: undefined,
      debouncedSaveAll: undefined,
      periodicSaverId: undefined,

      pageLeaveService: null,
    };
  },
  computed: {
    eecId(): string | null {
      return this.eeCurriculumTree.data?.self.eecId ?? null;
    },
    studentsDict(): Record<string, ClassStudent> | null {
      const students = this.students.data;
      if (isNullish(students)) return null;
      return Object.fromEntries(students.map(s => [s.studentUserId, s]));
    },
  },
  watch: {
    journalStudentTreeDict: {
      handler: function() {
        this.debouncedUpdateNeedSave();
        this.debouncedUpdateCurrentErrors();
      },
      deep: true,
    },
  },
  methods: {
    updateNeedSave() {
      const journalStudentTreeDict: Record<string, EditableEECJournalStudentTree> | null = this.journalStudentTreeDict
        .data;
      if (journalStudentTreeDict === null) {
        this.needSave = false;
        return;
      }

      this.needSave = Object.values(journalStudentTreeDict).some(js => js.journals.some(j => j.self.needSave()));
    },
    updateCurrentErrors() {
      const journalStudentTreeDict: Record<string, EditableEECJournalStudentTree> | null = this.journalStudentTreeDict
        .data;
      const studentsDict = this.studentsDict;
      if (journalStudentTreeDict === null || studentsDict === null) {
        this.currentErrors = [];
        return;
      }

      this.currentErrors = Object.values(journalStudentTreeDict).flatMap(studentTree => {
        const student = studentsDict[studentTree.self.studentUserId];
        return studentTree.journals.flatMap(journal =>
          journal.self.currentErrors().map(err => {
            return {
              heading: `${student.studentNumber}番 ${student.name} - ${journal.self.month}月 - ${journal.self.activity}`,
              text: err.message,
            };
          })
        );
      });
    },
    createModel() {
      this.resetAllSelections();
      this.model = createCurriculumListEECModel(
        this.eeCurriculumTree,
        this.students,
        this.journalStudentTreeDict,
        this.studentRowFilterState,
        this.journalRowFilterState
      );
    },
    selectFilesViewJournal(rname: string | null) {
      this.filesViewJournal = rname;
    },
    onInputMonth(studentUserId: string, journalId: string, value: MonthValue) {
      const journal = getJournal(this.eecId, studentUserId, journalId, this.journalStudentTreeDict);
      if (journal === null) return;

      journal.self.month = value;

      this.debouncedSaveAll();
    },
    onInputActivity(studentUserId: string, journalId: string, value: string) {
      const journal = getJournal(this.eecId, studentUserId, journalId, this.journalStudentTreeDict);
      if (journal === null) return;

      journal.self.activity = value;

      this.debouncedSaveAll();
    },
    onInputStudentComment(studentUserId: string, journalId: string, value: string) {
      const journal = getJournal(this.eecId, studentUserId, journalId, this.journalStudentTreeDict);
      if (journal === null) return;

      journal.self.studentComment = value;

      this.debouncedSaveAll();
    },
    onInputTeacherComment(studentUserId: string, journalId: string, value: string) {
      const journal = getJournal(this.eecId, studentUserId, journalId, this.journalStudentTreeDict);
      if (journal === null) return;

      journal.self.teacherComment = value;

      this.debouncedSaveAll();
    },
    async onUploadJournalFile(studentUserId: string, journalId: string, file: File) {
      const journal = getJournal(this.eecId, studentUserId, journalId, this.journalStudentTreeDict);
      if (journal === null) return;

      const resp = await this.curriculumRepository.uploadEECJournalFile(
        journal.self.eecId,
        journal.self.studentUserId,
        journal.self.journalId,
        file,
        30000
      );
      if (resp instanceof Err) {
        // TODO エラーハンドリング？
        log.error(`CurriculumWriteEEC:onUploadJournalFile: ${resp.internalMessage}`);
        return;
      }

      await journal.reloadJournalFiles();
    },
    async onDeleteJournalFile(studentUserId: string, journalId: string, journalFileId: string) {
      const journal = getJournal(this.eecId, studentUserId, journalId, this.journalStudentTreeDict);
      if (journal === null) return;

      const resp = await this.curriculumRepository.deleteEECJournalFile(
        journal.self.eecId,
        journal.self.studentUserId,
        journal.self.journalId,
        journalFileId
      );
      if (resp instanceof Err) {
        // TODO エラーハンドリング？
        log.error(`CurriculumWriteEEC:onDeleteJournalFile: ${resp.internalMessage}`);
        return;
      }

      await journal.reloadJournalFiles();
    },
    onChangeSelectionState(studentUserId: string, journalId: string, value: boolean) {
      const journal = getJournal(this.eecId, studentUserId, journalId, this.journalStudentTreeDict);
      if (journal === null) return;
      const journalRname = journal.self.resourceName;

      this.selectionState[journalRname] = value;
    },
    onChangeStudentRowFilterState(filterState: CurriculumListEECStudentRowFilterState) {
      this.studentRowFilterState = filterState;
      this.createModel();
    },
    onChangeJournalRowFilterState(filterState: CurriculumListEECJournalRowFilterState) {
      this.journalRowFilterState = filterState;
      this.createModel();
    },
    onClickPublish() {
      const journalStudentTreeDict = this.journalStudentTreeDict;
      Object.entries(this.selectionState)
        .filter(([_rname, selected]) => selected)
        .forEach(([rname, _selected]) => {
          const ids = eecJournalRnameToId(rname);
          if (ids === null) return;
          const { eecId, studentUserId, journalId } = ids;
          const journal = getJournal(eecId, studentUserId, journalId, journalStudentTreeDict);
          if (journal === null) return;
          journal.self.teacherInputPublished = true;
        });

      this.debouncedSaveAll();
    },
    onClickUnpublish() {
      const journalStudentTreeDict = this.journalStudentTreeDict;
      Object.entries(this.selectionState)
        .filter(([_rname, selected]) => selected)
        .forEach(([rname, _selected]) => {
          const ids = eecJournalRnameToId(rname);
          if (ids === null) return;
          const { eecId, studentUserId, journalId } = ids;
          const journal = getJournal(eecId, studentUserId, journalId, journalStudentTreeDict);
          if (journal === null) return;
          journal.self.teacherInputPublished = false;
        });
      this.debouncedSaveAll();
    },
    resetAllSelections() {
      Object.keys(this.selectionState).forEach(key => {
        this.selectionState[key] = false;
      });
    },
    toggleAllSelections(currentSelectionState: WholeSelectionState) {
      // 参考: https://github.com/vuejs/vue/issues/9535#issuecomment-466217819
      // TODO WindowsのChrome以外でもちゃんと動くか確認。
      setTimeout(() => {
        const changeTo = currentSelectionState === "none" ? true : false;

        const modelData = this.model.data;
        if (modelData === null) return;

        const journals = modelData.studentRows.flatMap(s => s.journals);

        journals.forEach(j => {
          this.selectionState[j.self.resourceName] = changeTo;
        });
      });
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      const journalStudentTreeDict = this.journalStudentTreeDict.data;
      if (journalStudentTreeDict === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(
        await Promise.all(
          Object.values(journalStudentTreeDict)
            .flatMap(js => js.journals)
            .map(j => j.self.saveAllChanges(force))
        )
      );
    },
    studentUserIdToStudentViewPath(studentUserId: string): string {
      return this.curriculumStoreT.path("studentview", `/eeCurriculums/${this.eecId}`, studentUserId);
    },
    exportCsv() {
      const model = this.model.data;
      if (isNullish(model)) return;

      exportCsv(model);
    },
  },
});

function getJournalStudent(
  studentUserId: string,
  journalStudentTreeDict: Loadable<Record<string, EditableEECJournalStudentTree>>
): EditableEECJournalStudentTree | null {
  const journalStudentTreeDictData = journalStudentTreeDict.data;
  if (journalStudentTreeDictData === null) return null;

  const journalStudent = journalStudentTreeDictData[studentUserId];
  if (isNullish(journalStudent)) return null;

  return journalStudent;
}

function getJournal(
  eecId: string | null,
  studentUserId: string,
  journalId: string,
  journalStudentTreeDict: Loadable<Record<string, EditableEECJournalStudentTree>>
): EditableEECJournalTree | null {
  if (eecId === null) return null;

  const journalStudent = getJournalStudent(studentUserId, journalStudentTreeDict);
  if (journalStudent === null) return null;

  const journal = journalStudent.journals.find(
    j => j.self.eecId === eecId && j.self.studentUserId === studentUserId && j.self.journalId === journalId
  );
  if (isNullish(journal)) return null;

  return journal;
}

function exportCsv(filteredModel: CurriculumListEECModel) {
  const csvRows = filteredModel.studentRows.flatMap(sr =>
    sr.journals.map(j => ({
      [words.studentNumber.d]: sr.studentNumber,
      [words.name.d]: sr.studentName,
      [words.month.d]: `${j.self.month}月`,
      [words.activity.d]: j.self.activity,
      [words.journalFile.d]: j.journalFiles.length,
      [words.studentComment.d]: j.self.studentComment,
      [words.teacherComment.d]: j.self.teacherComment,
      [words.teacherInputPublished.d]: j.self.teacherInputPublished ? words.published.d : words.unpublished.d,
    }))
  );
  const columnNames = [
    words.studentNumber.d,
    words.name.d,
    words.month.d,
    words.activity.d,
    words.journalFile.d,
    words.studentComment.d,
    words.teacherComment.d,
    words.teacherInputPublished.d,
  ];

  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, unparse(csvRows, { columns: columnNames })], { type: "text/plain" });
  downloadBlob(blob, `${filteredModel.curriculumName}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`);
}

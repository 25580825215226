














































































import Vue, { PropType } from "vue";
import { AppStateStore } from "@/store/AppStateStore";
import { StudentInfo } from "@/ts/StudentInfo";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import { AuthService } from "@/ts/AuthService";
import { words, Words } from "@/ts/const/Words";
import AppHeaderTab from "@/views/header/components/AppHeaderTab/AppHeaderTab.vue";
import { getDownloadUrlOfStorageObject, isNullish } from "@/ts/utils";
import AppHeaderHomeButton from "@/views/header/components/AppHeaderHomeButton/AppHeaderHomeButton.vue";

export default Vue.extend({
  name: "AppHeader",
  components: { AppHeaderHomeButton, AppHeaderTab, PopupMenuButton },
  props: {
    authService: { type: Object as PropType<AuthService>, required: true },
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
  },
  data(): {
    schoolLogoUrl: string | null;
    userMenuItems: MenuItem[];

    words: Words;
  } {
    return {
      schoolLogoUrl: null,
      userMenuItems: [new MenuButton("changeGoogleAccount", "Login with Different Account", undefined)],

      words,
    };
  },
  computed: {
    schoolLogoGcsUrl(): string | null {
      return this.appStateStore.school.logoGcsUrl;
    },
    isCurrentTabHome(): boolean {
      return this.$route.name === "S.Home";
    },
    isCurrentTabCurriculum(): boolean {
      return this.$route.name === "S.Curriculum" || (this.$route.name?.startsWith("S.Curriculum.") ?? false);
    },
    isCurrentTabProject(): boolean {
      return this.$route.name?.startsWith("S.Project.") ?? false;
    },
    isCurrentTabSolan(): boolean {
      return this.$route.name?.startsWith("S.Solan.") ?? false;
    },
    isCurrentTabProfile(): boolean {
      return this.$route.name?.startsWith("S.Profile.") ?? false;
    },
    studentInfo(): StudentInfo | null {
      return this.appStateStore.studentOrGuardianState?.studentInfo() ?? null;
    },
    studentName(): string {
      return this.studentInfo?.name ?? "";
    },
    studentIconUrl(): string {
      return this.studentInfo?.iconUrl ?? "";
    },
    guardianStudents(): StudentInfo[] {
      return this.appStateStore.guardianState?.students ?? [];
    },
  },
  watch: {
    schoolLogoGcsUrl: {
      async handler(newValue: string | null) {
        if (isNullish(newValue)) {
          this.schoolLogoUrl = null;
          return;
        }
        this.schoolLogoUrl = await getDownloadUrlOfStorageObject(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    guardianStudentSelected(e: Event) {
      const studentUserId = (e.target as HTMLInputElement | null)?.value;
      if (studentUserId === undefined) return;
      this.$router.push(`/s/${studentUserId}`).catch(() => {});
    },
    async selectUserMenu(menuItem: string) {
      switch (menuItem) {
        case "changeGoogleAccount":
          await this.authService.changeGoogleAccount();
          break;
      }
    },
  },
});

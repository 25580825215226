import { UserRepository } from "@/ts/repositories/UserRepository";
import { Err } from "@/ts/objects/Err";
import { ClassStudent } from "@/ts/objects/common/Class";
import { Quarter } from "@/ts/objects/common/Quarter";
import { BasicUserInfo } from "@/ts/objects/common/BasicUserInfo";
import { UserInfo as UserInfoResp } from "@/ts/api/user-service";
import { getUserTestData, UserTestDataSet } from "@/test-tools/UserTestData";
import { isNullish } from "@/ts/utils";
import { Student } from "@/ts/objects/user/Student";
import { Guardian } from "@/ts/objects/user/Guardian";
import { School } from "@/ts/objects/common/School";

export class UserRepositoryMock extends UserRepository {
  constructor(public readonly currentQuarter: Quarter, testData?: UserTestDataSet) {
    super();

    this.testData = isNullish(testData) ? getUserTestData() : testData;
  }

  private testData: UserTestDataSet;

  async getUserInfo(_userId: string): Promise<UserInfoResp | Err> {
    // AuthServiceImplからしか呼ばれない関数なので、デバッグモードではこの関数は呼ばれない。
    throw new Error("Unimplemented");
  }

  async getCurrentQuarter(): Promise<Quarter | Err> {
    return this.currentQuarter;
  }

  async getClassStudents(classId: string): Promise<ClassStudent[] | Err> {
    const cls = Object.values(this.testData.classes).find(c => c.id === classId);
    if (isNullish(cls)) {
      throw new Error(`UserRepositoryMock.getClassStudents: class ${classId} not found.`);
    }

    const classStudents = cls.sortedClassStudentsData();
    if (isNullish(classStudents)) {
      throw new Error(`UserRepositoryMock.getClassStudents: class ${classId} does not have loaded students.`);
    }

    return classStudents;
  }

  async getStudent(this: this, _userId: string): Promise<Student | Err> {
    return this.testData.student;
  }

  async getGuardian(this: this, _userId: string): Promise<Guardian | Err> {
    return this.testData.guardian;
  }

  async getBasicUserInfo(_userId: string): Promise<BasicUserInfo | Err> {
    // TODO UserRepositoryMock.getBasicUserInfoの実装。
    throw new Error("UserRepositoryMock: getBasicUserInfo is unimplemented.");
  }

  async getSchool(this: this): Promise<School | Err> {
    return {
      schoolName: "ABC小学校",
      logoGcsUrl: null,
    };
  }

  async updateFirebaseUser(): Promise<boolean | Err> {
    // 必ず成功する。 ※ 戻り値のfalseは、「更新不要だったので更新しなかった」という意味。
    return false;
  }
}

import { render, staticRenderFns } from "./LoadingDotsBlock.vue?vue&type=template&id=2ef79406&scoped=true&"
import script from "./LoadingDotsBlock.vue?vue&type=script&lang=ts&"
export * from "./LoadingDotsBlock.vue?vue&type=script&lang=ts&"
import style0 from "./LoadingDotsBlock.vue?vue&type=style&index=0&id=2ef79406&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ef79406",
  null
  
)

export default component.exports
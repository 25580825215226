import { WordInternalName } from "@/ts/const/Words";
import { UserColumnDef } from "@/ts/app/columns/UserColumnDef";
import { Guardian } from "@/ts/objects/user/Guardian";
import { attachValueToCols, UserColumn } from "@/ts/app/columns/UserColumn";
import { guardianProfileColumnDefs, GuardianProfileColumnId } from "@/ts/app/columns/guardian/GuardianProfileColumn";
import { guardianNotesColumnDefs, GuardianNotesColumnId } from "@/ts/app/columns/guardian/GuardianNotesColumn";
import { filterNotNullish, isNullish } from "@/ts/utils";

export type GuardianColumnId = GuardianProfileColumnId | GuardianNotesColumnId;

export type GuardianColumnDef<ColId extends WordInternalName, T> = UserColumnDef<ColId, Guardian, T>;

export type GuardianColumn<ColId extends WordInternalName, T> = UserColumn<ColId, Guardian, T>;

export function getGuardianColumnDefs(): GuardianColumnDef<GuardianColumnId, any>[] {
  return [...guardianProfileColumnDefs(), ...guardianNotesColumnDefs()];
}

export function getGuardianColumns(
  guardian: Guardian,
  colIds?: GuardianColumnId[]
): GuardianColumn<GuardianColumnId, any>[] {
  const allColumnDefs = getGuardianColumnDefs();
  const columnDefs = isNullish(colIds)
    ? allColumnDefs
    : filterNotNullish(colIds.map(id => allColumnDefs.find(def => def.id === id)));

  return attachValueToCols(columnDefs, guardian);
}










































































































import Vue, { PropType } from "vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import {
  ProjectJournalsTColFilterState,
  ProjectJournalsTStyleProps,
} from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsTModel";
import { words, Words } from "@/ts/const/Words";
import { v4 as uuidv4 } from "uuid";
import StudentActivityTableFilesCell from "@/components/student-activity-table/StudentActivityTableFilesCell/StudentActivityTableFilesCell.vue";
import StudentActivityTableTextCell from "@/components/student-activity-table/StudentActivityTableTextCell/StudentActivityTableTextCell.vue";
import StudentActivityTableRatingCell from "@/components/student-activity-table/StudentActivityTableRatingCell/StudentActivityTableRatingCell.vue";
import StudentActivityTableReadonlyTextCell from "@/components/student-activity-table/StudentActivityTableReadonlyTextCell/StudentActivityTableReadonlyTextCell.vue";
import StudentActivityTablePublishStateCell from "@/components/student-activity-table/StudentActivityTablePublishStateCell/StudentActivityTablePublishStateCell.vue";
import StudentActivityTableSelectRowCheckboxCell from "@/components/student-activity-table/StudentActivityTableSelectRowCheckboxCell/StudentActivityTableSelectRowCheckboxCell.vue";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";

export default Vue.extend({
  name: "ProjectJournalsTTableJournalRow",
  components: {
    JournalFilesView,
    StudentActivityTableSelectRowCheckboxCell,
    StudentActivityTablePublishStateCell,
    StudentActivityTableReadonlyTextCell,
    StudentActivityTableRatingCell,
    StudentActivityTableTextCell,
    StudentActivityTableFilesCell,
  },
  props: {
    journal: { type: Object as PropType<EditableProjectJournal>, required: true },
    selected: { type: Boolean, required: true },
    isAssociatedFilesViewOpen: { type: Boolean, required: true },

    onSelect: { type: Function as PropType<(selected: boolean) => void>, required: true },
    onCellBlur: { type: Function as PropType<() => void>, required: true },
    onFilesViewOpen: { type: Function as PropType<(open: boolean) => void>, required: true },

    colFilterState: { type: Object as PropType<ProjectJournalsTColFilterState>, required: true },
    highlightUnsaved: { type: Boolean, required: true },

    onJournalFileUpload: {
      type: Function as PropType<(file: File, journal: EditableProjectJournal) => Promise<void>>,
      required: true,
    },
    onJournalFileDelete: {
      type: Function as PropType<(journalFile: JournalFile, journal: EditableProjectJournal) => Promise<void>>,
      required: true,
    },

    styleProps: { type: Object as PropType<ProjectJournalsTStyleProps>, required: true },
  },
  data(): {
    journalFilesViewElementId: string;
    words: Words;
  } {
    return {
      journalFilesViewElementId: uuidv4(),
      words,
    };
  },
});

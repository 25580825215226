import sortBy from "lodash/sortBy";
import { Err } from "@/ts/objects/Err";
import log from "loglevel";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { Grade } from "@/ts/objects/common/Grade";
import { SchoolType } from "@/ts/objects/common/SchoolType";

export class Class {
  /**
   * classStudentsのキャッシュ。
   */
  private _classStudents: Map<string, ClassStudent> | null = null;

  /**
   * ソート済classStudentsのキャッシュ。
   */
  private _sortedClassStudents: ClassStudent[] | null = null;

  constructor(
    public readonly id: string,
    public readonly schoolYear: number,
    public readonly grade: Grade,
    public readonly name: string,
    public readonly classNo: number,
    classStudents?: ClassStudent[] // ここで指定しなかった場合、lazyに取得する。
  ) {
    if (classStudents !== undefined) {
      this._classStudents = new Map<string, ClassStudent>(classStudents.map(s => [s.studentUserId, s]));
      this._sortedClassStudents = sortBy<ClassStudent>([...classStudents.values()], [s => s.studentNumber]);
    }
  }

  get schoolType(): SchoolType {
    return this.grade.schoolType;
  }

  get gradeIntValue(): number {
    return this.grade.intValue;
  }

  async classStudents(userRepository: UserRepository): Promise<Map<string, ClassStudent>> {
    if (this._classStudents !== null) {
      // キャッシュがあればキャッシュを返す。
      return this._classStudents;
    }

    const resp = await userRepository.getClassStudents(this.id);
    if (resp instanceof Err) {
      log.error("Failed to fetch class students from user-service.");
      return new Map<string, ClassStudent>();
    }

    const classStudents = new Map<string, ClassStudent>(resp.map(s => [s.studentUserId, s]));

    this._classStudents = classStudents;
    return classStudents;
  }

  async sortedClassStudents(userRepository: UserRepository): Promise<ClassStudent[]> {
    if (this._sortedClassStudents !== null) {
      // キャッシュがあればキャッシュを返す。
      return this._sortedClassStudents;
    }

    const classStudents = await this.classStudents(userRepository);
    const sortedClassStudents = sortBy<ClassStudent>([...classStudents.values()], [s => s.studentNumber]);
    this._sortedClassStudents = sortedClassStudents;
    return sortedClassStudents;
  }

  sortedClassStudentsData(): ClassStudent[] | null {
    return this._sortedClassStudents;
  }
}

export class ClassStudent {
  constructor(
    public readonly studentUserId: string,
    public readonly studentNumber: number,
    public readonly name: string,
    public readonly iconUrl: string
  ) {}
}

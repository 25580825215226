// src/css/_color-variables.scss と対応している。変更する場合、両方とも変更すること。

export const colors = {
  transparent: "rgba(0, 0, 0, 0)",

  white: "#ffffff",
  "slightly-dark-white": "#f4f4f4",
  "dark-white": "#EEEEEE",
  "light-gray": "#DEDEDE",
  gray: "#B8B8B8",
  "dark-gray": "#7A7A7A",
  "light-black": "#505050",
  black: "#191919",
  "gray-transparent": "rgba(184, 184, 184, 0.2)",
  "gray-semi-transparent": "rgba(184, 184, 184, 0.5)",

  "gray-text": "#282828",
  "default-shadow": "rgba(0, 0, 0, 0.25)",
  "weak-border": "#999999",

  "curriculum-mainHeader": "#C4C8DF",
  "curriculum-header": "#D5D8ED",
  "curriculum-header-menuButton": "#8A8C9B",
  "curriculum-header-menuButton-active": "#fdfdfd",
  "curriculum-header-menuButton-active-text": "#25262A",
  "curriculum-menu": "#EAEBF2",
  "curriculum-background": "#FFFCF5",
  "curriculum-menu-scrollbar": "#e2dbc8",

  "project-mainHeader": "#ff8b8b",
  "project-header": "#ff6f6f",
  "project-header-menuButton": "#ff928b",
  "project-header-menuButton-active": "#fdfdfd",
  "project-header-menuButton-active-text": "#ff4747",

  "solan-mainHeader": "#7CA8FF",
  "solan-header": "#5F95FF",
  "solan-header-menuButton": "#61AAFF",
  "solan-header-menuButton-active": "#fdfdfd",
  "solan-header-menuButton-active-text": "#1E6BFF",

  "solan-process-0-text": "#1e6bff",
  "solan-process-1-text": "#117e15",
  "solan-process-2-text": "#d3a121",
  "solan-process-3-text": "#ff3c3c",

  "solan-process-0-cell-bg-weak": "#f6f6ff",
  "solan-process-1-cell-bg-weak": "#f5fff0",
  "solan-process-2-cell-bg-weak": "#fff7eb",
  "solan-process-3-cell-bg-weak": "#fff1f1",

  "solan-process-0-cell-bg": "#e9eaff",
  "solan-process-1-cell-bg": "#e7ffdf",
  "solan-process-2-cell-bg": "#fff2bc",
  "solan-process-3-cell-bg": "#ffe0e0",

  "solan-process-0-cell-bg-strong": "#ced0ff",
  "solan-process-1-cell-bg-strong": "#b2f38b",
  "solan-process-2-cell-bg-strong": "#f5d467",
  "solan-process-3-cell-bg-strong": "#ffb4b4",

  "profile-mainHeader": "#E1F9F2",
  "profile-header": "#E1F9F2",
  "profile-header-menuButton": "#99D1C1",
  "profile-header-menuButton-active": "#fdfdfd",
  "profile-header-menuButton-active-text": "#29543D",

  "link-text": "#2F5FC5",
  "table-cell-0": "#E1F9F2",
  "table-cell-1": "#F5FFFB",
  "table-cell-2": "#f8fffc",
  "unsaved-highlight-background": "#fff1f1",
  "error-highlight-background": "#ffecec",
} as const;
export const colorLabels = Object.keys(colors);
export type ColorLabel = keyof typeof colors;

export function isColorLabel(value: unknown): value is ColorLabel {
  return typeof value === "string" && colorLabels.some(v => v === value);
}

export function labelToColor(colorLabel: ColorLabel): string {
  return colors[colorLabel];
}















import Vue, { PropType } from "vue";
import ProjectHeader from "@/views/project/student/ProjectHeader/ProjectHeader.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { ProjectStore } from "@/store/ProjectStore";
import { AppStateStore } from "@/store/AppStateStore";
import { Quarter } from "@/ts/objects/common/Quarter";
import { DataSlotState } from "@/ts/DataSlot";
import log from "loglevel";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

export default Vue.extend({
  name: "Project",
  components: { ProjectHeader },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true },
  },
  created() {
    log.debug(`Project:created`);

    const classes = this.appStateStore.studentOrGuardianState?.studentInfo()?.classes ?? null;
    if (classes === null) return;

    const projectSchoolYearParam = this.$route.params["projectSchoolYear"];
    const projectQuarterParam = this.$route.params["projectQuarter"];
    this.projectStore.setQuarter(new Quarter(parseInt(projectSchoolYearParam, 10), parseInt(projectQuarterParam, 10)));

    const projectIdParam = this.$route.params["projectId"];
    this.projectStore.setProjectId(projectIdParam);

    this.projectStore.reloadProjects({
      projectRepository: this.projectRepository,
      classIds: classes.map(c => c.classId),
    });
  },
  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    log.debug(`Project:beforeRouteUpdate`);

    const projectSchoolYearParam = to.params["projectSchoolYear"];
    const projectQuarterParam = to.params["projectQuarter"];
    this.projectStore.setQuarter(new Quarter(parseInt(projectSchoolYearParam, 10), parseInt(projectQuarterParam, 10)));

    const projectIdParam = to.params["projectId"];

    if (projectIdParam === "init") {
      const firstProjectId = await this.projectStore.firstProjectIdOfQuarter.getDataWithTimeout();
      this.projectStore.setProjectId(firstProjectId);
      next(this.projectStore.studentBasePath);
    } else {
      this.projectStore.setProjectId(projectIdParam);
      next();
    }
  },
  // beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
  //   // beforeRouteEnter内ではthis(コンポーネント)にアクセスできないので、やむなくグローバルアクセスする。
  //   const appStateStore = appStateStoreGlobal;
  //   const projectStore = projectStoreGlobal;
  //
  //   const classes = appStateStore.studentOrGuardianState?.studentInfo()?.classes;
  //   if (classes === undefined) {
  //     navigate(projectStore, to, from, next);
  //     return;
  //   }
  //   projectStore.reloadProjects(classes.map(c => c.classId));
  //   navigate(projectStore, to, from, next);
  // },
  // beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
  //   navigate(this.projectStore, to, from, next);
  // },
  computed: {
    currentRouteName(): string | null {
      return this.$route.name ?? null;
    },
    projectStoreDataSlotState(): DataSlotState {
      return this.projectStore.allProjects.state;
    },
    projectRname(): string {
      return this.projectStore.project.data?.self ?? "";
    },
  },
  watch: {
    async projectStoreDataSlotState(newValue: DataSlotState, oldValue: DataSlotState) {
      // プロジェクトリストのロードが終わったとき、プロジェクト未選択状態なら、1個目のプロジェクトを選択して、そのパスに遷移する。
      // ※ initに遷移すれば beforeRouteUpdate が拾ってくれるかと思いきや、パスが全く変化しない場合は発火しないので、うまく働かない。ので、この方式。
      const currentlySelectedProject = await this.projectStore.project.getDataWithTimeout();
      const firstProjectId = await this.projectStore.firstProjectIdOfQuarter.getDataWithTimeout();
      if (
        oldValue === DataSlotState.Loading &&
        newValue === DataSlotState.DataLoaded &&
        currentlySelectedProject === null
      ) {
        this.projectStore.setProjectId(firstProjectId);
        this.$router.push(this.projectStore.studentBasePath).catch(() => {});
      }
    },
  },
});

// async function navigate(projectStore: ProjectStore, to: Route, from: Route, next: NavigationGuardNext) {
//   const schoolYearParam = to.params["projectSchoolYear"];
//   const quarterParam = to.params["projectQuarter"];
//   projectStore.setQuarter(new Quarter(parseInt(schoolYearParam, 10), parseInt(quarterParam, 10)));
//
//   const projectIdParam = to.params["projectId"];
//
//   if (projectIdParam === "-") {
//     projectStore.setProjectId(null);
//     next();
//     return;
//   }
//
//   if (projectIdParam === "init") {
//     if (projectStore.firstProjectIdOfQuarter.isLoading()) {
//       projectStore.setProjectId(null);
//       next();
//       return;
//     }
//     projectStore.setProjectId(projectStore.firstProjectIdOfQuarter.data);
//     next(projectStore.studentBasePath);
//     return;
//   }
//
//   projectStore.setProjectId(projectIdParam);
//   next();
// }

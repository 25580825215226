
















import TipBlock from "@/components/TipBlock.vue";
import Vue from "vue";
import { messages, Messages } from "@/ts/const/Messages";

/**
 * 非公開インジケータ。
 */
export default Vue.extend({
  name: "NotPublishedIndicator",
  components: { TipBlock },
  props: {
    published: { type: Boolean, required: true },

    fontSize: { type: Number, required: true },
  },
  data(): { messages: Messages } {
    return { messages };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--font-size": `${this.fontSize}px`,
      };
    },
  },
});

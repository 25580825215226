import { render, staticRenderFns } from "./AppHeaderTabT.vue?vue&type=template&id=1838c3b0&scoped=true&"
import script from "./AppHeaderTabT.vue?vue&type=script&lang=ts&"
export * from "./AppHeaderTabT.vue?vue&type=script&lang=ts&"
import style0 from "./AppHeaderTabT.vue?vue&type=style&index=0&id=1838c3b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1838c3b0",
  null
  
)

export default component.exports
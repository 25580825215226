

























import Vue, { PropType } from "vue";
import SecondaryHeaderTab from "@/components/button/SecondaryHeaderTab/SecondaryHeaderTab.vue";
import { words, Words } from "@/ts/const/Words";
import { ProfileTabName } from "@/ts/objects/profile/value/ProfileTabName";

export default Vue.extend({
  name: "ProfileHeader",
  components: { SecondaryHeaderTab },
  props: {
    currentTabName: { type: String as PropType<ProfileTabName>, required: false },
    studentButtonPath: { type: String, required: true },
    guardianButtonPath: { type: String, required: true },
  },
  data(): { words: Words } {
    return { words };
  },
});









































import Vue, { PropType } from "vue";
import { formatDate, FormattedDatetime } from "@/ts/objects/common/FormattedDatetime";
import RecentActivityView from "@/views/activity/components/RecentActivityView.vue";
import { RecentStudy } from "@/ts/objects/common/RecentStudy";
import { AppStateStore } from "@/store/AppStateStore";
import { Class } from "@/ts/objects/common/Class";
import { Err } from "@/ts/objects/Err";
import { DataSlot } from "@/ts/DataSlot";
import { LogRepository } from "@/ts/repositories/LogRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";
import ClassSelector from "@/views/home/teacher/components/ClassSelector/ClassSelector.vue";
import HomeTDateSection from "@/views/home/teacher/components/HomeTDateSection/HomeTDateSection.vue";
import HomeTRecentStudy from "@/views/home/teacher/components/HomeTRecentStudy/HomeTRecentStudy.vue";

type RecentStudiesTabState = {
  curriculum: boolean;
  project: boolean;
  solan: boolean;
};

export default Vue.extend({
  name: "HomeT",
  components: {
    HomeTRecentStudy,
    HomeTDateSection,
    ClassSelector,
    RecentActivityView,
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    activityRepository: { type: Object as PropType<ActivityRepository>, required: true },
    logRepository: { type: Object as PropType<LogRepository>, required: true },
  },
  created() {
    this.reloadRecentStudies();
  },
  data(): {
    now: FormattedDatetime;
    recentStudies: DataSlot<RecentStudy[]>;
    recentStudiesTabState: RecentStudiesTabState;
    debouncedReloadRecentStudies: () => void;

    words: Words;
    messages: Messages;
  } {
    return {
      now: formatDate(new Date()),
      recentStudies: new DataSlot<RecentStudy[]>(),
      recentStudiesTabState: {
        curriculum: true,
        project: true,
        solan: true,
      },
      debouncedReloadRecentStudies: () => {},

      words,
      messages,
    };
  },
  computed: {
    _appStateStore(): AppStateStore {
      return this.appStateStore;
    },
    selectedClass(): Class | null {
      return this.appStateStore.teacherState?.selectedClass() ?? null;
    },
    groupedClassSelections(): { schoolYear: number; selections: Class[] }[] | null {
      return this.appStateStore.teacherState?.orderedGroupedClasses() ?? null;
    },
  },
  methods: {
    handleChangeRecentStudiesTabState(tabState: RecentStudiesTabState) {
      this.recentStudiesTabState = tabState;
    },
    async reloadRecentStudies(): Promise<void> {
      const selectedClass = this.selectedClass;
      if (selectedClass === null) return;

      const classStudents = await selectedClass.sortedClassStudents(this.userRepository);
      this.recentStudies.startLoadingWith(async () => {
        const resp = await this.logRepository.loadRecentStudies(classStudents.map(s => s.studentUserId));
        if (resp instanceof Err) {
          return null;
        }
        return resp;
      });
    },
    selectedRecentActivity(studentUserId: string, uuid: string, date: Date) {
      // query にはstringしか指定できないためdate.toString() としている。ActivityTでnew Date(d)としている。
      this.$router
        .push({
          name: "T.Activity",
          query: {
            targetStudentUserId: studentUserId,
            targetUuid: uuid,
            targetBaseDate: date.toString(),
          },
        })
        .catch(() => {});
    },
  },
});

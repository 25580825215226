import { isNullish } from "@/ts/utils";

export const sexes = {
  male: "男",
  female: "女",
  other: "その他",
} as const;

/**
 * 性別。
 */
export type Sex = keyof typeof sexes;
export type SexDisplayValue = typeof sexes[Sex];

export function isSex(value: unknown): value is Sex {
  return typeof value === "string" && Object.keys(sexes).some(v => v === value);
}

export function sexToDisplayValue(value: Sex): SexDisplayValue {
  return sexes[value];
}

export function displayValueToSexOrNull(displayValue: string): Sex | null {
  const kv = Object.entries(sexes).find(([_k, v]) => v === displayValue);
  if (isNullish(kv)) return null;
  const k = kv[0];
  if (!isSex(k)) return null;
  return k;
}

export function displayValueToSexOrError(displayValue: string): Sex {
  const result = displayValueToSexOrNull(displayValue);
  if (isNullish(result)) throw new Error(`displayValueToSexOrError: invalid sex display value: ${displayValue}`);
  return result;
}

export function sexFromStringOrNull(value: string): Sex | null {
  return isSex(value) ? value : null;
}

export function sexFromStringOrError(value: string): Sex {
  const result = sexFromStringOrNull(value);
  if (isNullish(result)) throw new Error(`sexFromStringOrError: invalid sex value: ${value}`);
  return result;
}

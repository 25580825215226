/* tslint:disable */
/* eslint-disable */
/**
 * アクティビティサービス
 * アクティビティに関する処理を行う。
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    uuid: string;
    /**
     * 投稿者のユーザーID
     * @type {string}
     * @memberof Activity
     */
    userId: string;
    /**
     * 児童生徒のユーザーID。
     * @type {string}
     * @memberof Activity
     */
    studentUserId: string;
    /**
     * 児童生徒の登録時のクラスID。 backend(activity-service->user-service)でstundentUserIdより、classIDを取得 教師の場合は\"\"を設定。 （教師画面の検索条件として使用する）
     * @type {string}
     * @memberof Activity
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    updatedAt: string;
    /**
     * text / image / video / stamp / sound / other
     * @type {string}
     * @memberof Activity
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Activity
     */
    deleteFlg: boolean;
    /**
     * 
     * @type {ActivityFile}
     * @memberof Activity
     */
    file?: ActivityFile;
    /**
     * 
     * @type {ActivityAdvanced}
     * @memberof Activity
     */
    advanced?: ActivityAdvanced;
}
/**
 * **教師によるクエリ時のみ**返却する。
 * @export
 * @interface ActivityAdvanced
 */
export interface ActivityAdvanced {
    /**
     * マイリスト登録したユーザーのIDのリスト。
     * @type {Array<string>}
     * @memberof ActivityAdvanced
     */
    mylistedUsers?: Array<string>;
    /**
     * 既読ユーザーのIDのリスト。
     * @type {Array<string>}
     * @memberof ActivityAdvanced
     */
    readUsers?: Array<string>;
    /**
     * クエリ発行者（selfクエリパラメタ）がマイリスト登録していればtrue。
     * @type {boolean}
     * @memberof ActivityAdvanced
     */
    mylisted?: boolean;
    /**
     * クエリ発行者（selfクエリパラメタ）が既読済ならtrue。
     * @type {boolean}
     * @memberof ActivityAdvanced
     */
    read?: boolean;
}
/**
 * 
 * @export
 * @interface ActivityFile
 */
export interface ActivityFile {
    /**
     * image / video / stamp / sound / other
     * @type {string}
     * @memberof ActivityFile
     */
    type?: string;
    /**
     * image -> jpg / png ... video -> video/mp4 ... slides -> powerpoint / keynote ... ...
     * @type {string}
     * @memberof ActivityFile
     */
    subtype?: string;
    /**
     * バケット名を含めない、GCSにおけるパス。
     * @type {string}
     * @memberof ActivityFile
     */
    gcsObjectPath?: string;
    /**
     * バケット名を含めない、GCSにおけるThumbnailパス。
     * @type {string}
     * @memberof ActivityFile
     */
    gcsObjectThumbnailPath?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFile
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFile
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityFile
     */
    fileSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityFile
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityFile
     */
    height?: number;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject1
     */
    mylisted: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject2
     */
    mylisted: boolean;
}
/**
 * 
 * @export
 * @interface PostActivityStampUrl
 */
export interface PostActivityStampUrl {
    /**
     * 
     * @type {string}
     * @memberof PostActivityStampUrl
     */
    url: string;
}
/**
 * 
 * @export
 * @interface PostActivityText
 */
export interface PostActivityText {
    /**
     * 
     * @type {string}
     * @memberof PostActivityText
     */
    text: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。
         * @summary アクティビティを削除する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity: async (studentUserId: string, activityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('deleteActivity', 'studentUserId', studentUserId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('deleteActivity', 'activityId', activityId)
            const localVarPath = `/students/{studentUserId}/activities/{activityId}`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上恐らく無いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityMylist: async (studentUserId: string, activityId: string, inlineObject2?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('deleteActivityMylist', 'studentUserId', studentUserId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('deleteActivityMylist', 'activityId', activityId)
            const localVarPath = `/students/{studentUserId}/activities/{activityId}:mylist`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary アクティビティを取得する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity: async (studentUserId: string, activityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('getActivity', 'studentUserId', studentUserId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getActivity', 'activityId', activityId)
            const localVarPath = `/students/{studentUserId}/activities/{activityId}`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary アクティビティリストを取得する。
         * @param {string} studentUserId 
         * @param {string} [self] 自分自身のユーザーID。IDトークンのsubクレームと必ず一致する必要がある。マイリストや既読の判別に用いるので、教師のみ使用する意味がある。
         * @param {boolean} [mylisted] マイリスト登録済かどうか。教師のみ使用可能。使用には&#x60;self&#x60;の指定が必須。
         * @param {boolean} [read] 既読かどうか。教師のみ使用可能。使用には&#x60;self&#x60;の指定が必須。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivities: async (studentUserId: string, self?: string, mylisted?: boolean, read?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('listActivities', 'studentUserId', studentUserId)
            const localVarPath = `/students/{studentUserId}/activities`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (self !== undefined) {
                localVarQueryParameter['self'] = self;
            }

            if (mylisted !== undefined) {
                localVarQueryParameter['mylisted'] = mylisted;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、使用不可   * 保護者は、使用不可 * 教師   * なんでも取得可能。
         * @summary アクティビティリストを取得する。
         * @param {string} keyword 検索キーワード（あいまい検索）
         * @param {string} classId 検索対象のclassId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchActivities: async (keyword: string, classId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('listSearchActivities', 'keyword', keyword)
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('listSearchActivities', 'classId', classId)
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (classId !== undefined) {
                localVarQueryParameter['classId'] = classId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（テキスト）を作成する。
         * @param {string} studentUserId 
         * @param {PostActivityText} [postActivityText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivities: async (studentUserId: string, postActivityText?: PostActivityText, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('postActivities', 'studentUserId', studentUserId)
            const localVarPath = `/students/{studentUserId}/activities`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postActivityText, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（ファイル）を作成する。
         * @param {string} studentUserId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivitiesFile: async (studentUserId: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('postActivitiesFile', 'studentUserId', studentUserId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postActivitiesFile', 'file', file)
            const localVarPath = `/students/{studentUserId}/activities:file`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（スタンプ）を作成する。
         * @param {string} studentUserId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivitiesStamp: async (studentUserId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('postActivitiesStamp', 'studentUserId', studentUserId)
            const localVarPath = `/students/{studentUserId}/activities:stamp`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（テキスト）を作成する。
         * @param {string} studentUserId 
         * @param {PostActivityText} [postActivityText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivitiesText: async (studentUserId: string, postActivityText?: PostActivityText, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('postActivitiesText', 'studentUserId', studentUserId)
            const localVarPath = `/students/{studentUserId}/activities:text`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postActivityText, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上恐らく無いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @summary アクティビティのマイリスト登録状態を変更する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivityMylist: async (studentUserId: string, activityId: string, inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('postActivityMylist', 'studentUserId', studentUserId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('postActivityMylist', 'activityId', activityId)
            const localVarPath = `/students/{studentUserId}/activities/{activityId}:mylist`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上意味は薄いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @summary アクティビティを既読にする。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivityRead: async (studentUserId: string, activityId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentUserId' is not null or undefined
            assertParamExists('postActivityRead', 'studentUserId', studentUserId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('postActivityRead', 'activityId', activityId)
            const localVarPath = `/students/{studentUserId}/activities/{activityId}:read`
                .replace(`{${"studentUserId"}}`, encodeURIComponent(String(studentUserId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。
         * @summary アクティビティを削除する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivity(studentUserId: string, activityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivity(studentUserId, activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上恐らく無いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivityMylist(studentUserId: string, activityId: string, inlineObject2?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivityMylist(studentUserId, activityId, inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary アクティビティを取得する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivity(studentUserId: string, activityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(studentUserId, activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary アクティビティリストを取得する。
         * @param {string} studentUserId 
         * @param {string} [self] 自分自身のユーザーID。IDトークンのsubクレームと必ず一致する必要がある。マイリストや既読の判別に用いるので、教師のみ使用する意味がある。
         * @param {boolean} [mylisted] マイリスト登録済かどうか。教師のみ使用可能。使用には&#x60;self&#x60;の指定が必須。
         * @param {boolean} [read] 既読かどうか。教師のみ使用可能。使用には&#x60;self&#x60;の指定が必須。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActivities(studentUserId: string, self?: string, mylisted?: boolean, read?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Activity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActivities(studentUserId, self, mylisted, read, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、使用不可   * 保護者は、使用不可 * 教師   * なんでも取得可能。
         * @summary アクティビティリストを取得する。
         * @param {string} keyword 検索キーワード（あいまい検索）
         * @param {string} classId 検索対象のclassId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSearchActivities(keyword: string, classId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Activity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSearchActivities(keyword, classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（テキスト）を作成する。
         * @param {string} studentUserId 
         * @param {PostActivityText} [postActivityText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivities(studentUserId: string, postActivityText?: PostActivityText, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivities(studentUserId, postActivityText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（ファイル）を作成する。
         * @param {string} studentUserId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivitiesFile(studentUserId: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivitiesFile(studentUserId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（スタンプ）を作成する。
         * @param {string} studentUserId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivitiesStamp(studentUserId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivitiesStamp(studentUserId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（テキスト）を作成する。
         * @param {string} studentUserId 
         * @param {PostActivityText} [postActivityText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivitiesText(studentUserId: string, postActivityText?: PostActivityText, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivitiesText(studentUserId, postActivityText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上恐らく無いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @summary アクティビティのマイリスト登録状態を変更する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivityMylist(studentUserId: string, activityId: string, inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivityMylist(studentUserId, activityId, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上意味は薄いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @summary アクティビティを既読にする。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivityRead(studentUserId: string, activityId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivityRead(studentUserId, activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。
         * @summary アクティビティを削除する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivity(studentUserId: string, activityId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteActivity(studentUserId, activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上恐らく無いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {InlineObject2} [inlineObject2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityMylist(studentUserId: string, activityId: string, inlineObject2?: InlineObject2, options?: any): AxiosPromise<Activity> {
            return localVarFp.deleteActivityMylist(studentUserId, activityId, inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary アクティビティを取得する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity(studentUserId: string, activityId: string, options?: any): AxiosPromise<Activity> {
            return localVarFp.getActivity(studentUserId, activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
         * @summary アクティビティリストを取得する。
         * @param {string} studentUserId 
         * @param {string} [self] 自分自身のユーザーID。IDトークンのsubクレームと必ず一致する必要がある。マイリストや既読の判別に用いるので、教師のみ使用する意味がある。
         * @param {boolean} [mylisted] マイリスト登録済かどうか。教師のみ使用可能。使用には&#x60;self&#x60;の指定が必須。
         * @param {boolean} [read] 既読かどうか。教師のみ使用可能。使用には&#x60;self&#x60;の指定が必須。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActivities(studentUserId: string, self?: string, mylisted?: boolean, read?: boolean, options?: any): AxiosPromise<Array<Activity>> {
            return localVarFp.listActivities(studentUserId, self, mylisted, read, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、使用不可   * 保護者は、使用不可 * 教師   * なんでも取得可能。
         * @summary アクティビティリストを取得する。
         * @param {string} keyword 検索キーワード（あいまい検索）
         * @param {string} classId 検索対象のclassId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchActivities(keyword: string, classId: string, options?: any): AxiosPromise<Array<Activity>> {
            return localVarFp.listSearchActivities(keyword, classId, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（テキスト）を作成する。
         * @param {string} studentUserId 
         * @param {PostActivityText} [postActivityText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivities(studentUserId: string, postActivityText?: PostActivityText, options?: any): AxiosPromise<Activity> {
            return localVarFp.postActivities(studentUserId, postActivityText, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（ファイル）を作成する。
         * @param {string} studentUserId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivitiesFile(studentUserId: string, file: any, options?: any): AxiosPromise<Activity> {
            return localVarFp.postActivitiesFile(studentUserId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（スタンプ）を作成する。
         * @param {string} studentUserId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivitiesStamp(studentUserId: string, body?: object, options?: any): AxiosPromise<Activity> {
            return localVarFp.postActivitiesStamp(studentUserId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
         * @summary アクティビティ（テキスト）を作成する。
         * @param {string} studentUserId 
         * @param {PostActivityText} [postActivityText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivitiesText(studentUserId: string, postActivityText?: PostActivityText, options?: any): AxiosPromise<Activity> {
            return localVarFp.postActivitiesText(studentUserId, postActivityText, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上恐らく無いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @summary アクティビティのマイリスト登録状態を変更する。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivityMylist(studentUserId: string, activityId: string, inlineObject1?: InlineObject1, options?: any): AxiosPromise<Activity> {
            return localVarFp.postActivityMylist(studentUserId, activityId, inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上意味は薄いが、他教師や自分自身のアクティビティに対してでもOK。）
         * @summary アクティビティを既読にする。
         * @param {string} studentUserId 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivityRead(studentUserId: string, activityId: string, options?: any): AxiosPromise<void> {
            return localVarFp.postActivityRead(studentUserId, activityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。
     * @summary アクティビティを削除する。
     * @param {string} studentUserId 
     * @param {string} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteActivity(studentUserId: string, activityId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteActivity(studentUserId, activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上恐らく無いが、他教師や自分自身のアクティビティに対してでもOK。）
     * @param {string} studentUserId 
     * @param {string} activityId 
     * @param {InlineObject2} [inlineObject2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteActivityMylist(studentUserId: string, activityId: string, inlineObject2?: InlineObject2, options?: any) {
        return DefaultApiFp(this.configuration).deleteActivityMylist(studentUserId, activityId, inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
     * @summary アクティビティを取得する。
     * @param {string} studentUserId 
     * @param {string} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getActivity(studentUserId: string, activityId: string, options?: any) {
        return DefaultApiFp(this.configuration).getActivity(studentUserId, activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 児童生徒は、`studentUserId`が自分であるもののみ取得可能。   * 保護者は、`studentUserId`が自分に属する児童生徒であるもののみ取得可能。 * 教師   * なんでも取得可能。
     * @summary アクティビティリストを取得する。
     * @param {string} studentUserId 
     * @param {string} [self] 自分自身のユーザーID。IDトークンのsubクレームと必ず一致する必要がある。マイリストや既読の判別に用いるので、教師のみ使用する意味がある。
     * @param {boolean} [mylisted] マイリスト登録済かどうか。教師のみ使用可能。使用には&#x60;self&#x60;の指定が必須。
     * @param {boolean} [read] 既読かどうか。教師のみ使用可能。使用には&#x60;self&#x60;の指定が必須。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listActivities(studentUserId: string, self?: string, mylisted?: boolean, read?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).listActivities(studentUserId, self, mylisted, read, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 児童生徒は、使用不可   * 保護者は、使用不可 * 教師   * なんでも取得可能。
     * @summary アクティビティリストを取得する。
     * @param {string} keyword 検索キーワード（あいまい検索）
     * @param {string} classId 検索対象のclassId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSearchActivities(keyword: string, classId: string, options?: any) {
        return DefaultApiFp(this.configuration).listSearchActivities(keyword, classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
     * @summary アクティビティ（テキスト）を作成する。
     * @param {string} studentUserId 
     * @param {PostActivityText} [postActivityText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postActivities(studentUserId: string, postActivityText?: PostActivityText, options?: any) {
        return DefaultApiFp(this.configuration).postActivities(studentUserId, postActivityText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
     * @summary アクティビティ（ファイル）を作成する。
     * @param {string} studentUserId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postActivitiesFile(studentUserId: string, file: any, options?: any) {
        return DefaultApiFp(this.configuration).postActivitiesFile(studentUserId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
     * @summary アクティビティ（スタンプ）を作成する。
     * @param {string} studentUserId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postActivitiesStamp(studentUserId: string, body?: object, options?: any) {
        return DefaultApiFp(this.configuration).postActivitiesStamp(studentUserId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 児童生徒は、`studentUserId`に自分を指定したときのみ使用可能。   * 保護者は、`studentUserId`に自分に属する児童生徒を指定したときのみ使用可能。 * 教師   * いつでも使用可能。
     * @summary アクティビティ（テキスト）を作成する。
     * @param {string} studentUserId 
     * @param {PostActivityText} [postActivityText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postActivitiesText(studentUserId: string, postActivityText?: PostActivityText, options?: any) {
        return DefaultApiFp(this.configuration).postActivitiesText(studentUserId, postActivityText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上恐らく無いが、他教師や自分自身のアクティビティに対してでもOK。）
     * @summary アクティビティのマイリスト登録状態を変更する。
     * @param {string} studentUserId 
     * @param {string} activityId 
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postActivityMylist(studentUserId: string, activityId: string, inlineObject1?: InlineObject1, options?: any) {
        return DefaultApiFp(this.configuration).postActivityMylist(studentUserId, activityId, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * * 児童生徒と保護者   * 使用不可。 * 教師   * すべてに対して使用可能。（運用上意味は薄いが、他教師や自分自身のアクティビティに対してでもOK。）
     * @summary アクティビティを既読にする。
     * @param {string} studentUserId 
     * @param {string} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postActivityRead(studentUserId: string, activityId: string, options?: any) {
        return DefaultApiFp(this.configuration).postActivityRead(studentUserId, activityId, options).then((request) => request(this.axios, this.basePath));
    }
}



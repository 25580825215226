import { Err } from "@/ts/objects/Err";
import { ActionLog, ActionLogMeta } from "@/ts/objects/common/ActionLog";
import { Configuration, DefaultApi as LogDefaultApi } from "@/ts/api/log-service";
import { AxiosInstance } from "axios";
import { RecentStudy } from "@/ts/objects/common/RecentStudy";
import { doReq, parseUTCOrZero } from "@/ts/utils/AppUtil";

export abstract class LogRepository {
  abstract loadActionLogs(studentUserIds: string[]): Promise<ActionLog[] | Err>;

  abstract loadRecentStudies(studentUserIds: string[]): Promise<RecentStudy[] | Err>;
}

export class LogRepositoryImpl extends LogRepository {
  private readonly logService: LogDefaultApi;

  constructor(serviceBasePath: string, axiosConf: Configuration | undefined, axiosInstance: AxiosInstance) {
    super();
    this.logService = new LogDefaultApi(axiosConf, serviceBasePath, axiosInstance);
  }

  async loadActionLogs(studentUserIds: string[]): Promise<ActionLog[] | Err> {
    // クエリパラメタで0件配列を指定することはできず(少なくとも、 "?foo=1&foo=2" のように繰り返す方式では)、未指定と同じになってしまうので、仕方ないから特別扱いする。
    if (studentUserIds.length === 0) return [];

    const resp = await doReq(() =>
      this.logService.listActionLog(
        undefined,
        undefined,
        studentUserIds,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
    );
    if (resp instanceof Err) return resp;

    return resp.map(
      a =>
        new ActionLog(
          a.actionLogId,
          a.studentUserId,
          a.studentUserName,
          a.studentPhotoUrl,
          a.subjectUserType,
          a.subjectUserId,
          a.subjectUserName,
          a.subjectUserPhotoUrl,
          a.objectService,
          a.objectResourceName,
          a.updatedPropertyCategory,
          a.data,
          new ActionLogMeta(a.meta?.schoolYear, a.meta?.month),
          parseUTCOrZero(a.createdAt),
          parseUTCOrZero(a.updatedAt)
        )
    );
  }

  async loadRecentStudies(studentUserIds: string[]): Promise<RecentStudy[] | Err> {
    // クエリパラメタで0件配列を指定することはできず(少なくとも、 "?foo=1&foo=2" のように繰り返す方式では)、未指定と同じになってしまうので、仕方ないから特別扱いする。
    if (studentUserIds.length === 0) return [];

    const resp = await doReq(() =>
      this.logService.listRecentStudies(
        undefined,
        undefined,
        studentUserIds,
        undefined,
        undefined,
        undefined,
        undefined
      )
    );
    if (resp instanceof Err) return resp;

    const recentStudies = resp.map(recentStudy => {
      if (recentStudy.actionLogs.length === 0) {
        return null;
      } else {
        const actionLogs = recentStudy.actionLogs.map(
          a =>
            new ActionLog(
              a.actionLogId,
              a.studentUserId,
              a.studentUserName,
              a.studentPhotoUrl,
              a.subjectUserType,
              a.subjectUserId,
              a.subjectUserName,
              a.subjectUserPhotoUrl,
              a.objectService,
              a.objectResourceName,
              a.updatedPropertyCategory,
              a.data,
              new ActionLogMeta(a.meta?.schoolYear, a.meta?.month),
              parseUTCOrZero(a.createdAt),
              parseUTCOrZero(a.updatedAt)
            )
        );
        return new RecentStudy(actionLogs);
      }
    });

    return recentStudies.filter((rs: RecentStudy | null): rs is RecentStudy => rs !== null);
  }
}

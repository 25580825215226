import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";

/**
 * 編集可能なプロジェクト学習記録の児童生徒。
 */
export class EditableProjectStudent extends EditableObject {
  readonly studentUserId: string;
  readonly studentNumber: number;
  readonly name: string;
  readonly projectJournals: EditableProjectJournal[];
  readonly projectLookback: EditableProjectLookback;

  protected allEditables() {
    return [...this.projectJournals, this.projectLookback];
  }

  constructor(
    studentUserId: string,
    studentNumber: number,
    name: string,
    projectJournals: EditableProjectJournal[],
    projectLookback: EditableProjectLookback
  ) {
    super();
    this.studentUserId = studentUserId;
    this.studentNumber = studentNumber;
    this.name = name;
    this.projectJournals = projectJournals;
    this.projectLookback = projectLookback;
  }
}

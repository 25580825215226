import { isNullish } from "@/ts/utils";
import { isMonthValue, MonthValue } from "@/ts/objects/common/MonthValue";

/**
 * 日付。
 *
 * displayValue = 2000-01-01
 */
export type DateValue = {
  readonly year: number;
  readonly month: MonthValue;
  readonly day: number;
};

export function dateValueToDisplayValue(value: DateValue): string {
  const yearText = value.year.toString(10).padStart(4, "0");
  const monthText = value.month.toString(10).padStart(2, "0");
  const dayText = value.day.toString(10).padStart(2, "0");
  return `${yearText}-${monthText}-${dayText}`;
}

export function displayValueToDateValueOrNull(displayValue: string): DateValue | null {
  const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;

  const match = displayValue.match(dateRegex);
  if (match === null) return null;

  const year = parseInt(match[1]);
  const month = parseInt(match[2]);
  const day = parseInt(match[3]);
  if (isNullish(year) || isNaN(year) || !isMonthValue(month) || isNullish(day) || isNaN(day)) return null;

  return { year, month, day };
}
export function displayValueToDateValueOrError(displayValue: string): DateValue {
  const result = displayValueToDateValueOrNull(displayValue);
  if (isNullish(result)) throw new Error("displayValueToDateValueOrError: invalid date value");
  return result;
}

export function dateValueToDate(value: DateValue): Date {
  return new Date(value.year, value.month - 1, value.day);
}

export function dateValueToDateNullable(value: DateValue | null | undefined): Date | null {
  if (isNullish(value)) return null;
  return new Date(value.year, value.month - 1, value.day);
}

export function dateToDateValue(date: Date): DateValue {
  return {
    year: date.getFullYear(),
    month: (date.getMonth() + 1) as MonthValue,
    day: date.getDate(),
  };
}

export function dateToDateValueNullable(date: Date | null | undefined): DateValue | null {
  if (isNullish(date)) return null;
  return {
    year: date.getFullYear(),
    month: (date.getMonth() + 1) as MonthValue,
    day: date.getDate(),
  };
}

export function compareDateValue(a: DateValue, b: DateValue): -1 | 0 | 1 {
  if (a.year > b.year) return 1;
  if (a.year < b.year) return -1;

  if (a.month > b.month) return 1;
  if (a.month < b.month) return -1;

  if (a.day > b.day) return 1;
  if (a.day < b.day) return -1;

  return 0;
}

import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import Home from "@/views/home/student/Home.vue";
import Project from "@/views/project/student/Project.vue";
import ProjectJournals from "@/views/project/student/ProjectJournals/ProjectJournals.vue";
import ProjectRubrics from "@/views/project/student/ProjectRubrics/ProjectRubrics.vue";
import ProjectAbout from "@/views/project/student/ProjectAbout/ProjectAbout.vue";
import ProjectLookback from "@/views/project/student/ProjectLookback/ProjectLookback.vue";
import ProjectT from "@/views/project/teacher/ProjectT.vue";
import ProjectAboutT from "@/views/project/teacher/ProjectAboutT.vue";
import ProjectJournalsT from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsT.vue";
import ProjectStudentViewT from "@/views/project/teacher/ProjectStudentViewT.vue";
import ProjectStudentViewJournalsT from "@/views/project/teacher/studentview/ProjectStudentViewJournalsT.vue";
import ProjectStudentViewLookbackT from "@/views/project/teacher/studentview/ProjectStudentViewLookbackT.vue";
import ActivityT from "@/views/activity/teacher/ActivityT.vue";
import HomeT from "@/views/home/teacher/HomeT.vue";
import { AppStateStore } from "@/store/AppStateStore";
import Solan from "@/views/solan/student/Solan.vue";
import SolanAbout from "@/views/solan/student/SolanAbout/SolanAbout.vue";
import SolanLookback from "@/views/solan/student/SolanLookback/SolanLookback.vue";
import SolanJournals from "@/views/solan/student/SolanJournals/SolanJournals.vue";
import SolanRubrics from "@/views/solan/student/SolanRubrics/SolanRubrics.vue";
import SolanT from "@/views/solan/teacher/SolanT.vue";
import SolanJournalsT from "@/views/solan/teacher/SolanJournalsT/SolanJournalsT.vue";
import SolanStudentViewT from "@/views/solan/teacher/SolanStudentViewT/SolanStudentViewT.vue";
import SolanStudentViewJournalsT from "@/views/solan/teacher/SolanStudentViewT/SolanStudentViewJournalsT/SolanStudentViewJournalsT.vue";
import SolanStudentViewLookbackT from "@/views/solan/teacher/SolanStudentViewT/SolanStudentViewLookbackT/SolanStudentViewLookbackT.vue";
import SolanStudentViewRubricsT from "@/views/solan/teacher/SolanStudentViewT/SolanStudentViewRubricsT/SolanStudentViewRubricsT.vue";
import SolanStudentViewAboutT from "@/views/solan/teacher/SolanStudentViewT/SolanStudentViewAboutT/SolanStudentViewAboutT.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import log from "loglevel";
import { ProjectStore } from "@/store/ProjectStore";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { SolanStoreS, SolanStoreT } from "@/store/SolanStore";
import { SolanProjectInfoOnEditStore } from "@/store/SolanProjectOnEditStore";
import { LogRepository } from "@/ts/repositories/LogRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";
import { ProjectInfoOnEditStore } from "@/store/ProjectInfoOnEditStore";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import Curriculum from "@/views/curriculum/student/Curriculum.vue";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";
import CurriculumT from "@/views/curriculum/teacher/CurriculumT.vue";
import CurriculumWrite from "@/views/curriculum/student/CurriculumWrite.vue";
import CurriculumRead from "@/views/curriculum/student/CurriculumRead.vue";
import CurriculumReadEEC from "@/views/curriculum/student/CurriculumReadEEC/CurriculumReadEEC.vue";
import CurriculumReadNECYearly from "@/views/curriculum/student/CurriculumReadNECYearly/CurriculumReadNECYearly.vue";
import CurriculumReadNECMonthly from "@/views/curriculum/student/CurriculumReadNECMonthly/CurriculumReadNECMonthly.vue";
import CurriculumWriteEEC from "@/views/curriculum/student/CurriculumWriteEEC/CurriculumWriteEEC.vue";
import CurriculumListNEC from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNEC.vue";
import CurriculumSyllabusNEC from "@/views/curriculum/teacher/CurriculumSyllabusNEC/CurriculumSyllabusNEC.vue";
import CurriculumListEEC from "@/views/curriculum/teacher/CurriculumListEEC/CurriculumListEEC.vue";
import CurriculumMainT from "@/views/curriculum/teacher/CurriculumMainT.vue";
import CurriculumStudentView from "@/views/curriculum/teacher/CurriculumStudentView/CurriculumStudentView.vue";
import CurriculumSyllabusEEC from "@/views/curriculum/teacher/CurriculumSyllabusEEC/CurriculumSyllabusEEC.vue";
import Profile from "@/views/profile/Profile.vue";
import { ProfileStore } from "@/store/ProfileStore";
import ProfileStudent from "@/views/profile/ProfileStudent/ProfileStudent.vue";
import ProfileGuardian from "@/views/profile/ProfileGuardian/ProfileGuardian.vue";

export function getRouter(
  appStateStore: AppStateStore,
  curriculumStoreS: CurriculumStoreS,
  curriculumStoreT: CurriculumStoreT,
  projectStore: ProjectStore,
  projectInfoOnEditStore: ProjectInfoOnEditStore,
  solanStoreS: SolanStoreS,
  solanStoreT: SolanStoreT,
  solanProjectInfoOnEditStore: SolanProjectInfoOnEditStore,
  profileStore: ProfileStore,
  userRepository: UserRepository,
  activityRepository: ActivityRepository,
  projectRepository: ProjectRepository,
  solanRepository: SolanRepository,
  curriculumRepository: CurriculumRepository,
  logRepository: LogRepository
): VueRouter {
  const routes: RouteConfig[] = getRoutes(
    appStateStore,
    curriculumStoreS,
    curriculumStoreT,
    projectStore,
    projectInfoOnEditStore,
    solanStoreS,
    solanStoreT,
    solanProjectInfoOnEditStore,
    profileStore,
    userRepository,
    activityRepository,
    projectRepository,
    solanRepository,
    curriculumRepository,
    logRepository
  );

  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach((to, from, next) => {
    log.debug(`router.beforeEach: name=${to.name}, path=${to.path}`);
    if (to.name === "Error") {
      next();
    }

    if (appStateStore.isAppUserTeacher) {
      beforeEachT(to, from, next);
    } else {
      beforeEachS(to, from, next);
    }
  });

  function beforeEachS(to: Route, from: Route, next: NavigationGuardNext) {
    if (to.name?.startsWith("T.") ?? false) {
      // もし児童生徒なのに教師用画面にいた場合、エラーページにリダイレクトする。
      next("/error");
      return;
    }

    const studentUserId = to.params["studentUserId"] ?? "";

    if (studentUserId === "-") {
      // 児童生徒IDが、未指定を表す「-」の場合、そのまま通す。
      next();
      return;
    }

    if (appStateStore.isAppUserStudent && appStateStore.studentOrGuardianState?.studentUserId() !== studentUserId) {
      // もし児童生徒なのに違う児童生徒のページに行こうとしていたり、未指定だったら、ホーム画面にリダイレクトする。
      next(appStateStore.studentOrGuardianBasePath);
      return;
    }

    if (appStateStore.isAppUserGuardian) {
      // 保護者なら、指定した児童生徒を選択する。
      appStateStore.guardianState?.selectStudent(studentUserId);

      if (appStateStore.guardianState?.studentInfo() === null) {
        // 指定した児童生徒がいないなど、選択できていなければ、最初の児童生徒を選択する。
        const studentUserId = appStateStore.guardianState?.firstStudentId() ?? "-";
        appStateStore.guardianState?.selectStudent(studentUserId);
        next(appStateStore.studentOrGuardianBasePath);
        return;
      }
    }

    next();
  }

  function beforeEachT(to: Route, from: Route, next: NavigationGuardNext) {
    if (to.name?.startsWith("S.") ?? false) {
      // もし教師なのに児童生徒用画面にいた場合、エラーページにリダイレクトする。
      next("/error");
      return;
    }

    const classId = to.params["classId"] ?? "";

    log.debug(`router.beforeEach.beforeEachT: classId=${classId}`);

    appStateStore.teacherState?.selectClass(classId);
    if (appStateStore.teacherState?.selectedClass() === null) {
      // もしクラス未選択の場合、最初のクラスを選択する。
      const firstClassId = appStateStore.teacherState?.firstClassId() ?? null;

      log.debug(`router.beforeEach.beforeEachT: firstClassId=${firstClassId}`);
      if (firstClassId === null) {
        // もしクラスを1個も持たない場合、専用エラーページにリダイレクトする。
        next("/error/noclass");
        return;
      }

      appStateStore.teacherState?.selectClass(firstClassId);
      next(appStateStore.teacherBasePath);
      return;
    }

    next();
  }

  router.beforeResolve((to, from, next) => {
    log.debug(`router.beforeResolve: ${to.name}`);
    if (appStateStore.isAppUserTeacher) {
      beforeResolveT(to, from, next);
    } else {
      beforeResolveS(to, from, next);
    }
  });

  function beforeResolveS(to: Route, from: Route, next: NavigationGuardNext) {
    next();
  }

  function beforeResolveT(to: Route, from: Route, next: NavigationGuardNext) {
    next();
  }

  return router;
}

export function getRoutes(
  appStateStore: AppStateStore,
  curriculumStoreS: CurriculumStoreS,
  curriculumStoreT: CurriculumStoreT,
  projectStore: ProjectStore,
  projectInfoOnEditStore: ProjectInfoOnEditStore,
  solanStoreS: SolanStoreS,
  solanStoreT: SolanStoreT,
  solanProjectInfoOnEditStore: SolanProjectInfoOnEditStore,
  profileStore: ProfileStore,
  userRepository: UserRepository,
  activityRepository: ActivityRepository,
  projectRepository: ProjectRepository,
  solanRepository: SolanRepository,
  curriculumRepository: CurriculumRepository,
  logRepository: LogRepository
): RouteConfig[] {
  return [
    {
      path: "/s/:studentUserId/",
      name: "S.Home",
      component: Home,
      props: {
        appStateStore,
        userRepository,
        activityRepository,
        logRepository,
      },
    },
    {
      path: "/s/:studentUserId/curriculum/:curriculumSchoolYear",
      name: "S.Curriculum._._._",
      component: Curriculum,
      props: {
        appStateStore,
        curriculumStoreS,
        curriculumRepository,
      },
      children: [
        {
          path: "write",
          name: "S.Curriculum.write._._",
          component: CurriculumWrite,
          props: {
            curriculumStoreS,
            curriculumRepository,
          },
          children: [
            {
              path: "eeCurriculums/:curriculumId",
              name: "S.Curriculum.write.eeCurriculums._",
              component: CurriculumWriteEEC,
              props: {
                curriculumStoreS,
                curriculumRepository,
              },
            },
          ],
        },
        {
          path: "read",
          name: "S.Curriculum.read._._",
          component: CurriculumRead,
          props: {
            curriculumStoreS,
          },
          children: [
            {
              path: "yearly/-/neCurriculums/:curriculumId",
              name: "S.Curriculum.read.neCurriculums.yearly",
              component: CurriculumReadNECYearly,
              props: {
                curriculumStoreS,
              },
            },
            {
              path: "yearly/-/eeCurriculums/:curriculumId",
              name: "S.Curriculum.read.eeCurriculums.yearly",
              component: CurriculumReadEEC,
              props: {
                curriculumStoreS,
              },
            },
            {
              path: "monthly/:curriculumMonth/neCurriculums/:curriculumId",
              name: "S.Curriculum.read.neCurriculums.monthly",
              component: CurriculumReadNECMonthly,
              props: {
                curriculumStoreS,
              },
            },
            {
              path: "monthly/:curriculumMonth/eeCurriculums/:curriculumId",
              name: "S.Curriculum.read.eeCurriculums.monthly",
              component: CurriculumReadEEC,
              props: {
                curriculumStoreS,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/s/:studentUserId/project/:projectSchoolYear/:projectQuarter/:projectId",
      component: Project,
      props: {
        appStateStore,
        projectStore,
        projectRepository,
      },
      children: [
        {
          path: "",
          redirect: "journals",
        },
        {
          path: "journals",
          name: "S.Project.Journals",
          component: ProjectJournals,
          props: {
            appStateStore,
            projectStore,
            projectRepository,
          },
        },
        {
          path: "rubrics",
          name: "S.Project.Rubrics",
          component: ProjectRubrics,
          props: {
            projectStore,
            projectRepository,
          },
        },
        {
          path: "about",
          name: "S.Project.About",
          component: ProjectAbout,
          props: {
            projectStore,
          },
        },
        {
          path: "lookback",
          name: "S.Project.Lookback",
          component: ProjectLookback,
          props: {
            appStateStore,
            projectStore,
            projectRepository,
          },
        },
      ],
    },
    {
      path: "/s/:studentUserId/solan/:solanSchoolYear/:solanProjectId",
      component: Solan,
      props: {
        appStateStore,
        solanStoreS,
        solanProjectInfoOnEditStore,
        solanRepository,
      },
      children: [
        {
          path: "",
          redirect: "journals",
        },
        {
          path: "journals",
          name: "S.Solan.Journals",
          component: SolanJournals,
          props: {
            appStateStore,
            solanStoreS,
            solanRepository,
          },
        },
        {
          path: "rubrics",
          name: "S.Solan.Rubrics",
          component: SolanRubrics,
          props: {
            appStateStore,
            solanStoreS,
            solanRepository,
          },
        },
        {
          path: "about",
          name: "S.Solan.About",
          component: SolanAbout,
          props: {
            appStateStore,
            solanStoreS,
            solanProjectInfoOnEditStore,
            solanRepository,
          },
        },
        {
          path: "lookback",
          name: "S.Solan.Lookback",
          component: SolanLookback,
          props: {
            appStateStore,
            solanStoreS,
            solanRepository,
          },
        },
      ],
    },
    {
      path: "/s/:studentUserId/profile",
      component: Profile,
      props: {
        profileStore,
      },
      children: [
        {
          path: "",
          redirect: "student",
        },
        {
          path: "student",
          name: "S.Profile.student",
          component: ProfileStudent,
          props: {
            appStateStore,
            userRepository,
          },
        },
        {
          path: "guardian",
          name: "S.Profile.guardian",
          component: ProfileGuardian,
          props: {
            appStateStore,
            userRepository,
          },
        },
      ],
    },
    {
      path: "/t/:classId",
      name: "T.Home",
      component: HomeT,
      props: {
        appStateStore,
        userRepository,
        activityRepository,
        logRepository,
      },
    },
    {
      path: "/t/:classId/activity",
      name: "T.Activity",
      component: ActivityT,
      props: route => ({
        appStateStore,
        userRepository,
        activityRepository,

        targetStudentUserId: route.query.targetStudentUserId ?? undefined,
        targetBaseDate: route.query.targetBaseDate ?? undefined,
        targetUuid: route.query.targetUuid ?? undefined,
      }),
    },
    {
      // 教師用 教科学習
      path: "/t/:classId/curriculum",
      name: "T.Curriculum._._",
      component: CurriculumT,
      props: {
        appStateStore,
        curriculumStoreT,
        userRepository,
        curriculumRepository,
      },
      children: [
        {
          path: "list",
          name: "T.Curriculum.list._",
          component: CurriculumMainT,
          props: {
            curriculumStoreT,
          },
          children: [
            {
              path: "neCurriculums/:curriculumId",
              name: "T.Curriculum.list.neCurriculums",
              component: CurriculumListNEC,
              props: {
                appStateStore,
                curriculumStoreT,
                userRepository,
                curriculumRepository,
              },
            },
            {
              path: "eeCurriculums/:curriculumId",
              name: "T.Curriculum.list.eeCurriculums",
              component: CurriculumListEEC,
              props: {
                appStateStore,
                curriculumStoreT,
                userRepository,
                curriculumRepository,
              },
            },
          ],
        },
        {
          path: "syllabus",
          name: "T.Curriculum.syllabus._",
          component: CurriculumMainT,
          props: {
            curriculumStoreT,
          },
          children: [
            {
              path: "neCurriculums/:curriculumId",
              name: "T.Curriculum.syllabus.neCurriculums",
              component: CurriculumSyllabusNEC,
              props: {
                curriculumStoreT,
                curriculumRepository,
              },
            },
            {
              path: "eeCurriculums/:curriculumId",
              name: "T.Curriculum.syllabus.eeCurriculums",
              component: CurriculumSyllabusEEC,
              props: {
                curriculumStoreT,
                curriculumRepository,
              },
            },
          ],
        },
        {
          path: "studentview/:studentUserId/:curriculumPeriodMode/:curriculumMonth/neCurriculums/:curriculumId",
          name: "T.Curriculum.studentview.neCurriculums",
          component: CurriculumStudentView,
          props: {
            appStateStore,
            curriculumStoreT,
            userRepository,
            curriculumRepository,
          },
        },
        {
          path: "studentview/:studentUserId/:curriculumPeriodMode/:curriculumMonth/eeCurriculums/:curriculumId",
          name: "T.Curriculum.studentview.eeCurriculums",
          component: CurriculumStudentView,
          props: {
            appStateStore,
            curriculumStoreT,
            userRepository,
            curriculumRepository,
          },
        },
      ],
    },
    {
      path: "/t/:classId/project/:projectSchoolYear/:projectQuarter/:projectId",
      component: ProjectT,
      props: {
        appStateStore,
        projectStore,
        projectInfoOnEditStore,
        projectRepository,
      },
      children: [
        {
          path: "",
          redirect: "journals",
        },
        {
          path: "journals",
          name: "T.Project.Journals",
          component: ProjectJournalsT,
          props: {
            appStateStore,
            projectStore,
            userRepository,
            projectRepository,
          },
        },
        {
          path: "studentView",
          name: "T.Project.StudentView",
          component: ProjectStudentViewT,
          props: {
            appStateStore,
            projectStore,
            userRepository,
          },
        },
        {
          path: "studentView/:projectStudentUserId",
          component: ProjectStudentViewT,
          props: {
            appStateStore,
            projectStore,
            userRepository,
          },
          children: [
            {
              path: "",
              redirect: "journals",
            },
            {
              path: "journals",
              name: "T.Project.StudentView.Journals",
              component: ProjectStudentViewJournalsT,
              props: {
                projectStore,
                projectRepository,
              },
            },
            {
              path: "lookback",
              name: "T.Project.StudentView.Lookback",
              component: ProjectStudentViewLookbackT,
              props: {
                projectStore,
                projectRepository,
              },
            },
          ],
        },
        {
          path: "about",
          name: "T.Project.About",
          component: ProjectAboutT,
          props: {
            appStateStore,
            projectStore,
            projectInfoOnEditStore,
            projectRepository,
          },
        },
      ],
    },
    {
      path: "/t/:classId/solan",
      component: SolanT,
      props: {
        appStateStore,
      },
      children: [
        {
          path: "",
          redirect: "journals",
        },
        {
          path: "journals",
          name: "T.Solan.Journals",
          component: SolanJournalsT,
          props: {
            appStateStore,
            userRepository,
            solanRepository,
          },
        },
        {
          path: "studentView",
          name: "T.Solan.StudentView",
          component: SolanStudentViewT,
          props: {
            appStateStore,
            solanStoreT,
            userRepository,
            solanRepository,
          },
        },
        {
          path: "studentView/:solanStudentUserId/:solanProjectId",
          component: SolanStudentViewT,
          props: {
            appStateStore,
            solanStoreT,
            userRepository,
            solanRepository,
          },
          children: [
            {
              path: "",
              redirect: "journals",
            },
            {
              path: "journals",
              name: "T.Solan.StudentView.Journals",
              component: SolanStudentViewJournalsT,
              props: {
                solanStoreT,
                userRepository,
                solanRepository,
              },
            },
            {
              path: "rubrics",
              name: "T.Solan.StudentView.Rubrics",
              component: SolanStudentViewRubricsT,
              props: {
                solanStoreT,
                userRepository,
                solanRepository,
              },
            },
            {
              path: "about",
              name: "T.Solan.StudentView.About",
              component: SolanStudentViewAboutT,
              props: {
                solanStoreT,
                userRepository,
                solanRepository,
              },
            },
            {
              path: "lookback",
              name: "T.Solan.StudentView.Lookback",
              component: SolanStudentViewLookbackT,
              props: {
                solanStoreT,
                userRepository,
                solanRepository,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/error/noclass",
      name: "Error.NoClass",
      component: ErrorPage,
      props: { message: "閲覧できるクラスが見つかりません。" },
    },
    {
      path: "/error",
      name: "Error.Error",
      component: ErrorPage,
    },
    {
      path: "*",
      name: "Error.NotFound",
      component: ErrorPage,
    },
  ];
}

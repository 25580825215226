












import Vue, { PropType } from "vue";
import { StudentClass } from "@/ts/objects/common/StudentClass";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "StudentClassText",
  props: {
    studentClass: { type: Object as PropType<StudentClass>, required: true },

    fontSizeSmall: { type: Number, required: false, default: 11 },
    fontSize: { type: Number, required: false, default: 13 },
    wrap: { type: Boolean, required: false, default: true },
  },
  data(): { words: Words } {
    return { words };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--font-size-small": `${this.fontSizeSmall}px`,
        "--font-size": `${this.fontSize}px`,
        "--wrap": this.wrap ? "wrap" : "norwap",
        "--name-white-space": this.wrap ? "normal" : "norwap",
      };
    },
  },
});

import { User } from "@/ts/objects/user/User";
import { BasicUserInfoPartial } from "@/ts/objects/common/BasicUserInfo";
import { Guardian as GuardianResp } from "@/ts/api/user-service";

export type Guardian = { readonly userType: "guardian" } & User & GuardianProfile & GuardianNotes;

export type GuardianProfile = {
  // Userのnameが、name1。
  readonly nameKana1: string;
  readonly familyName1: string;
  readonly givenName1: string;
  readonly name2: string;
  readonly nameKana2: string;
  readonly familyName2: string;
  readonly givenName2: string;

  readonly children: readonly BasicUserInfoPartial[];

  readonly email1: string;
  readonly email2: string;
  readonly mobilePhone1: string;
  readonly mobilePhone2: string;
  readonly landlinePhone1: string;
  readonly landlinePhone2: string;
  readonly job1: string;
  readonly job2: string;
  readonly workPlace1: string;
  readonly workPlace2: string;
  readonly pictureGcsUrl1: string | null;
  readonly pictureGcsUrl2: string | null;
  readonly emergencyContactRelationship1: string;
  readonly emergencyContactPhone1: string;
  readonly emergencyContactRelationship2: string;
  readonly emergencyContactPhone2: string;
  readonly emergencyContactRelationship3: string;
  readonly emergencyContactPhone3: string;
};

export type GuardianNotes = {
  readonly note01: string;
  readonly note02: string;
  readonly note03: string;
  readonly note04: string;
  readonly note05: string;
  readonly note06: string;
  readonly note07: string;
  readonly note08: string;
  readonly note09: string;
  readonly note10: string;
};

export function guardianFromRespOrError(r: GuardianResp): Guardian {
  return {
    userType: "guardian",

    userId: r.userId,
    googleMail: r.googleMail,
    photoUrl: r.photoUrl,
    name: r.name1,

    children: r.children.map(c => ({
      userId: c.userId,
      googleMail: c.googleMail,
      userType: "student",
      name: c.name,
      photoUrl: c.photoUrl,
    })),

    nameKana1: r.nameKana1,
    familyName1: r.familyName1,
    givenName1: r.givenName1,
    name2: r.name2,
    nameKana2: r.nameKana2,
    familyName2: r.familyName2,
    givenName2: r.givenName2,
    email1: r.email1,
    email2: r.email2,
    mobilePhone1: r.mobilePhone1,
    mobilePhone2: r.mobilePhone2,
    landlinePhone1: r.landlinePhone1,
    landlinePhone2: r.landlinePhone2,
    job1: r.job1,
    job2: r.job2,
    workPlace1: r.workPlace1,
    workPlace2: r.workPlace2,
    pictureGcsUrl1: r.pictureGcsUrl1 ?? null,
    pictureGcsUrl2: r.pictureGcsUrl2 ?? null,
    emergencyContactRelationship1: r.emergencyContactRelationship1,
    emergencyContactPhone1: r.emergencyContactPhone1,
    emergencyContactRelationship2: r.emergencyContactRelationship2,
    emergencyContactPhone2: r.emergencyContactPhone2,
    emergencyContactRelationship3: r.emergencyContactRelationship3,
    emergencyContactPhone3: r.emergencyContactPhone3,

    note01: r.note01,
    note02: r.note02,
    note03: r.note03,
    note04: r.note04,
    note05: r.note05,
    note06: r.note06,
    note07: r.note07,
    note08: r.note08,
    note09: r.note09,
    note10: r.note10,
  };
}

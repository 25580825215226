













import Vue, { PropType } from "vue";
import AppHeader from "@/views/header/AppHeader.vue";
import AppHeaderT from "@/views/header/AppHeaderT.vue";
import { AppStateStore } from "@/store/AppStateStore";
import { AuthService } from "@/ts/AuthService";
import { ProjectStore } from "@/store/ProjectStore";

export default Vue.extend({
  name: "App",
  components: { AppHeader, AppHeaderT },
  props: {
    authService: { type: Object as PropType<AuthService>, required: true },
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
  },
  computed: {
    mainViewKey(): string | null {
      if (this.appStateStore.isAppUserTeacher) {
        return this.appStateStore.teacherState?.selectedClass()?.id ?? "noclass";
      } else {
        return this.appStateStore.studentOrGuardianState?.studentUserId() ?? "nouser";
      }
    },
  },
});


























import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { EditableSolanRubric } from "@/ts/objects/solan/editable/EditableSolanRubric";
import { words, Words } from "@/ts/const/Words";
import { UserType } from "@/ts/objects/common/UserType";
import SolanRubricsTable from "@/views/solan/components/SolanRubricsTable/SolanRubricsTable.vue";
import SolanCreateRubricButton from "@/views/solan/components/SolanCreateRubricButton/SolanCreateRubricButton.vue";

export default Vue.extend({
  name: "SolanRubricsPure",
  components: { SolanCreateRubricButton, SolanRubricsTable, LoadingBlock, MessageView },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },
    userType: { type: String as PropType<UserType>, required: false },

    project: { type: Object as PropType<SolanProject | null> },
    rubrics: { type: Array as PropType<EditableSolanRubric[] | null> },

    selectedProcess: { type: Number },
    addRubricEnabled: { type: Boolean },

    onProcessSelect: { type: Function as PropType<(process: number) => void>, required: true },
    onRubricAdd: { type: Function as PropType<(process: number) => Promise<void>>, required: true },
    onRubricDelete: { type: Function as PropType<(rubricId: string) => Promise<void>>, required: true },
  },
  data(): {
    words: Words;
  } {
    return {
      words,
    };
  },
});

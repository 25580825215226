













import Vue, { PropType } from "vue";
import CurriculumHeaderT from "@/views/curriculum/teacher/CurriculumHeaderT/CurriculumHeaderT.vue";
import { AppStateStore } from "@/store/AppStateStore";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import log from "loglevel";
import VueRouter from "vue-router";
import { getDataWithTimeout } from "@/ts/Loadable";

export default Vue.extend({
  name: "CurriculumT",
  components: {
    CurriculumHeaderT,
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    curriculumStoreT: { type: Object as PropType<CurriculumStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true },
  },
  async created() {
    await initialize(this.curriculumStoreT, this.curriculumRepository, this.$router);
  },
  computed: {
    neCurriculumTree(): NECurriculumTree | null {
      return this.curriculumStoreT.neCurriculumTree.data;
    },
    classId(): string | null {
      return this.curriculumStoreT.classId;
    },
    headerListButtonPath(): string {
      return this.curriculumStoreT.firstCurriculumListPath.data ?? this.appStateStore.teacherCurriculumDefaultPath;
    },
    headerStudentViewButtonPath(): string {
      return (
        this.curriculumStoreT.firstCurriculumStudentViewPath.data ?? this.appStateStore.teacherCurriculumDefaultPath
      );
    },
    headerSyllabusButtonPath(): string {
      return this.curriculumStoreT.firstCurriculumSyllabusPath.data ?? this.appStateStore.teacherCurriculumDefaultPath;
    },
  },
  watch: {
    /**
     * クラスが変更されたとき、再初期化する。
     */
    async classId() {
      log.debug(`CurriculumT: watch: classId`);
      await initialize(this.curriculumStoreT, this.curriculumRepository, this.$router);
    },
  },
});

/**
 * 教科学習ページを初期化(データリロード等)する。
 * クラスが変更されたとき、呼び出しが必要。
 */
async function initialize(
  curriculumStoreT: CurriculumStoreT,
  curriculumRepository: CurriculumRepository,
  router: VueRouter
) {
  log.debug("CurriculumT: initializing...");
  await curriculumStoreT.loadAllData(curriculumRepository);

  log.debug(`CurriculumT: curriculumStoreT.selectedCurriculumRname=${curriculumStoreT.selectedCurriculumRname}`);
  if (curriculumStoreT.selectedCurriculumRname === null) {
    const path = await getDataWithTimeout(() => curriculumStoreT.defaultPathOfSelectedTab);
    log.debug(`CurriculumT: path=${path}`);
    if (path !== null) router.push(path).catch(() => {});
  }
}

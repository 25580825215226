
























import Vue, { PropType } from "vue";
import clamp from "lodash/clamp";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "SchoolYearSwitch",
  props: {
    schoolYear: { type: Number as PropType<number | null>, required: true },
    onSchoolYearChange: { type: Function as PropType<(schoolYear: number) => void>, required: true },

    minSchoolYear: { type: Number as PropType<number | null>, required: true },
    maxSchoolYear: { type: Number as PropType<number | null>, required: true },
    color: { type: String, required: false, default: "#dddddd" },
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--color": this.color,
      };
    },
    prevSchoolYear(): number | null {
      const schoolYear = this.schoolYear;
      if (schoolYear === null) return null;
      return clamp(schoolYear - 1, this.minSchoolYear ?? 2000, this.maxSchoolYear ?? 2000);
    },
    nextSchoolYear(): number | null {
      const schoolYear = this.schoolYear;
      if (schoolYear === null) return null;
      return clamp(schoolYear + 1, this.minSchoolYear ?? 2000, this.maxSchoolYear ?? 2000);
    },
  },
  methods: {
    onClickPrev() {
      const prevSchoolYear = this.prevSchoolYear;
      if (isNullish(prevSchoolYear)) return;
      this.onSchoolYearChange(prevSchoolYear);
    },
    onClickNext() {
      const nextSchoolYear = this.nextSchoolYear;
      if (isNullish(nextSchoolYear)) return;
      this.onSchoolYearChange(nextSchoolYear);
    },
  },
});

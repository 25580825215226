





















import Vue, { PropType } from "vue";
import { hasValue } from "@/ts/utils";
import RatingDropdown from "@/components/dropdowns/RatingDropdown.vue";

const possibleValues = ["S", "A", "B", "C", ""] as const;
type PossibleValue = typeof possibleValues[number];

export default Vue.extend({
  name: "StudentActivityTableRatingCell",
  components: { RatingDropdown },
  props: {
    /**
     * select要素のname属性。
     */
    selectElementName: { type: String, required: true },
    rating: { type: String, required: true },
    onChange: { type: Function as PropType<(value: PossibleValue) => void>, required: true },

    unsavedHighlight: { type: Boolean, required: true },
    errorHighlight: { type: Boolean, required: true },

    width: { type: Number, required: false },
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--width": hasValue(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
});

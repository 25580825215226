






import Vue, { PropType } from "vue";
import { messages } from "@/ts/const/Messages";

export type MessageViewParam = {
  message: string;
  fadeIn?: boolean;
};

export default Vue.extend({
  name: "MessageView",
  props: {
    param: { type: Object as PropType<MessageViewParam | null | undefined> },
  },
  computed: {
    message(): string {
      return this.param?.message ?? messages.pageNotFound;
    },
    fadeIn(): boolean {
      return this.param?.fadeIn ?? false;
    },
  },
});

import { ClassStudent } from "@/ts/objects/common/Class";
import { Err, InternalErr } from "@/ts/objects/Err";
import log from "loglevel";
import { Configuration, DefaultApi as UserDefaultApi, UserInfo as UserInfoResp } from "@/ts/api/user-service";
import { AxiosInstance } from "axios";
import { Quarter } from "@/ts/objects/common/Quarter";
import { BasicUserInfo } from "@/ts/objects/common/BasicUserInfo";
import { UserType, userTypes } from "@/ts/objects/common/UserType";
import { doReq } from "@/ts/utils";
import { Student, studentFromRespOrError } from "@/ts/objects/user/Student";
import { Guardian, guardianFromRespOrError } from "@/ts/objects/user/Guardian";
import { School } from "@/ts/objects/common/School";

export abstract class UserRepository {
  abstract getUserInfo(userId: string): Promise<UserInfoResp | Err>;

  abstract getBasicUserInfo(userId: string): Promise<BasicUserInfo | Err>;

  abstract getCurrentQuarter(): Promise<Quarter | Err>;

  abstract getClassStudents(classId: string): Promise<ClassStudent[] | Err>;

  abstract getStudent(userId: string): Promise<Student | Err>;

  abstract getGuardian(userId: string): Promise<Guardian | Err>;

  abstract getSchool(): Promise<School | Err>;

  /**
   * (storageやFireStoreで使うための)Firebaseユーザーの設定(カスタムクレーム)の更新を行う。
   */
  abstract updateFirebaseUser(): Promise<boolean | Err>;
}

export class UserRepositoryImpl extends UserRepository {
  private readonly userService: UserDefaultApi;

  constructor(serviceBasePath: string, axiosConf: Configuration | undefined, axiosInstance: AxiosInstance) {
    super();
    this.userService = new UserDefaultApi(axiosConf, serviceBasePath, axiosInstance);
  }

  async getUserInfo(userId: string): Promise<UserInfoResp | Err> {
    return await doReq(() => this.userService.getUserInfo(userId));
  }

  async getBasicUserInfo(userId: string): Promise<BasicUserInfo | Err> {
    // TODO 一応キャッシュはブラウザレベルでしているはずだが、アプリレベルでもしても良いか。
    const resp = await doReq(() => this.userService.getBasicUserInfo(userId));
    if (resp instanceof Err) return resp;
    if (!(userTypes as readonly string[]).includes(resp.userType))
      return new InternalErr(`UserRepositoryImpl: Invalid userType: ${resp.userType}`);
    return {
      userId: resp.userId,
      googleMail: resp.googleMail,
      name: resp.name,
      userType: resp.userType as UserType,
      photoUrl: resp.photoUrl,
    };
  }

  async getCurrentQuarter(): Promise<Quarter | Err> {
    const resp = await doReq(() => this.userService.getSemesterV1());
    if (resp instanceof Err) return resp;
    return new Quarter(resp.year, resp.semester);
  }

  async getClassStudents(classId: string): Promise<ClassStudent[] | Err> {
    const resp = await doReq(() => this.userService.listClassStudents(classId));
    if (resp instanceof Err) {
      log.error("UserRepositoryImpl: Failed to fetch class students from user-service.");
      return new InternalErr("UserRepositoryImpl: Failed to fetch class students from user-service.");
    }
    return resp.map(s => new ClassStudent(s.userId, s.studentNumber ?? 0, s.name, s.photoUrl));
  }

  async getStudent(this: this, userId: string): Promise<Student | Err> {
    const resp = await doReq(() => this.userService.getStudent(userId));
    if (resp instanceof Err) {
      log.error("UserRepositoryImpl: Failed to fetch student from user-service.");
      return new InternalErr("UserRepositoryImpl: Failed to fetch student from user-service.");
    }
    return studentFromRespOrError(resp);
  }

  async getGuardian(this: this, userId: string): Promise<Guardian | Err> {
    const resp = await doReq(() => this.userService.getGuardian(userId));
    if (resp instanceof Err) {
      log.error("UserRepositoryImpl: Failed to fetch guardian from user-service.");
      return new InternalErr("UserRepositoryImpl: Failed to fetch guardian from user-service.");
    }
    return guardianFromRespOrError(resp);
  }

  async getSchool(this: this): Promise<School | Err> {
    const resp = await doReq(() => this.userService.getSchool());
    if (resp instanceof Err) {
      log.error("UserRepositoryImpl: Failed to fetch school from user-service.");
      return new InternalErr("UserRepositoryImpl: Failed to fetch school from user-service.");
    }
    return {
      schoolName: resp.schoolName ?? "",
      logoGcsUrl: resp.logoGcsUrl ?? null,
    };
  }

  async updateFirebaseUser(): Promise<boolean | Err> {
    const resp = await doReq(() => this.userService.firebaseAuthUser());
    if (resp instanceof Err) {
      log.error(`UserRepositoryImpl: Error from firebaseAuthUser: ${resp.internalMessage}`);
      return resp;
    }
    return resp.updated ?? false;
  }
}

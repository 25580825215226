




























import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import Vue, { PropType } from "vue";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { NECEvaluation } from "@/ts/objects/curriculum/value/NECEvaluation";
import { messages } from "@/ts/const/Messages";
import { Loadable } from "@/ts/Loadable";
import CurriculumReadMainHeader from "@/views/curriculum/student/components/CurriculumReadMainHeader/CurriculumReadMainHeader.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import CurriculumReadNECMonthlyViewPointRow from "@/views/curriculum/student/CurriculumReadNECMonthly/components/CurriculumReadNECMonthlyViewPointRow/CurriculumReadNECMonthlyViewPointRow.vue";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "CurriculumReadNECMonthlyPure",
  components: { CurriculumReadNECMonthlyViewPointRow, CurriculumReadMainHeader, MessageView, LoadingBlock },
  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String as PropType<string | null | undefined> },

    /**
     * 月。
     */
    month: { type: Number as PropType<number | null | undefined> },

    /**
     * 数値評価教科。
     */
    neCurriculumTree: { type: Object as PropType<Loadable<NECurriculumTree | null>>, required: true },

    /**
     * 現在表示中の教科・児童生徒・月 配下の評価。
     */
    evaluations: { type: Object as PropType<Loadable<NECEvaluation[]>>, required: true },
  },
  computed: {
    messageView(): MessageViewParam | null {
      if (isNullish(this.studentUserId)) return { message: messages.shouldSelectStudent };
      if (isNullish(this.month)) return { message: messages.pageNotFound };
      if (this.neCurriculumTree.isError || this.evaluations.isError) return { message: messages.failedToLoadData };
      return null;
    },
  },
});

import { isNullish } from "@/ts/utils";

const rgbStringPattern = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

function isValidAsColorNumber(value: number): boolean {
  return !isNaN(value) && 0 <= value && value <= 255;
}

export class Color {
  readonly red: number;
  readonly green: number;
  readonly blue: number;

  constructor(red: number, green: number, blue: number) {
    this.red = red;
    this.green = green;
    this.blue = blue;
  }

  static fromRGBString(value: string) {
    const result = rgbStringPattern.exec(value);
    if (isNullish(result)) return new Color(0, 0, 0);

    const red = parseInt(result[1], 16);
    const green = parseInt(result[2], 16);
    const blue = parseInt(result[3], 16);

    if (!isValidAsColorNumber(red) || !isValidAsColorNumber(green) || !isValidAsColorNumber(blue))
      return new Color(0, 0, 0);

    return new Color(red, green, blue);
  }
}

import { Student, StudentCustom } from "@/ts/objects/user/Student";
import { UserColumn } from "@/ts/app/columns/UserColumn";
import { StudentColumnDef } from "@/ts/app/columns/student/StudentColumn";
import { StudentCustomColumnNames } from "@/ts/objects/common/StudentCustomColumnNames";
import { genUserColumnDefs } from "@/ts/app/columns/UserColumnDef";
import { StringType } from "@/ts/app/columns/ColumnTypes";

export type StudentCustomColumnId = keyof StudentCustom;

export type StudentCustomColumnDef<T> = StudentColumnDef<StudentCustomColumnId, T>;

export type StudentCustomColumn<T> = UserColumn<StudentCustomColumnId, Student, T>;

export function studentCustomColumnDefs(colNames: StudentCustomColumnNames): StudentCustomColumnDef<any>[] {
  return genUserColumnDefs<StudentCustomColumnId, Student, any>([
    {
      id: "custom01",
      name: colNames.custom01,
      type: StringType,
      getValue: s => s.custom01.value,
    },
    {
      id: "custom02",
      name: colNames.custom02,
      type: StringType,
      getValue: s => s.custom02.value,
    },
    {
      id: "custom03",
      name: colNames.custom03,
      type: StringType,
      getValue: s => s.custom03.value,
    },
    {
      id: "custom04",
      name: colNames.custom04,
      type: StringType,
      getValue: s => s.custom04.value,
    },
    {
      id: "custom05",
      name: colNames.custom05,
      type: StringType,
      getValue: s => s.custom05.value,
    },
    {
      id: "custom06",
      name: colNames.custom06,
      type: StringType,
      getValue: s => s.custom06.value,
    },
    {
      id: "custom07",
      name: colNames.custom07,
      type: StringType,
      getValue: s => s.custom07.value,
    },
    {
      id: "custom08",
      name: colNames.custom08,
      type: StringType,
      getValue: s => s.custom08.value,
    },
    {
      id: "custom09",
      name: colNames.custom09,
      type: StringType,
      getValue: s => s.custom09.value,
    },
    {
      id: "custom10",
      name: colNames.custom10,
      type: StringType,
      getValue: s => s.custom10.value,
    },
    {
      id: "custom11",
      name: colNames.custom11,
      type: StringType,
      getValue: s => s.custom11.value,
    },
    {
      id: "custom12",
      name: colNames.custom12,
      type: StringType,
      getValue: s => s.custom12.value,
    },
    {
      id: "custom13",
      name: colNames.custom13,
      type: StringType,
      getValue: s => s.custom13.value,
    },
    {
      id: "custom14",
      name: colNames.custom14,
      type: StringType,
      getValue: s => s.custom14.value,
    },
    {
      id: "custom15",
      name: colNames.custom15,
      type: StringType,
      getValue: s => s.custom15.value,
    },
    {
      id: "custom16",
      name: colNames.custom16,
      type: StringType,
      getValue: s => s.custom16.value,
    },
    {
      id: "custom17",
      name: colNames.custom17,
      type: StringType,
      getValue: s => s.custom17.value,
    },
    {
      id: "custom18",
      name: colNames.custom18,
      type: StringType,
      getValue: s => s.custom18.value,
    },
    {
      id: "custom19",
      name: colNames.custom19,
      type: StringType,
      getValue: s => s.custom19.value,
    },
    {
      id: "custom20",
      name: colNames.custom20,
      type: StringType,
      getValue: s => s.custom20.value,
    },
    {
      id: "custom21",
      name: colNames.custom21,
      type: StringType,
      getValue: s => s.custom21.value,
    },
    {
      id: "custom22",
      name: colNames.custom22,
      type: StringType,
      getValue: s => s.custom22.value,
    },
    {
      id: "custom23",
      name: colNames.custom23,
      type: StringType,
      getValue: s => s.custom23.value,
    },
    {
      id: "custom24",
      name: colNames.custom24,
      type: StringType,
      getValue: s => s.custom24.value,
    },
    {
      id: "custom25",
      name: colNames.custom25,
      type: StringType,
      getValue: s => s.custom25.value,
    },
    {
      id: "custom26",
      name: colNames.custom26,
      type: StringType,
      getValue: s => s.custom26.value,
    },
    {
      id: "custom27",
      name: colNames.custom27,
      type: StringType,
      getValue: s => s.custom27.value,
    },
    {
      id: "custom28",
      name: colNames.custom28,
      type: StringType,
      getValue: s => s.custom28.value,
    },
    {
      id: "custom29",
      name: colNames.custom29,
      type: StringType,
      getValue: s => s.custom29.value,
    },
    {
      id: "custom30",
      name: colNames.custom30,
      type: StringType,
      getValue: s => s.custom30.value,
    },
    {
      id: "custom31",
      name: colNames.custom31,
      type: StringType,
      getValue: s => s.custom31.value,
    },
    {
      id: "custom32",
      name: colNames.custom32,
      type: StringType,
      getValue: s => s.custom32.value,
    },
    {
      id: "custom33",
      name: colNames.custom33,
      type: StringType,
      getValue: s => s.custom33.value,
    },
    {
      id: "custom34",
      name: colNames.custom34,
      type: StringType,
      getValue: s => s.custom34.value,
    },
    {
      id: "custom35",
      name: colNames.custom35,
      type: StringType,
      getValue: s => s.custom35.value,
    },
    {
      id: "custom36",
      name: colNames.custom36,
      type: StringType,
      getValue: s => s.custom36.value,
    },
    {
      id: "custom37",
      name: colNames.custom37,
      type: StringType,
      getValue: s => s.custom37.value,
    },
    {
      id: "custom38",
      name: colNames.custom38,
      type: StringType,
      getValue: s => s.custom38.value,
    },
    {
      id: "custom39",
      name: colNames.custom39,
      type: StringType,
      getValue: s => s.custom39.value,
    },
    {
      id: "custom40",
      name: colNames.custom40,
      type: StringType,
      getValue: s => s.custom40.value,
    },
  ]);
}

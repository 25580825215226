import {
  Configuration,
  DefaultApi as ProjectDefaultApi,
  ProjectWrite,
  Project as ProjectResp,
  RubricWrite,
  Rubric as RubricResp,
  JournalWrite,
  Journal as JournalResp,
  LookbackWrite,
  Lookback as LookbackResp,
  JournalFile as JournalFileResp,
} from "@/ts/api/project-service";
import { DisplayableErr, Err } from "@/ts/objects/Err";
import { Quarter } from "@/ts/objects/common/Quarter";
import { EditableProject } from "@/ts/objects/project/editable/EditableProject";
import { Project } from "@/ts/objects/project/value/Project";
import { doReq, projectJournalRnameToId, projectLookbackRnameToId, projectRubricRnameToId } from "@/ts/utils/AppUtil";
import { messages } from "@/ts/const/Messages";
import { EditableProjectRubric } from "@/ts/objects/project/editable/EditableProjectRubric";
import { ProjectRubric } from "@/ts/objects/project/value/ProjectRubric";
import { ProjectJournalFile } from "@/ts/objects/project/value/ProjectJournalFile";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import { Class, ClassStudent } from "@/ts/objects/common/Class";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { EditableProjectStudent } from "@/ts/objects/project/editable/EditableProjectStudent";
import { AxiosInstance } from "axios";
import { UserRepository } from "@/ts/repositories/UserRepository";

export abstract class ProjectRepository {
  abstract listProjects(this: this, classIds: string[], quarter: Quarter | undefined): Promise<Project[] | Err>;

  abstract getEditableProject(this: this, projectId: string, savable: boolean): Promise<EditableProject | Err>;

  abstract listEditableProjects(
    this: this,
    classIds: string[],
    quarter: Quarter | undefined,
    savable: boolean
  ): Promise<EditableProject[] | Err>;

  abstract postProject(this: this, classId: string, quarter: Quarter): Promise<ProjectResp | Err>;

  abstract patchProject(this: this, projectId: string, projectWrite: ProjectWrite): Promise<ProjectResp | Err>;

  abstract deleteProject(this: this, projectId: string): Promise<void | Err>;

  abstract getEditableProjectLookback(
    this: this,
    resourceName: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableProjectLookback | Err>;

  abstract listEditableProjectLookbacks(
    this: this,
    projectId: string,
    studentUserId: string | undefined,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableProjectLookback[] | Err>;

  abstract patchLookback(
    this: this,
    projectId: string,
    lookbackId: string,
    lookbackWrite: LookbackWrite
  ): Promise<LookbackResp | Err>;

  abstract listProjectRubrics(this: this, projectId: string): Promise<ProjectRubric[] | Err>;

  abstract getEditableProjectRubric(
    this: this,
    resourceName: string,
    savable: boolean
  ): Promise<EditableProjectRubric | Err>;

  abstract listEditableProjectRubrics(
    this: this,
    projectId: string,
    savable: boolean
  ): Promise<EditableProjectRubric[] | Err>;

  abstract postRubric(this: this, projectId: string, rubricWrite: RubricWrite): Promise<RubricResp | Err>;

  abstract patchRubric(
    this: this,
    projectId: string,
    rubricId: string,
    rubricWrite: RubricWrite
  ): Promise<RubricResp | Err>;

  abstract deleteRubric(this: this, projectId: string, rubricId: string): Promise<void | Err>;

  abstract listEditableProjectJournals(
    this: this,
    projectId: string,
    studentUserId: string | undefined,
    teacherInputSavable: boolean,
    studentInputSavable: boolean
  ): Promise<{ rubrics: ProjectRubric[]; editableJournals: EditableProjectJournal[] } | Err>;

  abstract patchJournal(
    this: this,
    projectId: string,
    rubricId: string,
    journalId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err>;

  abstract listJournalFiles(this: this, journal: string): Promise<ProjectJournalFile[] | Err>;

  abstract postJournalFile(
    this: this,
    projectId: string,
    rubricId: string,
    journalId: string,
    file: any,
    timeoutMillis: number
  ): Promise<JournalFileResp | Err>;

  abstract deleteJournalFile(
    this: this,
    projectId: string,
    rubricId: string,
    journalId: string,
    journalFileId: string
  ): Promise<void | Err>;

  async listEditableProjectStudents(
    this: this,
    userRepository: UserRepository,
    projectId: string,
    cls: Class | null,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<{ rubrics: ProjectRubric[]; editableStudents: EditableProjectStudent[] } | Err> {
    if (cls === null) {
      return new DisplayableErr(`listEditableProjectStudents: class must not be null`, messages.shouldSelectClass);
    }
    const [journalResp, lookbackResp, classStudents] = await Promise.all<
      { rubrics: ProjectRubric[]; editableJournals: EditableProjectJournal[] } | Err,
      EditableProjectLookback[] | Err,
      Map<string, ClassStudent>
    >([
      this.listEditableProjectJournals(projectId, undefined, teacherInputSavable, studentInputSavable),
      this.listEditableProjectLookbacks(
        projectId,
        undefined,
        teacherInputSavable,
        studentInputSavable,
        guardianInputSavable
      ),
      cls.classStudents(userRepository),
    ]);
    if (journalResp instanceof Err) return journalResp;
    if (lookbackResp instanceof Err) return lookbackResp;

    const groupedJournals = groupBy(journalResp.editableJournals, j => j.studentUserId);
    const students = Object.entries(groupedJournals)
      .map(([studentUserId, journals]) => {
        const student = classStudents.get(studentUserId);
        const lookback = lookbackResp.find(l => l.studentUserId === studentUserId);
        if (student === undefined || lookback === undefined) return null;
        return new EditableProjectStudent(
          student.studentUserId,
          student.studentNumber,
          student.name,
          journals,
          lookback
        );
      })
      .filter((v): v is EditableProjectStudent => v !== null);

    const sortedStudents = sortBy(students, ["studentNumber"]);

    return {
      rubrics: journalResp.rubrics,
      editableStudents: sortedStudents,
    };
  }
}

export class ProjectRepositoryImpl extends ProjectRepository {
  private readonly projectService: ProjectDefaultApi;

  constructor(serviceBasePath: string, axiosConf: Configuration | undefined, axiosInstance: AxiosInstance) {
    super();
    this.projectService = new ProjectDefaultApi(axiosConf, serviceBasePath, axiosInstance);
  }

  async listProjects(this: this, classIds: string[], quarter: Quarter | undefined): Promise<Project[] | Err> {
    const resp = await doReq(() => this.projectService.listProject(classIds, quarter?.schoolYear, quarter?.quarter));
    if (resp instanceof Err) return resp;

    return resp.map(
      p =>
        new Project(
          p.self,
          p.classId,
          new Quarter(p.quarter.schoolYear, p.quarter.quarter),
          p.name.value,
          p.description.value,
          p.relatedSyllabus.value,
          p.viewPointSEnabled,
          p.viewPointAEnabled,
          p.viewPointBEnabled,
          p.viewPointCEnabled,
          p.startingMonth.year,
          p.startingMonth.month,
          p.endingMonth.year,
          p.endingMonth.month,
          p.published,
          p.completed,
          p.rubrics
        )
    );
  }

  async getEditableProject(this: this, projectId: string, savable: boolean): Promise<EditableProject | Err> {
    const r = await doReq(() => this.projectService.getProject(projectId));
    if (r instanceof Err) return r;

    return new EditableProject(
      this,
      savable,
      r.self,
      r.classId,
      new Quarter(r.quarter.schoolYear, r.quarter.quarter),
      r.name.value,
      r.name.hash,
      r.description.value,
      r.description.hash,
      r.relatedSyllabus.value,
      r.relatedSyllabus.hash,
      r.viewPointSEnabled,
      r.viewPointAEnabled,
      r.viewPointBEnabled,
      r.viewPointCEnabled,
      r.startingMonth.year,
      r.startingMonth.month,
      r.endingMonth.year,
      r.endingMonth.month,
      r.published,
      r.completed,
      r.rubrics
    );
  }

  async listEditableProjects(
    this: this,
    classIds: string[],
    quarter: Quarter | undefined,
    savable: boolean
  ): Promise<EditableProject[] | Err> {
    const r = await doReq(() => this.projectService.listProject(classIds, quarter?.schoolYear, quarter?.quarter));
    if (r instanceof Err) return r;

    return r.map(
      p =>
        new EditableProject(
          this,
          savable,
          p.self,
          p.classId,
          new Quarter(p.quarter.schoolYear, p.quarter.quarter),
          p.name.value,
          p.name.hash,
          p.description.value,
          p.description.hash,
          p.relatedSyllabus.value,
          p.relatedSyllabus.hash,
          p.viewPointSEnabled,
          p.viewPointAEnabled,
          p.viewPointBEnabled,
          p.viewPointCEnabled,
          p.startingMonth.year,
          p.startingMonth.month,
          p.endingMonth.year,
          p.endingMonth.month,
          p.published,
          p.completed,
          p.rubrics
        )
    );
  }

  async postProject(this: this, classId: string, quarter: Quarter): Promise<ProjectResp | Err> {
    return await doReq(() =>
      this.projectService.postProject({
        classId: classId,
        quarter: quarter,
      })
    );
  }

  async patchProject(this: this, projectId: string, projectWrite: ProjectWrite): Promise<ProjectResp | Err> {
    return await doReq(() => this.projectService.patchProject(projectId, projectWrite));
  }

  async deleteProject(this: this, projectId: string): Promise<void | Err> {
    return await doReq(() => this.projectService.deleteProject(projectId));
  }

  async getEditableProjectLookback(
    this: this,
    resourceName: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableProjectLookback | Err> {
    const ids = projectLookbackRnameToId(resourceName);
    if (ids === null) return new DisplayableErr(`invalid resourceName: ${resourceName}`, messages.failedToLoadData);
    const r = await doReq(() => this.projectService.getLookback(ids[0], ids[1]));
    if (r instanceof Err) return r;

    return new EditableProjectLookback(
      this,
      teacherInputSavable,
      studentInputSavable,
      guardianInputSavable,
      r.self,
      r.studentUserId,
      r.studentComment.value,
      r.studentComment.hash,
      r.studentRating,
      r.teacherComment?.value ?? "",
      r.teacherComment?.hash ?? "",
      r.teacherRating ?? "",
      r.teacherInputPublished,
      r.guardianComment.value,
      r.guardianComment.hash,
      r.studentInputLocked,
      r.guardianInputLocked
    );
  }

  async listEditableProjectLookbacks(
    this: this,
    projectId: string,
    studentUserId: string | undefined,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableProjectLookback[] | Err> {
    const r = await doReq(() => this.projectService.listLookback(projectId, studentUserId));
    if (r instanceof Err) return r;

    return r.map(
      l =>
        new EditableProjectLookback(
          this,
          teacherInputSavable,
          studentInputSavable,
          guardianInputSavable,
          l.self,
          l.studentUserId,
          l.studentComment.value,
          l.studentComment.hash,
          l.studentRating,
          l.teacherComment?.value ?? "",
          l.teacherComment?.hash ?? "",
          l.teacherRating ?? "",
          l.teacherInputPublished,
          l.guardianComment.value,
          l.guardianComment.hash,
          l.studentInputLocked,
          l.guardianInputLocked
        )
    );
  }

  async patchLookback(
    this: this,
    projectId: string,
    lookbackId: string,
    lookbackWrite: LookbackWrite
  ): Promise<LookbackResp | Err> {
    return await doReq(() => this.projectService.patchLookback(projectId, lookbackId, lookbackWrite));
  }

  async listProjectRubrics(this: this, projectId: string): Promise<ProjectRubric[] | Err> {
    const resp = await doReq(() => this.projectService.listRubric(projectId));
    if (resp instanceof Err) return resp;

    return resp.map(
      r =>
        new ProjectRubric(
          r.self,
          r.orderNum,
          r.learningActivity.value,
          r.viewPointS.value,
          r.viewPointA.value,
          r.viewPointB.value,
          r.viewPointC.value
        )
    );
  }

  async getEditableProjectRubric(
    this: this,
    resourceName: string,
    savable: boolean
  ): Promise<EditableProjectRubric | Err> {
    const ids = projectRubricRnameToId(resourceName);
    if (ids === null) return new DisplayableErr(`invalid resourceName: ${resourceName}`, messages.failedToLoadData);
    const r = await doReq(() => this.projectService.getRubric(ids[0], ids[1]));
    if (r instanceof Err) return r;

    return new EditableProjectRubric(
      this,
      savable,
      r.self,
      r.orderNum,
      r.learningActivity.value,
      r.learningActivity.hash,
      r.viewPointS.value,
      r.viewPointS.hash,
      r.viewPointA.value,
      r.viewPointA.hash,
      r.viewPointB.value,
      r.viewPointB.hash,
      r.viewPointC.value,
      r.viewPointC.hash
    );
  }

  async listEditableProjectRubrics(
    this: this,
    projectId: string,
    savable: boolean
  ): Promise<EditableProjectRubric[] | Err> {
    const r = await doReq(() => this.projectService.listRubric(projectId));
    if (r instanceof Err) return r;

    return r.map(
      r =>
        new EditableProjectRubric(
          this,
          savable,
          r.self,
          r.orderNum,
          r.learningActivity.value,
          r.learningActivity.hash,
          r.viewPointS.value,
          r.viewPointS.hash,
          r.viewPointA.value,
          r.viewPointA.hash,
          r.viewPointB.value,
          r.viewPointB.hash,
          r.viewPointC.value,
          r.viewPointC.hash
        )
    );
  }

  async postRubric(this: this, projectId: string, rubricWrite: RubricWrite): Promise<RubricResp | Err> {
    return await doReq(() => this.projectService.postRubric(projectId, rubricWrite));
  }

  async patchRubric(
    this: this,
    projectId: string,
    rubricId: string,
    rubricWrite: RubricWrite
  ): Promise<RubricResp | Err> {
    return await doReq(() => this.projectService.patchRubric(projectId, rubricId, rubricWrite));
  }

  async deleteRubric(this: this, projectId: string, rubricId: string): Promise<void | Err> {
    return await doReq(() => this.projectService.deleteRubric(projectId, rubricId));
  }

  async listEditableProjectJournals(
    this: this,
    projectId: string,
    studentUserId: string | undefined,
    teacherInputSavable: boolean,
    studentInputSavable: boolean
  ): Promise<{ rubrics: ProjectRubric[]; editableJournals: EditableProjectJournal[] } | Err> {
    const [rubricResp, journalResp] = await Promise.all([
      doReq(() => this.projectService.listRubric(projectId)),
      doReq(() => this.projectService.listJournal(projectId, "-", studentUserId, true)),
    ]);
    if (rubricResp instanceof Err) return rubricResp;
    if (journalResp instanceof Err) return journalResp;

    const rubrics = rubricResp.map(
      r =>
        new ProjectRubric(
          r.self,
          r.orderNum,
          r.learningActivity.value,
          r.viewPointS.value,
          r.viewPointA.value,
          r.viewPointB.value,
          r.viewPointC.value
        )
    );

    const editableJournals = journalResp.map(j => {
      const learningActivity = rubricResp.find(r => r.self === j.rubric)?.learningActivity.value ?? "";
      return new EditableProjectJournal(
        this,
        teacherInputSavable,
        studentInputSavable,
        j.self,
        j.rubric,
        learningActivity,
        j.studentUserId,
        j.files.map(
          f =>
            new ProjectJournalFile(
              f.self,
              f.journal,
              f.type,
              f.subtype,
              f.mediaType,
              f.filename,
              f.ext,
              f.gcsObjectPath,
              f.thumbnailGcsObjectPath ?? null,
              f.hasThumbnail,
              f.width ?? null,
              f.height ?? null,
              f.createdAt,
              f.updatedAt
            )
        ),
        j.studentComment.value,
        j.studentComment.hash,
        j.studentRating,
        j.teacherComment?.value ?? "",
        j.teacherComment?.hash ?? "",
        j.teacherRating ?? "",
        j.teacherInputPublished,
        j.studentInputLocked
      );
    });

    return { rubrics: rubrics, editableJournals: editableJournals };
  }

  async patchJournal(
    this: this,
    projectId: string,
    rubricId: string,
    journalId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err> {
    return await doReq(() => this.projectService.patchJournal(projectId, rubricId, journalId, journalWrite));
  }

  async listJournalFiles(this: this, journal: string): Promise<ProjectJournalFile[] | Err> {
    const ids = projectJournalRnameToId(journal);
    if (ids === null) return new DisplayableErr(`invalid journal resourceName: ${journal}`, messages.failedToLoadData);
    const [projectId, rubricId, journalId] = ids;
    const resp = await doReq(() => this.projectService.listJournalFile(projectId, rubricId, journalId));
    if (resp instanceof Err) return resp;

    return resp.map(
      r =>
        new ProjectJournalFile(
          r.self,
          r.journal,
          r.type,
          r.subtype,
          r.mediaType,
          r.filename,
          r.ext,
          r.gcsObjectPath,
          r.thumbnailGcsObjectPath ?? null,
          r.hasThumbnail,
          r.width ?? null,
          r.height ?? null,
          r.createdAt,
          r.updatedAt
        )
    );
  }

  async postJournalFile(
    this: this,
    projectId: string,
    rubricId: string,
    journalId: string,
    file: any,
    timeoutMillis: number
  ): Promise<JournalFileResp | Err> {
    return await doReq(() =>
      this.projectService.postJournalFile(projectId, rubricId, journalId, file, { timeout: timeoutMillis })
    );
  }

  async deleteJournalFile(
    this: this,
    projectId: string,
    rubricId: string,
    journalId: string,
    journalFileId: string
  ): Promise<void | Err> {
    return await doReq(() => this.projectService.deleteJournalFile(projectId, rubricId, journalId, journalFileId));
  }
}

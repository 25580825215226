















import Vue from "vue";

export default Vue.extend({
  name: "AppHeaderHomeButton",
  props: {
    isActive: { type: Boolean, required: true },
    homePath: { type: String, required: true },
    schoolLogoUrl: { type: String, required: false },
    /**
     * サイズ。単位はpx。
     */
    size: { type: Number, required: true },
  },
  computed: {
    styles() {
      const size = this.size;
      const padding = (size / 10).toFixed();
      const borderRadius = (size / 2).toFixed();
      const iconSize = ((size * 7) / 10).toFixed();
      return {
        "--size": `${size}px`,
        "--padding": `${padding}px`,
        "--border-radius": `${borderRadius}px`,
        "--icon-size": `${iconSize}px`,
      };
    },
  },
});

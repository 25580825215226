import { BasicUserInfoPartial, basicUserInfoPartialFromRespOrError } from "@/ts/objects/common/BasicUserInfo";
import { User } from "@/ts/objects/user/User";
import { hasValue } from "@/ts/utils";
import { DateValue, displayValueToDateValueOrError } from "@/ts/objects/common/DateValue";
import { Sex, sexFromStringOrError } from "@/ts/objects/common/Sex";
import { Student as StudentResp } from "@/ts/api/user-service";
import { HasLunchValue, hasLunchValueFromStringOrError } from "@/ts/objects/common/HasLunchValue";
import { StudentClass, studentClassFromRespOrError } from "@/ts/objects/common/StudentClass";
import { CustomColumn } from "@/ts/objects/common/CustomColumn";

export type Student = { readonly userType: "student" } & User & StudentProfile & StudentHealth & StudentCustom;

export type StudentProfile = {
  readonly classes: readonly StudentClass[];
  readonly guardian: BasicUserInfoPartial | null;

  readonly nameKana: string;
  readonly nameRome: string;
  readonly familyName: string;
  readonly familyNameKana: string;
  readonly givenName: string;
  readonly givenNameKana: string;
  readonly sex: Sex;
  readonly nickname: string;
  readonly nicknameKana: string;
  readonly birthday: DateValue | null;
  readonly applicationDate: DateValue | null;
  readonly kindergartenEntranceDate: DateValue | null;
  readonly elementarySchoolEntranceDate: DateValue | null;
  readonly juniorHighSchoolEntranceDate: DateValue | null;
  readonly transferDate: DateValue | null;
  readonly graduationDate: DateValue | null;
  readonly kindergarten: string;
  readonly previousSchool: string;
  readonly zipcode: string;
  readonly address: string;
  readonly email: string;
  readonly mobilePhone: string;
  readonly pictureGcsUrl: string | null;
  readonly country: string;
  readonly religion: string;
  readonly commutingBy: string;
  readonly bus: string;
  readonly nearestStation: string;
  readonly hasLunch: HasLunchValue;
  readonly activityMonday: string;
  readonly activityTuesday: string;
  readonly activityWednesday: string;
  readonly activityThursday: string;
  readonly activityFriday: string;
};

export type StudentHealth = {
  readonly bloodType: string;
  readonly normalBodyTemperature: string;
  readonly inoculation: string;
  readonly medicalHistory: string;
  readonly homeDoctor: string;
  readonly foodAllergy: string;
  readonly anaphylaxis: string;
  readonly otherAllergy: string;
  readonly asthma: string;
};

export type StudentCustom = {
  readonly custom01: CustomColumn;
  readonly custom02: CustomColumn;
  readonly custom03: CustomColumn;
  readonly custom04: CustomColumn;
  readonly custom05: CustomColumn;
  readonly custom06: CustomColumn;
  readonly custom07: CustomColumn;
  readonly custom08: CustomColumn;
  readonly custom09: CustomColumn;
  readonly custom10: CustomColumn;
  readonly custom11: CustomColumn;
  readonly custom12: CustomColumn;
  readonly custom13: CustomColumn;
  readonly custom14: CustomColumn;
  readonly custom15: CustomColumn;
  readonly custom16: CustomColumn;
  readonly custom17: CustomColumn;
  readonly custom18: CustomColumn;
  readonly custom19: CustomColumn;
  readonly custom20: CustomColumn;
  readonly custom21: CustomColumn;
  readonly custom22: CustomColumn;
  readonly custom23: CustomColumn;
  readonly custom24: CustomColumn;
  readonly custom25: CustomColumn;
  readonly custom26: CustomColumn;
  readonly custom27: CustomColumn;
  readonly custom28: CustomColumn;
  readonly custom29: CustomColumn;
  readonly custom30: CustomColumn;
  readonly custom31: CustomColumn;
  readonly custom32: CustomColumn;
  readonly custom33: CustomColumn;
  readonly custom34: CustomColumn;
  readonly custom35: CustomColumn;
  readonly custom36: CustomColumn;
  readonly custom37: CustomColumn;
  readonly custom38: CustomColumn;
  readonly custom39: CustomColumn;
  readonly custom40: CustomColumn;
};

export function studentFromRespOrError(r: StudentResp): Student {
  return {
    userType: "student",

    userId: r.userId,
    googleMail: r.googleMail,
    photoUrl: r.photoUrl,
    name: r.name,

    classes: r.classes.map(studentClassFromRespOrError),
    guardian: hasValue(r.guardian) ? basicUserInfoPartialFromRespOrError(r.guardian) : null,

    nameKana: r.nameKana,
    nameRome: r.nameRome,
    familyName: r.familyName,
    familyNameKana: r.familyNameKana,
    givenName: r.givenName,
    givenNameKana: r.givenNameKana,
    sex: sexFromStringOrError(r.sex),
    nickname: r.nickname,
    nicknameKana: r.nicknameKana,
    birthday: hasValue(r.birthday) ? displayValueToDateValueOrError(r.birthday) : null,
    applicationDate: hasValue(r.applicationDate) ? displayValueToDateValueOrError(r.applicationDate) : null,
    kindergartenEntranceDate: hasValue(r.kindergartenEntranceDate)
      ? displayValueToDateValueOrError(r.kindergartenEntranceDate)
      : null,
    elementarySchoolEntranceDate: hasValue(r.elementarySchoolEntranceDate)
      ? displayValueToDateValueOrError(r.elementarySchoolEntranceDate)
      : null,
    juniorHighSchoolEntranceDate: hasValue(r.juniorHighSchoolEntranceDate)
      ? displayValueToDateValueOrError(r.juniorHighSchoolEntranceDate)
      : null,
    transferDate: hasValue(r.transferDate) ? displayValueToDateValueOrError(r.transferDate) : null,
    graduationDate: hasValue(r.graduationDate) ? displayValueToDateValueOrError(r.graduationDate) : null,
    kindergarten: r.kindergarten,
    previousSchool: r.previousSchool,
    zipcode: r.zipcode,
    address: r.address,
    email: r.email,
    mobilePhone: r.mobilePhone,
    pictureGcsUrl: r.pictureGcsUrl ?? null,
    country: r.country,
    religion: r.religion,
    commutingBy: r.commutingBy,
    bus: r.bus,
    nearestStation: r.nearestStation,
    hasLunch: hasLunchValueFromStringOrError(r.hasLunch),
    activityMonday: r.activityMonday,
    activityTuesday: r.activityTuesday,
    activityWednesday: r.activityWednesday,
    activityThursday: r.activityThursday,
    activityFriday: r.activityFriday,

    bloodType: r.bloodType,
    normalBodyTemperature: r.normalBodyTemperature,
    inoculation: r.inoculation,
    medicalHistory: r.medicalHistory,
    homeDoctor: r.homeDoctor,
    foodAllergy: r.foodAllergy,
    anaphylaxis: r.anaphylaxis,
    otherAllergy: r.otherAllergy,
    asthma: r.asthma,

    custom01: r.custom01,
    custom02: r.custom02,
    custom03: r.custom03,
    custom04: r.custom04,
    custom05: r.custom05,
    custom06: r.custom06,
    custom07: r.custom07,
    custom08: r.custom08,
    custom09: r.custom09,
    custom10: r.custom10,
    custom11: r.custom11,
    custom12: r.custom12,
    custom13: r.custom13,
    custom14: r.custom14,
    custom15: r.custom15,
    custom16: r.custom16,
    custom17: r.custom17,
    custom18: r.custom18,
    custom19: r.custom19,
    custom20: r.custom20,
    custom21: r.custom21,
    custom22: r.custom22,
    custom23: r.custom23,
    custom24: r.custom24,
    custom25: r.custom25,
    custom26: r.custom26,
    custom27: r.custom27,
    custom28: r.custom28,
    custom29: r.custom29,
    custom30: r.custom30,
    custom31: r.custom31,
    custom32: r.custom32,
    custom33: r.custom33,
    custom34: r.custom34,
    custom35: r.custom35,
    custom36: r.custom36,
    custom37: r.custom37,
    custom38: r.custom38,
    custom39: r.custom39,
    custom40: r.custom40,
  };
}






















import Vue, { PropType } from "vue";
import { words, Words } from "@/ts/const/Words";
import { hasValue } from "@/ts/utils";

export default Vue.extend({
  name: "StudentActivityTablePublishStateCell",
  props: {
    published: { type: Boolean, required: true },
    onChange: { type: Function as PropType<(published: boolean) => void>, required: true },

    unsavedHighlight: { type: Boolean, required: true },
    errorHighlight: { type: Boolean, required: true },

    width: { type: Number, required: false },
  },
  data(): { words: Words } {
    return { words };
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--width": hasValue(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
});

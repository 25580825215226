import { Grade } from "@/ts/objects/common/Grade";
import { Curriculum } from "@/ts/objects/curriculum/value/Curriculum";
import { EECurriculum as EECurriculumResp } from "@/ts/api/curriculum-service";
import { EECMonthTree } from "@/ts/objects/curriculum/value/EECMonth";
import { IEECurriculum, IEECurriculumTree } from "@/ts/objects/curriculum/IEECurriculum";

/**
 * 文書評価教科。
 *
 * リソース名は /eeCurriculums/{eecId}。
 *
 * EEC = Essay-Evaluated Curriculumの略とする。
 */
export class EECurriculum implements Curriculum, IEECurriculum {
  constructor(
    public readonly eecId: string,
    public readonly schoolYear: number,
    public readonly grade: Grade,
    public readonly name: string,
    public syllabusFileGcsObjectPath: string | null
  ) {}

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}`;
  }

  static fromEECurriculumResp(resp: EECurriculumResp): EECurriculum {
    return new EECurriculum(
      resp.eecId,
      resp.schoolYear,
      new Grade(resp.grade),
      resp.name.value,
      resp.syllabusFileGcsObjectPath ?? null
    );
  }
}

export class EECurriculumTree implements IEECurriculumTree {
  constructor(public readonly self: EECurriculum, public readonly months: EECMonthTree[]) {}
}

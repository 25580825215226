







import Vue, { PropType } from "vue";
import { messages, Messages } from "@/ts/const/Messages";

export default Vue.extend({
  name: "CreateJournalButton",
  props: {
    onClick: { type: Function as PropType<() => Promise<void>>, required: true },
  },
  data(): { messages: Messages } {
    return {
      messages,
    };
  },
});











import Vue from "vue";
import ProfileInnerHeaderUpper from "@/views/profile/components/ProfileInnerHeaderUpper/ProfileInnerHeaderUpper.vue";

export default Vue.extend({
  name: "ProfileGuardianHeader",
  components: { ProfileInnerHeaderUpper },
  props: {
    googleMail: { type: String, required: true },
    photoUrl: { type: String, required: false },
    name: { type: String, required: true },
  },
});
















import Vue, { PropType } from "vue";
import { messages } from "@/ts/const/Messages";
import { Err } from "@/ts/objects/Err";
import { MessageViewParam } from "@/components/MessageView.vue";
import { SolanStoreS } from "@/store/SolanStore";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { EditableSolanRubric } from "@/ts/objects/solan/editable/EditableSolanRubric";
import { solanProcessTextOf } from "@/ts/utils/AppUtil";
import { NavigationGuardNext, Route } from "vue-router";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import SolanRubricsPure from "@/views/solan/student/SolanRubrics/SolanRubricsPure.vue";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { AppStateStore } from "@/store/AppStateStore";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "SolanRubrics",
  components: { SolanRubricsPure },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    solanStoreS: { type: Object as PropType<SolanStoreS>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true },
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
    });
    this.reloadData();
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      this.reloadData();
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;
    pageLeaveService: PageLeaveService | null;

    project: SolanProject | null;
    rubrics: EditableSolanRubric[] | null;

    selectedProcess: number;
    addRubricEnabled: boolean;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,
      pageLeaveService: null,

      project: null,
      rubrics: null,

      selectedProcess: 0,
      addRubricEnabled: true,
    };
  },
  computed: {
    needSave(): boolean {
      const rubrics = this.rubrics;
      if (rubrics === null) return false;
      return rubrics.some(s => s.needSave());
    },
  },
  methods: {
    async reloadData() {
      const project = await this.solanStoreS.project.getDataWithTimeout();
      if (isNullish(project)) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      this.rubrics = null;
      this.messageView = null;

      const resp = await this.solanRepository.listEditableSolanRubrics(project.projectId, true);
      if (resp instanceof Err) {
        this.messageView = { message: messages.failedToLoadData, fadeIn: true };
        return;
      }

      this.project = project;
      this.rubrics = resp;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      const rubrics = this.rubrics;
      if (rubrics === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(rubrics.map(r => r.saveAllChanges(force))));
    },
    selectProcess(process: number) {
      this.selectedProcess = process;
    },
    async addRubric(process: number) {
      const project = this.project;
      const rubrics = this.rubrics;
      if (isNullish(project) || isNullish(rubrics)) return;

      if (project.studentInputLocked) return;

      const projectId = project?.projectId;

      if (!this.addRubricEnabled) return;
      this.addRubricEnabled = false;
      const resp = await this.solanRepository.postAndGetEditableSolanRubric(projectId, process, true);
      this.addRubricEnabled = true;
      if (resp instanceof Err) return; // TODO エラー処理？
      rubrics.push(resp);
    },
    async deleteRubric(rubricId: string) {
      const deletingRubric = this.rubrics?.find(r => r.rubricId === rubricId);
      if (isNullish(deletingRubric)) return;

      // 入力したものがすべて消えます。消しますか？
      if (!window.confirm(messages.confirmDeleteAllYourInputs)) {
        return;
      }

      const resp = await this.solanRepository.deleteRubric(deletingRubric.projectId, deletingRubric.rubricId);
      if (resp instanceof Err) {
        log.error("Failed to delete rubric.");
        return;
      }
      this.rubrics = this.rubrics?.filter(r => r !== deletingRubric) ?? null;
    },
    onRubricPopupMenuClicked(menuKey: string, rubricId: string) {
      switch (menuKey) {
        case "delete":
          this.deleteRubric(rubricId);
          return;
      }
    },
    _solanProcessTextOf(process: number): string {
      return solanProcessTextOf(process);
    },
  },
});









import ProfileInnerHeaderUserName from "@/views/profile/components/ProfileInnerHeaderUserName/ProfileInnerHeaderUserName.vue";
import Vue from "vue";
import ProfileInnerHeaderItem from "@/views/profile/components/ProfileInnerHeaderItem/ProfileInnerHeaderItem.vue";
import { Words, words } from "@/ts/const/Words";

export default Vue.extend({
  name: "ProfileInnerHeaderUpper",
  components: { ProfileInnerHeaderItem, ProfileInnerHeaderUserName },
  props: {
    googleMail: { type: String, required: true },
    photoUrl: { type: String, required: false },
    name: { type: String, required: true },
  },
  data(): { words: Words } {
    return {
      words,
    };
  },
});








import Vue from "vue";
import { hasValue } from "@/ts/utils";

export default Vue.extend({
  name: "StudentActivityTableReadonlyTextCell",
  props: {
    value: { type: String, required: true },

    width: { type: Number, required: false },
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--width": hasValue(this.width) ? `${this.width}px` : "auto",
      };
    },
  },
});


































import Vue, { PropType } from "vue";
import StudentActivityTableStudentCell from "@/components/student-activity-table/StudentActivityTableStudentCell/StudentActivityTableStudentCell.vue";
import SolanJournalsTTableProjectRow from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTTable/SolanJournalsTTableStudentRow/SolanJournalsTTableProjectRow/SolanJournalsTTableProjectRow.vue";
import {
  SolanJournalsTColFilterState,
  SolanJournalsTStudentRow,
  SolanJournalsTStyleProps,
} from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTModel";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { JournalFile } from "@/ts/objects/common/JournalFile";

export default Vue.extend({
  name: "SolanJournalsTTableStudentRow",
  components: { SolanJournalsTTableProjectRow, StudentActivityTableStudentCell },
  props: {
    model: { type: Object as PropType<SolanJournalsTStudentRow>, required: true },

    /**
     * ファイルビューを開いている学習記録のリソース名。（正確には、ルーブリックのリソース名。）
     */
    filesViewJournalRname: { type: String, required: false },

    /**
     * 児童生徒ビューへのパス。
     * 未設定なら、リンク自体表示しない。
     */
    studentViewPath: { type: Function as PropType<(projectId: string) => string>, required: false },

    onCellBlur: { type: Function as PropType<() => void>, required: true },
    onFilesViewOpen: {
      type: Function as PropType<(studentUserId: string, projectId: string, rubricId: string | null) => void>,
      required: true,
    },
    onJournalFileUpload: {
      type: Function as PropType<(file: File, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },
    onJournalFileDelete: {
      type: Function as PropType<(journalFile: JournalFile, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },

    colFilterState: { type: Object as PropType<SolanJournalsTColFilterState>, required: true },
    highlightUnsaved: { type: Boolean, required: true },

    styleProps: { type: Object as PropType<SolanJournalsTStyleProps>, required: true },
  },
});
















import Vue, { PropType } from "vue";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { AppStateStore } from "@/store/AppStateStore";
import { Guardian } from "@/ts/objects/user/Guardian";
import { isNullish } from "@/ts/utils";
import { Err } from "@/ts/objects/Err";
import ProfileGuardianHeader from "@/views/profile/ProfileGuardian/ProfileGuardianHeader/ProfileGuardianHeader.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { getGuardianColumns, GuardianColumn, GuardianColumnId } from "@/ts/app/columns/guardian/GuardianColumn";
import ProfileTable from "@/views/profile/components/ProfileTable/ProfileTable.vue";

const tableColIds: GuardianColumnId[] = [
  "pictureGcsUrl1",
  "name1",
  "nameKana1",
  "email1",
  "mobilePhone1",
  "landlinePhone1",
  "job1",
  "workPlace1",

  "pictureGcsUrl2",
  "name2",
  "nameKana2",
  "email2",
  "mobilePhone2",
  "landlinePhone2",
  "job2",
  "workPlace2",

  "emergencyContactRelationship1",
  "emergencyContactRelationship2",
  "emergencyContactRelationship3",
];

export default Vue.extend({
  name: "ProfileGuardian",
  components: { ProfileTable, LoadingBlock, ProfileGuardianHeader },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
  },
  data(): {
    guardian: Guardian | null;
  } {
    return {
      guardian: null,
    };
  },
  computed: {
    guardianUserId(): string | null {
      if (!this.appStateStore.isAppUserGuardian) return null;
      return this.appStateStore.userState?.userId ?? null;
    },
    guardianColumns(): GuardianColumn<GuardianColumnId, any>[] | null {
      const _guardian = this.guardian;
      if (isNullish(_guardian)) return null;
      return getGuardianColumns(_guardian, tableColIds);
    },
  },
  watch: {
    guardianUserId: {
      handler: async function(newValue: string | null) {
        if (isNullish(newValue)) {
          this.guardian = null;
          return;
        }

        const result = await this.userRepository.getGuardian(newValue);
        if (result instanceof Err) {
          this.guardian = null;
          return;
        }
        this.guardian = result;
      },
      immediate: true,
    },
  },
});
























import Vue, { PropType } from "vue";
import { Project } from "@/ts/objects/project/value/Project";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "ProjectAboutPure",
  components: { MessageView, LoadingBlock },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },

    project: { type: Object as PropType<Project | null> },
  },
  data(): {
    words: Words;
  } {
    return {
      words,
    };
  },
});












import Vue, { PropType } from "vue";
import JournalFilePreviewItem from "@/components/journalfile/JournalFilePreviewItem.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "JournalFilePreviewArea",
  components: { JournalFilePreviewItem },
  props: {
    journalFiles: { type: Array as PropType<JournalFile[]>, required: true },
    noFilesMessage: { type: String, required: true },
    useSmallFont: { type: Boolean, default: false },
  },
  data(): {
    words: Words;
  } {
    return { words };
  },
});


























































































import Vue, { PropType } from "vue";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import { ProjectJournalsTColFilterState, ProjectJournalsTStyleProps } from "../../../ProjectJournalsTModel";
import { words, Words } from "@/ts/const/Words";
import StudentActivityTableReadonlyTextCell from "@/components/student-activity-table/StudentActivityTableReadonlyTextCell/StudentActivityTableReadonlyTextCell.vue";
import StudentActivityTableTextCell from "@/components/student-activity-table/StudentActivityTableTextCell/StudentActivityTableTextCell.vue";
import StudentActivityTableRatingCell from "@/components/student-activity-table/StudentActivityTableRatingCell/StudentActivityTableRatingCell.vue";
import StudentActivityTablePublishStateCell from "@/components/student-activity-table/StudentActivityTablePublishStateCell/StudentActivityTablePublishStateCell.vue";
import StudentActivityTableSelectRowCheckboxCell from "@/components/student-activity-table/StudentActivityTableSelectRowCheckboxCell/StudentActivityTableSelectRowCheckboxCell.vue";

export default Vue.extend({
  name: "ProjectJournalsTTableLookbackRow",
  components: {
    StudentActivityTableSelectRowCheckboxCell,
    StudentActivityTablePublishStateCell,
    StudentActivityTableRatingCell,
    StudentActivityTableTextCell,
    StudentActivityTableReadonlyTextCell,
  },
  props: {
    lookback: { type: Object as PropType<EditableProjectLookback>, required: true },
    selected: { type: Boolean, required: true },

    onSelect: { type: Function as PropType<(selected: boolean) => void>, required: true },
    onCellBlur: { type: Function as PropType<() => void>, required: true },

    colFilterState: { type: Object as PropType<ProjectJournalsTColFilterState>, required: true },
    highlightUnsaved: { type: Boolean, required: true },

    styleProps: { type: Object as PropType<ProjectJournalsTStyleProps>, required: true },
  },
  data(): { words: Words } {
    return { words };
  },
});

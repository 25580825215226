







import Vue from "vue";

export default Vue.extend({
  name: "SaveStateIndicator",
  props: {
    saved: { type: Boolean, required: true },
  },
});














































import Vue, { PropType } from "vue";
import { words, Words } from "@/ts/const/Words";
import {
  ProjectJournalsTColFilterState,
  ProjectJournalsTStudentRow,
  ProjectJournalsTStyleProps,
} from "../../ProjectJournalsTModel";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { messages, Messages } from "@/ts/const/Messages";
import { isNullish } from "@/ts/utils";
import ProjectJournalsTTableJournalRow from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsTTable/ProjectJournalsTTableStudentRow/ProjectJournalsTTableJournalRow/ProjectJournalsTTableJournalRow.vue";
import StudentActivityTableStudentCell from "@/components/student-activity-table/StudentActivityTableStudentCell/StudentActivityTableStudentCell.vue";
import ProjectJournalsTTableLookbackRow from "@/views/project/teacher/ProjectJournalsT/ProjectJournalsTTable/ProjectJournalsTTableStudentRow/ProjectJournalsTTableLookbackRow/ProjectJournalsTTableLookbackRow.vue";

export default Vue.extend({
  name: "ProjectJournalsTTableStudentRow",
  components: {
    ProjectJournalsTTableLookbackRow,
    StudentActivityTableStudentCell,
    ProjectJournalsTTableJournalRow,
  },
  props: {
    model: { type: Object as PropType<ProjectJournalsTStudentRow>, required: true },

    /**
     * ファイルビューを開いている学習記録のリソース名。
     */
    filesViewJournalRname: { type: String, required: false },

    /**
     * 児童生徒ビューへのパス。
     * 未設定なら、リンク自体表示しない。
     */
    studentViewPath: { type: String, required: false },

    onCellBlur: { type: Function as PropType<() => void>, required: true },
    onFilesViewOpen: {
      type: Function as PropType<(studentUserId: string | null, journalRname: string | null) => void>,
      required: true,
    },
    onJournalFileUpload: {
      type: Function as PropType<(file: File, journal: EditableProjectJournal) => Promise<void>>,
      required: true,
    },
    onJournalFileDelete: {
      type: Function as PropType<(journalFile: JournalFile, journal: EditableProjectJournal) => Promise<void>>,
      required: true,
    },

    colFilterState: { type: Object as PropType<ProjectJournalsTColFilterState>, required: true },
    highlightUnsaved: { type: Boolean, required: true },

    styleProps: { type: Object as PropType<ProjectJournalsTStyleProps>, required: true },
  },
  data(): { words: Words; messages: Messages } {
    return { words, messages };
  },
  methods: {
    isFilesViewOpen(rname: string): boolean {
      const filesViewJournalRname = this.filesViewJournalRname;
      if (isNullish(filesViewJournalRname)) return false;
      return filesViewJournalRname === rname;
    },
  },
});

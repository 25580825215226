
































import Vue, { PropType } from "vue";
import CurriculumSyllabusEECTable from "@/views/curriculum/teacher/CurriculumSyllabusEEC/components/CurriculumSyllabusEECTable/CurriculumSyllabusEECTable.vue";
import { Loadable } from "@/ts/Loadable";
import MessageView from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import CurriculumSyllabusHeader from "@/views/curriculum/teacher/components/CurriculumSyllabusHeader/CurriculumSyllabusHeader.vue";
import { QuarterSwitchValue, quarterSwitchValueToMonths } from "@/components/QuarterSwitch/QuarterSwitch.vue";
import { ICurriculumSyllabusEECModel } from "@/views/curriculum/teacher/CurriculumSyllabusEEC/CurriculumSyllabusEECModel";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import { MonthValue } from "@/ts/objects/common/MonthValue";

export default Vue.extend({
  name: "CurriculumSyllabusEECPure",
  components: { CurriculumSyllabusEECTable, CurriculumSyllabusHeader, LoadingBlock, MessageView },
  props: {
    model: { type: Object as PropType<Loadable<ICurriculumSyllabusEECModel>>, required: true },
    quarterSwitchValue: { type: String as PropType<QuarterSwitchValue>, required: true },
    needSave: { type: Boolean, required: true },
    currentErrors: { type: Array as PropType<ErrorNotificationParam[]>, required: true },

    uploadSyllabusFile: { type: Function as PropType<(file: File) => Promise<void>>, required: true },
    onInputQuarterSwitch: { type: Function as PropType<(value: QuarterSwitchValue) => void>, required: true },

    onInputText: {
      type: Function as PropType<(month: MonthValue, activityId: string, value: string) => void>,
      required: true,
    },
    onInputEnabled: {
      type: Function as PropType<(month: MonthValue, activityId: string, value: boolean) => void>,
      required: true,
    },
    moveActivity: {
      type: Function as PropType<(month: MonthValue, activityId: string, up: boolean) => Promise<void>>,
      required: true,
    },
    createActivity: {
      type: Function as PropType<(month: MonthValue) => Promise<void>>,
      required: true,
    },
    deleteActivity: {
      type: Function as PropType<(month: MonthValue, activityId: string) => Promise<void>>,
      required: true,
    },
    exportCsv: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  computed: {
    eecName(): string {
      return this.model.data?.eecName ?? "";
    },
    syllabusFileGcsObjectPath(): string | null {
      return this.model.data?.syllabusFileGcsObjectPath ?? null;
    },
    monthsToDisplay(): readonly MonthValue[] {
      return quarterSwitchValueToMonths(this.quarterSwitchValue);
    },
  },
});













































import Vue, { PropType } from "vue";
import StudentActivityTableProjectCell from "@/components/student-activity-table/StudentActivityTableProjectCell/StudentActivityTableProjectCell.vue";
import SolanJournalsTTableJournalRow from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTTable/SolanJournalsTTableStudentRow/SolanJournalsTTableProjectRow/SolanJournalsTTableJournalRow/SolanJournalsTTableJournalRow.vue";
import SolanJournalsTTableLookbackRow from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTTable/SolanJournalsTTableStudentRow/SolanJournalsTTableProjectRow/SolanJournalsTTableLookbackRow/SolanJournalsTTableLookbackRow.vue";
import {
  SolanJournalsTColFilterState,
  SolanJournalsTProjectRow,
  SolanJournalsTStyleProps,
} from "@/views/solan/teacher/SolanJournalsT/SolanJournalsTModel";
import { isNullish } from "@/ts/utils";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { JournalFile } from "@/ts/objects/common/JournalFile";

export default Vue.extend({
  name: "SolanJournalsTTableProjectRow",
  components: {
    SolanJournalsTTableLookbackRow,
    SolanJournalsTTableJournalRow,
    StudentActivityTableProjectCell,
  },
  props: {
    model: { type: Object as PropType<SolanJournalsTProjectRow>, required: true },

    /**
     * ファイルビューを開いている学習記録のリソース名。（正確には、ルーブリックのリソース名。）
     */
    filesViewJournalRname: { type: String, required: false },

    /**
     * 児童生徒ビューへのパス。
     * 未設定なら、リンク自体表示しない。
     */
    studentViewPath: { type: String, required: false },

    onCellBlur: { type: Function as PropType<() => void>, required: true },
    onFilesViewOpen: { type: Function as PropType<(rubricId: string | null) => void>, required: true },
    onJournalFileUpload: {
      type: Function as PropType<(file: File, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },
    onJournalFileDelete: {
      type: Function as PropType<(journalFile: JournalFile, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },

    colFilterState: { type: Object as PropType<SolanJournalsTColFilterState>, required: true },
    highlightUnsaved: { type: Boolean, required: true },

    styleProps: { type: Object as PropType<SolanJournalsTStyleProps>, required: true },
  },
  methods: {
    isFilesViewOpen(rname: string): boolean {
      const filesViewJournalRname = this.filesViewJournalRname;
      if (isNullish(filesViewJournalRname)) return false;
      return filesViewJournalRname === rname;
    },
  },
});

import {
  StudentCustomColumnDefSet as StudentCustomColumnDefSetResp,
  StudentCustomColumnDefSetWrite,
} from "@/ts/api/user-service";
import { Student } from "@/ts/objects/user/Student";
import { hasValue } from "@/ts/utils";

export const studentCustomColumnIds = [
  "custom01",
  "custom02",
  "custom03",
  "custom04",
  "custom05",
  "custom06",
  "custom07",
  "custom08",
  "custom09",
  "custom10",
  "custom11",
  "custom12",
  "custom13",
  "custom14",
  "custom15",
  "custom16",
  "custom17",
  "custom18",
  "custom19",
  "custom20",
  "custom21",
  "custom22",
  "custom23",
  "custom24",
  "custom25",
  "custom26",
  "custom27",
  "custom28",
  "custom29",
  "custom30",
  "custom31",
  "custom32",
  "custom33",
  "custom34",
  "custom35",
  "custom36",
  "custom37",
  "custom38",
  "custom39",
  "custom40",
] as const;

export type StudentCustomColumnNames = {
  custom01: string;
  custom02: string;
  custom03: string;
  custom04: string;
  custom05: string;
  custom06: string;
  custom07: string;
  custom08: string;
  custom09: string;
  custom10: string;
  custom11: string;
  custom12: string;
  custom13: string;
  custom14: string;
  custom15: string;
  custom16: string;
  custom17: string;
  custom18: string;
  custom19: string;
  custom20: string;
  custom21: string;
  custom22: string;
  custom23: string;
  custom24: string;
  custom25: string;
  custom26: string;
  custom27: string;
  custom28: string;
  custom29: string;
  custom30: string;
  custom31: string;
  custom32: string;
  custom33: string;
  custom34: string;
  custom35: string;
  custom36: string;
  custom37: string;
  custom38: string;
  custom39: string;
  custom40: string;
};

export function studentCustomColumnNamesDefault(): StudentCustomColumnNames {
  return {
    custom01: "Custom Item 01",
    custom02: "Custom Item 02",
    custom03: "Custom Item 03",
    custom04: "Custom Item 04",
    custom05: "Custom Item 05",
    custom06: "Custom Item 06",
    custom07: "Custom Item 07",
    custom08: "Custom Item 08",
    custom09: "Custom Item 09",
    custom10: "Custom Item 10",
    custom11: "Custom Item 11",
    custom12: "Custom Item 12",
    custom13: "Custom Item 13",
    custom14: "Custom Item 14",
    custom15: "Custom Item 15",
    custom16: "Custom Item 16",
    custom17: "Custom Item 17",
    custom18: "Custom Item 18",
    custom19: "Custom Item 19",
    custom20: "Custom Item 20",
    custom21: "Custom Item 21",
    custom22: "Custom Item 22",
    custom23: "Custom Item 23",
    custom24: "Custom Item 24",
    custom25: "Custom Item 25",
    custom26: "Custom Item 26",
    custom27: "Custom Item 27",
    custom28: "Custom Item 28",
    custom29: "Custom Item 29",
    custom30: "Custom Item 30",
    custom31: "Custom Item 31",
    custom32: "Custom Item 32",
    custom33: "Custom Item 33",
    custom34: "Custom Item 34",
    custom35: "Custom Item 35",
    custom36: "Custom Item 36",
    custom37: "Custom Item 37",
    custom38: "Custom Item 38",
    custom39: "Custom Item 39",
    custom40: "Custom Item 40",
  };
}

export function studentCustomColumnNamesFromStudent(s: Student): StudentCustomColumnNames {
  return {
    custom01: s.custom01.name,
    custom02: s.custom02.name,
    custom03: s.custom03.name,
    custom04: s.custom04.name,
    custom05: s.custom05.name,
    custom06: s.custom06.name,
    custom07: s.custom07.name,
    custom08: s.custom08.name,
    custom09: s.custom09.name,
    custom10: s.custom10.name,
    custom11: s.custom11.name,
    custom12: s.custom12.name,
    custom13: s.custom13.name,
    custom14: s.custom14.name,
    custom15: s.custom15.name,
    custom16: s.custom16.name,
    custom17: s.custom17.name,
    custom18: s.custom18.name,
    custom19: s.custom19.name,
    custom20: s.custom20.name,
    custom21: s.custom21.name,
    custom22: s.custom22.name,
    custom23: s.custom23.name,
    custom24: s.custom24.name,
    custom25: s.custom25.name,
    custom26: s.custom26.name,
    custom27: s.custom27.name,
    custom28: s.custom28.name,
    custom29: s.custom29.name,
    custom30: s.custom30.name,
    custom31: s.custom31.name,
    custom32: s.custom32.name,
    custom33: s.custom33.name,
    custom34: s.custom34.name,
    custom35: s.custom35.name,
    custom36: s.custom36.name,
    custom37: s.custom37.name,
    custom38: s.custom38.name,
    custom39: s.custom39.name,
    custom40: s.custom40.name,
  };
}

export function studentCustomColumnNamesFromRespOrError(r: StudentCustomColumnDefSetResp): StudentCustomColumnNames {
  return {
    custom01: r.custom01.name,
    custom02: r.custom02.name,
    custom03: r.custom03.name,
    custom04: r.custom04.name,
    custom05: r.custom05.name,
    custom06: r.custom06.name,
    custom07: r.custom07.name,
    custom08: r.custom08.name,
    custom09: r.custom09.name,
    custom10: r.custom10.name,
    custom11: r.custom11.name,
    custom12: r.custom12.name,
    custom13: r.custom13.name,
    custom14: r.custom14.name,
    custom15: r.custom15.name,
    custom16: r.custom16.name,
    custom17: r.custom17.name,
    custom18: r.custom18.name,
    custom19: r.custom19.name,
    custom20: r.custom20.name,
    custom21: r.custom21.name,
    custom22: r.custom22.name,
    custom23: r.custom23.name,
    custom24: r.custom24.name,
    custom25: r.custom25.name,
    custom26: r.custom26.name,
    custom27: r.custom27.name,
    custom28: r.custom28.name,
    custom29: r.custom29.name,
    custom30: r.custom30.name,
    custom31: r.custom31.name,
    custom32: r.custom32.name,
    custom33: r.custom33.name,
    custom34: r.custom34.name,
    custom35: r.custom35.name,
    custom36: r.custom36.name,
    custom37: r.custom37.name,
    custom38: r.custom38.name,
    custom39: r.custom39.name,
    custom40: r.custom40.name,
  };
}

export function studentCustomColumnNamesToReq(n: Partial<StudentCustomColumnNames>): StudentCustomColumnDefSetWrite {
  return {
    custom01: hasValue(n.custom01) ? { name: n.custom01 } : undefined,
    custom02: hasValue(n.custom02) ? { name: n.custom02 } : undefined,
    custom03: hasValue(n.custom03) ? { name: n.custom03 } : undefined,
    custom04: hasValue(n.custom04) ? { name: n.custom04 } : undefined,
    custom05: hasValue(n.custom05) ? { name: n.custom05 } : undefined,
    custom06: hasValue(n.custom06) ? { name: n.custom06 } : undefined,
    custom07: hasValue(n.custom07) ? { name: n.custom07 } : undefined,
    custom08: hasValue(n.custom08) ? { name: n.custom08 } : undefined,
    custom09: hasValue(n.custom09) ? { name: n.custom09 } : undefined,
    custom10: hasValue(n.custom10) ? { name: n.custom10 } : undefined,
    custom11: hasValue(n.custom11) ? { name: n.custom11 } : undefined,
    custom12: hasValue(n.custom12) ? { name: n.custom12 } : undefined,
    custom13: hasValue(n.custom13) ? { name: n.custom13 } : undefined,
    custom14: hasValue(n.custom14) ? { name: n.custom14 } : undefined,
    custom15: hasValue(n.custom15) ? { name: n.custom15 } : undefined,
    custom16: hasValue(n.custom16) ? { name: n.custom16 } : undefined,
    custom17: hasValue(n.custom17) ? { name: n.custom17 } : undefined,
    custom18: hasValue(n.custom18) ? { name: n.custom18 } : undefined,
    custom19: hasValue(n.custom19) ? { name: n.custom19 } : undefined,
    custom20: hasValue(n.custom20) ? { name: n.custom20 } : undefined,
    custom21: hasValue(n.custom21) ? { name: n.custom21 } : undefined,
    custom22: hasValue(n.custom22) ? { name: n.custom22 } : undefined,
    custom23: hasValue(n.custom23) ? { name: n.custom23 } : undefined,
    custom24: hasValue(n.custom24) ? { name: n.custom24 } : undefined,
    custom25: hasValue(n.custom25) ? { name: n.custom25 } : undefined,
    custom26: hasValue(n.custom26) ? { name: n.custom26 } : undefined,
    custom27: hasValue(n.custom27) ? { name: n.custom27 } : undefined,
    custom28: hasValue(n.custom28) ? { name: n.custom28 } : undefined,
    custom29: hasValue(n.custom29) ? { name: n.custom29 } : undefined,
    custom30: hasValue(n.custom30) ? { name: n.custom30 } : undefined,
    custom31: hasValue(n.custom31) ? { name: n.custom31 } : undefined,
    custom32: hasValue(n.custom32) ? { name: n.custom32 } : undefined,
    custom33: hasValue(n.custom33) ? { name: n.custom33 } : undefined,
    custom34: hasValue(n.custom34) ? { name: n.custom34 } : undefined,
    custom35: hasValue(n.custom35) ? { name: n.custom35 } : undefined,
    custom36: hasValue(n.custom36) ? { name: n.custom36 } : undefined,
    custom37: hasValue(n.custom37) ? { name: n.custom37 } : undefined,
    custom38: hasValue(n.custom38) ? { name: n.custom38 } : undefined,
    custom39: hasValue(n.custom39) ? { name: n.custom39 } : undefined,
    custom40: hasValue(n.custom40) ? { name: n.custom40 } : undefined,
  };
}

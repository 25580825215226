























import Vue, { PropType } from "vue";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { SolanStoreS } from "@/store/SolanStore";
import { SelectItem } from "@/components/PopupSelectButton.vue";
import { SolanProjectInfoOnEditStore } from "@/store/SolanProjectOnEditStore";
import { AppStateStore } from "@/store/AppStateStore";
import { Err } from "@/ts/objects/Err";
import log from "loglevel";
import SolanHeaderPure from "@/views/solan/student/SolanHeader/SolanHeaderPure.vue";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { words } from "@/ts/const/Words";

export default Vue.extend({
  name: "SolanHeader",
  components: { SolanHeaderPure },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    solanStoreS: { type: Object as PropType<SolanStoreS>, required: true },
    solanProjectInfoOnEditStore: { type: Object as PropType<SolanProjectInfoOnEditStore>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true },
  },
  computed: {
    _appStateStore(): AppStateStore {
      return this.appStateStore;
    },
    projectBasePath(): string {
      return this.solanStoreS.studentBasePath;
    },
    isCurrentTabJournals() {
      return this.$route.name === "S.Solan.Journals";
    },
    isCurrentTabRubrics() {
      return this.$route.name === "S.Solan.Rubrics";
    },
    isCurrentTabAbout() {
      return this.$route.name === "S.Solan.About";
    },
    isCurrentTabLookback() {
      return this.$route.name === "S.Solan.Lookback";
    },

    project(): SolanProject | null {
      return this.solanStoreS.project.data;
    },
    editing(): boolean {
      return this.solanProjectInfoOnEditStore.info !== null;
    },
    projectName: {
      get(): string | null {
        if (this.solanProjectInfoOnEditStore.info !== null) return this.solanProjectInfoOnEditStore.info.name;
        if (this.project !== null) return this.project.name;
        return null;
      },
      set(value: string) {
        log.debug(`Update name! : ${value}`);
        this.solanProjectInfoOnEditStore.updateName(value);
      },
    },
    schoolYear(): number | null {
      return this.solanStoreS.schoolYear;
    },
    minSchoolYear(): number | null {
      return this.appStateStore.studentOrGuardianState?.studentMinSchoolYear() ?? null;
    },
    maxSchoolYear(): number | null {
      return this.appStateStore.studentOrGuardianState?.studentMaxSchoolYear() ?? null;
    },
    projectSelections(): SelectItem[] {
      const projectsOfSchoolYear = this.solanStoreS.projectsOfSchoolYear.data ?? [];
      return projectsOfSchoolYear.map(p => {
        return {
          key: `${this.appStateStore.studentOrGuardianBasePath}/solan/${p.schoolYear}/${p.projectId}`,
          label: p.name !== "" ? p.name : `New ${words.theme.d}`,
        };
      });
    },

    /**
     * 新プロジェクトを作成可能ならtrue。
     * 選択中の年度が、現在の年度である場合のみtrueとする。
     */
    canCreateNewProject(): boolean {
      const schoolYear = this.solanStoreS.schoolYear;
      if (schoolYear === null) return false;

      return schoolYear === this.appStateStore.currentQuarter.schoolYear;
    },
  },
  methods: {
    selectProject(path: string) {
      log.debug(`selectProject: path=${path}`);
      this.$router.push(path).catch(() => {});
    },
    async selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "newProject":
          await this.createNewProjectAndGoEdit();
          break;
      }
    },
    async createNewProjectAndGoEdit() {
      const studentUserId = this.appStateStore.studentOrGuardianState?.studentInfo()?.studentUserId;
      const schoolYear = this.solanStoreS.schoolYear;
      if (studentUserId === undefined || schoolYear === null) return;
      const resp = await this.solanRepository.postProject(studentUserId, schoolYear);
      if (resp instanceof Err) return; // TODO エラーの場合どうする？
      await this.solanStoreS.reloadProjects({ solanRepository: this.solanRepository, studentUserId });
      await this.$router
        .push(`${this.appStateStore.studentOrGuardianBasePath}/solan/${resp.schoolYear}/${resp.projectId}/about`)
        .catch(() => {});
    },
    updateProjectName(event: Event) {
      this.projectName = (event.target as HTMLInputElement).value;
    },
  },
});























import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import firebase from "firebase/app";
import "firebase/storage";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";
import { UserType } from "@/ts/objects/common/UserType";
import SolanJournalsTable from "@/views/solan/components/SolanJournalsTable/SolanJournalsTable.vue";

const processColWidth = 100;
const ratingColWidth = 60;

export default Vue.extend({
  name: "SolanJournalsPure",
  components: {
    SolanJournalsTable,
    LoadingBlock,
    MessageView,
  },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },
    storage: { type: Object as PropType<firebase.storage.Storage | null> },
    userType: { type: String as PropType<UserType>, required: false },

    journals: { type: Array as PropType<EditableSolanJournal[] | null> },

    filesViewJournal: { type: Object as PropType<EditableSolanJournal | null> },

    reloadData: { type: Function },
    saveAll: { type: Function },
    setFilesView: { type: Function as PropType<(rubricId: string | null) => void>, required: true },
    isMyFilesViewOpen: { type: Function },

    /**
     * 学習記録ファイルをアップロードする関数。
     */
    uploadJournalFile: {
      type: Function as PropType<(file: File, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },

    /**
     * 学習記録ファイルを削除する関数。
     */
    deleteJournalFile: {
      type: Function as PropType<(file: JournalFile, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },
  },
  data(): {
    processColWidth: number;
    ratingColWidth: number;

    words: Words;
    messages: Messages;
  } {
    return {
      processColWidth,
      ratingColWidth,

      words,
      messages,
    };
  },
});

import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";

export class EditableSolanStudent extends EditableObject {
  protected allEditables() {
    return this.projects;
  }

  constructor(
    public readonly studentUserId: string,
    public readonly studentNumber: number,
    public readonly name: string,
    public readonly projects: EditableSolanStudentProject[]
  ) {
    super();
  }
}

export class EditableSolanStudentProject extends EditableObject {
  protected allEditables() {
    return [...this.journals, this.lookback];
  }

  constructor(
    public readonly project: SolanProject,
    public readonly journals: EditableSolanJournal[],
    public readonly lookback: EditableSolanLookback
  ) {
    super();
  }
}

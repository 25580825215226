




























import Vue, { PropType } from "vue";
import SolanJournalsTableHeaderRow from "@/views/solan/components/SolanJournalsTable/SolanJournalsTableHeaderRow/SolanJournalsTableHeaderRow.vue";
import SolanJournalsTableRow from "@/views/solan/components/SolanJournalsTable/SolanJournalsTableRow/SolanJournalsTableRow.vue";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { UserType } from "@/ts/objects/common/UserType";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "SolanJournalsTable",
  components: { SolanJournalsTableRow, SolanJournalsTableHeaderRow },
  props: {
    userType: { type: String as PropType<UserType>, required: false },
    journals: { type: Array as PropType<EditableSolanJournal[]>, required: true },

    filesViewJournal: { type: Object as PropType<EditableSolanJournal>, required: false },

    onBlur: { type: Function as PropType<() => void>, required: true },
    onFilesViewChange: { type: Function as PropType<(rubricId: string | null) => void>, required: true },

    /**
     * 学習記録ファイルをアップロードする関数。
     */
    uploadJournalFile: {
      type: Function as PropType<(file: File, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },
    /**
     * 学習記録ファイルを削除する関数。
     */
    deleteJournalFile: {
      type: Function as PropType<(file: JournalFile, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },

    headerFontSize: { type: Number, required: true },
    processColWidth: { type: Number, required: true },
    ratingColWidth: { type: Number, required: true },
  },
  data(): {
    words: Words;
    messages: Messages;
  } {
    return {
      words,
      messages,
    };
  },
  computed: {
    reversedJournals(): EditableSolanJournal[] {
      const journals = this.journals;
      return journals.slice().reverse();
    },
  },
  methods: {
    isAssociatedFilesViewOpen(rubricId: string): boolean {
      const filesViewJournal = this.filesViewJournal;
      if (isNullish(filesViewJournal)) return false;
      return filesViewJournal.rubricId === rubricId;
    },
  },
});

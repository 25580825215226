



















import Vue, { PropType } from "vue";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "CurriculumListNECEvaluationCellPublishState",
  props: {
    published: { type: Boolean, required: true },

    onInput: { type: Function as PropType<(published: boolean) => void>, required: true },
  },
  data(): {
    showPopup: boolean;

    words: Words;
  } {
    return {
      showPopup: false,

      words,
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    onClickOutside() {
      this.showPopup = false;
    },
    onClickPublish() {
      this.showPopup = false;
      this.onInput(true);
    },
    onClickUnpublish() {
      this.showPopup = false;
      this.onInput(false);
    },
  },
});

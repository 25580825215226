















import { isNullish } from "@/ts/utils";
import Vue, { PropType } from "vue";
import log from "loglevel";
import TipBlock from "@/components/TipBlock.vue";

export default Vue.extend({
  name: "UploadSyllabusFileButton",
  components: { TipBlock },
  props: {
    uploadSyllabusFile: { type: Function as PropType<(file: File) => Promise<void>>, required: true },
  },
  data(): {
    uploading: boolean;
  } {
    return {
      uploading: false,
    };
  },
  methods: {
    async startUploading(e: Event) {
      if (isNullish(this.uploadSyllabusFile)) return;
      if (this.uploading) {
        log.debug(`UploadSyllabusFileButton: Already uploading!`);
        return;
      }

      const files = (e.target as HTMLInputElement).files;
      if (isNullish(files) || files.length === 0) return;
      const file = files[0];
      if (!(file instanceof File)) return;

      this.uploading = true;
      await this.uploadSyllabusFile(file);
      this.uploading = false;
    },
  },
});

import { getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import { store } from "@/store/Store";
import {
  UserState,
  TeacherState,
  StudentOrGuardianState,
  GuardianState,
  StudentState,
} from "@/ts/objects/common/UserState";
import { Quarter } from "@/ts/objects/common/Quarter";
import log from "loglevel";
import clamp from "lodash/clamp";
import { formatDate } from "@/ts/objects/common/FormattedDatetime";
import { CurriculumTabName } from "@/ts/objects/curriculum/value/CurriculumTabName";
import { CurriculumPeriodMode } from "@/ts/objects/curriculum/value/CurriculumPeriodMode";
import { MonthValue } from "@/ts/objects/common/MonthValue";
import { School } from "@/ts/objects/common/School";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { Err } from "@/ts/objects/Err";
import { UserType } from "@/ts/objects/common/UserType";

export type RouteState = {
  readonly name: string;
  readonly path: string;
  readonly params: Record<string, string>;
  readonly query: Record<string, string>;
};

@Module({ dynamic: true, store, name: "appStateStore", namespaced: true })
export class AppStateStore extends VuexModule {
  /**
   * 本番環境ならtrue。
   */
  isProduction: boolean = false;

  userState: UserState | null = null;

  currentQuarter = new Quarter(1970, 1);

  now = formatDate(new Date());

  @MutationAction({ mutate: ["now"] })
  async updateNow() {
    return { now: formatDate(new Date()) };
  }

  school: School = { schoolName: "", logoGcsUrl: null };

  @MutationAction({ mutate: ["school"] })
  async updateSchool(userRepository: UserRepository) {
    const school = await userRepository.getSchool();
    if (school instanceof Err) return { school: { schoolName: "", logoGcsUrl: null } };
    return { school };
  }

  /**
   * 現在のURLのパス、パラメータ、クエリパラメータを返す。
   */
  get route(): RouteState {
    const routeModule = (store.state as any).route;
    const routeState = {
      name: routeModule.name,
      path: routeModule.path,
      params: routeModule.params,
      query: routeModule.query,
    };
    log.debug(`routeState=${JSON.stringify(routeState)}`);
    return routeState;
  }

  get appUserType(): UserType | null {
    return this.userState?.userType ?? null;
  }

  get isAppUserTeacher(): boolean {
    return this.userState?.isTeacher ?? false;
  }

  get isAppUserStudent(): boolean {
    return this.userState?.isStudent ?? false;
  }

  get isAppUserGuardian(): boolean {
    return this.userState?.isGuardian ?? false;
  }

  get teacherState(): TeacherState | null {
    const userState = this.userState;
    if (!(userState instanceof TeacherState)) return null;
    return userState;
  }

  get studentOrGuardianState(): StudentOrGuardianState | null {
    const userState = this.userState;
    if (!(userState instanceof StudentOrGuardianState)) return null; // TODO うまくいく？
    return userState;
  }

  get guardianState(): GuardianState | null {
    const userState = this.userState;
    if (!(userState instanceof GuardianState)) return null;
    return userState;
  }

  get teacherBasePath(): string {
    const userState = this.userState;
    if (userState instanceof TeacherState) {
      const selectedClassId = userState.selectedClass()?.id;
      return selectedClassId === undefined ? "/t" : `/t/${selectedClassId}`;
    } else {
      return "";
    }
  }

  get studentOrGuardianBasePath(): string {
    const userState = this.userState;
    if (userState instanceof StudentOrGuardianState) {
      const studentUserId = userState.studentUserId() ?? "-";
      return `/s/${studentUserId}`;
    } else {
      return "";
    }
  }

  get studentOrGuardianCurriculumDefaultPath(): string {
    const userState = this.userState;
    if (!(userState instanceof StudentOrGuardianState)) {
      return "";
    }

    const schoolYear = clamp(
      this.currentQuarter.schoolYear,
      userState.studentMinSchoolYear() ?? 1,
      userState.studentMaxSchoolYear() ?? 9999
    );

    if (userState.isStudent) {
      return `${this.studentOrGuardianBasePath}/curriculum/${schoolYear}/write/eeCurriculums/-`;
    } else {
      return `${this.studentOrGuardianBasePath}/curriculum/${schoolYear}/read/monthly/${this.now.month}/neCurriculums/-`;
    }
  }

  get studentOrGuardianCurriculumPath() {
    const _base = this.studentOrGuardianBasePath;

    return function(
      schoolYear: number | null,
      tab: CurriculumTabName,
      periodMode: CurriculumPeriodMode | null,
      month: MonthValue | null | undefined,
      resourceName: string | null
    ) {
      const _schoolYear = schoolYear ?? 2000;

      switch (tab) {
        case "write": {
          const _resourceName = resourceName ?? "/eeCurriculums/-";
          return `${_base}/curriculum/${_schoolYear}/${tab}${_resourceName}`;
        }
        case "read": {
          const _periodMode = periodMode ?? "-";
          const _month = month ?? "-";
          const _resourceName = resourceName ?? "/neCurriculums/-";
          return `${_base}/curriculum/${_schoolYear}/${tab}/${_periodMode}/${_month}${_resourceName}`;
        }
      }
      return _base;
    };
  }

  get teacherCurriculumDefaultPath(): string {
    const teacherState = this.teacherState;
    if (teacherState === null) return "";

    return `${this.teacherBasePath}/curriculum/list/neCurriculums/-`;
  }

  get needRuby(): boolean {
    const userState = this.userState;
    if (!(userState instanceof StudentState)) return false;

    const grade = userState.studentInfo()?.classOfSchoolYear(this.currentQuarter.schoolYear)?.grade;
    return grade !== undefined && grade.intValue <= 3;
  }

  @Mutation
  public setIsProduction(value: boolean) {
    this.isProduction = value;
  }

  @Mutation
  public setUserId(userId: string) {
    const userState = this.userState;
    if (userState === null) return;
    userState.userId = userId;
  }

  @Mutation
  public setAppUser(user: UserState | null) {
    this.userState = user;
  }

  @Mutation
  public setCurrentQuarter(value: Quarter) {
    this.currentQuarter = value;
  }
}

/**
 * AppStateStoreのグローバル変数。
 *
 * - 他のストアからの（タイプセーフな）アクセスを可能にするには、恐らくこの方法しかない。
 * - TypeScriptにはパッケージプライベートにあたるものが無い。
 *
 * というような理由により、グローバル変数として公開せざるを得ない。
 * が、本当に必要な場合以外は、コンポーネントプロパティとして受け渡した値からアクセスするようにすること。
 */
export const appStateStoreGlobal = getModule(AppStateStore);

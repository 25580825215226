













import Vue, { PropType } from "vue";
import { StudentClass } from "@/ts/objects/common/StudentClass";
import StudentClassText from "@/components/texts/StudentClassText.vue";

export default Vue.extend({
  name: "ProfileTableItemValueStudentClasses",
  components: { StudentClassText },
  props: {
    studentClasses: { type: Array as PropType<StudentClass[]>, required: false },
  },
});

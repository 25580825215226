











import Vue from "vue";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "CurriculumJournalHeaderRow",
  props: {
    /**
     * 月カラムの幅（px）。
     */
    monthColWidth: { type: Number, required: true },

    /**
     * メニューボタンカラムの幅（px）。
     */
    extraMenuColWidth: { type: Number, required: true },

    /**
     * その他のカラムの最小幅（px）。
     */
    otherColMinWidth: { type: Number, required: true },
  },
  data(): {
    words: Words;
  } {
    return { words };
  },
  computed: {
    styles(): any {
      return {
        "--monthColWidth": `${this.monthColWidth}px`,
        "--extraMenuColWidth": `${this.extraMenuColWidth}px`,
        "--otherColMinWidth": `${this.otherColMinWidth}px`,
      };
    },
  },
});

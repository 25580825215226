











































































































import Vue, { PropType } from "vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { solanProcessTextOf } from "@/ts/utils";
import { words, Words } from "@/ts/const/Words";
import RatingDropdown from "@/components/dropdowns/RatingDropdown.vue";
import { colors } from "@/ts/objects/common/ColorLabel";
import { messages, Messages } from "@/ts/const/Messages";
import { UserType } from "@/ts/objects/common/UserType";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import NotPublishedIndicator from "@/components/other/NotPublishedIndicator/NotPublishedIndicator.vue";
import { v4 as uuidv4 } from "uuid";
import JournalFileExpandButton from "@/components/journalfile/JournalFileExpandButton/JournalFileExpandButton.vue";

export default Vue.extend({
  name: "SolanJournalsTableRow",
  components: {
    JournalFileExpandButton,
    NotPublishedIndicator,
    JournalFilesView,
    RatingDropdown,
    DisableableAutoResizeTextarea,
  },
  props: {
    userType: { type: String as PropType<UserType>, required: false },
    journal: { type: Object as PropType<EditableSolanJournal>, required: true },

    isAssociatedFilesViewOpen: { type: Boolean, required: true },

    onBlur: { type: Function as PropType<() => void>, required: true },
    onFilesViewChange: { type: Function as PropType<(rubricId: string | null) => void>, required: true },

    /**
     * 学習記録ファイルをアップロードする関数。
     */
    uploadJournalFile: {
      type: Function as PropType<(file: File, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },
    /**
     * 学習記録ファイルを削除する関数。
     */
    deleteJournalFile: {
      type: Function as PropType<(file: JournalFile, journal: EditableSolanJournal) => Promise<void>>,
      required: true,
    },

    processColWidth: { type: Number, required: true },
    ratingColWidth: { type: Number, required: true },
  },
  data(): {
    process0FilesViewColor: string;
    process1FilesViewColor: string;
    process2FilesViewColor: string;
    process3FilesViewColor: string;

    journalFilesViewElementId: string;

    words: Words;
    messages: Messages;
  } {
    return {
      process0FilesViewColor: colors["solan-process-0-cell-bg-strong"],
      process1FilesViewColor: colors["solan-process-1-cell-bg-strong"],
      process2FilesViewColor: colors["solan-process-2-cell-bg-strong"],
      process3FilesViewColor: colors["solan-process-3-cell-bg-strong"],

      journalFilesViewElementId: uuidv4(),

      words,
      messages,
    };
  },
  computed: {
    permissions(): {
      journalFile: boolean;
      studentComment: boolean;
      studentRating: boolean;
      teacherRating: boolean;
      teacherComment: boolean;
    } {
      const studentInputLocked = this.journal.studentInputLocked;

      switch (this.userType) {
        case "teacher":
          return {
            journalFile: true,
            studentComment: true,
            studentRating: true,
            teacherRating: true,
            teacherComment: true,
          };
        case "student":
          return {
            journalFile: !studentInputLocked,
            studentComment: !studentInputLocked,
            studentRating: !studentInputLocked,
            teacherRating: false,
            teacherComment: false,
          };
        case "guardian":
          return {
            journalFile: !studentInputLocked,
            studentComment: !studentInputLocked,
            studentRating: !studentInputLocked,
            teacherRating: false,
            teacherComment: false,
          };
        default: {
          return {
            journalFile: false,
            studentComment: false,
            studentRating: false,
            teacherRating: false,
            teacherComment: false,
          };
        }
      }
    },

    styles(): Record<string, string> {
      return {
        "--process-0-files-view-color": this.process0FilesViewColor,
        "--process-1-files-view-color": this.process1FilesViewColor,
        "--process-2-files-view-color": this.process2FilesViewColor,
        "--process-3-files-view-color": this.process3FilesViewColor,

        "--process-col-width": `${this.processColWidth}px`,
        "--rating-col-width": `${this.ratingColWidth}px`,
      };
    },
  },
  methods: {
    _solanProcessTextOf(process: number): string {
      return solanProcessTextOf(process);
    },
    onFilesViewChangeInternal() {
      this.onFilesViewChange(this.isAssociatedFilesViewOpen ? null : this.journal.rubricId);
    },
    filesViewColorFor(process: number): string {
      switch (process) {
        case 0:
          return colors["solan-process-0-cell-bg-strong"];
        case 1:
          return colors["solan-process-1-cell-bg-strong"];
        case 2:
          return colors["solan-process-2-cell-bg-strong"];
        case 3:
          return colors["solan-process-3-cell-bg-strong"];
        default:
          return "#ffffff";
      }
    },
  },
});


















import Vue, { PropType } from "vue";
import { MessageViewParam } from "@/components/MessageView.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { messages } from "@/ts/const/Messages";
import { Err } from "@/ts/objects/Err";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import firebase from "firebase/app";
import "firebase/storage";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { SolanStoreS } from "@/store/SolanStore";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import SolanJournalsPure from "@/views/solan/student/SolanJournals/SolanJournalsPure.vue";
import { SolanJournalFile } from "@/ts/objects/solan/value/SolanJournalFile";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { AppStateStore } from "@/store/AppStateStore";

export default Vue.extend({
  name: "SolanJournals",
  components: {
    SolanJournalsPure,
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    solanStoreS: { type: Object as PropType<SolanStoreS>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true },
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.storage = this.$storage ?? null;
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
    });
    this.reloadData();
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      this.reloadData();
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;
    storage: firebase.storage.Storage | null;

    journals: EditableSolanJournal[] | null;

    filesViewJournal: EditableSolanJournal | null;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,
      storage: null,

      journals: null,

      filesViewJournal: null,

      pageLeaveService: null,
    };
  },
  computed: {
    needSave(): boolean {
      const journals = this.journals;
      if (journals === null) return false;
      return journals.some(j => j.needSave());
    },
  },
  methods: {
    async reloadData() {
      this.journals = null;
      this.messageView = null;

      const project = await this.solanStoreS.project.getDataWithTimeout();
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      if (!project.started) {
        this.messageView = { message: messages.solanThemeNotStarted };
        return;
      }

      const resp = await this.solanRepository.listEditableSolanJournals(project.projectId, false, true);
      if (resp instanceof Err) {
        log.error("Error loading project or journals!");
        this.messageView = { message: messages.failedToLoadData };
        return;
      }

      this.journals = resp.editableJournals;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      const journals = this.journals;
      if (journals === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(journals.map(j => j.saveAllChanges(force))));
    },
    setFilesView(rubricId: string | null) {
      const journals = this.journals;
      if (rubricId === null || journals === null) {
        this.filesViewJournal = null;
        return;
      }
      const journal = journals.find(j => j.rubricId === rubricId);
      if (journal === undefined) {
        this.filesViewJournal = null;
        return;
      }
      this.filesViewJournal = journal;
    },
    isMyFilesViewOpen(rubricId: string): boolean {
      const filesViewJournal = this.filesViewJournal;
      if (filesViewJournal === null) return false;
      return filesViewJournal.rubricId === rubricId;
    },
    async uploadJournalFile(file: File, journal: EditableSolanJournal) {
      const resp = await this.solanRepository.postJournalFile(journal.projectId, journal.rubricId, file, 30000);
      log.debug(`uploadFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    async deleteJournalFile(journalFile: JournalFile, journal: EditableSolanJournal): Promise<void> {
      if (!(journalFile instanceof SolanJournalFile)) return;

      const resp = await this.solanRepository.deleteJournalFile(
        journalFile.projectId,
        journalFile.rubricId,
        journalFile.journalFileId
      );
      log.debug(`deleteFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
  },
});

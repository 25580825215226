



























































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { ProjectRubric } from "@/ts/objects/project/value/ProjectRubric";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { Project } from "@/ts/objects/project/value/Project";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "ProjectRubricsPure",
  components: { LoadingBlock, MessageView },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },

    project: { type: Object as PropType<Project | null> },
    rubrics: { type: Array as PropType<ProjectRubric[] | null> },
  },
  data(): {
    words: Words;
  } {
    return { words };
  },
});

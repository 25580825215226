











import Vue from "vue";
import ProfileInnerHeaderUpper from "@/views/profile/components/ProfileInnerHeaderUpper/ProfileInnerHeaderUpper.vue";
import ProfileInnerHeaderItem from "@/views/profile/components/ProfileInnerHeaderItem/ProfileInnerHeaderItem.vue";
import { words, Words } from "@/ts/const/Words";

export default Vue.extend({
  name: "ProfileStudentHeader",
  components: { ProfileInnerHeaderItem, ProfileInnerHeaderUpper },
  props: {
    googleMail: { type: String, required: true },
    photoUrl: { type: String, required: false },
    name: { type: String, required: true },
    gradeNumber: { type: Number, required: false },
    className: { type: String, required: false },
    studentNumber: { type: Number, required: false },
  },
  data(): { words: Words } {
    return { words };
  },
});

export type ColumnType = {
  readonly category: "string" | "date" | "image" | "student-classes";
};

// admin-appと違い、全部nullableとして扱う。

export const StringType: ColumnType = {
  category: "string",
};

export const DateType: ColumnType = {
  category: "date",
};

export const ImageType: ColumnType = {
  category: "image",
};

export const StudentClassesType: ColumnType = {
  category: "student-classes",
};

import numeral from "numeral";
import clamp from "lodash/clamp";

export class Quarter {
  readonly schoolYear: number;
  readonly quarter: number;

  readonly quarterIndicator: string;
  readonly isValid: boolean;

  readonly prevSchoolYear: number;
  readonly prevQuarter: number;

  readonly nextSchoolYear: number;
  readonly nextQuarter: number;

  constructor(schoolYear: number, quarter: number) {
    this.schoolYear = schoolYear;
    this.quarter = quarter;

    this.quarterIndicator = numeral.locales.en.ordinal(quarter);
    this.isValid =
      Number.isInteger(this.schoolYear) &&
      1 <= this.schoolYear &&
      this.schoolYear <= 9999 &&
      Number.isInteger(this.quarter) &&
      1 <= this.quarter &&
      this.quarter <= 4;

    if (2 <= this.quarter && this.quarter <= 4) {
      this.prevSchoolYear = schoolYear;
      this.prevQuarter = quarter - 1;
    } else {
      this.prevSchoolYear = clamp(schoolYear - 1, 1, 9999);
      this.prevQuarter = 4;
    }

    if (1 <= this.quarter && this.quarter <= 3) {
      this.nextSchoolYear = schoolYear;
      this.nextQuarter = quarter + 1;
    } else {
      this.nextSchoolYear = clamp(schoolYear + 1, 1, 9999);
      this.nextQuarter = 1;
    }
  }

  quarterWithIndicator(): string {
    return `${this.quarter}${this.quarterIndicator}`;
  }

  /**
   * 年度の最小値を指定して、1個前のquarterを取得する。
   *
   * 本quarterが最小年度未満か、最小年度の1st quarterである場合は、最小年度の1st quarterを返す。
   * それ以外の場合、普通に1個前のquarterを返す。
   *
   * @param minSchoolYear
   */
  getPrevQuarterUnderMinSchoolYear(minSchoolYear: number): Quarter {
    if (this.schoolYear < minSchoolYear || (this.schoolYear === minSchoolYear && this.quarter === 1))
      return new Quarter(minSchoolYear, 1);
    return new Quarter(this.prevSchoolYear, this.prevQuarter);
  }

  /**
   * 年度の最大値を指定して、1個後のquarterを取得する。
   *
   * 本quarterが最大年度より大きいか、最大年度の4th quarterである場合は、最大年度の4th quarterを返す。
   * それ以外の場合、普通に1個後のquarterを返す。
   *
   * @param maxSchoolYear
   */
  getNextQuarterUnderMaxSchoolYear(maxSchoolYear: number): Quarter {
    if (this.schoolYear > maxSchoolYear || (this.schoolYear === maxSchoolYear && this.quarter === 4))
      return new Quarter(maxSchoolYear, 4);
    return new Quarter(this.nextSchoolYear, this.nextQuarter);
  }
}

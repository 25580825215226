import isEqual from "lodash/isEqual";
import { Err } from "@/ts/objects/Err";
import { EditableValue } from "@/ts/objects/editable/value/EditableValue";
import log from "loglevel";

export abstract class EditableArray<T> extends EditableValue {
  readonly name: string;
  private _lastRequestValue: T[];
  private _value: T[];
  private _savedValue: T[];
  private readonly _saver: (value: T[]) => Promise<T[] | Err>;

  get value(): T[] {
    return this._value;
  }

  set value(v: T[]) {
    this._value = v;
  }

  protected _needSave(): boolean {
    const needSave = !isEqual(this._value, this._savedValue);
    if (needSave)
      log.debug(`${this.name}: needs save: ${JSON.stringify(this._savedValue)} -> ${JSON.stringify(this._value)}`);
    return needSave;
  }

  protected async _save(): Promise<Err | null> {
    const resp = await this._saver(this._value);
    if (resp instanceof Err) return resp;
    this._savedValue = resp;
    return null;
  }

  protected _changedAfterLastRequest(): boolean {
    return !isEqual(this._value, this._lastRequestValue);
  }

  protected constructor(name: string, value: T[], savable: boolean, saver: (value: T[]) => Promise<T[] | Err>) {
    super(savable);
    this.name = name;
    this._value = value;
    this._lastRequestValue = value;
    this._savedValue = value;
    this._saver = saver;
  }
}

export class EditableStringArray extends EditableArray<string> {
  constructor(name: string, value: string[], savable: boolean, saver: (value: string[]) => Promise<string[] | Err>) {
    super(name, value, savable, saver);
  }
}

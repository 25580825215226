














import Vue, { PropType } from "vue";
import SolanHeader from "@/views/solan/student/SolanHeader/SolanHeader.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { AppStateStore } from "@/store/AppStateStore";
import { SolanStoreS } from "@/store/SolanStore";
import { DataSlotState } from "@/ts/DataSlot";
import { SolanProjectInfoOnEditStore } from "@/store/SolanProjectOnEditStore";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import log from "loglevel";

export default Vue.extend({
  name: "Solan",
  components: { SolanHeader },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    solanStoreS: { type: Object as PropType<SolanStoreS>, required: true },
    solanProjectInfoOnEditStore: { type: Object as PropType<SolanProjectInfoOnEditStore>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true },
  },
  created() {
    log.debug(`Solan:created`);

    const studentUserId = this.appStateStore.studentOrGuardianState?.studentInfo()?.studentUserId ?? null;
    if (studentUserId === null) return;

    const schoolYearParam = this.$route.params["solanSchoolYear"];
    this.solanStoreS.setSchoolYear(parseInt(schoolYearParam, 10));

    const projectIdParam = this.$route.params["solanProjectId"];
    this.solanStoreS.setProjectId(projectIdParam);

    this.solanStoreS.reloadProjects({ solanRepository: this.solanRepository, studentUserId });
  },
  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    log.debug(`Solan:beforeRouteUpdate`);

    const schoolYearParam = to.params["solanSchoolYear"];
    this.solanStoreS.setSchoolYear(parseInt(schoolYearParam, 10));

    const projectIdParam = to.params["solanProjectId"];

    if (projectIdParam === "init") {
      const firstProjectId = await this.solanStoreS.firstProjectIdInSchoolYear.getDataWithTimeout();
      this.solanStoreS.setProjectId(firstProjectId);
      next(this.solanStoreS.studentBasePath);
    } else {
      this.solanStoreS.setProjectId(projectIdParam);
      next();
    }
  },
  computed: {
    solanStoreDataSlotState(): DataSlotState {
      return this.solanStoreS.allProjects.state;
    },
    projectId(): string {
      return this.solanStoreS.project.data?.projectId ?? "";
    },
  },
  watch: {
    async solanStoreDataSlotState(newValue: DataSlotState, oldValue: DataSlotState) {
      // プロジェクトリストのロードが終わったとき、プロジェクト未選択状態なら、1個目のプロジェクトを選択して、そのパスに遷移する。
      // ※ initに遷移すれば beforeRouteUpdate が拾ってくれるかと思いきや、パスが全く変化しない場合は発火しないので、うまく働かない。ので、この方式。
      const currentlySelectedProject = await this.solanStoreS.project.getDataWithTimeout();
      const firstProjectId = await this.solanStoreS.firstProjectIdInSchoolYear.getDataWithTimeout();
      if (
        oldValue === DataSlotState.Loading &&
        newValue === DataSlotState.DataLoaded &&
        currentlySelectedProject === null
      ) {
        this.solanStoreS.setProjectId(firstProjectId);
        this.$router.push(this.solanStoreS.studentBasePath).catch(() => {});
      }
    },
  },
});

import {
  EditableBoolean,
  EditableHashedString,
  EditableString,
} from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { DisplayableErr, Err } from "@/ts/objects/Err";
import { projectJournalRnameToId } from "@/ts/utils/AppUtil";
import { ProjectJournalFile } from "@/ts/objects/project/value/ProjectJournalFile";
import { messages } from "@/ts/const/Messages";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

/**
 * 編集可能なプロジェクト学習記録。
 *
 * リソース名は、 /projects/{projectId}/rubrics/{rubricId}/journals/{journalId}。
 */
export class EditableProjectJournal extends EditableObject {
  private readonly repo: ProjectRepository;

  readonly self: string;
  readonly projectId: string;
  readonly rubricId: string;
  readonly journalId: string;
  readonly rubric: string;
  readonly rubricLearningActivity: string;
  readonly studentUserId: string;
  private _journalFiles: ProjectJournalFile[];
  readonly _studentComment: EditableHashedString;
  readonly _studentRating: EditableString;
  readonly _teacherComment: EditableHashedString;
  readonly _teacherRating: EditableString;
  readonly _teacherInputPublished: EditableBoolean;
  readonly studentInputLocked: boolean;

  constructor(
    repo: ProjectRepository,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    self: string,
    rubric: string,
    rubricLearningActivity: string,
    studentUserId: string,
    journalFiles: ProjectJournalFile[],
    studentComment: string,
    studentCommentHash: string,
    studentRating: string,
    teacherComment: string,
    teacherCommentHash: string,
    teacherRating: string,
    teacherInputPublished: boolean,
    studentInputLocked: boolean
  ) {
    super();
    this.repo = repo;

    this.self = self;
    this.rubric = rubric;
    this.rubricLearningActivity = rubricLearningActivity;
    const [projectId, rubricId, journalId] = projectJournalRnameToId(self) ?? ["unknown", "unknown", "unknown"]; // TODO これどうにかする？
    this.projectId = projectId;
    this.rubricId = rubricId;
    this.journalId = journalId;
    this.studentUserId = studentUserId;
    this._journalFiles = journalFiles;
    this._studentComment = new EditableHashedString(
      "studentComment",
      studentComment,
      studentCommentHash,
      studentInputSavable,
      async ({ value, hash }) => {
        const resp = await repo.patchJournal(projectId, rubricId, journalId, {
          studentComment: {
            value: value,
            hash: hash,
          },
        });
        if (resp instanceof Err) return resp;
        return [resp.studentComment.value, resp.studentComment.hash];
      }
    );
    this._studentRating = new EditableString("studentRating", studentRating, studentInputSavable, async value => {
      const resp = await repo.patchJournal(projectId, rubricId, journalId, {
        studentRating: value,
      });
      if (resp instanceof Err) return resp;
      return resp.studentRating;
    });
    this._teacherComment = new EditableHashedString(
      "teacherComment",
      teacherComment,
      teacherCommentHash,
      teacherInputSavable,
      async ({ value, hash }) => {
        const resp = await repo.patchJournal(projectId, rubricId, journalId, {
          teacherComment: {
            value: value,
            hash: hash,
          },
        });
        if (resp instanceof Err) return resp;
        if (resp.teacherComment === undefined)
          return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
        return [resp.teacherComment.value, resp.teacherComment.hash];
      }
    );
    this._teacherRating = new EditableString("teacherRating", teacherRating, teacherInputSavable, async value => {
      const resp = await repo.patchJournal(projectId, rubricId, journalId, {
        teacherRating: value,
      });
      if (resp instanceof Err) return resp;
      if (resp.teacherRating === undefined)
        return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
      return resp.teacherRating;
    });
    this._teacherInputPublished = new EditableBoolean(
      "teacherInputPublished",
      teacherInputPublished,
      teacherInputSavable,
      async value => {
        const resp = await repo.patchJournal(projectId, rubricId, journalId, {
          teacherInputPublished: value,
        });
        if (resp instanceof Err) return resp;
        return resp.teacherInputPublished;
      }
    );
    this.studentInputLocked = studentInputLocked;
  }

  protected allEditables() {
    return [
      this._studentComment,
      this._studentRating,
      this._teacherComment,
      this._teacherRating,
      this._teacherInputPublished,
    ];
  }

  get journalFiles(): ProjectJournalFile[] {
    return this._journalFiles;
  }

  // TODO エラーが発生したら、currentErrorとして追加すべきだろう。
  async reloadJournalFiles(): Promise<void> {
    const result = await this.repo.listJournalFiles(this.self);
    if (result instanceof Err) return;
    this._journalFiles = result;
  }

  get studentComment(): string {
    return this._studentComment.value;
  }

  set studentComment(value: string) {
    this._studentComment.value = value;
  }

  get studentRating(): string {
    return this._studentRating.value;
  }

  set studentRating(value: string) {
    this._studentRating.value = value;
  }

  get teacherRating(): string {
    return this._teacherRating.value;
  }

  set teacherRating(value: string) {
    this._teacherRating.value = value;
  }

  get teacherInputPublished(): boolean {
    return this._teacherInputPublished.value;
  }

  set teacherInputPublished(value: boolean) {
    this._teacherInputPublished.value = value;
  }

  get teacherComment(): string {
    return this._teacherComment.value;
  }

  set teacherComment(value: string) {
    this._teacherComment.value = value;
  }
}

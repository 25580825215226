





























import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { ClassStudent } from "@/ts/objects/common/Class";
import { AppStateStore, appStateStoreGlobal } from "@/store/AppStateStore";
import { SolanStoreT } from "@/store/SolanStore";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { DataSlotState } from "@/ts/DataSlot";
import log from "loglevel";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { words, Words } from "@/ts/const/Words";
import { messages, Messages } from "@/ts/const/Messages";
import StudentListPane from "@/components/other/StudentListPane/StudentListPane.vue";
import SolanStudentViewHeader from "@/views/solan/teacher/SolanStudentViewT/components/SolanStudentViewHeader/SolanStudentViewHeader.vue";
import { SolanStudentViewTPathSegment } from "@/views/solan/teacher/SolanStudentViewT/SolanStudentViewTPathSegment";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "SolanStudentViewT",
  components: { SolanStudentViewHeader, StudentListPane, MessageView },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    solanStoreT: { type: Object as PropType<SolanStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true },
  },
  created() {
    this.solanStoreT.resetState();
  },
  beforeDestroy() {
    this.solanStoreT.resetState();
  },
  data(): {
    messageView: MessageViewParam | null;

    words: Words;
    messages: Messages;
  } {
    return {
      messageView: null,

      words,
      messages,
    };
  },
  computed: {
    classSchoolYear(): number {
      return this.appStateStore.teacherState?.selectedClass()?.schoolYear ?? 2000;
    },
    selectedStudentUserId(): string | null {
      const id: string | null | undefined = this.$route.params["solanStudentUserId"];
      if (isNullish(id) || id.length === 0) return null;
      return id;
    },
    selectedProjectId(): string | null {
      const id: string | null | undefined = this.$route.params["solanProjectId"];
      if (isNullish(id) || id.length === 0) return null;
      return id;
    },
    selectedTab(): SolanStudentViewTPathSegment | null {
      switch (this.$route.name) {
        case "T.Solan.StudentView.Journals":
          return "journals";
        case "T.Solan.StudentView.Rubrics":
          return "rubrics";
        case "T.Solan.StudentView.About":
          return "about";
        case "T.Solan.StudentView.Lookback":
          return "lookback";
        default:
          return null;
      }
    },
    solanStoreDataSlotState(): DataSlotState {
      return this.solanStoreT.allProjects.state;
    },
  },
  asyncComputed: {
    async selectedStudent(): Promise<ClassStudent | null> {
      const studentUserId = this.selectedStudentUserId;
      if (studentUserId === null) return null;
      return this.appStateStore.teacherState?.studentOfSelectedClass(this.userRepository, studentUserId) ?? null;
    },
    async selectedProject(): Promise<SolanProject | null> {
      const projectId = this.selectedProjectId;
      if (projectId === null) return null;
      return await this.solanStoreT.getProject(projectId).getDataWithTimeout();
    },
    async classStudents(): Promise<ClassStudent[]> {
      return this.appStateStore.teacherState?.studentsOfSelectedClass(this.userRepository) ?? [];
    },
    async selectableProjects(): Promise<SolanProject[]> {
      const allProjects = await this.solanStoreT.allProjects.getDataWithTimeout();
      if (isNullish(allProjects)) return [];
      return allProjects;
    },
  },
  watch: {
    selectedStudentUserId: {
      handler: function(newValue: string, oldValue: string) {
        log.debug(`watch:selectedStudentUserId: oldValue=${oldValue}, newValue=${newValue}`);
        if (newValue !== oldValue) {
          this.solanStoreT.reloadProjectsIfStudentChanged({
            userRepository: this.userRepository,
            solanRepository: this.solanRepository,
            studentUserId: newValue,
          });
        }
      },
      immediate: true,
    },
    solanStoreDataSlotState(newValue: DataSlotState, oldValue: DataSlotState) {
      // プロジェクトリストのロードが終わったとき、プロジェクト未指定状態なら、最初のプロジェクトのパスに遷移する。
      const project = this.solanStoreT.getProject(this.selectedProjectId).data;
      const firstProjectPath = this.solanStoreT.firstProjectPath.data;
      log.debug(
        `watch:solanStoreDataSlotState: oldValue=${oldValue}, newValue=${newValue}, project=${project?.projectId}`
      );
      if (
        oldValue === DataSlotState.Loading &&
        newValue === DataSlotState.DataLoaded &&
        project === null &&
        firstProjectPath !== null
      ) {
        this.$router.push(firstProjectPath).catch(() => {});
      }
    },
  },
  methods: {
    toProjectPath(studentUserId: string, projectId: string): string {
      return `${this.appStateStore.teacherBasePath}/solan/studentView/${studentUserId}/${projectId}`;
    },
  },
});

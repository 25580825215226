








import Vue from "vue";

export default Vue.extend({
  name: "ProfileInnerHeaderItem",
  props: {
    name: { type: String, required: true },
    value: { type: [String, Number], required: false },
  },
});

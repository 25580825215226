






import Vue from "vue";

export default Vue.extend({
  name: "ProfileTableItemValueText",
  props: {
    value: { type: String, required: false },
  },
});

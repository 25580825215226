























import ClassModalButton from "./ClassModalButton/ClassModalButton.vue";
import Vue, { PropType } from "vue";
import { Class } from "@/ts/objects/common/Class";
import { SchoolType, schoolTypeToDisplayValue } from "@/ts/objects/common/SchoolType";

export default Vue.extend({
  name: "ClassSelector",
  components: { ClassModalButton },
  props: {
    selectedClass: { type: Object as PropType<Class>, required: true },
    prevClassId: { type: String, required: true },
    nextClassId: { type: String, required: true },
    groupedClassSelections: { type: Array as PropType<{ schoolYear: number; selections: Class[] }[]>, required: false },
  },
  computed: {
    classSchoolYear(): number {
      return this.selectedClass.schoolYear;
    },
  },
  methods: {
    schoolTypeText(schoolType: SchoolType): string {
      return schoolTypeToDisplayValue(schoolType);
    },
  },
});

import { parseUTCOrZero } from "@/ts/utils/AppUtil";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import {
  JournalStudent as JournalStudentResp,
  Journal as JournalResp,
  JournalFile as JournalFileResp,
} from "@/ts/api/curriculum-service";
import { IEECJournal, IEECJournalTree } from "@/ts/objects/curriculum/IEECJournal";
import { isMonthValue, MonthValue } from "@/ts/objects/common/MonthValue";

/**
 * 文書評価教科の学習活動記録を持つ児童生徒。
 *
 * リソース名は /eeCurriculums/{eecId}/journalStudents/{studentUserId}。
 *
 * 文書評価教科 : 学習活動記録児童生徒  = 1 : n
 */
export class EECJournalStudent {
  /**
   * コンストラクタ。
   *
   * @param eecId 文書評価教科ID。
   * @param studentUserId 児童生徒ID。
   */
  constructor(public readonly eecId: string, public readonly studentUserId: string) {}

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/journalStudents/${this.studentUserId}`;
  }

  static fromJournalStudentResp(resp: JournalStudentResp): EECJournalStudent {
    return new EECJournalStudent(resp.eecId, resp.studentUserId);
  }
}

export class EECJournalStudentTree {
  constructor(public readonly self: EECJournalStudent, public readonly journals: EECJournalTree[]) {}
}

/**
 * 文書評価教科の学習活動記録。
 *
 * リソース名は /eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}。
 *
 * 学習活動記録児童生徒 : 学習活動記録  = 1 : n
 */
export class EECJournal implements IEECJournal {
  /**
   * コンストラクタ。
   *
   * @param eecId 文書評価教科ID。
   * @param studentUserId 児童生徒ID。
   * @param journalId 学習記録ID。
   * @param month
   * @param activity
   * @param studentComment
   * @param teacherComment
   * @param teacherInputPublished
   * @param createdAt
   * @param studentInputLocked
   */
  constructor(
    public readonly eecId: string,
    public readonly studentUserId: string,
    public readonly journalId: string,
    public readonly month: MonthValue,
    public readonly activity: string,
    public readonly studentComment: string,
    public readonly teacherComment: string,
    public readonly teacherInputPublished: boolean,
    createdAt: string,
    public readonly studentInputLocked: boolean
  ) {
    this.createdAt = parseUTCOrZero(createdAt);
  }

  readonly createdAt: Date;

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/journalStudents/${this.studentUserId}/journals/${this.journalId}`;
  }

  static fromJournalResp(resp: JournalResp): EECJournal {
    if (!isMonthValue(resp.month))
      throw new Error(`EECJournal.fromJournalResp: invalid month value on ${resp.self}: ${resp.month}`);
    return new EECJournal(
      resp.eecId,
      resp.studentUserId,
      resp.journalId,
      resp.month,
      resp.activity.value,
      resp.studentComment.value,
      resp.teacherComment?.value ?? "",
      resp.teacherInputPublished,
      resp.createdAt,
      resp.studentInputLocked
    );
  }
}

export class EECJournalTree implements IEECJournalTree {
  constructor(public readonly self: EECJournal, public readonly journalFiles: EECJournalFileTree[]) {}
}

/**
 * 文書評価教科の学習活動記録ファイル。
 *
 * リソース名は /eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}/journalFiles/{journalFileId}。
 *
 * 学習活動記録 : 学習活動記録ファイル  = 1 : n
 */
export class EECJournalFile extends JournalFile {
  constructor(
    public readonly eecId: string,
    public readonly studentUserId: string,
    public readonly journalId: string,
    public readonly journalFileId: string,
    public readonly type: string,
    public readonly subtype: string,
    public readonly mediaType: string,
    public readonly filename: string,
    public readonly ext: string,
    public readonly gcsObjectPath: string,
    public readonly thumbnailGcsObjectPath: string | null,
    public readonly hasThumbnail: boolean,
    public readonly width: number | null,
    public readonly height: number | null,
    createdAt: string,
    updatedAt: string
  ) {
    super(hasThumbnail, gcsObjectPath, thumbnailGcsObjectPath);
    this.createdAt = parseUTCOrZero(createdAt);
    this.updatedAt = parseUTCOrZero(updatedAt);
  }

  readonly createdAt: Date;
  readonly updatedAt: Date;

  static fromJournalFileResp(resp: JournalFileResp): EECJournalFile {
    return new EECJournalFile(
      resp.eecId,
      resp.studentUserId,
      resp.journalId,
      resp.journalFileId,
      resp.type,
      resp.subtype,
      resp.mediaType,
      resp.filename,
      resp.ext,
      resp.gcsObjectPath,
      resp.thumbnailGcsObjectPath,
      resp.hasThumbnail,
      resp.width ?? null,
      resp.height ?? null,
      resp.createdAt,
      resp.updatedAt
    );
  }
}

export class EECJournalFileTree {
  constructor(public readonly self: EECJournalFile) {}
}

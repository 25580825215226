import { Grade } from "@/ts/objects/common/Grade";
import { Curriculum } from "@/ts/objects/curriculum/value/Curriculum";
import { NECurriculum as NECurriculumResp } from "@/ts/api/curriculum-service";
import { NECViewPointTree } from "@/ts/objects/curriculum/value/NECViewPoint";

/**
 * 数値評価教科。
 *
 * リソース名は /neCurriculums/{necId}。
 *
 * NEC = Numerically-Evaluated Curriculumの略とする。
 */
export class NECurriculum implements Curriculum {
  constructor(
    public readonly necId: string,
    public readonly schoolYear: number,
    public readonly grade: Grade,
    public readonly name: string,
    public syllabusFileGcsObjectPath: string | null
  ) {}

  get resourceName(): string {
    return `/neCurriculums/${this.necId}`;
  }

  static fromNECurriculumResp(resp: NECurriculumResp): NECurriculum {
    return new NECurriculum(
      resp.necId,
      resp.schoolYear,
      new Grade(resp.grade),
      resp.name.value,
      resp.syllabusFileGcsObjectPath ?? null
    );
  }
}

export class NECurriculumTree {
  constructor(public readonly self: NECurriculum, public readonly viewPoints: NECViewPointTree[]) {}
}
